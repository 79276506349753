import { Message } from "controls";

import usePushSettings from "./usePushSettings";

export default function PushPromoMessage() {
  const pushSettings = usePushSettings();
  if (pushSettings.isEnabled || !pushSettings.support) return null;

  return (
    <Message type="warning">
      您的浏览器支持推送但您尚未开启，您可以
      <a href="#" onClick={pushSettings.enable}>
        开启浏览器推送
      </a>
      便于接受新消息和通知
    </Message>
  );
}
