import { gql } from "@apollo/client";
import { defer, uniq } from "lodash-es";

import { teamRoute } from "helpers/routes";
import apolloClient from "helpers/apolloClient";

import useProgressBar from "./useProgressBar";
import useRoute from "./useRoute";
import useHistory from "./useHistory";

export default function useOpenChat() {
  const history = useHistory();
  const progressBar = useProgressBar();
  const { teamId } = useRoute(teamRoute);

  return ({ userIds, projectId }) =>
    progressBar(async () => {
      let chat;
      if (userIds) {
        const { data } = await apolloClient.query({
          query: gql`
            query useOpenChat($teamId: ID!, $userIds: [ID!]!) {
              team(id: $teamId) {
                id
                chatForUsers(userIds: $userIds) {
                  id
                }
              }
            }
          `,
          variables: { teamId, userIds: uniq(userIds) },
        });
        chat = data.team.chatForUsers;
      }

      if (projectId) {
        const { data } = await apolloClient.query({
          query: gql`
            query useOpenChat($projectId: ID!) {
              project(id: $projectId) {
                id
                chat {
                  id
                }
              }
            }
          `,
          variables: { projectId },
        });
        chat = data.project.chat;
      }

      if (!chat) return;

      defer(() =>
        apolloClient.query({
          fetchPolicy: "network-only",
          query: gql`
            query useOpenChat($teamId: ID!) {
              team(id: $teamId) {
                id
                chats {
                  id
                }
              }
            }
          `,
          variables: { teamId },
        }),
      );

      history.push(`/${teamId}/${chat.id}`);
    });
}
