import React from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";

import { UI_FONT_FAMILIES } from "helpers/fontFamilies";

const theme = createTheme({
  palette: {
    primary: { main: "#718B90" },
    secondary: { main: "#bf360c" },
  },
  typography: {
    fontSize: 14,
    fontFamily: UI_FONT_FAMILIES.join(","),
    h1: {
      fontSize: 28,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 22,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
  },
});

export default function MUIProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
