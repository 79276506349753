import { defer } from "lodash-es";

import UserError from "./UserError";

export default async function retry(
  func,
  {
    //
    maxRetries = 60,
    shouldRetry = (e) => !e.isAbortError,
  } = {},
) {
  let retries = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      return await func();
    } catch (e) {
      if (!shouldRetry(e)) throw e;

      if (retries >= maxRetries) throw e;

      defer(() => {
        throw new UserError(`错误(${e.message}),正在进行重试...`);
      });
      retries += 1;
    }
  }
}
