import React from "react";

import { AppMultiSelect } from "controls/AppSelect";

Object.assign(MultiDropdownFilter, {
  defaultValue: [],
  serialize: (v) => [...v].sort().join(","),
  deserialize: (v) => v.split(",").filter((s) => s),
});

export default function MultiDropdownFilter({
  label,
  options,
  value,
  valueSet,
}) {
  return (
    <AppMultiSelect
      size="small"
      style={{ minWidth: 150, margin: 5 }}
      label={label}
      values={value}
      onChange={valueSet}
      options={options}
    />
  );
}
