import React from "react";

import TextArea from "../TextArea";

FieldTextLong.parseString = (str) => str;
FieldTextLong.toFormData = (value) => ({ content: value });

export default function FieldTextLong({
  field,
  documentContentMode,
  updateField,
  ...others
}) {
  return (
    <TextArea
      {...others}
      multiline
      readOnly={documentContentMode !== "fill"}
      value={field.content}
      onChange={(content) =>
        updateField({
          input: {
            fieldId: field.id,
            content,
          },
        })
      }
    />
  );
}
