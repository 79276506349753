import React, { useEffect } from "react";

import OutlinedBox from "controls/OutlinedBox";

export default function ComponentInput({
  label,
  value,
  onChange,
  helperText,
  name,
  error,
  readOnly,
  component,
  componentValue = null,
  ...others
}) {
  useEffect(() => {
    if (value !== componentValue) onChange(componentValue);
  }, [componentValue]);

  return (
    <OutlinedBox size="small" fullWidth label={label} {...others}>
      <div style={{ minHeight: 35 }}>{component}</div>
    </OutlinedBox>
  );
}
