import React, { useId, useState } from "react";
import { CloseCircle } from "mdi-material-ui";
import { Collapse, TextField, InputAdornment, IconButton } from "@mui/material";

import DebouncedInput from "controls/DebouncedInput";

export default function FilterBar({
  searchTerm,
  searchTermSet,
  filters,
  enableSearchTerm,
  searchTermAutofocus,
  ...others
}) {
  const id = useId();
  const [focused, focusedSet] = useState(false);
  const [hover, hoverSet] = useState(false);
  const expanded =
    !enableSearchTerm ||
    focused ||
    hover ||
    !!filters.find((f) => f.enabled) ||
    !filters.length;

  return (
    <div
      {...others}
      tabIndex={0}
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        position: "relative",
        ...others.style,
      }}
      onFocus={() => focusedSet(true)}
      onBlur={() => focusedSet(false)}
      onMouseEnter={() => hoverSet(true)}
      onMouseLeave={() => hoverSet(false)}
    >
      {enableSearchTerm && (
        <DebouncedInput
          component={TextField}
          id={`${id}-searchTerm`}
          size="small"
          style={{ flex: "1 0 auto", margin: 5 }}
          label="按关键字筛选"
          value={searchTerm}
          onChange={(searchTerm) => searchTermSet(searchTerm)}
          InputProps={{
            autoFocus: searchTermAutofocus,
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => searchTermSet("")}
                  edge="end"
                  title="清除关键字"
                >
                  <CloseCircle />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Collapse in={expanded}>
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
          }}
        >
          {filters.map((filter) => (
            <filter.component {...filter} key={filter.name} />
          ))}
        </div>
      </Collapse>
    </div>
  );
}
