import React from "react";
import { ViewGrid, ViewList, CardText } from "mdi-material-ui";
import { sortBy } from "lodash-es";

import CardsList from "controls/CardsList";

import useModeSwitcher from "./useModeSwitcher";

export default function useList(
  type,
  {
    rows,
    rowsCount,
    fetchMore,
    card,
    table,
    grid,
    defaultMode,
    cardsList = <CardsList />,
  },
) {
  const [listMode, listModeSwitcher] = useModeSwitcher(
    `${type}_list_mode`,
    sortBy(
      [
        card && {
          name: "cards",
          title: "详情",
          icon: <CardText />,
        },
        table && {
          name: "table",
          title: "列表",
          icon: <ViewList />,
        },
        grid && {
          name: "grid",
          title: "缩略图",
          icon: <ViewGrid />,
        },
      ].filter((m) => m),
      (m) => m.name !== defaultMode,
    ),
    { remember: true },
  );

  const list = (
    <>
      {listMode === "cards" &&
        React.cloneElement(cardsList, {
          rows,
          rowsCount,
          fetchMore,
          cardComponent: card,
          cardPropName: type,
        })}
      {listMode === "table" &&
        React.cloneElement(table, {
          rows,
          rowsCount,
          fetchMore,
        })}
      {listMode === "grid" &&
        React.cloneElement(grid, {
          rows,
          rowsCount,
          fetchMore,
        })}
    </>
  );

  return [list, listModeSwitcher];
}
