import {
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { gql } from "@apollo/client";

import { PropertyIcon } from "controls";
import { useData, useActionFragment } from "hooks";
import { getDialogProps } from "helpers";
import { ListLoader } from "loaders";

export default function PicklistDialog({ propertyId, onClose, ...others }) {
  const data = useData(
    gql`
      query PicklistDialog($propertyId: ID!) {
        property(id: $propertyId) {
          id
          label
          value
          picklist {
            id
            name
            options
          }
        }
      }
    `,
    { propertyId },
  );

  const propertyFill = useActionFragment(
    "propertyFill",
    "property { id value }",
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      {...others}
      {...getDialogProps()}
    >
      <DialogTitle>选择“{data?.property.label}”</DialogTitle>
      {!data && <ListLoader subheaders />}
      <List
        disablePadding
        subheader={
          <ListSubheader>
            使用选项列表“{data?.property.picklist?.name}”
          </ListSubheader>
        }
      >
        {["", ...(data?.property.picklist?.options || [])].map((option) => (
          <ListItem
            key={option}
            disablePadding
            selected={option === data?.property.value}
          >
            <ListItemButton
              component="a"
              href="#"
              onClick={async () => {
                await propertyFill({
                  input: {
                    propertyId,
                    value: option,
                  },
                });
                onClose();
              }}
            >
              <ListItemIcon>
                <PropertyIcon />
              </ListItemIcon>
              <ListItemText primary={option || "(未填写)"} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
      </DialogActions>
    </Dialog>
  );
}
