import { gql } from "@apollo/client";

import { useData, useActionFragment, useRoute } from "hooks";
import { teamRoute } from "helpers";
import { ProjectInput, CompanyInput, SuggestiveTextInput } from "inputs";
import { FormDialog } from "controls";

export default function AddProjectCompanyDialog({
  projectId,
  companyId,
  role,
  ...others
}) {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query AddProjectCompanyDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          projectCompanyRoleCounts {
            value
            count
          }
        }
      }
    `,
    { teamId },
  );

  const addProjectCompany = useActionFragment(
    "addProjectCompany",
    `project { id projectCompanies { id } }
    company { id projects { id } projectCompanies { id } }`,
  );

  return (
    <FormDialog
      title="添加项目单位"
      fields={[
        {
          label: "项目",
          name: "projectId",
          value: projectId,
          inputComponent: ProjectInput,
          required: true,
        },
        {
          label: "角色",
          name: "role",
          value: role,
          inputComponent: SuggestiveTextInput,
          required: true,
          options: {
            suggestions: data?.team.projectCompanyRoleCounts,
          },
        },
        {
          label: "单位",
          name: "companyId",
          value: companyId,
          inputComponent: CompanyInput,
          required: true,
        },
      ]}
      onSubmit={async (formData) => {
        await addProjectCompany({
          input: {
            ...formData,
          },
        });
      }}
      {...others}
    />
  );
}
