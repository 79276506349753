import React, { useEffect } from "react";
import { Button } from "@mui/material";

import { ENABLE_SERVICE_WORKER } from "helpers/config";
import showMessage from "helpers/showMessage";

import { register, unregister } from "../serviceWorkerRegistration.js";

export default function ServiceWorkerNotification() {
  useEffect(() => {
    Promise.resolve().then(async () => {
      if (ENABLE_SERVICE_WORKER)
        await register({
          onWaiting: () =>
            showMessage({
              message: <>优建云已更新，您可以随时刷新页面加载新版本</>,
              actions: (
                <>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => window.location.reload()}
                  >
                    更新
                  </Button>
                </>
              ),
            }),
        });
      else await unregister();
    });
  }, []);

  return null;
}
