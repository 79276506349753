import pmap from "promise.map";
import React, { useState, useEffect } from "react";
import {
  Avatar,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { filesize } from "filesize";
import { Folder, FolderOpen, File } from "mdi-material-ui";

import newId from "helpers/newId";
import { FolderIcon } from "controls/icons";
import Message from "controls/Message";
import uploadBlob, { abortUploadBlob } from "helpers/uploadBlob";
import selectFiles from "helpers/selectFiles";
import sum from "helpers/sum";

import SingleInput from "./SingleInput";

function ItemLabel({ item }) {
  return <>{item.name}</>;
}

function ItemAvatar({ item, ...others }) {
  return (
    <Avatar {...others}>
      <FolderIcon />
    </Avatar>
  );
}

export default function FolderUploadInput({ ...others }) {
  return (
    <SingleInput
      {...others}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemDialogContent}
    />
  );
}

function ItemDialogContent({ item, itemSet }) {
  const [state, stateSet] = useState({ uploading: false });

  const handleAbort = () =>
    stateSet((state) => {
      Promise.resolve().then(async () => {
        for (const blob of state.blobs || [])
          await abortUploadBlob({ id: blob.id });
      });
      return {
        ...state,
        uploading: false,
      };
    });

  const selectFolder = () =>
    selectFiles({ folder: true }, (blobs) => {
      if (!blobs.length) return;

      stateSet((state) => ({
        ...state,
        uploading: true,
      }));

      handleBlobEntries(blobs.map((blob) => [blob.webkitRelativePath, blob]));
    });

  const selectZipFile = () =>
    selectFiles({ accept: ".zip" }, async ([zipfile]) => {
      stateSet((state) => ({
        ...state,
        uploading: true,
      }));

      const { default: JSZip } = await import("jszip");

      let zipEntries;
      {
        const zip = await JSZip.loadAsync(zipfile);
        zipEntries = [];
        zip.forEach((entryPath, entry) => zipEntries.push([entryPath, entry]));
      }
      if (zipEntries.find(([entryPath]) => entryPath.match(/\uFFFD/))) {
        const iconv = await import("iconv-lite");
        const zip = await JSZip.loadAsync(zipfile, {
          decodeFileName: (bytes) => iconv.decode(bytes, "gb2312"),
        });
        zipEntries = [];
        zip.forEach((entryPath, entry) => zipEntries.push([entryPath, entry]));
      }

      const blobEntries = [];

      for (const [entryPath, entry] of zipEntries) {
        if (entry.dir) blobEntries.push([entryPath]);
        else {
          const blob = await entry.async("blob");
          blobEntries.push([entryPath, blob]);
        }
      }

      handleBlobEntries(blobEntries, {
        rootName: zipfile.name,
      });
    });

  async function handleBlobEntries(blobEntries, { rootName = "新目录" } = {}) {
    let rootFolderUpload = {
      name: rootName,
      subFolderUploads: [],
      fileUploads: [],
    };
    const blobs = [];

    for (const [entryPath, blob] of blobEntries) {
      const segs = entryPath.replace(/^\//g, "").replace(/\/$/g, "").split("/");
      const name = segs.pop();
      let folder = rootFolderUpload;

      // mkdirp and locate folder
      for (const seg of segs) {
        if (seg.match(/^\./) || seg.match(/^__MACOSX/)) break;
        let childFolder = folder.subFolderUploads.find((f) => f.name === seg);
        if (!childFolder) {
          childFolder = {
            name: seg,
            subFolderUploads: [],
            fileUploads: [],
          };
          folder.subFolderUploads.push(childFolder);
        }
        folder = childFolder;
      }

      if (name.match(/^\./) || name.match(/^__MACOSX/)) continue;

      if (blob) {
        const fileUpload = {
          name,
          size: String(blob.size),
        };
        folder.fileUploads.push(fileUpload);
        blob.uploaded = 0;
        blob.id = newId();
        blob.fileUpload = fileUpload;
        blobs.push(blob);
      } else {
        let childFolder = folder.subFolderUploads.find((f) => f.name === name);
        if (!childFolder) {
          childFolder = {
            name: name,
            subFolderUploads: [],
            fileUploads: [],
          };
          folder.subFolderUploads.push(childFolder);
        }
      }
    }

    stateSet((state) => ({
      ...state,
      blobs,
    }));

    try {
      await pmap(
        blobs,
        async (blob) => {
          const { etag } = await uploadBlob({
            id: blob.id,
            blob: blob,
            onProgress: ({ uploaded }) => {
              blob.uploaded = uploaded;
              stateSet((state) => ({
                ...state,
                total: sum(blobs.map((b) => b.size)),
                uploaded: sum(blobs.map((b) => b.uploaded)),
              }));
            },
          });
          Object.assign(blob.fileUpload, { etag });
        },
        5,
      );

      if (
        !rootFolderUpload.fileUploads.length &&
        rootFolderUpload.subFolderUploads.length === 1
      )
        rootFolderUpload = rootFolderUpload.subFolderUploads[0];

      stateSet((state) => ({
        ...state,
        uploading: false,
      }));

      itemSet(rootFolderUpload);
    } finally {
      stateSet((state) => ({
        ...state,
        uploading: false,
        blobs: null,
        total: null,
      }));
    }
  }

  useEffect(() => {
    return () => {
      handleAbort();
    };
  }, []);

  return (
    <>
      {!state.uploading && (
        <>
          {!item && (
            <Message
              actions={[
                {
                  title: "选择目录",
                  onClick: selectFolder,
                },
                {
                  title: "选择ZIP压缩包",
                  onClick: selectZipFile,
                },
              ]}
            >
              请选择一个目录或ZIP压缩包
            </Message>
          )}
          {!!item && (
            <>
              <Message>已上传目录“{item.name}”</Message>
              <List>
                <ListItem dense>
                  <ListItemIcon>
                    <FolderOpen />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
                {item.subFolderUploads.map((folderUpload) => (
                  <ListItem
                    key={folderUpload.name}
                    dense
                    style={{ paddingLeft: 40 }}
                  >
                    <ListItemIcon>
                      <Folder />
                    </ListItemIcon>
                    <ListItemText primary={folderUpload.name} />
                  </ListItem>
                ))}
                {item.fileUploads.map((fileUpload) => (
                  <ListItem
                    key={fileUpload.name}
                    dense
                    style={{ paddingLeft: 40 }}
                  >
                    <ListItemIcon>
                      <File />
                    </ListItemIcon>
                    <ListItemText primary={fileUpload.name} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </>
      )}
      {state.uploading && (
        <>
          {!state.total && (
            <>
              <Message>正在加载</Message>
              <LinearProgress />
            </>
          )}
          {state.total && (
            <>
              <Message
                actions={[
                  {
                    title: "取消",
                    onClick: handleAbort,
                  },
                ]}
              >
                正在上传目录 ({filesize(state.uploaded)} /{" "}
                {filesize(state.total)})
              </Message>
              <LinearProgress
                variant="determinate"
                value={(state.uploaded / state.total) * 100}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
