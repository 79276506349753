import React from "react";
import { Paper } from "@mui/material";
import Color from "color";

export default function Widget({ children, color, icon, title, ...others }) {
  return (
    <Paper
      variant="outlined"
      {...others}
      style={{
        width: "fit-content",
        flex: `1 1 auto`,
        margin: "35px 5px 5px 5px",
        ...others.style,
      }}
    >
      <div
        style={{
          marginTop: -30,
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "flex-end",
        }}
      >
        <Paper
          style={{
            background: `linear-gradient(60deg, ${color}, ${Color(color)
              .darken(0.15)
              .string()})`,
            color: "#fefefe",
            borderRadius: 3,
            width: 60,
            height: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
          }}
          elevation={5}
        >
          {icon}
        </Paper>
        <div style={{ color: "#aaa", margin: 10 }}>{title}</div>
      </div>
      {children}
    </Paper>
  );
}
