import { authContext } from "contexts";
import { useContext, useEffect } from "react";
import WebOffice from "web-office-sdk";
import { useComponentSize } from "react-use-size";
import { useDebounce } from "use-debounce";
import PreviewLoader from "views/previewable/PreviewLoader";

export default function WebOfficeView({ webOfficeUrl }) {
  const { ref, width } = useComponentSize();
  const { token } = useContext(authContext);
  const [widthDebounced] = useDebounce(width, 300);

  useEffect(() => {
    let webOffice;
    if (webOfficeUrl && widthDebounced) {
      webOffice = WebOffice.config({
        url: webOfficeUrl,
        mount: ref.current,
        commonOptions: {
          isShowTopArea: true, // 隐藏顶部区域（头部和工具栏）
          isShowHeader: true, // 隐藏头部区域
          acceptVisualViewportResizeEvent: true, // 控制 WebOffice 是否接受外部的 VisualViewport
        },
        commandBars: [
          // 通用
          "HeaderLeft",
          "HeaderRight",
          "FloatQuickHelp",
          // 文字
          "TabReviewWord",
          // 表格
          "TabCooperationTab",
          // 演示
          "TabReviewTab",
          // 移动端
          "SendButton",
          "Invitation",
          "Cooperation",
        ].map((cmbId) => ({
          cmbId,
          attributes: {
            visible: false, // 隐藏组件
          },
        })),
      });
      webOffice.setToken({ token });
    }

    return () => {
      webOffice?.destroy();
    };
  }, [webOfficeUrl, widthDebounced]);

  return (
    <div style={{ flex: "1 1 auto" }} ref={ref}>
      {!(webOfficeUrl && widthDebounced) && <PreviewLoader />}
    </div>
  );
}
