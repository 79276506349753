import React from "react";
import { Checkbox, Chip, IconButton } from "@mui/material";
import { filesize } from "filesize";
import { gql } from "@apollo/client";

import UserAvatars from "blocks/UserAvatars";
import ItemPath, { ItemPathFragment } from "blocks/ItemPath";
import useData from "hooks/useData";

import DateTime from "./DateTime";

export const TEXT_PADDING = 10;

export function TextCell({ value }) {
  return (
    <span style={{ display: "inline-block", margin: TEXT_PADDING }}>
      {value}
    </span>
  );
}

export function LinkCell({
  value: { label, title = label, href, onClick, disabled },
}) {
  return (
    <a
      style={{
        color: "inherit",
        display: "inline-block",
        margin: TEXT_PADDING,
      }}
      href={href || "#"}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </a>
  );
}

export function ComponentCell({ value: { component } }) {
  return <>{component}</>;
}

export function FileSizeCell({ value }) {
  return (
    <span style={{ display: "inline-block", margin: TEXT_PADDING }}>
      {filesize(value)}
    </span>
  );
}

export function ItemPathCell({ value: itemId }) {
  const data = useData(
    gql`
      query ItemPathCell($itemId: ID!) {
        item(id: $itemId) {
          id
          ...ItemPathFragment
        }
      }
      ${ItemPathFragment}
    `,
    { itemId },
  );
  return (
    <ItemPath clickable item={data?.item} style={{ margin: TEXT_PADDING }} />
  );
}

export function DateTimeCell({ value }) {
  return (
    <span style={{ display: "inline-block", margin: TEXT_PADDING }}>
      <DateTime value={value} />
    </span>
  );
}

export function CheckboxCell({
  value: { checked, onChange, disabled, title },
}) {
  return (
    <Checkbox
      component="button"
      checked={checked}
      readOnly={!onChange}
      disabled={disabled}
      onChange={(event) => {
        if (onChange) onChange(event.target.checked);
      }}
      title={title}
    />
  );
}

export function IconButtonCell({
  value: { onClick, href, target, title, icon },
}) {
  return (
    <IconButton
      href={href || "#"}
      onClick={onClick}
      target={target}
      title={title}
      size="large"
    >
      {icon}
    </IconButton>
  );
}

export function UsersCell({ value }) {
  return (
    <UserAvatars
      userAvatarProps={{
        style: {
          width: 32,
          height: 32,
        },
        triggerUserDialog: true,
      }}
      style={{
        display: "inline-flex",
      }}
      userIds={value}
    />
  );
}

export function ChipsCell({ value: chips }) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row wrap",
      }}
    >
      {chips?.map((chip) => (
        <Chip
          size="small"
          clickable
          component="a"
          key={chip.label}
          label={chip.label}
          icon={chip.icon}
          href={chip.href || "#"}
          style={{ margin: 3 }}
        />
      ))}
    </div>
  );
}
