import React, { useContext } from "react";
import {
  Drawer,
  BottomNavigation,
  BottomNavigationAction,
  Badge,
} from "@mui/material";

import { rightDrawerContext } from "providers/RightDrawerProvider";

export default function RightDrawer() {
  const {
    rightDrawerCurrentTab,
    rightDrawerTabs,
    rightDrawerOpen,
    rightDrawerClose,
  } = useContext(rightDrawerContext);

  return (
    <>
      <Drawer
        anchor="right"
        open={!!rightDrawerCurrentTab}
        onClose={() => rightDrawerClose(null)}
        PaperProps={{
          style: {
            width: 320,
            paddingTop: `env(safe-area-inset-top)`,
            flexFlow: "column nowrap",
            justifyContent: "stretch",
          },
        }}
      >
        <BottomNavigation
          style={{
            flex: "0 0 auto",
          }}
          value={rightDrawerCurrentTab?.name}
          onChange={(event, value) => rightDrawerOpen(value)}
        >
          {rightDrawerTabs.map((tab) => (
            <BottomNavigationAction
              key={tab.name}
              icon={
                <Badge color="secondary" badgeContent={tab.badgeContent}>
                  {tab.icon}
                </Badge>
              }
              label={tab.title}
              value={tab.name}
            />
          ))}
        </BottomNavigation>
        <div
          style={{
            flex: "1 1 auto",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {rightDrawerCurrentTab && rightDrawerCurrentTab.render()}
        </div>
      </Drawer>
    </>
  );
}
