import React from "react";
import { gql } from "@apollo/client";
import { filesize } from "filesize";

import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";
import DateTime from "controls/DateTime";
import ObjectCard from "controls/ObjectCard";
import filterBlobUrl from "helpers/filterBlobUrl";

import ItemPath, { ItemPathFragment } from "./ItemPath";
import UsersLabel, { UsersLabelFragment } from "./UsersLabel";
import FileObjectIcon from "./FileObjectIcon";

export const FileCardFragment = gql`
  fragment FileCardFragment on File {
    id
    name
    size
    thumbnailUrl
    createdAt
    revisionedAt
    revisionedByUsers {
      ...UsersLabelFragment
    }
    revisions {
      id
    }
    ...ItemPathFragment
  }
  ${UsersLabelFragment}
  ${ItemPathFragment}
`;

export default function FileCard({ file }) {
  const { teamId } = useRoute(teamRoute);

  return (
    <ObjectCard
      icon={<FileObjectIcon fileId={file.id} />}
      title={file.name}
      subheader={<ItemPath item={file} />}
      href={`/${teamId}/${file.id}`}
      fields={[
        {
          label: "创建时间",
          content: <DateTime value={file.createdAt} />,
        },
        {
          label: "尺寸",
          content: filesize(file.size),
        },
        {
          label: "修订成员",
          content: <UsersLabel users={file.revisionedByUsers} />,
        },
        {
          label: "修订时间",
          content: (
            <>
              <DateTime value={file.revisionedAt} />
              (共{file.revisions.length + 1}个版本)
            </>
          ),
        },
      ]}
      thumbnailUrl={filterBlobUrl(file.thumbnailUrl)}
    />
  );
}
