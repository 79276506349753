import React, { useContext, useLayoutEffect } from "react";
import { Switch, Route, matchPath, useLocation } from "react-router";
import { gql } from "@apollo/client";
import QueryString from "query-string";

import { RouteNotFound } from "controls";
import {
  teamRoute,
  shareRoute,
  DESKTOP_BUILD,
  companyRegisterRoute,
} from "helpers";
import { useData, useHistory } from "hooks";
import { authContext } from "contexts";
import Login from "views/login/Login";
import SelectTeamDialog from "dialogs/SelectTeamDialog";
import Viewport from "views/viewport/Viewport";
import CanvasView from "views/canvas/CanvasView";
import ShareView from "views/shares/ShareView";
import DesktopView from "views/desktop/DesktopView";
import SupportView from "views/support/SupportView";
import CompanyRegisterView from "./register/CompanyRegisterView";

export default function MainView() {
  const location = useLocation();
  const history = useHistory();
  const { authenticated } = useContext(authContext);

  const [
    //
    isRootPath,
    isLoginPath,
    isTeamPath,
    isCanvasPath,
    isSupportPath,
  ] = [
    ["/", true],
    ["/login", false],
    [teamRoute, false],
    ["/canvas", false],
    ["/support", false],
  ].map(([path, exact]) => !!matchPath(location.pathname, { path, exact }));

  const isAuthOnlyPath =
    isRootPath || isTeamPath || isCanvasPath || isSupportPath;

  let shouldSwitchTo = null;

  const query = QueryString.parse(location.search.replace(/^\?/, ""));
  if (isLoginPath && authenticated) shouldSwitchTo = query.loginRedirect || "/";
  if (isAuthOnlyPath && !authenticated)
    shouldSwitchTo = `/login?${QueryString.stringify({
      loginRedirect: `${location.pathname}${location.search}`,
    })}`;

  useLayoutEffect(() => {
    if (shouldSwitchTo) history.replace(shouldSwitchTo);
  }, [shouldSwitchTo]);

  if (shouldSwitchTo) return null;

  if (DESKTOP_BUILD)
    return (
      <>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" exact component={SelectTeam} />
          <Route path={teamRoute} exact component={DesktopView} />
          <Route component={RouteNotFound} />
        </Switch>
      </>
    );

  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" exact component={SelectTeam} />
        <Route path={teamRoute} component={Viewport} />
        <Route path={"/canvas"} component={CanvasView} />
        <Route path={"/support"} component={SupportView} />
        <Route path={shareRoute} component={ShareView} />
        <Route path={companyRegisterRoute} component={CompanyRegisterView} />
        <Route component={RouteNotFound} />
      </Switch>
    </>
  );
}

function SelectTeam() {
  const location = useLocation();
  const data = useData(gql`
    query MainView {
      teams {
        id
      }
    }
  `);
  const teamIdNew = localStorage.getItem("visitedTeam") || data?.teams[0]?.id;

  if (
    matchPath(location.pathname, { path: "/", exact: true }) &&
    data?.teams.map((t) => t.id).includes(teamIdNew)
  )
    return <Redirect to={`/${teamIdNew}${location.search}`} />;

  return <SelectTeamDialog />;
}

function Redirect({ to }) {
  const history = useHistory();

  useLayoutEffect(() => {
    history.replace(to);
  });

  return null;
}
