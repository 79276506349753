import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepButton,
  StepContent,
  Stepper,
} from "@mui/material";
import { getDialogProps } from "helpers";
import { every, noop } from "lodash-es";

import AppToolbar from "./AppToolbar";

export default function WizardDialog({
  title,
  disabled,
  onClose,
  onSubmit = noop,
  steps = [],
  ...others
}) {
  const [activeStepIndex, activeStepIndexSet] = useState(0);
  const activeStep = steps[activeStepIndex];
  const prevStep = steps[activeStepIndex - 1];
  const nextStep = steps[activeStepIndex + 1];

  return (
    <Dialog
      {...others}
      {...getDialogProps()}
      open
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stepper nonLinear activeStep={activeStepIndex} orientation="vertical">
          {steps.map((step, stepIndex) => (
            <Step key={stepIndex} completed={step.completed}>
              <StepButton
                color="inherit"
                disabled={disabled || step.disabled}
                onClick={() => activeStepIndexSet(stepIndex)}
              >
                {step.title}
              </StepButton>
              <StepContent>
                {activeStep?.content}
                <AppToolbar
                  dense
                  actions={[
                    prevStep && {
                      variant: "default",
                      title: "上一步",
                      onClick: () => activeStepIndexSet(activeStepIndex - 1),
                      disabled: prevStep.disabled,
                    },
                    nextStep && {
                      variant: "contained",
                      title: "下一步",
                      primary: true,
                      onClick: () => activeStepIndexSet(activeStepIndex + 1),
                      disabled: nextStep.disabled,
                    },
                  ]}
                />
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      {activeStep && (
        <DialogActions>
          <Button
            variant="contained"
            onClick={async () => {
              await onSubmit();
              onClose();
            }}
            disabled={!every(steps, (s) => s.completed) || disabled}
          >
            完成
          </Button>
          <Button onClick={onClose}>取消</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
