import React from "react";
import { uniq, flatten } from "lodash-es";
import { gql } from "@apollo/client";
import { Tag, Pencil, ContentCopy, Delete } from "mdi-material-ui";

import {
  Table,
  DateTimeCell,
  UsersCell,
  FolderIcon,
  ItemPathCell,
} from "controls";
import { ItemObjectIcon } from "blocks";
import { teamRoute, sortBy } from "helpers";
import { useRoute, useDialogOpener } from "hooks";
import RenameItemDialog from "views/folders/RenameItemDialog";
import MoveItemsDialog from "views/folders/MoveItemsDialog";
import CopyItemsDialog from "views/folders/CopyItemsDialog";
import RemoveItemsDialog from "views/folders/RemoveItemsDialog";

export const DocumentsTableFragment = gql`
  fragment DocumentsTableFragment on Document {
    id
    name
    dataRowName
    revisionedAt
    revisionedByUsers {
      id
    }
    parentItem {
      id
    }
    createdAt
    createdBy {
      id
    }
    canRemove
    canMove
    canRename
    fields {
      id
      label
      stringValue
    }
  }
`;

function getFolderId(rows) {
  const parentItemIds = uniq(rows?.map((i) => i.parentItem?.id)).filter(
    (i) => i,
  );
  if (parentItemIds.length !== 1) return null;
  return parentItemIds[0];
}

export default function DocumentsTable({
  rows,
  rowsCount,
  enablePath,
  ...others
}) {
  const openRenameItemDialog = useDialogOpener(RenameItemDialog);
  const openMoveItemsDialog = useDialogOpener(MoveItemsDialog);
  const openCopyItemsDialog = useDialogOpener(CopyItemsDialog);
  const openRemoveItemsDialog = useDialogOpener(RemoveItemsDialog);
  const { teamId } = useRoute(teamRoute);
  const dataRowNames = uniq(rows?.map((r) => r.dataRowName));
  const enableFields = dataRowNames.length === 1 && dataRowNames[0];
  const fieldLabels = !enableFields
    ? []
    : sortBy(
        uniq(
          flatten(
            rows.map((r) => r.fields?.map((f) => f.label).filter((l) => l)),
          ),
        ),
      );

  return (
    <Table
      {...others}
      batchActions={[
        {
          name: "removeItems",
          icon: <Delete />,
          title: "删除选择的项目",
          onClick: (itemIds) => openRemoveItemsDialog({ itemIds }),
        },
        {
          name: "moveItems",
          icon: <FolderIcon />,
          title: "移动选择的项目到其他位置",
          onClick: (itemIds) =>
            openMoveItemsDialog({
              itemIds,
              folderId: getFolderId(
                rows?.filter((r) => itemIds.includes(r.id)),
              ),
            }),
        },
        {
          name: "copyItems",
          icon: <ContentCopy />,
          title: "复制选择的项目到其他位置",
          onClick: (itemIds) =>
            openCopyItemsDialog({
              itemIds,
              folderId: getFolderId(
                rows?.filter((r) => itemIds.includes(r.id)),
              ),
            }),
        },
      ]}
      columns={[
        {
          label: "名称",
        },
        enablePath && {
          label: "位置",
          cellComponent: ItemPathCell,
        },
        {
          label: "数据表",
        },
        {
          label: "修订时间",
          cellComponent: DateTimeCell,
        },
        {
          label: "修订成员",
          cellComponent: UsersCell,
        },
        {
          label: "创建时间",
          cellComponent: DateTimeCell,
        },
        {
          label: "创建成员",
          cellComponent: UsersCell,
        },
        ...fieldLabels.map((label) => ({
          icon: <Tag />,
          label: `字段-${label}`,
        })),
      ].filter((c) => c)}
      rowsCount={rowsCount}
      rows={rows?.map((item) => ({
        id: item.id,
        title: item.name,
        href: `/${teamId}/${item.id}`,
        icon: <ItemObjectIcon itemId={item.id} />,
        values: [
          item.name,
          ...(enablePath ? [item.parentItem?.id].filter((i) => i) : []),
          item.dataRowName,
          item.revisionedAt,
          item.revisionedByUsers.map((u) => u.id),
          item.createdAt,
          item.createdBy && [item.createdBy.id],
          ...fieldLabels.map(
            (label) => item.fields?.find((f) => f.label === label)?.stringValue,
          ),
        ],
        batchActionNames: [
          item.canRemove && "removeItems",
          item.canMove && "moveItems",
          "copyItems",
        ].filter((a) => a),
        actions: [
          {
            label: "更改名称",
            icon: <Pencil />,
            disabled: !item.canRename,
            onClick: () => openRenameItemDialog({ itemId: item.id }),
          },
          {
            label: "移动到其他位置",
            icon: <FolderIcon />,
            onClick: () =>
              openMoveItemsDialog({
                itemIds: [item.id],
                folderId: getFolderId([item]),
              }),
            disabled: !item.canMove,
          },
          {
            label: "复制到其他位置",
            icon: <ContentCopy />,
            onClick: () =>
              openCopyItemsDialog({
                itemIds: [item.id],
                folderId: getFolderId([item]),
              }),
          },
          {
            label: "删除",
            icon: <Delete />,
            disabled: !item.canRemove,
            onClick: () => openRemoveItemsDialog({ itemIds: [item.id] }),
          },
        ].filter((a) => a),
      }))}
    />
  );
}
