import { ApolloClient, ApolloLink } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";

import { PERSIST_CACHE, API_ORIGIN, API_VERSION } from "./config";
import apolloCache from "./apolloCache";
import ExtendedError from "./ExtendedError";

const SCHEMA_VERSION = "apollo-3-cache/3"; // Must be a string.
const SCHEMA_VERSION_KEY = "apollo-schema-version";

async function initApolloClient() {
  if (initApolloClient.inited) return;

  if (PERSIST_CACHE) {
    const persistor = new CachePersistor({
      cache: apolloCache,
      storage: new LocalStorageWrapper(window.localStorage),
    });
    const currentVersion = await localStorage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      await persistor.restore();
    } else {
      await persistor.purge();
      await localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }
  }
  initApolloClient.inited = true;
}

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    new RetryLink({
      delay: (count) => {
        return Math.max(
          //
          10000,
          count * 1000 * Math.random(),
        );
      },
      attempts: (count, operation, error) => {
        if (!error) return false;
        return true;
      },
    }),
    setContext(() => {
      const token = window._fmsToken;

      return {
        headers: {
          "X-FMS-API-Version": API_VERSION,
          ...(token && {
            Authorization: `Bearer ${token}`,
          }),
        },
      };
    }),
    new ApolloLink((operation, forward) =>
      forward(operation).map((response) => {
        const context = operation.getContext();
        const {
          response: { headers },
        } = context;

        const responseApiVersion = headers?.get("x-fms-api-version");

        if (responseApiVersion !== API_VERSION)
          return {
            errors: [
              new ExtendedError("优建云客户端已升级，请稍候更新。", {
                API_VERSION,
                responseApiVersion,
              }),
            ],
            data: null,
          };

        return response;
      }),
    ),
    new BatchHttpLink({
      batchMax: 50,
      uri: `${API_ORIGIN}/graphql`,
    }),
  ]),
  cache: apolloCache,
});

export default apolloClient;
export { initApolloClient };
