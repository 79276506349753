export default function Dashboard({ children }) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "stretch",
        alignItems: "stretch",
      }}
    >
      {children}
    </div>
  );
}
