import React from "react";
import { times } from "lodash-es";
import { Card, CardHeader, CardContent } from "@mui/material";

import AvatarLoader from "./AvatarLoader";
import TextLoader from "./TextLoader";

export default function CardLoader({ content }) {
  return (
    <>
      <Card
        style={{
          margin: 5,
        }}
      >
        <CardHeader
          avatar={<AvatarLoader />}
          title={<TextLoader />}
          subheader={<TextLoader width={100} />}
        />
        {content && (
          <CardContent>
            {times(3).map((i) => (
              <div key={i}>
                <TextLoader />
              </div>
            ))}
          </CardContent>
        )}
      </Card>
    </>
  );
}
