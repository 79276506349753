import React from "react";
import { gql } from "@apollo/client";
import { Download } from "mdi-material-ui";

import {
  ProjectIcon,
  FolderIcon,
  FileIcon,
  TabContent,
  Message,
  DocumentIcon,
  CardsList,
} from "controls";
import { ItemCard, ItemCardFragment } from "blocks";
import {
  folderRoute,
  downloadFromAPI,
  showMessage,
  ORDER_BY_REVISIONED_AT_DESC,
  ORDER_BY_REVISIONED_AT,
  ORDER_BY_CREATED_AT_DESC,
  ORDER_BY_CREATED_AT,
  ORDER_BY_NAME,
} from "helpers";
import {
  useData,
  useRoute,
  useDialogOpener,
  useFormDialog,
  useAction,
  useFilterBar,
  useList,
  useHistory,
} from "hooks";
import { FileUploadsInput } from "inputs";
import ItemsTable, { ItemsTableFragment } from "lists/ItemsTable";
import ItemsGrid, { ItemsGridFragment } from "lists/ItemsGrid";

import UploadFilesDialog from "./UploadFilesDialog";
import UploadFolderDialog from "./UploadFolderDialog";
import AddSubFolderDialog from "./AddSubFolderDialog";
import EnableFolderProjectDialog from "./EnableFolderProjectDialog";
import CreateDocumentDialog from "./CreateDocumentDialog";
import AddFileDialog from "./AddFileDialog";

export default React.memo(ContentTab);

function ContentTab() {
  const history = useHistory();
  const formDialog = useFormDialog();
  const openEnableFolderProjectDialog = useDialogOpener(
    EnableFolderProjectDialog,
  );
  const openUploadFilesDialog = useDialogOpener(UploadFilesDialog);
  const openAddSubFolderDialog = useDialogOpener(AddSubFolderDialog);
  const openUploadFolderDialog = useDialogOpener(UploadFolderDialog);
  const openCreateDocumentDialog = useDialogOpener(CreateDocumentDialog);
  const openAddFileDialog = useDialogOpener(AddFileDialog);

  const { folderId, teamId } = useRoute(folderRoute);

  const [
    {
      searchTerm,
      itemTypes,
      revisionedAtRange,
      revisionedBy,
      createdBy,
      createdAtRange,
      includeDescendants,
      orderBy,
    },
    filterBar,
  ] = useFilterBar(
    {
      enableSearchTerm: true,
    },
    [
      {
        type: "multi_select",
        label: "类别",
        name: "itemTypes",
        defaultValue: ["Folder", "File", "Document"],
        options: [
          {
            icon: <FolderIcon />,
            label: "目录",
            value: "Folder",
          },
          {
            icon: <FileIcon />,
            label: "文档",
            value: "File",
          },
          {
            icon: <DocumentIcon />,
            label: "表单",
            value: "Document",
          },
        ],
      },
      {
        type: "user",
        label: "修订成员",
        name: "revisionedBy",
      },
      {
        type: "date_range",
        label: "修订日期",
        name: "revisionedAtRange",
      },
      {
        type: "user",
        label: "创建成员",
        name: "createdBy",
      },
      {
        type: "date_range",
        label: "创建日期",
        name: "createdAtRange",
      },
      {
        type: "boolean",
        label: "包含子目录内容",
        name: "includeDescendants",
      },
      {
        name: "orderBy",
        type: "select",
        label: "排序",
        defaultValue: ORDER_BY_NAME.value,
        options: [
          ORDER_BY_REVISIONED_AT_DESC,
          ORDER_BY_REVISIONED_AT,
          ORDER_BY_CREATED_AT_DESC,
          ORDER_BY_CREATED_AT,
          ORDER_BY_NAME,
        ],
      },
    ],
  );

  const [data, dataMeta] = useData(
    gql`
      query ContentTab(
        $folderId: ID!
        $searchTerm: String
        $itemTypes: [String!]
        $revisionedAtRange: DateRange
        $revisionedBy: ID
        $createdAtRange: DateRange
        $createdBy: ID
        $offset: Int
        $includeDescendants: Boolean
        $orderBy: ItemsOrderBy
      ) {
        folder(id: $folderId) {
          id
          project {
            id
          }

          canAddFile
          canAddDocument
          canAddFolder
          canAddProject
          canDownloadFolder

          parentItem {
            id
            ...ItemsTableFragment
            ...ItemsGridFragment
          }
          items(
            offset: $offset
            limit: 20
            orderBy: $orderBy

            searchTerm: $searchTerm
            itemTypes: $itemTypes
            revisionedAtRange: $revisionedAtRange
            revisionedBy: $revisionedBy
            createdAtRange: $createdAtRange
            createdBy: $createdBy
            includeDescendants: $includeDescendants
          ) {
            id
            createdAt
            revisionedAt
            ...ItemsTableFragment
            ...ItemsGridFragment
            ...ItemCardFragment
          }
          itemsCount(
            searchTerm: $searchTerm
            itemTypes: $itemTypes
            revisionedAtRange: $revisionedAtRange
            revisionedBy: $revisionedBy
            createdAtRange: $createdAtRange
            createdBy: $createdBy
            includeDescendants: $includeDescendants
          )
          ancestorFolderType
        }
      }
      ${ItemsTableFragment}
      ${ItemsGridFragment}
      ${ItemCardFragment}
    `,
    {
      orderBy,
      folderId,
      searchTerm,
      itemTypes,
      revisionedAtRange,
      revisionedBy,
      createdBy,
      createdAtRange,
      includeDescendants,
    },
    { returnMeta: true },
  );
  const createDocument = useAction(gql`
    mutation ContentTab($input: CreateDocumentInput!) {
      createDocument(input: $input) {
        document {
          id
        }
        folder {
          id
          items {
            id
          }
          documents {
            id
          }
        }
      }
    }
  `);

  const addProjectTemplate = useAction(gql`
    mutation ContentTab($input: AddProjectTemplateInput!) {
      addProjectTemplate(input: $input) {
        folder {
          id
          items {
            id
          }
          subFolders {
            id
          }
        }
        projectFolder {
          id
        }
      }
    }
  `);
  const uploadFiles = useAction(gql`
    mutation ContentTab($input: UploadFilesInput!) {
      uploadFiles(input: $input) {
        folder {
          id
          items {
            id
          }
          files {
            id
          }
        }
      }
    }
  `);

  const [list, listModeSwitcher] = useList("item", {
    defaultMode: "table",
    rows: data?.folder.items,
    rowsCount: data?.folder.itemsCount,
    fetchMore: dataMeta.fetchMore,
    card: <ItemCard />,
    table: <ItemsTable enablePath={includeDescendants} />,
    grid: <ItemsGrid />,
    cardsList: (
      <CardsList
        getRowDate={(row) =>
          ({
            [ORDER_BY_CREATED_AT.value]: row.createdAt,
            [ORDER_BY_CREATED_AT_DESC.value]: row.createdAt,
            [ORDER_BY_REVISIONED_AT.value]: row.revisionedAt,
            [ORDER_BY_REVISIONED_AT_DESC.value]: row.revisionedAt,
          })[orderBy]
        }
      />
    ),
  });

  return (
    <>
      <TabContent
        toolbar={<>{listModeSwitcher}</>}
        actions={{
          doc_templates: [
            {
              icon: <FileIcon />,
              title: "创建一个新表单模版",
              disabled: !data?.folder.canAddDocument,
              onClick: () =>
                formDialog({
                  title: "创建新表单模版",
                  fields: [
                    {
                      label: "模版名称",
                      name: "name",
                      required: true,
                    },
                  ],
                  onSubmit: async (formData) => {
                    const result = await createDocument({
                      input: {
                        folderId,
                        ...formData,
                      },
                    });
                    showMessage({
                      message: "成功创建表单模版",
                    });
                    history.push(
                      `/${teamId}/${result.createDocument.document.id}`,
                    );
                  },
                }),
            },
            {
              title: "创建子目录",
              icon: <FolderIcon />,
              disabled: !data?.folder.canAddFolder,
              onClick: () =>
                openAddSubFolderDialog({ folderId: data.folder.id }),
            },
          ],
          project_templates: [
            {
              icon: <ProjectIcon />,
              title: "创建一个新项目模版",
              disabled: !data?.folder.canAddProject,
              onClick: () =>
                formDialog({
                  title: "创建新项目模版",
                  fields: [
                    {
                      label: "模版名称",
                      name: "name",
                      required: true,
                    },
                  ],
                  onSubmit: async (formData) => {
                    const result = await addProjectTemplate({
                      input: {
                        folderId,
                        ...formData,
                      },
                    });
                    showMessage({
                      message: "成功创建项目模版",
                    });
                    history.push(
                      `/${teamId}/${result.addProjectTemplate.projectFolder.id}`,
                    );
                  },
                }),
            },
            {
              title: "创建子目录",
              icon: <FolderIcon />,
              disabled: !data?.folder.canAddFolder,
              onClick: () =>
                openAddSubFolderDialog({ folderId: data.folder.id }),
            },
          ],
          file_templates: [
            {
              icon: <FileIcon />,
              title: "上传一个文档模版",
              disabled: !data?.folder.canAddFile,
              onClick: () =>
                formDialog({
                  title: "上传文档模版",
                  fields: [
                    {
                      label: "要上传的文档模版",
                      name: "fileUploads",
                      value: [],
                      inputComponent: FileUploadsInput,
                      required: true,
                    },
                  ],
                  onSubmit: async (formData) => {
                    await uploadFiles({
                      input: {
                        folderId,
                        ...formData,
                      },
                    });
                    showMessage({
                      message: "成功上传文档模版",
                    });
                  },
                }),
            },
            {
              title: "创建子目录",
              icon: <FolderIcon />,
              disabled: !data?.folder.canAddFolder,
              onClick: () =>
                openAddSubFolderDialog({ folderId: data.folder.id }),
            },
          ],
          root: [
            {
              title: "创建项目",
              icon: <ProjectIcon />,
              disabled: !data?.folder.canAddProject,
              onClick: () =>
                openEnableFolderProjectDialog({ folderId: data.folder.id }),
            },
            {
              title: "上传文档",
              icon: <FileIcon />,
              disabled: !data?.folder.canAddFile,
              onClick: () =>
                openUploadFilesDialog({ folderId: data.folder.id }),
            },
            {
              title: "创建文档",
              icon: <FileIcon />,
              disabled: !data?.folder.canAddFile,
              onClick: () => openAddFileDialog({ folderId: data.folder.id }),
            },
            {
              title: "创建表单",
              icon: <FileIcon />,
              disabled: !data?.folder.canAddDocument,
              onClick: () =>
                openCreateDocumentDialog({ folderId: data.folder.id }),
            },
            {
              title: "创建子目录",
              icon: <FolderIcon />,
              disabled: !data?.folder.canAddFolder,
              onClick: () =>
                openAddSubFolderDialog({ folderId: data.folder.id }),
            },
            {
              title: `下载当前目录`,
              icon: <Download />,
              disabled: !data?.folder.canDownloadFolder,
              onClick: () =>
                downloadFromAPI("download_folder", { folder_id: folderId }),
            },
            {
              title: "上传目录",
              icon: <FolderIcon />,
              disabled: !data?.folder.canAddFolder,
              onClick: () =>
                openUploadFolderDialog({ folderId: data.folder.id }),
            },
          ],
        }[data?.folder.ancestorFolderType]?.filter((t) => t)}
      >
        {!!data && (
          <>
            {data.folder.ancestorFolderType === "doc_templates" && (
              <Message message="该目录仅用于存放文档模版，创建文档时，将需要在此处选择对应的模版进行创建。" />
            )}
            {data.folder.ancestorFolderType === "project_templates" && (
              <>
                {!!data.folder.project && (
                  <Message message="这是一个项目模版，您可以在该模版中添加预置的目录结构。" />
                )}
                {!data.folder.project && (
                  <Message message="该目录仅用于存放项目模版，创建项目时，将需要使用此处的模版进行创建。" />
                )}
              </>
            )}
          </>
        )}
        {filterBar}
        {list}
      </TabContent>
    </>
  );
}
