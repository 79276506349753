import React, { useLayoutEffect, useState } from "react";

let _converter = null;

async function getConverter() {
  if (!_converter) {
    const Showdown = (await import("showdown")).default;
    const converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
      emoji: true,
      openLinksInNewWindow: true,
    });
    converter.setFlavor("github");
    _converter = converter;
  }
  return _converter;
}

export default function Markdown({ source, ...others }) {
  const [html, htmlSet] = useState("");
  useLayoutEffect(() => {
    Promise.resolve().then(async () => {
      htmlSet((await getConverter()).makeHtml(source || ""));
    });
  }, [source]);

  return (
    <div
      className="markdown"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
      {...others}
    />
  );
}
