import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { throttle, fromPairs } from "lodash-es";
import { useComponentSize } from "react-use-size";
import { useDebounce } from "use-debounce";

const DEFAULT_HEIGHT = 50;
const EXTEND = 500;
const ONENDREACHED_EXTEND = 1000;
const SCROLL_THROTTLE = 100;
const ONENDREACHED_THROTTLE = 100;
const CONTAINER_PAGE = 2000;
const CONTAINER_EXTEND = 1000;

export default function ReverseFlatList({
  data,
  renderItem,
  keyExtractor = (item, index) => index,
  onEndReached,
  endOfList,
  ...others
}) {
  const [range, rangeSet] = useState(null);
  const rangeSetThrottled = useMemo(
    () => throttle(rangeSet, SCROLL_THROTTLE),
    [],
  );
  const [itemHeightHash, itemHeightHashSet] = useState({});
  const [containerHeight, containerHeightSet] = useState(CONTAINER_PAGE);
  const itemHeightHashSetQueue = useMemo(() => [], []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!itemHeightHashSetQueue.length) return;

      itemHeightHashSet((itemHeightHash) => {
        const itemHeightHashNew = {
          ...itemHeightHash,
          ...fromPairs(itemHeightHashSetQueue),
        };

        itemHeightHashSetQueue.splice(0, itemHeightHashSetQueue.length);

        return itemHeightHashNew;
      });
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const frameRef = useRef();

  // get entries
  const entries = useMemo(() => {
    const entries = [];
    let offset = 0;
    for (const [index, item] of data.entries()) {
      const key = String(keyExtractor(item, index));
      let height = itemHeightHash[key];
      const heightLoaded = height !== undefined;
      // first item defaulting to 0, so it smoothly apears
      height ||= index ? DEFAULT_HEIGHT : 0;
      entries.push({
        item,
        key,
        height,
        heightLoaded,
        offset,
        index,
      });
      offset += height;
    }
    return entries;
  }, [data, itemHeightHash]);

  // scroll to bottom by default
  useLayoutEffect(() => {
    const frame = frameRef.current;
    frame.scrollTop = frame.scrollHeight;
  }, [entries[0].key]);

  // increase container size
  const lastEntry = entries[entries.length - 1];
  const offsetEnd = lastEntry ? lastEntry.offset + lastEntry.height : null;
  const [offsetEndDebounced] = useDebounce(offsetEnd, 100);
  useEffect(() => {
    let containerHeightNew = containerHeight;
    if (endOfList) {
      containerHeightNew = Math.max(
        offsetEndDebounced,
        frameRef.current.clientHeight,
      );
    } else {
      if (range && range[1] + CONTAINER_EXTEND > containerHeight)
        containerHeightNew =
          Math.ceil((range[1] + CONTAINER_EXTEND) / CONTAINER_PAGE) *
          CONTAINER_PAGE;
    }
    if (containerHeight !== containerHeightNew)
      window.requestAnimationFrame(() =>
        containerHeightSet(containerHeightNew),
      );
  }, [range, containerHeight, offsetEndDebounced, endOfList]);

  // adjust scrollTop when container size increases
  const oldContainerHeightRef = useRef(null);
  useLayoutEffect(() => {
    if (oldContainerHeightRef.current !== null) {
      const delta = containerHeight - oldContainerHeightRef.current;
      frameRef.current.scrollTop += Math.abs(delta);
    }

    return () => {
      oldContainerHeightRef.current = containerHeight;
    };
  }, [containerHeight]);

  // onEndReached
  const onEndReachedLockRef = useRef(false);
  useEffect(() => {
    if (!range) return;
    if (range[1] + ONENDREACHED_EXTEND < offsetEnd) return;
    if (endOfList) return;
    if (onEndReachedLockRef.current) return;

    onEndReachedLockRef.current = true;
    setTimeout(() => {
      onEndReachedLockRef.current = false;
    }, ONENDREACHED_THROTTLE);
    window.requestAnimationFrame(() => {
      onEndReached();
    });
  }, [endOfList, range, offsetEnd]);

  // set range without first scroll event
  useLayoutEffect(() => {
    const range = [0, frameRef.current.clientHeight];
    rangeSet(range);
  }, []);

  return (
    <div
      {...others}
      style={{
        ...others.style,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "stretch",
      }}
    >
      <div
        // frame
        ref={frameRef}
        style={{
          flex: "1 0 auto",
          overflowY: "auto",
          height: 0,
        }}
        onScroll={() => {
          const { scrollTop, clientHeight, scrollHeight } = frameRef.current;
          const range = [
            //
            scrollHeight - clientHeight - scrollTop,
            scrollHeight - scrollTop,
          ];
          rangeSetThrottled(range);
        }}
      >
        <div
          // container
          style={{
            height: containerHeight,
            position: "relative",
            overflow: "hidden",
          }}
        >
          {range &&
            entries
              .filter(
                (entry) =>
                  entry.offset + entry.height + EXTEND > range[0] &&
                  entry.offset - entry.height - EXTEND < range[1],
              )
              .map((entry) => (
                <Entry
                  key={entry.key}
                  entry={entry}
                  renderItem={renderItem}
                  onHeightChange={(height) =>
                    itemHeightHashSetQueue.push([entry.key, height])
                  }
                  style={{
                    position: "absolute",
                    bottom: entry.offset,
                    left: 0,
                    right: 0,
                  }}
                />
              ))}
        </div>
      </div>
    </div>
  );
}

function Entry({ entry, renderItem, onHeightChange, ...others }) {
  const { ref, height } = useComponentSize();
  useLayoutEffect(() => {
    if (!height) return;
    if (entry.height === height) return;
    onHeightChange(height);
  }, [entry.height, height]);

  return (
    <div
      ref={ref}
      {...others}
      style={{
        visibility: entry.heightLoaded ? "visible" : "hidden",
        ...others.style,
      }}
    >
      {renderItem({ item: entry.item, index: entry.index })}
    </div>
  );
}
