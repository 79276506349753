import { useState, useEffect } from "react";
import { Popper, Paper } from "@mui/material";

import { IS_TEST } from "helpers/config";
import isTouchDevice from "helpers/isTouchDevice";
import useDOMEvent from "hooks/useDOMEvent";

export default function TooltipProvider({ children }) {
  const [element, elementSet] = useState(null);

  useEffect(() => {
    if (!element) return;

    const elementTitle = element.getAttribute("title");
    element.removeAttribute("title");
    return () => {
      if (elementTitle === null) return;
      element.setAttribute("title", elementTitle);
    };
  }, [element]);

  useDOMEvent(
    "mouseenter",
    (event) => {
      if (IS_TEST || isTouchDevice) return;
      // fired for removed element
      if (event.target === document) return elementSet(null);
      if (!event.target.getAttribute("title")) return;

      event.target.setAttribute("fms-tooltip-title", event.target.title);
      event.target.setAttribute("aria-label", event.target.title);
      elementSet(event.target);
    },
    [],
    () => document,
    { passive: true, capture: true },
  );

  useDOMEvent(
    "mouseleave",
    (event) => {
      if (event.target === document) return;
      if (!event.target.getAttribute("fms-tooltip-title")) return;
      elementSet(null);
    },
    [],
    () => document,
    { passive: true, capture: true },
  );

  return (
    <>
      <Popper
        open={!!element}
        anchorEl={element}
        style={{
          zIndex: 10000,
          pointerEvents: "none",
          opacity: 0.6,
        }}
      >
        <Paper
          style={{
            padding: "2px 5px",
            backgroundColor: "#000",
            color: "#fff",
            fontSize: 12,
          }}
          aria-hidden="true"
          variant="outlined"
        >
          {element?.getAttribute("fms-tooltip-title")}
        </Paper>
      </Popper>
      {children}
    </>
  );
}
