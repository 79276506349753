import React from "react";
import { gql } from "@apollo/client";

import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";
import ItemPath, { ItemPathFragment } from "blocks/ItemPath";
import { ProjectIcon } from "controls/icons";
import DateTime from "controls/DateTime";
import ObjectCard from "controls/ObjectCard";
import CardLoader from "loaders/CardLoader";

export const ProjectCardFragment = gql`
  fragment ProjectCardFragment on Project {
    id
    name
    createdAt
    folder {
      id
      ...ItemPathFragment
    }
    properties {
      id
      label
      value
    }
  }
  ${ItemPathFragment}
`;

export default function ProjectCard({ project, ...others }) {
  const { teamId } = useRoute(teamRoute);
  if (!project) return <CardLoader content />;
  return (
    <ObjectCard
      icon={<ProjectIcon />}
      title={project?.name}
      subheader={<ItemPath item={project?.folder} />}
      fields={[
        ...(project?.properties.map((property) => ({
          label: property.label,
          content: property.value,
        })) || []),
        {
          label: "创建时间",
          content: <DateTime value={project.createdAt} />,
        },
      ]}
      href={`/${teamId}/${project.folder.id}`}
      {...others}
    />
  );
}
