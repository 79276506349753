import React from "react";
import { Paper } from "@mui/material";

import { canvasDocumentRoute } from "helpers";
import { useRoute } from "hooks";
import { documentContentContext } from "contexts";
import DocumentContent from "views/documents/DocumentContent";

export default function DocumentCanvas() {
  const { documentId } = useRoute(canvasDocumentRoute);

  return (
    <documentContentContext.Provider
      value={{ documentContentMode: "view", printing: true }}
    >
      <Paper style={{ boxShadow: "none", borderRadius: 0 }}>
        <DocumentContent documentId={documentId} />
      </Paper>
    </documentContentContext.Provider>
  );
}
