// This file is generated by updateSchema.mjs. Please do not edit manually.

const schema = {
  possibleTypes: [
    ["Favoritable", ["DataQuery", "Document", "File", "Folder"]],
    ["Item", ["Document", "File", "Folder"]],
    ["ItemUnion", ["Document", "File", "Folder"]],
    [
      "Previewable",
      [
        "Document",
        "File",
        "FileRevision",
        "Folder",
        "Message",
        "RegistrationFile",
        "ShareItem",
      ],
    ],
    ["PropertiesOwner", ["Company", "Project"]],
    [
      "Record",
      [
        "Activity",
        "Cell",
        "ChangelogPost",
        "Chat",
        "Company",
        "CompanyRegistration",
        "CompanyRole",
        "DataQuery",
        "DeletedItem",
        "Document",
        "Field",
        "File",
        "FileRevision",
        "Folder",
        "Integration",
        "Message",
        "Notice",
        "Notification",
        "PTemplate",
        "PTemplateItem",
        "Page",
        "Permission",
        "Picklist",
        "Project",
        "ProjectCompany",
        "Property",
        "RegistrationFile",
        "Session",
        "ShareLink",
        "Signature",
        "SupportComment",
        "SupportIssue",
        "Team",
        "User",
        "Workflow",
        "WorkflowField",
        "WorkflowStep",
      ],
    ],
    [
      "RecordUnion",
      [
        "Activity",
        "Cell",
        "ChangelogPost",
        "Chat",
        "Company",
        "CompanyRegistration",
        "CompanyRole",
        "DataQuery",
        "DeletedItem",
        "Document",
        "Field",
        "File",
        "FileRevision",
        "Folder",
        "Integration",
        "Message",
        "Notice",
        "Notification",
        "PTemplate",
        "PTemplateItem",
        "Page",
        "Permission",
        "Picklist",
        "Project",
        "ProjectCompany",
        "Property",
        "RegistrationFile",
        "Session",
        "ShareLink",
        "Signature",
        "SupportComment",
        "SupportIssue",
        "Team",
        "User",
        "Workflow",
        "WorkflowField",
        "WorkflowStep",
      ],
    ],
    [
      "Searchable",
      [
        "Activity",
        "Document",
        "File",
        "Folder",
        "Picklist",
        "Project",
        "ShareLink",
        "User",
      ],
    ],
  ],
  pagedFields: [
    ["Chat", "messages"],
    ["Company", "activities"],
    ["Company", "companyRoles"],
    ["Company", "projectCompanies"],
    ["Company", "projects"],
    ["Company", "users"],
    ["Document", "activities"],
    ["Document", "fields"],
    ["Document", "items"],
    ["Document", "itemsToSync"],
    ["Document", "workflows"],
    ["File", "activities"],
    ["File", "items"],
    ["File", "itemsToSync"],
    ["Folder", "activities"],
    ["Folder", "inheritedPermissions"],
    ["Folder", "items"],
    ["Folder", "itemsToSync"],
    ["Folder", "permissions"],
    ["Item", "activities"],
    ["Item", "items"],
    ["Item", "itemsToSync"],
    ["Login", "notifications"],
    ["Login", "supportIssues"],
    ["PTemplate", "pTemplateItems"],
    ["Project", "activities"],
    ["Project", "companies"],
    ["Project", "documents"],
    ["Project", "files"],
    ["Project", "projectCompanies"],
    ["Query", "changelogPosts"],
    ["Query", "teams"],
    ["Share", "items"],
    ["SupportIssue", "supportComments"],
    ["Team", "activities"],
    ["Team", "auditRequests"],
    ["Team", "chats"],
    ["Team", "companies"],
    ["Team", "companyRegistrations"],
    ["Team", "documents"],
    ["Team", "folders"],
    ["Team", "items"],
    ["Team", "messages"],
    ["Team", "pTemplates"],
    ["Team", "picklists"],
    ["Team", "projects"],
    ["Team", "sessions"],
    ["Team", "shareLinks"],
    ["Team", "signatures"],
    ["Team", "users"],
    ["User", "activities"],
    ["User", "companies"],
    ["User", "companyRoles"],
    ["User", "sessions"],
    ["User", "warningMessages"],
  ],
  argedFields: [
    ["Chat", "messages"],
    ["Chat", "messagesCount"],
    ["Company", "activities"],
    ["Company", "activitiesCount"],
    ["Company", "companyRoles"],
    ["Company", "projectCompanies"],
    ["Company", "projects"],
    ["Company", "users"],
    ["Company", "usersCount"],
    ["Document", "activities"],
    ["Document", "activitiesCount"],
    ["Document", "dataRowNames"],
    ["Document", "fields"],
    ["Document", "items"],
    ["Document", "itemsCount"],
    ["Document", "itemsToSync"],
    ["Document", "itemsToSyncCount"],
    ["Document", "itemsToSyncSize"],
    ["Document", "workflows"],
    ["File", "activities"],
    ["File", "activitiesCount"],
    ["File", "dataRowNames"],
    ["File", "items"],
    ["File", "itemsCount"],
    ["File", "itemsToSync"],
    ["File", "itemsToSyncCount"],
    ["File", "itemsToSyncSize"],
    ["Folder", "activities"],
    ["Folder", "activitiesCount"],
    ["Folder", "dataRowNames"],
    ["Folder", "inheritedPermissions"],
    ["Folder", "items"],
    ["Folder", "itemsCount"],
    ["Folder", "itemsToSync"],
    ["Folder", "itemsToSyncCount"],
    ["Folder", "itemsToSyncSize"],
    ["Folder", "permissions"],
    ["Folder", "permissionsCount"],
    ["Item", "activities"],
    ["Item", "activitiesCount"],
    ["Item", "dataRowNames"],
    ["Item", "items"],
    ["Item", "itemsCount"],
    ["Item", "itemsToSync"],
    ["Item", "itemsToSyncCount"],
    ["Item", "itemsToSyncSize"],
    ["Login", "notifications"],
    ["Login", "notificationsCount"],
    ["Login", "supportIssues"],
    ["Login", "supportIssuesCount"],
    ["PTemplate", "pTemplateItems"],
    ["Picklist", "options"],
    ["Project", "activities"],
    ["Project", "activitiesCount"],
    ["Project", "companies"],
    ["Project", "documentField"],
    ["Project", "documents"],
    ["Project", "files"],
    ["Project", "projectCompanies"],
    ["Query", "activity"],
    ["Query", "auditRequest"],
    ["Query", "auditVersion"],
    ["Query", "cell"],
    ["Query", "changelogPost"],
    ["Query", "changelogPosts"],
    ["Query", "changelogPostsCount"],
    ["Query", "chat"],
    ["Query", "clientApp"],
    ["Query", "company"],
    ["Query", "companyRegister"],
    ["Query", "companyRegistration"],
    ["Query", "companyRole"],
    ["Query", "dataQuery"],
    ["Query", "deletedItem"],
    ["Query", "document"],
    ["Query", "documents"],
    ["Query", "field"],
    ["Query", "file"],
    ["Query", "folder"],
    ["Query", "integration"],
    ["Query", "item"],
    ["Query", "itemUnion"],
    ["Query", "items"],
    ["Query", "message"],
    ["Query", "notification"],
    ["Query", "pTemplate"],
    ["Query", "pTemplateItem"],
    ["Query", "page"],
    ["Query", "picklist"],
    ["Query", "previewable"],
    ["Query", "project"],
    ["Query", "projectCompanies"],
    ["Query", "projectCompany"],
    ["Query", "projects"],
    ["Query", "propertiesOwner"],
    ["Query", "property"],
    ["Query", "session"],
    ["Query", "share"],
    ["Query", "shareLink"],
    ["Query", "signature"],
    ["Query", "supportIssue"],
    ["Query", "tWorkflow"],
    ["Query", "team"],
    ["Query", "teams"],
    ["Query", "user"],
    ["Query", "workflow"],
    ["Session", "user"],
    ["Share", "item"],
    ["Share", "items"],
    ["Share", "itemsCount"],
    ["SupportIssue", "supportComments"],
    ["Team", "activities"],
    ["Team", "auditRequests"],
    ["Team", "auditRequestsCount"],
    ["Team", "chatForUsers"],
    ["Team", "chats"],
    ["Team", "chatsCount"],
    ["Team", "companies"],
    ["Team", "companiesCount"],
    ["Team", "companyRegistrations"],
    ["Team", "companyRegistrationsCount"],
    ["Team", "deletedItems"],
    ["Team", "documents"],
    ["Team", "documentsCount"],
    ["Team", "fieldLabelCounts"],
    ["Team", "fieldValueCounts"],
    ["Team", "folders"],
    ["Team", "foldersCount"],
    ["Team", "items"],
    ["Team", "itemsCount"],
    ["Team", "messages"],
    ["Team", "messagesCount"],
    ["Team", "pTemplate"],
    ["Team", "pTemplates"],
    ["Team", "picklists"],
    ["Team", "picklistsCount"],
    ["Team", "projects"],
    ["Team", "projectsCount"],
    ["Team", "propertyLabelCounts"],
    ["Team", "propertyValueCounts"],
    ["Team", "sessions"],
    ["Team", "shareLinks"],
    ["Team", "signatures"],
    ["Team", "users"],
    ["Team", "usersCount"],
    ["User", "activities"],
    ["User", "activitiesCount"],
    ["User", "companies"],
    ["User", "companyRoles"],
    ["User", "sessions"],
    ["User", "warningMessages"],
    ["__Directive", "args"],
    ["__Field", "args"],
    ["__Type", "enumValues"],
    ["__Type", "fields"],
    ["__Type", "inputFields"],
  ],
};
export default schema;
