import React from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import RightDrawerProvider from "providers/RightDrawerProvider";
import apolloClient from "helpers/apolloClient";
import { DESKTOP_BUILD } from "helpers/config";
import MessagesProvider from "providers/MessagesProvider";
import InitProvider from "providers/InitProvider";
import AppErrorBoundary from "providers/AppErrorBoundary";
import HooksPortalProvider, {
  HooksPortalRenderer,
} from "providers/HooksPortalProvider";
import AppSettingsProvider from "providers/AppSettingsProvider";
import AuthProvider from "providers/AuthProvider";
import MUIProvider from "providers/MUIProvider";
import ProgressBarProvider from "providers/ProgressBarProvider";
import FormDialogsProvider from "providers/FormDialogsProvider";
import ServiceWorkerNotification from "providers/ServiceWorkerNotification";
import TooltipProvider from "providers/TooltipProvider";
import RouteEventListener from "views/RouteEventListener";
import SpinnerEventListener from "views/SpinnerEventListener";
import ErrorEventListener from "views/ErrorEventListener";
import DesktopStateProvider from "providers/DesktopStateProvider";
import MainView from "views/MainView";

export default App;

const Router = DESKTOP_BUILD ? HashRouter : BrowserRouter;

function App() {
  return (
    <>
      <MUIProvider>
        <CssBaseline />
        <TooltipProvider>
          <HooksPortalProvider>
            <ErrorEventListener />
            <SpinnerEventListener />
            <ProgressBarProvider>
              <Router>
                <RouteEventListener />
                <AppErrorBoundary>
                  <ApolloProvider client={apolloClient}>
                    <MessagesProvider>
                      <AppSettingsProvider>
                        <InitProvider>
                          <FormDialogsProvider>
                            <AuthProvider>
                              <RightDrawerProvider>
                                <DesktopStateProvider>
                                  <HooksPortalRenderer />
                                  <MainView />
                                  <ServiceWorkerNotification />
                                </DesktopStateProvider>
                              </RightDrawerProvider>
                            </AuthProvider>
                          </FormDialogsProvider>
                        </InitProvider>
                      </AppSettingsProvider>
                    </MessagesProvider>
                  </ApolloProvider>
                </AppErrorBoundary>
              </Router>
            </ProgressBarProvider>
          </HooksPortalProvider>
        </TooltipProvider>
      </MUIProvider>
    </>
  );
}
