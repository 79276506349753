import React, { useState } from "react";
import { Button } from "@mui/material";

export default function FetchMoreButton({
  fetchMore,
  rows,
  rowsCount = null,
  ...others
}) {
  const [offset, offsetSet] = useState();
  const [fetchingMore, fetchingMoreSet] = useState(false);
  const endOfPage =
    rowsCount != null ? rows?.length >= rowsCount : offset === rows?.length;

  return (
    <div {...others} style={{ padding: 5, ...others.style }}>
      <Button
        fullWidth
        variant="outlined"
        style={{ padding: 10 }}
        onClick={async () => {
          fetchingMoreSet(true);
          try {
            await fetchMore({
              variables: {
                offset: rows.length,
              },
            });
            offsetSet(rows.length);
          } finally {
            fetchingMoreSet(false);
          }
        }}
        disabled={fetchingMore || !rows || endOfPage}
      >
        {endOfPage && <>没有更多</>}
        {fetchingMore && <>正在加载...</>}
        {!fetchingMore && !!rows && !endOfPage && <>加载更多</>}
        {!!rows && (
          <>
            (已加载{rows.length}/{rowsCount === null ? "?" : rowsCount}项)
          </>
        )}
      </Button>
    </div>
  );
}
