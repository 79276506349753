import React from "react";
import { gql } from "@apollo/client";
import { SwapVertical, Pencil, Delete, Plus } from "mdi-material-ui";

import {
  useRoute,
  useData,
  useFormDialog,
  useAction,
  useConfirmDialog,
} from "hooks";
import { teamRoute, showMessage } from "helpers";
import {
  ActionFormDialog,
  Table,
  Markdown,
  ComponentCell,
  TabContent,
} from "controls";
import { SortInput } from "inputs";

export default function NoticesTab() {
  const { teamId } = useRoute(teamRoute);
  const formDialog = useFormDialog();
  const confirmDialog = useConfirmDialog();

  const data = useData(
    gql`
      query NoticesTab($teamId: ID!) {
        team(id: $teamId) {
          id
          name
          notices {
            id
            title
            content
          }
          canManageNotices
        }
      }
    `,
    { teamId },
  );

  const addNotice = useAction(gql`
    mutation ($input: AddNoticeInput!) {
      addNotice(input: $input) {
        team {
          id
          notices {
            id
          }
        }
      }
    }
  `);

  const noticeRemove = useAction(gql`
    mutation ($input: NoticeRemoveInput!) {
      noticeRemove(input: $input) {
        team {
          id
          notices {
            id
          }
        }
      }
    }
  `);

  const noticeUpdate = useAction(gql`
    mutation ($input: NoticeUpdateInput!) {
      noticeUpdate(input: $input) {
        notice {
          id
          title
          content
        }
      }
    }
  `);

  const sortNotices = useAction(gql`
    mutation ($input: SortNoticesInput!) {
      sortNotices(input: $input) {
        team {
          id
          notices {
            id
          }
        }
      }
    }
  `);

  return (
    <TabContent
      actions={[
        {
          icon: <Plus />,
          title: "创建公告",
          disabled: !data?.team.canManageNotices,
          onClick: () =>
            formDialog({
              title: "创建公告",
              formDialogComponent: ActionFormDialog,
              fields: [
                {
                  label: "标题",
                  name: "title",
                },
                {
                  label: "内容",
                  name: "content",
                  options: {
                    multiline: true,
                    minRows: 5,
                  },
                },
              ],
              onSubmit: async (formData) => {
                await addNotice({
                  input: {
                    teamId,
                    ...formData,
                  },
                });
              },
            }),
        },
        {
          title: "调整公告顺序",
          icon: <SwapVertical />,
          disabled: !data?.team.canManageNotices,
          onClick: () =>
            formDialog({
              formDialogComponent: ActionFormDialog,
              title: "调整公告顺序",
              fields: [
                {
                  label: "顺序",
                  name: "noticeIds",
                  required: true,
                  inputComponent: SortInput,
                  value: data?.team.notices.map((n) => n.id),
                  options: {
                    items: data?.team.notices.map((n) => ({
                      value: n.id,
                      label: n.title || "(无标题公告)",
                    })),
                  },
                },
              ],
              onSubmit: async (formData) => {
                await sortNotices({
                  input: {
                    teamId,
                    ...formData,
                  },
                });
              },
            }),
        },
      ]}
    >
      <Table
        columns={[
          {
            label: "标题",
          },
          {
            label: "内容",
            cellComponent: ComponentCell,
          },
        ]}
        rows={data?.team.notices.map((notice) => ({
          title: notice.title,
          values: [
            notice.title || "(无标题公告)",
            {
              component: <Markdown source={notice.content} />,
            },
          ],
          actions: [
            {
              title: "修改公告",
              icon: <Pencil />,
              onClick: () =>
                formDialog({
                  title: "修改公告",
                  formDialogComponent: ActionFormDialog,
                  fields: [
                    {
                      label: "标题",
                      name: "title",
                      value: notice.title,
                    },
                    {
                      label: "内容",
                      name: "content",
                      value: notice.content,
                      options: {
                        multiline: true,
                        minRows: 5,
                      },
                    },
                  ],
                  onSubmit: async (formData) => {
                    await noticeUpdate({
                      input: {
                        noticeId: notice.id,
                        ...formData,
                      },
                    });
                  },
                }),
            },
            {
              label: "删除",
              icon: <Delete />,
              onClick: async () => {
                await confirmDialog();
                await noticeRemove({ input: { noticeId: notice.id } });
                showMessage({ message: "成功删除公告" });
              },
            },
          ],
        }))}
      />
    </TabContent>
  );
}
