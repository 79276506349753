import React from "react";
import { gql } from "graphql-tag";

import { useAction } from "hooks";
import { FolderInput, FileUploadsInput } from "inputs";
import { FormDialog } from "controls";
import { showMessage } from "helpers";

export default React.memo(UploadFilesDialog);
function UploadFilesDialog({ folderId, onClose }) {
  const uploadFiles = useAction(gql`
    mutation UploadFilesDialog($input: UploadFilesInput!) {
      uploadFiles(input: $input) {
        folder {
          id
          items {
            id
          }
          files {
            id
          }
        }
      }
    }
  `);

  return (
    <FormDialog
      title="上传文档"
      onClose={onClose}
      fields={[
        {
          label: "位置",
          name: "folderId",
          value: folderId,
          inputComponent: FolderInput,
          required: true,
        },
        {
          label: "要上传的文档",
          name: "fileUploads",
          value: [],
          inputComponent: FileUploadsInput,
          required: true,
        },
      ]}
      onSubmit={async (formData) => {
        await uploadFiles({
          input: {
            ...formData,
          },
        });
        showMessage({ message: "成功上传文档" });
      }}
    />
  );
}
