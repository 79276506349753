import React from "react";
import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import { Plus, Delete } from "mdi-material-ui";

import { teamRoute, showMessage } from "helpers";
import {
  useData,
  useAction,
  useRoute,
  useConfirmDialog,
  useDialogOpener,
} from "hooks";
import {
  TabContent,
  Message,
  Table,
  ComponentCell,
  DateTimeCell,
} from "controls";
import AddSignatureDialog from "views/signatures/AddSignatureDialog";
import { SIGNATURE_WIDTH, SIGNATURE_HEIGHT } from "inputs/SignatureInput";

export default function SignaturesTab() {
  const confirmDialog = useConfirmDialog();
  const { teamId } = useRoute(teamRoute);
  const openAddSignatureDialog = useDialogOpener(AddSignatureDialog);
  const data = useData(
    gql`
      query SignaturesTab($teamId: ID!) {
        team(id: $teamId) {
          id
          signatures {
            id
            name
            blobUrl
            createdAt
          }
        }
      }
    `,
    { teamId },
  );

  const signatureRemove = useAction(gql`
    mutation ($input: SignatureRemoveInput!) {
      signatureRemove(input: $input) {
        team {
          id
          signatures {
            id
          }
        }
      }
    }
  `);

  return (
    <TabContent
      actions={[
        {
          title: "添加签名",
          icon: <Plus />,
          onClick: () => openAddSignatureDialog(),
        },
      ]}
    >
      <Message>签名可用于填写表单中的签名字段</Message>
      <Table
        columns={[
          { label: "名称" },
          { label: "创建时间", cellComponent: DateTimeCell },
          { label: "内容", cellComponent: ComponentCell },
        ]}
        rows={data?.team.signatures.map((signature) => ({
          id: signature.id,
          title: signature.name,
          values: [
            signature.name,
            signature.createdAt,
            {
              component: (
                <Paper
                  component="img"
                  src={signature.blobUrl}
                  alt="签名图片"
                  style={{
                    width: SIGNATURE_WIDTH,
                    height: SIGNATURE_HEIGHT,
                    margin: 5,
                  }}
                />
              ),
            },
          ],
          actions: [
            {
              icon: <Delete />,
              title: "删除该签名",
              onClick: async () => {
                await confirmDialog();
                await signatureRemove({ input: { signatureId: signature.id } });
                showMessage({ message: "成功删除该签名" });
              },
            },
          ],
        }))}
      />
    </TabContent>
  );
}
