import React from "react";
import gql from "graphql-tag";

import { userRoute } from "helpers";
import { useRoute, useData, useActivitiesFilterBar, useList } from "hooks";
import { ActivityCard, ActivityCardFragment } from "blocks";
import { TabContent, CardsList } from "controls";
import ActivitiesTable, {
  ActivitiesTableFragment,
} from "lists/ActivitiesTable";

export default function ActivitiesTab() {
  const { userId } = useRoute(userRoute);
  const [filter, filterBar] = useActivitiesFilterBar({ hideUserFilter: true });
  const [data, { fetchMore }] = useData(
    gql`
      query Activities($userId: ID!, $offset: Int, $filter: ActivitiesFilter!) {
        user(id: $userId) {
          id
          activities(offset: $offset, filter: $filter, limit: 10) {
            id
            createdAt
            ...ActivityCardFragment
            ...ActivitiesTableFragment
          }
          activitiesCount(filter: $filter)
        }
      }
      ${ActivityCardFragment}
      ${ActivitiesTableFragment}
    `,
    { userId, filter },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("activity", {
    rows: data?.user.activities,
    rowsCount: data?.user.activitiesCount,
    fetchMore,
    card: <ActivityCard />,
    table: <ActivitiesTable />,
    cardsList: <CardsList getRowDate={(activity) => activity.createdAt} />,
  });

  return (
    <TabContent toolbar={<>{listModeSwitcher}</>}>
      {filterBar}
      {list}
    </TabContent>
  );
}
