import React from "react";
import gql from "graphql-tag";

import TextLoader from "loaders/TextLoader";

import UserAvatar, { UserAvatarFragment } from "./UserAvatar";

export const UserLabelFragment = gql`
  fragment UserLabelFragment on User {
    name
    ...UserAvatarFragment
  }
  ${UserAvatarFragment}
`;

export default React.memo(UserLabel);

function UserLabel({ user, ...others }) {
  return (
    <span {...others}>
      <UserAvatar
        component="div"
        user={user}
        style={{
          height: "1.6em",
          width: "1.6em",
          fontSize: "inherit",
          display: "inline-flex",
          margin: "-.3em .3em",
          verticalAlign: "text-top",
        }}
      />
      <span>{user?.name || <TextLoader />}</span>
    </span>
  );
}
