import React from "react";
import {
  FormatText,
  TextLong,
  FormatListCheckbox,
  CalendarMonth,
  Image,
  Numeric,
} from "mdi-material-ui";

import { SignatureIcon } from "controls/icons";

import FieldText from "./fields/FieldText";
import FieldTextLong from "./fields/FieldTextLong";
import FieldOptions from "./fields/FieldOptions";
import FieldDate from "./fields/FieldDate";
import FieldNumber from "./fields/FieldNumber";
import FieldPhoto from "./fields/FieldPhoto";
import FieldSignature from "./fields/FieldSignature";

export const getFieldType = (name) => FIELD_TYPES.find((t) => t.name === name);
const FIELD_TYPES = [
  {
    label: "文本字段",
    name: "text",
    icon: <FormatText />,
    component: FieldText,
  },
  {
    label: "文本段落字段",
    name: "text_long",
    icon: <TextLong />,
    component: FieldTextLong,
  },
  {
    label: "选项字段",
    name: "options",
    icon: <FormatListCheckbox />,
    component: FieldOptions,
  },
  {
    label: "日期字段",
    name: "date",
    icon: <CalendarMonth />,
    component: FieldDate,
  },
  {
    label: "数值字段",
    name: "number",
    icon: <Numeric />,
    component: FieldNumber,
  },
  {
    label: "插图字段",
    name: "photo",
    icon: <Image />,
    component: FieldPhoto,
  },
  {
    label: "签名字段",
    name: "signature",
    icon: <SignatureIcon />,
    component: FieldSignature,
  },
];
export default FIELD_TYPES;
