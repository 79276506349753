import { gql } from "@apollo/client";

import { useData, useRoute, useActionFragment } from "hooks";
import { ActionFormDialog, FieldsContainer } from "controls";
import { CompanyInput, UserInput, SuggestiveTextInput } from "inputs";
import { teamRoute } from "helpers";

export function CompanyRoleUpdateDialog({ companyRoleId, ...others }) {
  const data = useData(
    gql`
      query CompanyRoleUpdateDialog($companyRoleId: ID!) {
        companyRole(id: $companyRoleId) {
          id
          title
          user {
            id
          }
          company {
            id
          }
        }
      }
    `,
    { companyRoleId },
  );

  const companyRoleUpdate = useActionFragment(
    "companyRoleUpdate",
    "companyRole { id title }",
  );

  return (
    <ActionFormDialog
      title="修改单位角色"
      disabled={!data}
      fieldsContainer={
        <CompanyRoleFields companyRole={data?.companyRole || {}} />
      }
      onSubmit={async (formData) => {
        await companyRoleUpdate({ input: { companyRoleId, ...formData } });
      }}
      {...others}
    />
  );
}

export function CompanyAddCompanyRoleDialog({ companyId, ...others }) {
  const companyAddCompanyRole = useActionFragment(
    "companyAddCompanyRole",
    "company { companyRoles { id } users { id } }",
  );

  return (
    <ActionFormDialog
      title="修改单位角色"
      fieldsContainer={
        <CompanyRoleFields
          companyRole={{
            company: { id: companyId },
          }}
        />
      }
      onSubmit={async (formData) => {
        await companyAddCompanyRole({ input: { ...formData } });
      }}
      {...others}
    />
  );
}

function CompanyRoleFields({ companyRole, ...others }) {
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query CompanyRoleFields($teamId: ID!) {
        team(id: $teamId) {
          id
          companyRoleTitleCounts {
            value
          }
        }
      }
    `,
    { teamId },
  );

  return (
    <FieldsContainer
      {...others}
      fields={[
        {
          label: "单位",
          name: "companyId",
          readOnly: !!companyRole.id,
          value: companyRole.company?.id,
          required: true,
          inputComponent: CompanyInput,
        },
        {
          label: "成员",
          name: "userId",
          readOnly: !!companyRole.id,
          value: companyRole.user?.id,
          required: true,
          inputComponent: UserInput,
        },
        {
          label: "单位角色",
          name: "title",
          value: companyRole.title,
          inputComponent: SuggestiveTextInput,
          options: {
            suggestions: data?.team.companyRoleTitleCounts,
          },
        },
      ]}
    />
  );
}
