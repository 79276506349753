import { gql } from "@apollo/client";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  DialogContent,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { times } from "lodash-es";
import React, { useState } from "react";
import { Minus, Plus } from "mdi-material-ui";

import { AppForm, FieldsContainer, Message } from "controls";
import { companyRegisterRoute } from "helpers";
import { useActionFragment, useData, useRoute } from "hooks";
import DialogFooter from "views/viewport/DialogFooter";
import { OptionsInput, FileUploadsInput } from "inputs";
import { ListLoader } from "loaders";

export default function CompanyRegisterView() {
  const [numberOfContacts, numberOfContactsSet] = useState(1);
  const [submitting, submittingSet] = useState(false);
  const [submitted, submittedSet] = useState(false);
  const { token } = useRoute(companyRegisterRoute);
  const data = useData(
    gql`
      query CompanyRegisterView($token: String!) {
        companyRegister(token: $token) {
          teamName
          categoryOptions
        }
      }
    `,
    { token },
  );

  const cards = [
    {
      title: "单位信息",
      content: (
        <FieldsContainer
          fields={[
            {
              name: "name",
              label: "单位名称",
              required: true,
            },
            {
              name: "category",
              label: "单位类别",
              required: true,
              inputComponent: OptionsInput,
              options: {
                allowNewOption: !data?.companyRegister.categoryOptions?.length,
                options: data?.companyRegister.categoryOptions,
              },
            },
            {
              name: "qualification",
              label: "资质",
              required: true,
              inputComponent: FileUploadsInput,
              value: [],
              helperText: "请上传图片或PDF文件",
              options: {
                accept: ".pdf,image/*",
              },
            },
            {
              name: "license",
              label: "营业执照",
              required: true,
              inputComponent: FileUploadsInput,
              value: [],
              helperText: "请上传图片或PDF文件",
              options: {
                accept: ".pdf,image/*",
              },
            },
            {
              name: "unifiedCreditCode",
              label: "统一信用代码",
              required: true,
              value: "",
            },
            {
              name: "projects",
              label: "相关项目名称",
              helperText: "多个项目请用逗号分隔",
              value: "",
            },
          ]}
        />
      ),
    },
    {
      title: "收款信息",
      content: (
        <FieldsContainer
          fields={[
            {
              name: "accountName",
              label: "账户名称",
              required: true,
            },
            {
              name: "accountNumber",
              label: "账号",
              required: true,
            },
            {
              name: "accountBranch",
              label: "开户行",
              required: true,
            },
            {
              name: "accountBulkNumber",
              label: "大额行号",
              required: true,
            },
          ]}
        />
      ),
    },
    {
      title: "授权书",
      content: (
        <FieldsContainer
          fields={[
            {
              name: "delegationStatement",
              label: "授权书",
              required: true,
              inputComponent: FileUploadsInput,
              helperText: "请上传图片或PDF文件",
              value: [],
              options: {
                accept: ".pdf,image/*",
              },
            },
            {
              name: "delegationIddoc",
              label: "身份证",
              required: true,
              inputComponent: FileUploadsInput,
              helperText: "请上传图片或PDF文件",
              value: [],
              options: {
                accept: ".pdf,image/*",
              },
            },
          ]}
        />
      ),
    },
    ...times(numberOfContacts, (i) => ({
      title: "联系人信息",
      content: (
        <>
          <FieldsContainer
            fields={[
              {
                name: `contacts[${i}]name`,
                label: "姓名",
                required: true,
                options: {
                  autoComplete: "name",
                },
              },
              {
                name: `contacts[${i}]mobile`,
                label: "手机号",
                required: true,
                options: {
                  autoComplete: "tel",
                },
              },
              {
                name: `contacts[${i}]email`,
                label: "邮箱",
                options: {
                  autoComplete: "email",
                },
              },
              {
                name: `contacts[${i}]role`,
                label: "单位角色",
                required: true,
              },
            ]}
          />
          {i === numberOfContacts - 1 && (
            <CardActions>
              <Button
                type="button"
                onClick={() => numberOfContactsSet((n) => n + 1)}
              >
                <Plus />
                增加一个联系人
              </Button>
              {numberOfContacts > 1 && (
                <Button
                  type="button"
                  onClick={() => numberOfContactsSet((n) => n - 1)}
                >
                  <Minus />
                  删除该联系人
                </Button>
              )}
            </CardActions>
          )}
        </>
      ),
    })),
  ];
  const companyRegisterSubmit = useActionFragment("companyRegisterSubmit");

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        paddingTop: `env(safe-area-inset-top)`,
        paddingRight: `env(safe-area-inset-right)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLet: `env(safe-area-inset-left)`,
      }}
    >
      <Helmet>
        <title>{`单位注册表单 - ${data?.companyRegister?.teamName}`}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          maxWidth: 640,
          flex: "1 0 auto",
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        <Card
          style={{
            flex: "1 0 auto",
            margin: 20,
          }}
          elevation={10}
          component={AppForm}
          onSubmit={async (formData) => {
            submittingSet(true);
            try {
              const contacts = [];
              for (const i of times(numberOfContacts)) {
                const contact = {};
                for (const field of ["name", "mobile", "email", "role"]) {
                  contact[field] = formData[`contacts[${i}]${field}`];
                  delete formData[`contacts[${i}]${field}`];
                }
                contacts.push(contact);
              }
              await companyRegisterSubmit({
                input: {
                  token,
                  ...formData,
                  contacts,
                },
              });
            } finally {
              submittingSet(false);
            }
            submittedSet(true);
          }}
        >
          <CardHeader
            title={<Typography variant="h1">单位注册表单</Typography>}
            subheader={
              <Typography variant="h5">
                {data?.companyRegister?.teamName}
              </Typography>
            }
          />
          {!data ? (
            <ListLoader />
          ) : (
            <>
              {!data.companyRegister ? (
                <DialogContent>
                  <Message type="error">无效的注册链接</Message>
                </DialogContent>
              ) : (
                <>
                  {!submitted ? (
                    <>
                      {cards.map((card, cardIndex) => (
                        <Card
                          key={cardIndex}
                          style={{ margin: 10 }}
                          variant="outlined"
                        >
                          <CardHeader
                            title={
                              <Typography variant="h2">{card.title}</Typography>
                            }
                          />
                          <div style={{ margin: 5 }}>{card.content}</div>
                        </Card>
                      ))}
                      <CardActions>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting}
                        >
                          提交
                        </Button>
                        <Button type="reset">重置</Button>
                      </CardActions>
                    </>
                  ) : (
                    <DialogContent>
                      <Message type="success">
                        注册成功，我们会尽快审核您的注册信息。
                      </Message>
                    </DialogContent>
                  )}
                </>
              )}
            </>
          )}
        </Card>
        <DialogFooter />
      </div>
    </div>
  );
}
