const IGNORE_ERRORS = [
  // Maybe HOC defer's?
  "Can't perform a React state update on an unmounted component.",

  // old stuff relating class components
  "Cannot update during an existing state transition",

  // Material-UI warns when tabs are still loading
  "You have provided an out-of-range value",

  // usage of OutlinedBox
  "Expected an element type that can hold a ref",
  "Function components cannot be given refs",
];

const IGNORE_WARNINGS = [
  // False-positive as reducing elements from list
  "Cache data may be lost when replacing",
];

for (const [methodName, keywords] of [
  ["error", IGNORE_ERRORS],
  ["warn", IGNORE_WARNINGS],
]) {
  // eslint-disable-next-line no-console
  const original = console[methodName];
  // eslint-disable-next-line no-console
  console[methodName] = function (...args) {
    if (
      keywords.find((str) => args.find((arg) => arg?.includes?.call(arg, str)))
    )
      return;
    original.call(this, ...args);
  };
}
