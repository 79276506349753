import { useState, useEffect } from "react";

import { desktopStateContext } from "contexts";
import useDOMEvent from "hooks/useDOMEvent";
import useProgressBar from "hooks/useProgressBar";

window.fmsDesktop ||= {
  _commandsInvoked: [],

  async command(command, ...args) {
    this._commandsInvoked.push([command, ...args]);
    if (command === "getState") return window._fmsMockDesktopState || null;
    if (command === "selectFolderDialog") return "/fake/local/path";
    if (
      [
        "setToken",
        "addSyncEntry",
        "removeSyncEntry",
        "autoLaunchToggle",
        "installAutoUpdate",
      ].includes(command)
    )
      return;
    throw new Error(`Unknow command: ${command}`);
  },
};

export default function DesktopStateProvider({ children }) {
  const progressBar = useProgressBar();
  const [desktopState, desktopStateSet] = useState(null);

  useEffect(() => {
    progressBar(async () => {
      const desktopStateNew = await window.fmsDesktop.command("getState");
      desktopStateSet(desktopStateNew);
    });
  }, []);

  useDOMEvent("fmsDesktopStateChange", (event) =>
    desktopStateSet(event.detail),
  );

  return (
    <desktopStateContext.Provider value={desktopState}>
      {children}
    </desktopStateContext.Provider>
  );
}
