import React from "react";
import { gql } from "@apollo/client";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { FileFind, ViewGrid, ViewList } from "mdi-material-ui";

import GridList from "controls/GridList";
import ListLoader from "loaders/ListLoader";
import ItemObjectIcon from "blocks/ItemObjectIcon";
import useDialogOpener from "hooks/useDialogOpener";
import useModeSwitcher from "hooks/useModeSwitcher";
import PreviewDialog from "views/previewable/PreviewDialog";

export const ItemInput_ItemsFragment = gql`
  fragment ItemInput_ItemsFragment on Item {
    id
    blobUrl
    thumbnailUrl
    name
    path {
      id
      name
    }
  }
`;

export default function Items({
  items,
  selectedItemId,
  onItemSelect,
  enablePath,
}) {
  const openPreviewDialog = useDialogOpener(PreviewDialog);
  const [mode, modeSwitcher] = useModeSwitcher(
    "ItemInput_Items",
    [
      {
        name: "list",
        title: "列表",
        icon: <ViewList />,
      },
      {
        name: "grid",
        title: "缩略图",
        icon: <ViewGrid />,
      },
    ],
    { noUrl: true, remember: true },
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-end",
        }}
      >
        {modeSwitcher}
      </div>
      {mode === "list" && (
        <>
          {!items && <ListLoader subheaders={enablePath} avatars />}
          <List>
            {items?.map((item) => (
              <ListItem
                key={item.id}
                component="a"
                href="#"
                button
                selected={selectedItemId === item.id}
                onClick={() => onItemSelect(item.id)}
              >
                <ItemObjectIcon itemId={item.id} />
                <ListItemText
                  primary={item.name}
                  secondary={
                    enablePath
                      ? item.path.map((p) => p.name).join("->")
                      : undefined
                  }
                />
                {!!item.blobUrl && (
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() =>
                        openPreviewDialog({ previewableId: item.id })
                      }
                      title="预览"
                    >
                      <FileFind />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </>
      )}
      {mode === "grid" && (
        <GridList
          rows={items?.map((item) => ({
            id: item.id,
            onClick: () => onItemSelect(item.id),
            title: item.name,
            thumbnailUrl: item.thumbnailUrl,
            selected: item.id === selectedItemId,
            icon: <ItemObjectIcon itemId={item.id} />,
          }))}
        />
      )}
    </>
  );
}
