import { gql } from "@apollo/client";
import {
  Step,
  StepContent,
  StepLabel,
  Table,
  Stepper,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import { UserLabelFragment } from "blocks";
import { UsersLabelFragment } from "blocks";
import { DateTime, Message, ObjectCard } from "controls";
import { showMessage } from "helpers";
import { useActionFragment, useConfirmDialog, useFormDialog } from "hooks";
import { UsersInput } from "inputs";
import { Check, Play } from "mdi-material-ui";
import { Fragment, useEffect, useState } from "react";
import Field, { FieldFragment } from "views/documents/Field";
import { documentContentContext } from "contexts";
import { UsersLabel } from "blocks";
import { UserLabel } from "blocks";

export const WorkflowCardFragment = gql`
  fragment WorkflowCardFragment on Workflow {
    id
    name
    workflowSteps {
      id
      name
      active
      canFill
      filled
      requested
      canRequest
      canFinish
      finished
      finishedAt
      finishedByUser {
        id
        ...UserLabelFragment
      }
      users {
        id
        ...UsersLabelFragment
      }
      workflowFields {
        id
        fieldLabel
        field {
          id
          ...FieldFragment
          cell {
            id
            height
            width
            padding
          }
        }
      }
    }
  }
  ${FieldFragment}
  ${UserLabelFragment}
  ${UsersLabelFragment}
`;

export default function WorkflowCard({ workflow, dataMeta }) {
  const activeWorkflowStepIndex = workflow.workflowSteps.findIndex(
    (ws) => ws.active,
  );

  const [activeStepIndex, activeStepIndexSet] = useState(
    activeWorkflowStepIndex,
  );
  const formDialog = useFormDialog();
  const workflowStepRequest = useActionFragment("workflowStepRequest");
  const workflowStepFinish = useActionFragment("workflowStepFinish");
  const confirmDialog = useConfirmDialog();

  useEffect(() => {
    if (activeStepIndex !== activeWorkflowStepIndex)
      activeStepIndexSet(activeWorkflowStepIndex);
  }, [activeWorkflowStepIndex]);

  return (
    <ObjectCard>
      <Stepper orientation="vertical" activeStep={activeStepIndex}>
        {workflow.workflowSteps.map((workflowStep, workflowStepIndex) => (
          <Step key={workflowStep.id} completed={workflowStep.finished}>
            <StepLabel
              onClick={() =>
                activeStepIndexSet((i) =>
                  i === workflowStepIndex ? -1 : workflowStepIndex,
                )
              }
              optional={
                <>
                  {!workflowStep.finished && (
                    <UsersLabel users={workflowStep.users} />
                  )}
                  {workflowStep.finished && (
                    <>
                      由
                      <UserLabel user={workflowStep.finishedByUser} />
                      于
                      <DateTime value={workflowStep.finishedAt} />
                      完成
                    </>
                  )}
                </>
              }
            >
              {workflowStep.name}
            </StepLabel>
            <StepContent>
              {workflowStep.active ? (
                <>
                  {!workflowStep.requested ? (
                    <Message
                      type="warning"
                      actions={[
                        {
                          icon: <Play />,
                          disabled: !workflowStep.canRequest,
                          title: "发起步骤",
                          onClick: () =>
                            formDialog({
                              title: "发起步骤",
                              fields: [
                                {
                                  label: "审核步骤",
                                  readOnly: true,
                                  value: workflowStep.name,
                                },
                                {
                                  label: "成员",
                                  name: "userIds",
                                  inputComponent: UsersInput,
                                  required: true,
                                  value: workflowStep.users.map((u) => u.id),
                                },
                              ],
                              onSubmit: async (formData) => {
                                await workflowStepRequest({
                                  input: {
                                    workflowStepId: workflowStep.id,
                                    ...formData,
                                  },
                                });
                                await dataMeta?.refetch();
                                showMessage({
                                  message: "成功发起步骤，已通知对应成员",
                                });
                              },
                            }),
                        },
                      ]}
                    >
                      该步骤尚未开始，请需要选取成员并发起该步骤。
                    </Message>
                  ) : (
                    <Message type="info">
                      该步骤已开始，需要由以下成员之一填写对应数据字段并标记完成：
                      <br />
                      <UsersLabel users={workflowStep.users} />
                    </Message>
                  )}

                  {!workflowStep.filled ? (
                    <Message type="warning">
                      该步骤对应的数据字段尚未填写完毕。
                    </Message>
                  ) : (
                    <Message
                      actions={[
                        {
                          icon: <Check />,
                          disabled: !workflowStep.canFinish,
                          title: "完成",
                          onClick: async () => {
                            await confirmDialog();
                            await workflowStepFinish({
                              input: {
                                workflowStepId: workflowStep.id,
                              },
                            });
                            await dataMeta?.refetch();
                            showMessage({ message: "已完成该审核步骤。" });
                          },
                        },
                      ]}
                    >
                      该步骤包含的数据字段已填写完毕，请点击<b>完成</b>
                      进行确认。
                    </Message>
                  )}
                </>
              ) : (
                <>
                  {workflowStep.finished ? (
                    <Message type="success">该步骤已完成。</Message>
                  ) : (
                    <Message type="warning">正等待前一个步骤。</Message>
                  )}
                </>
              )}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>数据列</TableCell>
                    <TableCell>数据字段 (以表单中实际尺寸显示)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workflowStep.workflowFields.map((workflowField) => (
                    <TableRow key={workflowField.id}>
                      <TableCell>{workflowField.fieldLabel}:</TableCell>
                      <TableCell>
                        {!workflowField.field && (
                          <Message type="error">
                            对应数据字段<b>{workflowField.fieldLabel}</b>
                            不存在，请修改表单及其对应模版。
                          </Message>
                        )}
                        {workflowField.field && (
                          <div
                            style={{
                              border: "solid 1px #ccc",
                              width: `${workflowField.field.cell.width}mm`,
                              height: `${workflowField.field.cell.height}mm`,
                              padding: `${workflowField.field.cell.padding}mm`,
                              position: "relative",
                            }}
                          >
                            <documentContentContext.Provider
                              value={{
                                documentContentMode: workflowStep.canFill
                                  ? "fill"
                                  : "view",
                              }}
                            >
                              <Field
                                field={workflowField.field}
                                style={{ width: "100%", height: "100%" }}
                                onUpdate={async () => {
                                  await dataMeta?.refetch();
                                }}
                              />
                            </documentContentContext.Provider>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </ObjectCard>
  );
}
