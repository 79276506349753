import React from "react";
import { gql } from "@apollo/client";
import {
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";

import { ProjectIcon } from "controls/icons";
import useData from "hooks/useData";
import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";
import useFilterBar from "hooks/useFilterBar";
import ItemPath, { ItemPathFragment } from "blocks/ItemPath";
import FetchMoreButton from "controls/FetchMoreButton";

import SingleInput from "./SingleInput";

export default function ProjectInput({ ...others }) {
  return (
    <SingleInput
      {...others}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemDialogContent}
    />
  );
}

function ItemLabel({ item: projectId }) {
  const data = useData(
    gql`
      query ItemLabel($projectId: ID!) {
        project(id: $projectId) {
          id
          name
        }
      }
    `,
    { projectId },
  );

  return <>{data?.project.name}</>;
}

function ItemAvatar({ ...others }) {
  return (
    <Avatar {...others}>
      <ProjectIcon fontSize="inherit" />
    </Avatar>
  );
}

function ItemDialogContent({ itemSubmit }) {
  const [{ searchTerm }, filterBar] = useFilterBar({
    enableSearchTerm: true,
    noUrl: true,
  });
  const { teamId } = useRoute(teamRoute);

  const [data, dataMeta] = useData(
    gql`
      query ItemDialogContent($teamId: ID!, $searchTerm: String, $offset: Int) {
        team(id: $teamId) {
          id
          projects(searchTerm: $searchTerm, offset: $offset, limit: 5) {
            id
            name
            folder {
              id
              ...ItemPathFragment
            }
          }
          projectsCount
        }
      }
      ${ItemPathFragment}
    `,
    { teamId, searchTerm },
    { returnMeta: true },
  );

  return (
    <>
      {filterBar}
      <List dense>
        {data?.team.projects.map((project) => (
          <ListItem
            key={project.id}
            button
            component="a"
            href="#"
            onClick={() => itemSubmit(project.id)}
          >
            <ListItemIcon>
              <ProjectIcon />
            </ListItemIcon>
            <ListItemText
              primary={project.name}
              secondary={<ItemPath item={project.folder} />}
            />
          </ListItem>
        ))}
      </List>
      <FetchMoreButton
        fetchMore={dataMeta.fetchMore}
        rows={data?.team.projects}
        rowsCount={data?.team.projectsCount}
      />
    </>
  );
}
