import { IS_TEST } from "helpers";

export default function testPatch() {
  if (IS_TEST) {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      [aria-hidden="true"]:not(.MuiChip-deleteIcon), 
      body[data-fms-loading] a, 
      body[data-fms-loading] button {
        visibility: hidden !important;
      }
      input[type="checkbox"], input[type="radio"] {
        opacity: 1!important;
      }
      * {
        transition: none !important;
      }`;
    document.body.appendChild(styleElement);
  }
}
