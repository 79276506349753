import React from "react";
import { gql } from "@apollo/client";

import { TabContent, AppView, FolderIcon } from "controls";
import { useData, useList, useFilterBar, useRoute } from "hooks";
import { teamRoute } from "helpers";
import { FolderCard, FolderCardFragment } from "blocks";
import ItemsTable, { ItemsTableFragment } from "lists/ItemsTable";

export default function FoldersView() {
  const { teamId } = useRoute(teamRoute);

  const [{ searchTerm }, filterBar] = useFilterBar({ enableSearchTerm: true });

  const [data, { fetchMore }] = useData(
    gql`
      query FoldersView($teamId: ID!, $offset: Int, $searchTerm: String) {
        team(id: $teamId) {
          id
          foldersCount(searchTerm: $searchTerm)
          folders(
            searchTerm: $searchTerm

            offset: $offset
            limit: 20
          ) {
            id
            ...ItemsTableFragment
            ...FolderCardFragment
          }
        }
      }
      ${ItemsTableFragment}
      ${FolderCardFragment}
    `,
    {
      teamId,
      searchTerm,
    },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("folder", {
    rows: data?.team.folders,
    rowsCount: data?.team.foldersCount,
    fetchMore,
    card: <FolderCard />,
    table: <ItemsTable enablePath />,
  });

  return (
    <AppView
      title="目录"
      crumbs={[
        {
          icon: <FolderIcon />,
          label: "目录",
          href: `/${teamId}/folders`,
        },
      ]}
    >
      <TabContent toolbar={<>{listModeSwitcher}</>}>
        {filterBar}
        {list}
      </TabContent>
    </AppView>
  );
}
