import React, { useState, useId, useEffect } from "react";
import { times } from "lodash-es";
import gql from "graphql-tag";
import {
  Popover,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import { useData, useFilterBar } from "hooks";
import { ItemPathFragment, ItemPath } from "blocks";
import { API_ORIGIN, checkFetchResponse, literalMatch } from "helpers";
import PreviewLoader from "views/previewable/PreviewLoader";

export default React.memo(DataTable);

export const DataTableFragment = gql`
  fragment DataTableFragment on DataQuery {
    id
  }
`;

function DataTable({ dataQuery }) {
  const id = useId();
  const [{ searchTerm }, filterBar] = useFilterBar({ enableSearchTerm: true });
  const [popoverState, popoverStateSet] = useState(null);

  const [rows, rowsSet] = useState(null);

  useEffect(() => {
    Promise.resolve().then(async () => {
      const response = await fetch(
        `${API_ORIGIN}/api/data_query_result?data_query_id=${
          dataQuery.id
        }&token=${encodeURIComponent(window._fmsToken)}`,
      );
      await checkFetchResponse(response);
      const { rows } = (await response.json()) || {};
      for (const [rowIndex, row] of rows.entries())
        for (const [cellIndex, cell] of row.cells.entries())
          if (cell.col_span !== 1 || cell.row_span !== 1) {
            for (const rowDelta of times(cell.row_span))
              for (const cellDelta of times(cell.col_span))
                if (rowDelta || cellDelta) {
                  rows[rowIndex + rowDelta].cells[
                    cellIndex + cellDelta
                  ].hidden = true;
                }
          }
      rowsSet(rows);
    });
  }, [dataQuery.id]);

  const keywords = (searchTerm || "").split(" ").filter((s) => s);
  const matchRow = (dataQueryRow) =>
    !keywords.length ||
    keywords.find((keyword) =>
      dataQueryRow.dataQueryCells.find(
        (c) => c.header || literalMatch(c.value, keyword),
      ),
    );

  return (
    <>
      {filterBar}
      {!rows && <PreviewLoader />}
      <TableContainer
        component={Paper}
        style={{
          margin: 5,
          width: "calc(100% - 10px)",
          flex: "1 1 0",
        }}
      >
        <Table stickyHeader size="small">
          <TableBody>
            {rows?.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                style={{
                  display: matchRow(row.cells) ? undefined : "none",
                }}
              >
                {row.cells
                  .filter((cell) => !cell.hidden)
                  .map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      style={{
                        whiteSpace: "nowrap",
                        cursor: cell.item_id ? "pointer" : undefined,
                        backgroundColor:
                          !cell.header && !cell.item_id ? "#efefef" : undefined,
                      }}
                      colSpan={cell.col_span}
                      rowSpan={cell.row_span}
                      data-cell-index={cellIndex}
                      data-row-index={rowIndex}
                      variant={cell.header ? "head" : "body"}
                      onClick={(event) =>
                        cell.item_id &&
                        popoverStateSet({
                          cell,
                          anchorEl: event.currentTarget,
                        })
                      }
                    >
                      {cell.value}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        id={`${id}-Popover`}
        open={!!popoverState}
        anchorEl={popoverState?.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => popoverStateSet(null)}
      >
        {popoverState && <PopoverContent itemId={popoverState.cell.item_id} />}
      </Popover>
    </>
  );
}

function PopoverContent({ itemId }) {
  const data = useData(
    gql`
      query PopoverContent($itemId: ID!) {
        item(id: $itemId) {
          ...ItemPathFragment
        }
      }
      ${ItemPathFragment}
    `,
    { itemId },
  );

  return (
    <div style={{ padding: 5, width: 500, maxWidth: "100vw" }}>
      <ItemPath clickable item={data?.item} />
    </div>
  );
}
