import React from "react";
import { gql } from "@apollo/client";

import { Message } from "controls";
import { showMessage, teamRoute } from "helpers";
import { useData, useAction, useHistory, useRoute, useFormDialog } from "hooks";
import { ItemInput, OptionsInput } from "inputs";

export default function CloneItemToTeam() {
  const history = useHistory();
  const { teamId } = useRoute(teamRoute);
  const formDialog = useFormDialog();
  const data = useData(
    gql`
      query CloneItemToTeam($teamId: ID!) {
        teams {
          id
          name
        }
        team(id: $teamId) {
          id
          docTemplatesFolder {
            id
          }
          projectTemplatesFolder {
            id
          }
        }
      }
    `,
    { teamId },
  );

  const cloneItemToTeam = useAction(gql`
    mutation CloneItemToTeam($input: CloneItemToTeamInput!) {
      cloneItemToTeam(input: $input) {
        newTeam {
          id
        }
        destinationFolder {
          id
        }
      }
    }
  `);

  return (
    <Message
      actions={[
        ["复制文件到其他团队", null],
        ["复制文档模版到其他团队", data?.team.docTemplatesFolder.id],
        ["复制项目模版到其他团队", data?.team.projectTemplatesFolder.id],
      ].map(([title, topFolderId]) => ({
        title,
        onClick: () =>
          formDialog({
            title,
            fields: [
              {
                name: "itemId",
                label: "要复制的文档或表单",
                required: true,
                value: null,
                inputComponent: ItemInput,
                options: { topFolderId },
              },
              {
                name: "newTeamId",
                label: "目标团队",
                required: true,
                inputComponent: OptionsInput,
                helperText: "将复制到目标团队的根目录里",
                options: {
                  options: data?.teams.map((team) => ({
                    name: team.id,
                    label: team.name,
                  })),
                },
              },
            ],
            onSubmit: async (formData) => {
              const result = await cloneItemToTeam({
                input: {
                  ...formData,
                },
              });
              showMessage({
                message: `成功复制文件`,
              });
              history.push(
                `/${result.cloneItemToTeam.newTeam.id}/${result.cloneItemToTeam.destinationFolder.id}`,
              );
            },
          }),
      }))}
    >
      复制文件到其他团队
      <br />
      您可以复制文件到其他团队的根目录，复制完成后您将可以将文件移动到其他位置
    </Message>
  );
}
