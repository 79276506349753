import { gql } from "@apollo/client";

import { useData, useRoute } from "hooks";
import { teamRoute } from "helpers";
import {
  CardsList,
  ProjectIcon,
  CompanyIcon,
  DocumentIcon,
  ActivityIcon,
  ObjectCard,
  DateTime,
} from "controls";
import {
  UsersLabel,
  UsersLabelFragment,
  ActivityCard,
  ActivityCardFragment,
} from "blocks";
import Dashboard from "dashboard/Dashboard";
import Widget from "dashboard/Widget";
import DASHBOARD_COLORS from "dashboard/DASHBOARD_COLORS";

export default function ProjectDashboard({ projectId }) {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query ProjectDashboard($projectId: ID!) {
        project(id: $projectId) {
          id
          name
          createdAt
          properties {
            id
            label
            values
          }
          projectCompanies {
            id
            role
            company {
              id
              name
              users {
                id
                ...UsersLabelFragment
              }
            }
          }
          documents {
            id
            name
            dataRowName
          }
        }
      }
      ${UsersLabelFragment}
    `,
    { projectId },
  );

  const [activitiesData, activitiesDataMeta] = useData(
    gql`
      query ProjectDashboard($projectId: ID!, $offset: Int) {
        project(id: $projectId) {
          id
          activities(offset: $offset, limit: 20) {
            id
            createdAt
            ...ActivityCardFragment
          }
          activitiesCount
        }
      }
      ${ActivityCardFragment}
    `,
    { projectId },
    { returnMeta: true },
  );

  return (
    <Dashboard>
      <Widget
        color={DASHBOARD_COLORS[2]}
        title={data?.project.name}
        icon={<ProjectIcon />}
      >
        <ObjectCard
          elevation={0}
          fields={[
            {
              label: "创建时间",
              content: <DateTime value={data?.project.createdAt} />,
            },
            ...(data?.project.properties || []).map((property) => ({
              label: property.label,
              content: property.values.join(","),
            })),
          ]}
        />
      </Widget>

      <Widget
        color={DASHBOARD_COLORS[1]}
        title="相关单位"
        icon={<CompanyIcon />}
      >
        <CardsList
          style={{ margin: 5 }}
          cardWidth={250}
          cards={data?.project.projectCompanies.map((projectCompany) => (
            <ObjectCard
              key={projectCompany.id}
              icon={<CompanyIcon />}
              title={projectCompany.company.name}
              subheader={projectCompany.role}
              href={`/${teamId}/${projectCompany.company.id}`}
              fields={[
                {
                  label: "成员",
                  content: <UsersLabel users={projectCompany.company.users} />,
                },
              ]}
            />
          ))}
        />
      </Widget>

      <Widget
        color={DASHBOARD_COLORS[2]}
        title="表单"
        icon={<DocumentIcon />}
        style={{ width: "100%" }}
      >
        <CardsList
          style={{ margin: 5 }}
          cardWidth={250}
          cards={data?.project.documents.map((doc) => (
            <ObjectCard
              key={doc.id}
              icon={<DocumentIcon />}
              title={doc.name}
              subheader={<>数据表:{doc.dataRowName || "(无)"}</>}
              href={`/${teamId}/${doc.id}`}
            />
          ))}
        />
      </Widget>
      <Widget
        color={DASHBOARD_COLORS[5]}
        icon={<ActivityIcon />}
        title="动态"
        style={{ width: "100%" }}
      >
        <CardsList
          style={{ margin: 5 }}
          rows={activitiesData?.project.activities}
          rowsCount={activitiesData?.project.activitiesCount}
          fetchMore={activitiesDataMeta.fetchMore}
          cardComponent={<ActivityCard />}
          cardPropName="activity"
          getRowDate={(activity) => activity.createdAt}
        />
      </Widget>
    </Dashboard>
  );
}
