import React from "react";
import { gql } from "@apollo/client";

import {
  USER_PERMISSION_TYPES,
  teamRoute,
  UserPermissionsFragment,
} from "helpers";
import { Table, CheckboxCell, Message, UserIcon } from "controls";
import { useRoute, useData } from "hooks";
import { UserAvatar, UserAvatarFragment } from "blocks";

export default function PermissionsTab() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query PermissionsTab($teamId: ID!) {
        team(id: $teamId) {
          id
          admins {
            id
            name
            ...UserPermissionsFragment
            ...UserAvatarFragment
          }
        }
      }
      ${UserPermissionsFragment}
      ${UserAvatarFragment}
    `,
    { teamId },
  );

  return (
    <>
      <Message>
        这里列出了所有拥有团队权限的成员（管理员），如需设置或增加管理员请在对应成员的团队权限标签页里设置。
      </Message>
      <Table
        columns={[
          {
            label: "管理员",
          },
          ...USER_PERMISSION_TYPES.map((permissionType) => ({
            label: permissionType.label,
            cellComponent: CheckboxCell,
            labelWidth: "3em",
          })),
        ]}
        rows={data?.team.admins.map((admin) => ({
          id: admin.id,
          href: `/${teamId}/${admin.id}`,
          icon: <UserAvatar user={admin} />,
          title: admin.name,
          values: [
            admin.name,
            ...USER_PERMISSION_TYPES.map((permissionType) => ({
              checked: admin[permissionType.name],
              disabled: true,
            })),
          ],
          actions: [
            {
              icon: <UserIcon />,
              label: "查看",
              href: `/${teamId}/${admin.id}`,
            },
          ],
        }))}
      />
    </>
  );
}
