import React from "react";
import { gql } from "@apollo/client";

import { useAction } from "hooks";
import { FieldFragment } from "./Field";

const MM_PER_PX = 0.26458333333719;
const ROUND_TO = 0.5;
const SNAPPING = 3;

export const CellFragment = gql`
  fragment CellFragment on Cell {
    id
    top
    left
    width
    height
    content
    align
    padding
    fontSize
    fontFamily
    align
    borderStyle
    underlined
    multiline
    color
    field {
      ...FieldFragment
    }
  }
  ${FieldFragment}
`;

export const pageContext = React.createContext();
function insideRect(pos, rect) {
  if (pos[0] < rect[0]) return false;
  if (pos[0] > rect[0] + rect[2]) return false;
  if (pos[1] < rect[1]) return false;
  if (pos[1] > rect[1] + rect[3]) return false;
  return true;
}

export function useUpdatePageCells() {
  const updatePageCells = useAction(gql`
    mutation useUpdatePageCells($input: UpdatePageCellsInput!) {
      updatePageCells(input: $input) {
        cells {
          id
          ...CellFragment
        }
        page {
          id
          cells {
            id
          }
        }
      }
    }
    ${CellFragment}
  `);
  return updatePageCells;
}

export function round(v) {
  return Math.round(v / ROUND_TO) * ROUND_TO;
}

export function snap(pos, { lefts, tops, insideRects }) {
  const snapped = [
    snapCoordinate(pos[0], [...tops]),
    snapCoordinate(pos[1], [...lefts]),
  ];

  if (insideRects.find((rect) => !insideRect(snapped, rect))) return null;

  return snapped;
}

function snapCoordinate(c, snapCs) {
  let snapped = c;
  let snappedD = null;
  for (const snapC of snapCs) {
    const delta = Math.abs(snapC - c);
    if (delta > SNAPPING) continue;
    if (!snappedD || delta < snappedD) {
      snappedD = delta;
      snapped = snapC;
    }
  }
  return snapped;
}

export function getEventPos(event) {
  const rect = event.currentTarget.getBoundingClientRect();
  const pos = [
    round((event.clientY - rect.top) * MM_PER_PX),
    round((event.clientX - rect.left) * MM_PER_PX),
  ];
  return pos;
}

export function getRect(pos1, pos2) {
  const top = Math.min(pos1[0], pos2[0]);
  const left = Math.min(pos1[1], pos2[1]);
  const height = Math.max(pos1[0], pos2[0]) - top;
  const width = Math.max(pos1[1], pos2[1]) - left;
  const rect = { top, left, height, width };
  return rect;
}
