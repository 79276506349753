import React from "react";
import { gql } from "@apollo/client";
import { filesize } from "filesize";

import { UserAvatar, UserAvatarFragment } from "blocks";
import { ObjectCard, DateTime, Message } from "controls";
import { useData, useDialogOpener } from "hooks";
import { filterBlobUrl } from "helpers";
import PreviewDialog from "views/previewable/PreviewDialog";

export default function RevisionsTab({ itemId }) {
  const openPreviewDialog = useDialogOpener(PreviewDialog);
  const data = useData(
    gql`
      query RevisionsTab($itemId: ID!) {
        item(id: $itemId) {
          id
          canView
          blobDownloadUrl
          size
          revisionedAt
          revisionedBy {
            id
            name
            ...UserAvatarFragment
          }
          thumbnailUrl
          revisions {
            id
            size
            thumbnailUrl
            revisionedAt
            revisionedBy {
              id
              name
              ...UserAvatarFragment
            }
          }
        }
      }
      ${UserAvatarFragment}
    `,
    { itemId },
  );
  return (
    <>
      {data?.item.canView === false && (
        <Message type="warning">没有权限查看该文档历史版本内容</Message>
      )}
      {data &&
        [
          {
            title: (
              <>
                当前版本：
                <DateTime value={data?.item.revisionedAt} />
              </>
            ),
            ...data?.item,
          },
          ...(data?.item.revisions || []).map((revision) => ({
            title: (
              <>
                历史版本：
                <DateTime value={revision.revisionedAt} />
              </>
            ),
            ...revision,
          })),
        ].map(({ title, ...revision }) => (
          <ObjectCard
            key={revision.id}
            title={title}
            subheader={filesize(revision.size)}
            avatar={
              <>
                <UserAvatar user={revision.revisionedBy} triggerUserDialog />
              </>
            }
            thumbnailUrl={filterBlobUrl(revision.thumbnailUrl)}
            onClick={() => openPreviewDialog({ previewableId: revision.id })}
            disabled={!data?.item.canView}
            fields={[
              {
                label: "修改时间",
                content: <DateTime value={revision.revisionedAt} />,
              },
              {
                label: "修改成员",
                content: <>{revision.revisionedBy?.name}</>,
              },
            ]}
          />
        ))}
    </>
  );
}
