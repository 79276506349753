import React from "react";
import { gql } from "@apollo/client";
import { Cog } from "mdi-material-ui";

import { useRoute, useData } from "hooks";
import { picklistRoute } from "helpers";
import { PicklistIcon, AppView, AppTabs } from "controls";

import InfoTab from "./InfoTab";
import OptionsTab from "./OptionsTab";

export default function PicklistView() {
  const { teamId, picklistId } = useRoute(picklistRoute);

  const data = useData(
    gql`
      query PicklistView($picklistId: ID!) {
        picklist(id: $picklistId) {
          id
          name
          optionsCount
        }
      }
    `,
    { picklistId },
  );

  return (
    <AppView
      title={data?.picklist.name}
      crumbs={[
        {
          icon: <Cog />,
          label: "团队设置",
          href: `/${teamId}/configure`,
        },
        {
          label: data?.picklist.name,
          icon: <PicklistIcon />,
          href: `/${teamId}/${picklistId}`,
        },
      ]}
    >
      <AppTabs
        tabs={[
          {
            title: "基本信息",
            name: "info",
            content: <InfoTab />,
          },
          {
            title: "选项",
            name: "options",
            content: <OptionsTab />,
            badgeContent: data?.picklist.optionsCount,
          },
        ]}
      />
    </AppView>
  );
}
