import PropTypes from "prop-types";

import { useProgressBar } from "hooks";

ProgressBar.propTypes = {
  unobstrusive: PropTypes.bool,
  blocking: PropTypes.bool,
};

export default function ProgressBar({
  unobstrusive = false,
  blocking = false,
}) {
  useProgressBar(true, { unobstrusive, blocking });
  return null;
}
