import React from "react";
import { gql } from "@apollo/client";
import { Cog } from "mdi-material-ui";

import { itemRoute } from "helpers";
import {
  ItemObjectIcon,
  FavoritableIconButton,
  FavoritableIconButtonFragment,
} from "blocks";
import { useData, useRoute } from "hooks";
import { AppView } from "controls";
import FolderView from "views/folders/FolderView";
import FileView from "views/files/FileView";
import DocumentView from "views/documents/DocumentView";

export default function ItemView() {
  const { teamId, itemId } = useRoute(itemRoute);
  const data = useData(
    gql`
      query ItemView($itemId: ID!) {
        item(id: $itemId) {
          id
          name
          ancestorFolderType
          ...FavoritableIconButtonFragment
          path {
            id
            name
          }
        }
      }
      ${FavoritableIconButtonFragment}
    `,
    { itemId },
  );

  return (
    <>
      <AppView
        title={data?.item.name}
        crumbs={
          data &&
          [
            data.item.ancestorFolderType !== "root" && {
              icon: <Cog />,
              label: "团队设置",
              href: `/${teamId}/configure`,
            },
            ...data.item.path.map((parentItem) => ({
              href: `/${teamId}/${parentItem.id}`,
              icon: <ItemObjectIcon itemId={parentItem.id} />,
              label: parentItem.name,
            })),
          ].filter((c) => c)
        }
        tools={
          <>
            <FavoritableIconButton favoritable={data?.item} />
          </>
        }
      >
        {itemId.startsWith("I") && <FileView />}
        {itemId.startsWith("D") && <DocumentView />}
        {itemId.startsWith("F") && <FolderView />}
      </AppView>
    </>
  );
}
