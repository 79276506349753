import { useLayoutEffect, useState } from "react";
import { uniq } from "lodash-es";
import {
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
  DialogTitle,
  DialogActions,
  Button,
  Checkbox,
} from "@mui/material";
import { gql } from "@apollo/client";

import { PropertyIcon } from "controls";
import { useData, useActionFragment } from "hooks";
import { getDialogProps, toggleArray } from "helpers";
import { ListLoader } from "loaders";

export default function MultiPicklistDialog({
  propertyId,
  onClose,
  ...others
}) {
  const [selectedOptions, selectedOptionsSet] = useState([]);

  const data = useData(
    gql`
      query MultiPicklistDialog($propertyId: ID!) {
        property(id: $propertyId) {
          id
          label
          values
          picklist {
            id
            name
            options
          }
        }
      }
    `,
    { propertyId },
  );

  useLayoutEffect(() => {
    if (data) selectedOptionsSet(data.property.values);
  }, [data]);

  const propertyFill = useActionFragment(
    "propertyFill",
    "property { id values }",
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      {...others}
      {...getDialogProps()}
    >
      <DialogTitle>选择“{data?.property.label}”</DialogTitle>
      {!data && <ListLoader subheaders />}
      <List
        disablePadding
        subheader={
          <ListSubheader>
            使用选项列表“{data?.property.picklist?.name}”
          </ListSubheader>
        }
      >
        {data &&
          uniq([
            ...data.property.values,
            ...(data.property.picklist?.options || []),
          ]).map((option) => (
            <ListItem
              key={option}
              disablePadding
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={() =>
                    selectedOptionsSet(toggleArray(selectedOptions, option))
                  }
                  checked={selectedOptions.includes(option)}
                />
              }
            >
              <ListItemButton
                component="a"
                href="#"
                onClick={() =>
                  selectedOptionsSet(toggleArray(selectedOptions, option))
                }
              >
                <ListItemIcon>
                  <PropertyIcon />
                </ListItemIcon>
                <ListItemText primary={option} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <DialogActions>
        <Button
          onClick={async () => {
            await propertyFill({
              input: { propertyId, values: selectedOptions },
            });
            onClose();
          }}
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
