import React, { useEffect, useContext, useState } from "react";
import { times, pick, flatten } from "lodash-es";
import { gql } from "@apollo/client";
import { InputAdornment } from "@mui/material";
import {
  Square,
  Delete,
  Plus,
  BorderAllVariant,
  BorderNoneVariant,
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter,
  FormatUnderline,
  Pencil,
} from "mdi-material-ui";

import {
  useFormDialog,
  useAction,
  useDocumentActions,
  useConfirmDialog,
} from "hooks";
import { showMessage } from "helpers";
import { documentContentContext } from "contexts";
import { NumberInput } from "inputs";

import Sheet from "./Sheet";
import Cell from "./Cell";
import Resize from "./Resize";
import AddCell from "./AddCell";
import { CellFragment, pageContext, useUpdatePageCells } from "./helpers";

export const PageFragment = gql`
  fragment PageFragment on Page {
    id
    width
    height
    paddingWidth
    paddingHeight
    cells {
      id
      top
      left
      width
      height
      ...CellFragment
    }
  }
  ${CellFragment}
`;

export default function Page({ page, ...others }) {
  const deletePage = useAction(gql`
    mutation Page($input: DeletePageInput!) {
      deletePage(input: $input) {
        document {
          id
          pages {
            id
          }
        }
      }
    }
  `);
  const updatePageCells = useUpdatePageCells();
  const formDialog = useFormDialog();
  const { documentContentMode } = useContext(documentContentContext);
  const confirmDialog = useConfirmDialog();
  const [addingCell, addingCellSet] = useState(false);
  const { handleFocus, handleBlur } = useDocumentActions(
    "文档页",
    (documentContentMode === "edit" && [
      {
        icon: <Delete />,
        title: "删除该页",
        onClick: async () => {
          await confirmDialog();
          await deletePage({
            input: { pageId: page.id },
          });
          await showMessage({ message: "成功删除文档页" });
        },
      },
      {
        icon: <Plus />,
        title: "增加单元格",
        selected: addingCell,
        onClick: async () => addingCellSet((s) => !s),
      },
    ]) ||
      [],
  );

  const [cellSelected, cellSelectedSet] = useState(null);
  useEffect(() => {
    if (cellSelected)
      cellStyleSet(
        pick(cellSelected, [
          "borderStyle",
          "fontFamily",
          "fontSize",
          "padding",
          "align",
          "underlined",
          "color",
        ]),
      );
  }, [cellSelected?.id]);
  const [cellStyle, cellStyleSet] = useState({
    borderStyle: "solid",
    fontFamily: "songti",
    fontSize: 4,
    padding: 3,
    align: "left",
    underlined: false,
    color: "#000000",
  });
  const cellStyleAssign = async (attributes) => {
    cellStyleSet((cellStyle) => ({
      ...cellStyle,
      ...attributes,
    }));
    if (cellSelected) {
      await updatePageCells({
        input: {
          pageId: page.id,
          updateCells: [
            {
              cellId: cellSelected.id,
              ...attributes,
            },
          ],
        },
      });
      showMessage({ message: "成功更新单元格样式" });
    }
  };

  const cellStyleActions = useDocumentActions(
    "表单样式",
    (documentContentMode === "edit" && [
      ...flatten(
        [
          [
            "边框样式",
            "borderStyle",
            [
              ["solid", "实线边框", <BorderAllVariant />],
              ["none", "隐藏边框", <BorderNoneVariant />],
            ],
          ],
          [
            "字体",
            "fontFamily",
            [
              ["heiti", "黑体"],
              ["songti", "宋体"],
            ],
          ],
          [
            "字体颜色",
            "color",
            [
              ["#000000", "黑色"],
              ["#ff0000", "红色"],
            ].map(([color, label]) => [
              color,
              label,
              <Square color="inherit" style={{ color }} />,
            ]),
          ],
          [
            "字体大小",
            "fontSize",
            times(20)
              .map((i) => 4 + 0.5 * i)
              .map((mm) => [mm, `${mm}mm`]),
          ],
          [
            "对齐方式",
            "align",
            [
              ["left", "靠左对齐", <FormatAlignLeft />],
              ["center", "居中对齐", <FormatAlignCenter />],
              ["right", "靠右对齐", <FormatAlignRight />],
            ],
          ],
        ].map(([group, key, options]) =>
          options.map(([value, title, icon]) => ({
            group,
            dropdown: true,
            title,
            label: title,
            icon,
            selected: cellStyle[key] === value,
            onClick: () => cellStyleAssign({ [key]: value }),
          })),
        ),
      ),
      ...[
        //
        ["下滑线", "underlined", <FormatUnderline />],
      ].map(([label, key, icon]) => ({
        title: label,
        icon,
        selected: cellStyle[key],
        onClick: () => cellStyleAssign({ [key]: !cellStyle[key] }),
      })),
      ...[
        [
          <Pencil />,
          "内衬距",
          "padding",
          (v) => `${v}mm`,
          {
            required: true,
            inputComponent: NumberInput,
            options: {
              min: 0,
              step: 0.5,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">毫米</InputAdornment>
                ),
              },
            },
          },
        ],
      ].map(([icon, title, key, formatValue, fieldOptions]) => ({
        icon,
        title,
        label: `${title}:${formatValue(cellStyle[key])}`,
        onClick: () =>
          formDialog({
            title,
            fields: [
              {
                label: title,
                value: cellStyle[key],
                name: key,
                ...fieldOptions,
              },
            ],
            onSubmit: (formData) =>
              cellStyleAssign({
                [key]: formData[key],
              }),
          }),
      })),
    ]) ||
      [],
  );

  const tops = [
    page.paddingHeight,
    -page.paddingHeight + page.height,
    ...page.cells.map((c) => c.top),
    ...page.cells.map((c) => c.top + c.height),
  ];
  const lefts = [
    page.paddingWidth,
    -page.paddingWidth + page.width,
    ...page.cells.map((c) => c.left),
    ...page.cells.map((c) => c.left + c.width),
  ];
  const [resizeFrom, resizeFromSet] = useState(null);
  const [resizeCellId, resizeCellIdSet] = useState(null);
  const [resizeCursor, resizeCursorSet] = useState(null);
  const pageContentRect = [
    page.paddingHeight,
    page.paddingWidth,
    page.height - page.paddingHeight * 2,
    page.width - page.paddingWidth * 2,
  ];

  return (
    <pageContext.Provider
      value={{
        page,
        tops,
        lefts,
        resizeFrom,
        resizeFromSet,
        resizeCellId,
        resizeCellIdSet,
        resizeCursor,
        resizeCursorSet,
        pageContentRect,
        cellSelectedSet,
        cellStyle,
      }}
    >
      <Sheet
        square
        data-page
        data-page-id={page.id}
        {...(documentContentMode === "edit" && {
          onFocus: () => {
            cellStyleActions.handleFocus();
            handleFocus();
          },
          onBlur: () => {
            cellStyleActions.handleBlur();
            handleBlur();
          },
          tabIndex: 0,
        })}
        {...others}
      >
        {page.cells.map((cell) => (
          <Cell key={cell.id} cell={cell} />
        ))}
        {documentContentMode === "edit" && (
          <>
            {resizeFrom && <Resize onExit={() => resizeFromSet(false)} />}
            {addingCell && <AddCell onExit={() => addingCellSet(false)} />}
          </>
        )}
      </Sheet>
    </pageContext.Provider>
  );
}
