import { useHistory as useHistoryRaw } from "react-router-dom";

import embedInMobile from "helpers/embedInMobile";

export default function useHistory() {
  const historyRaw = useHistoryRaw();

  const history = {
    push: (pathname) => {
      if (embedInMobile)
        return window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            historyPush: pathname,
          }),
        );
      historyRaw.push(pathname);
    },
    replace: (pathname) => historyRaw.replace(pathname),
    goBack: () => historyRaw.goBack(),
  };

  return history;
}
