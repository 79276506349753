import { gql } from "@apollo/client";
import { useState, useEffect } from "react";

import { UserError } from "helpers";
import { useData, useAction } from "hooks";

export default function usePushSettings() {
  const [support, supportSet] = useState(null);
  useEffect(() => {
    Promise.resolve().then(async () => {
      try {
        await getPushManager();
        supportSet(true);
      } catch (e) {
        supportSet(false);
      }
    });
  }, []);
  const data = useData(gql`
    query UISettingsDialog {
      userLogin {
        id
        isWebPushEnabled
      }
      vapidPublicKey
    }
  `);

  const enableWebPush = useAction(gql`
    mutation UISettingsDialog($input: EnableWebPushInput!) {
      enableWebPush(input: $input) {
        login {
          id
          isWebPushEnabled
        }
      }
    }
  `);

  const disableWebPush = useAction(gql`
    mutation UISettingsDialog($input: DisableWebPushInput!) {
      disableWebPush(input: $input) {
        login {
          id
          isWebPushEnabled
        }
      }
    }
  `);

  const isEnabled = data?.userLogin.isWebPushEnabled;

  const enable = async () => {
    await unsubscribe();
    const pushManager = await getPushManager();
    const pushSubscription = await pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: base64UrlToUint8Array(data.vapidPublicKey),
    });

    const p256dh = uint8ArrayToBase64Url(pushSubscription.getKey("p256dh"));
    const auth = uint8ArrayToBase64Url(pushSubscription.getKey("auth"));
    await enableWebPush({
      input: {
        endpoint: pushSubscription.endpoint,
        auth,
        p256dh,
      },
    });
  };
  const disable = async () => {
    await unsubscribe();
    await disableWebPush({ input: {} });
  };

  return { enable, support, disable, isEnabled };
}

async function getPushManager() {
  if (!navigator.serviceWorker) throw new UserError("当前浏览器不支持桌面推送");
  const serviceWorker = await navigator.serviceWorker.ready;

  if (!serviceWorker.pushManager)
    throw new UserError("当前浏览器不支持桌面推送");
  return serviceWorker.pushManager;
}

async function unsubscribe() {
  const pushManager = await getPushManager();
  const pushSubscription = await pushManager.getSubscription();
  if (!pushSubscription) return;
  await pushSubscription.unsubscribe();
}

function base64UrlToUint8Array(base64UrlData) {
  const padding = "=".repeat((4 - (base64UrlData.length % 4)) % 4);
  const base64 = (base64UrlData + padding)
    .replace(/-/g, "+")
    .replace(/_/g, "/");

  const rawData = atob(base64);
  const buffer = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }

  return buffer;
}

function uint8ArrayToBase64Url(buffer) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}
