import React, { Suspense, useLayoutEffect, useState } from "react";
import dateFnsLocale from "date-fns/locale/zh-CN";

const DatePickerRaw = React.lazy(() => import("react-datepicker"));

let _loaded = false;

export default function DatePicker({ ...others }) {
  const [loaded, loadedSet] = useState(_loaded);
  useLayoutEffect(() => {
    if (!loaded)
      Promise.resolve().then(async () => {
        const { registerLocale, setDefaultLocale } = await import(
          "react-datepicker"
        );
        registerLocale("zh-CN", dateFnsLocale);
        setDefaultLocale("zh-CN");
        loadedSet(true);
        _loaded = true;
      });
  }, []);

  const loader = null;

  return !loaded ? (
    loader
  ) : (
    <Suspense fallback={loader}>
      <DatePickerRaw {...others} />
    </Suspense>
  );
}
