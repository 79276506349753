import React from "react";
import { gql } from "@apollo/client";

import DateTime from "controls/DateTime";
import ObjectCard from "controls/ObjectCard";
import filterBlobUrl from "helpers/filterBlobUrl";

import UserAvatar, { UserAvatarFragment } from "./UserAvatar";
import ItemPath, { ItemPathFragment } from "./ItemPath";

export const ActivityCardFragment = gql`
  fragment ActivityCardFragment on Activity {
    id
    content
    url
    createdAt
    createdBy {
      id
      name
      ...UserAvatarFragment
    }
    categoryName
    item {
      id
      thumbnailUrl
      ...ItemPathFragment
    }
    category {
      id
      title
    }
  }
  ${ItemPathFragment}
  ${UserAvatarFragment}
`;

export default function ActivityCard({ activity }) {
  return (
    <ObjectCard
      avatar={
        <UserAvatar user={activity.createdBy} nullUser={!activity.createdBy} />
      }
      href={activity.url}
      title={activity.category?.title || `未知动态(${activity.categoryName})`}
      subheader={
        <>
          {activity.createdBy?.name}于<DateTime value={activity.createdAt} />
        </>
      }
      thumbnailUrl={filterBlobUrl(activity.item?.thumbnailUrl)}
      fields={[
        {
          label: "位置",
          span: 3,
          content: activity.item?.path && <ItemPath item={activity.item} />,
        },
      ]}
    >
      {activity.content}
    </ObjectCard>
  );
}
