import { gql } from "@apollo/client";
import { FormDialog } from "controls";
import { useActionFragment, useData } from "hooks";
import { ItemInput, UsersInput } from "inputs";

export default function AddWorkflowDialog({
  documentId,
  tWorkflowId,
  ...others
}) {
  const data = useData(
    gql`
      query AddWorkflowDialog($tWorkflowId: ID!) {
        tWorkflow(id: $tWorkflowId) {
          id
          name
          tWorkflowSteps {
            id
            name
          }
        }
      }
    `,
    { tWorkflowId },
  );
  const documentAddWorkflow = useActionFragment(
    "documentAddWorkflow",
    "document { id tWorkflows { id } workflows { id } }",
  );

  return (
    <FormDialog
      title="创建审核流程"
      message="您可以设置各步骤的默认成员，发起相应步骤时可以依然可以更改。"
      disabled={!data}
      fields={[
        {
          label: "表单",
          value: documentId,
          readOnly: true,
          inputComponent: ItemInput,
          options: {
            itemTypes: ["Document"],
          },
        },
        {
          label: "审核流程",
          value: data?.tWorkflow.name,
          readOnly: true,
        },
        ...(data?.tWorkflow.tWorkflowSteps || []).map((tWorkflowStep) => ({
          label: `步骤“${tWorkflowStep.name}”默认成员`,
          name: `steps_${tWorkflowStep.id}_userIds`,
          inputComponent: UsersInput,
          value: [],
        })),
      ]}
      onSubmit={async (formData) => {
        const stepsUserIds = data.tWorkflow.tWorkflowSteps.map(
          (tWorkflowStep) => formData[`steps_${tWorkflowStep.id}_userIds`],
        );
        await documentAddWorkflow({
          input: {
            documentId,
            tWorkflowId,
            stepsUserIds,
          },
        });
      }}
      {...others}
    />
  );
}
