import React from "react";
import PropTypes from "prop-types";
import { Help } from "mdi-material-ui";

import { DocumentIcon } from "controls/icons";

import FolderObjectIcon from "./FolderObjectIcon";
import FileObjectIcon from "./FileObjectIcon";

export default React.memo(ItemObjectIcon);

ItemObjectIcon.propTypes = {
  //
  itemId: PropTypes.string,
  item: PropTypes.object,
};

function ItemObjectIcon({ itemId, ...others }) {
  const itemIdPrefix = `${itemId.match(/^[A-Z]/)}`;

  return (
    <>
      {{
        F: <FolderObjectIcon folderId={itemId} {...others} />,
        I: <FileObjectIcon fileId={itemId} {...others} />,
        D: <DocumentIcon {...others} />,
      }[itemIdPrefix] || <Help {...others} />}
    </>
  );
}
