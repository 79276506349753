import React from "react";
import { gql } from "@apollo/client";

import { ActionFormDialog } from "controls";
import { TextInput } from "inputs";
import { useData, useAction } from "hooks";

export default function RenameItemDialog({ itemId, ...others }) {
  const data = useData(
    gql`
      query RenameItemDialog($itemId: ID!) {
        item(id: $itemId) {
          id
          name
          basename
        }
      }
    `,
    { itemId },
  );
  const renameItem = useAction(gql`
    mutation RenameItemDialog($input: RenameItemInput!) {
      renameItem(input: $input) {
        item {
          id
          name
          basename
        }
      }
    }
  `);
  return (
    <ActionFormDialog
      {...others}
      title={`重命名${data?.item.name}`}
      disabled={!data}
      onSubmit={async (formData) => {
        await renameItem({
          input: {
            itemId,
            ...formData,
          },
        });
      }}
      fields={[
        {
          name: "basename",
          label: "新名称",
          required: true,
          inputComponent: TextInput,
          value: data?.item.basename,
        },
      ]}
    />
  );
}
