import React from "react";
import { Image } from "mdi-material-ui";
import { Button } from "@mui/material";

import FileUploadInput from "inputs/FileUploadInput";
import useDocumentOverlay from "hooks/useDocumentOverlay";
import useFormDialog from "hooks/useFormDialog";
import showMessage from "helpers/showMessage";

export default React.memo(FieldPhoto);

function FieldPhoto({ field, documentContentMode, updateField, ...others }) {
  const { id: fieldId, photoBlobUrl, photoEtag, photoName, photoSize } = field;
  const formDialog = useFormDialog();

  const overlay = useDocumentOverlay({
    enabled: documentContentMode === "fill",
    overlay: (
      <Button
        variant="contained"
        fullWidth
        onClick={() =>
          formDialog({
            title: "更改图片",
            fields: [
              {
                name: "upload",
                label: "图片",
                inputComponent: FileUploadInput,
                options: {
                  accept: "image/*",
                },
                value: photoEtag
                  ? {
                      etag: photoEtag,
                      name: photoName,
                      size: photoSize,
                    }
                  : null,
              },
            ],
            onSubmit: async ({ upload }) => {
              await updateField({
                input: {
                  fieldId,
                  ...(upload
                    ? {
                        photoEtag: upload.etag,
                        photoSize: upload.size,
                        photoName: upload.name,
                      }
                    : { photoEtag: "", photoName: "", photoSize: "0" }),
                },
              });
              showMessage({ message: "成功设置图片" });
            },
          })
        }
      >
        <Image />
        更改图片
      </Button>
    ),
  });

  return (
    <>
      <div
        data-field-photo
        {...others}
        style={{
          ...others.style,
          ...(documentContentMode === "fill" && {
            cursor: "pointer",
          }),
        }}
        tabIndex={0}
        onFocus={overlay.handleFocus}
        onBlur={overlay.handleBlur}
      >
        {photoBlobUrl && (
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            src={photoBlobUrl}
            alt={photoName}
          />
        )}
      </div>
    </>
  );
}
