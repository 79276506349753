import { useContext } from "react";
import { LinearProgress } from "@mui/material";
import { filesize } from "filesize";
import { Plus, Sync, SyncAlert, Check, Delete } from "mdi-material-ui";

import { TabContent, Table, ItemPathCell, ComponentCell } from "controls";
import { desktopStateContext } from "contexts";
import { useRoute, useDialogOpener } from "hooks";
import { teamRoute, showMessage } from "helpers";

import AddSyncEntryDialog from "./AddSyncEntryDialog";

export default function SyncsTab() {
  const desktopState = useContext(desktopStateContext);
  const { teamId } = useRoute(teamRoute);
  const openAddSyncEntryDialog = useDialogOpener(AddSyncEntryDialog);

  return (
    <TabContent
      actions={[
        {
          icon: <Plus />,
          title: "增加备份位置",
          onClick: () => openAddSyncEntryDialog(),
        },
      ]}
    >
      <Table
        columns={[
          {
            label: "本地目录",
          },
          {
            label: "位置",
            cellComponent: ItemPathCell,
          },
          {
            label: "同步状态",
            cellComponent: ComponentCell,
          },
        ]}
        rows={desktopState?.syncEntries
          ?.filter((e) => e.teamId === teamId)
          .map((entry) => ({
            id: entry.id,
            onClick: () =>
              window.fmsDesktop.command("openLocalPath", entry.localPath),
            icon: entry.error ? (
              <SyncAlert />
            ) : entry.syncing ? (
              <Sync
                className="spinning"
                style={{
                  animation: "spinning 1.4s infinite linear",
                  transform: "translateZ(0)",
                }}
              />
            ) : (
              <Check />
            ),

            title: entry.localPath,
            values: [
              entry.localPath,
              entry.itemId,
              {
                component: (
                  <div
                    style={{
                      margin: "0 10px",
                      display: "flex",
                      flexFlow: "column nowrap",
                      alignItems: "stretch",
                      width: 300,
                    }}
                  >
                    {!entry.syncing && (
                      <>
                        {entry.error ? (
                          <>错误: {entry.error.message}</>
                        ) : (
                          <>已同步</>
                        )}
                      </>
                    )}
                    {entry.syncing && (
                      <>
                        <LinearProgress
                          {...(entry.itemsSize && {
                            variant: "determinate",
                            value:
                              ((entry.itemsSyncedSize || 0) / entry.itemsSize) *
                              100,
                          })}
                        />
                        <div>
                          {entry.itemsSyncedCount}/{entry.itemsCount}个文件{" "}
                          {`(${filesize(entry.itemsSyncedSize || 0)}/${
                            entry.itemsSize ? filesize(entry.itemsSize) : "?"
                          })`}
                        </div>
                      </>
                    )}
                  </div>
                ),
              },
            ],
            actions: [
              {
                icon: <Delete />,
                label: "取消该备份位置",
                onClick: async () => {
                  await window.fmsDesktop?.command("removeSyncEntry", {
                    id: entry.id,
                  });
                  showMessage({ message: "已取消该备份位置" });
                },
              },
            ],
          }))}
      />
    </TabContent>
  );
}
