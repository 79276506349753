import React from "react";
import { gql } from "@apollo/client";

import { useAction } from "hooks";
import { ActionFormDialog } from "controls";
import { ItemInput } from "inputs";

export default function CreateShareLinkDialog({ ...others }) {
  const addShareLink = useAction(gql`
    mutation CreateShareLinkDialog($input: AddShareLinkInput!) {
      addShareLink(input: $input) {
        team {
          id
          shareLinks {
            id
          }
        }
      }
    }
  `);

  return (
    <ActionFormDialog
      {...others}
      title="创建公开浏览链接"
      fields={[
        {
          name: "itemId",
          label: "位置",
          required: true,
          inputComponent: ItemInput,
        },
        {
          name: "expiresAt",
          label: "有效期至",
          options: { type: "date", InputLabelProps: { shrink: true } },
        },
        {
          name: "name",
          label: "备注",
          helperText: "仅为管理员辨别该链接",
        },
      ]}
      onSubmit={async (formData) => {
        await addShareLink({
          input: {
            ...formData,
          },
        });
      }}
    />
  );
}
