import React from "react";
import { gql } from "@apollo/client";

import { FormDialog } from "controls";
import { showMessage } from "helpers";
import { useAction, useData, useHistory } from "hooks";
import { FolderInput } from "inputs";

export default function RestoreDeletedItemDialog({ deletedItemId, ...others }) {
  const data = useData(
    gql`
      query RestoreDeletedItemDialog($deletedItemId: ID!) {
        deletedItem(id: $deletedItemId) {
          id
          name
        }
      }
    `,
    { deletedItemId },
  );
  const history = useHistory();
  const restoreDeletedItem = useAction(gql`
    mutation RestoreDeletedItemDialog($input: RestoreDeletedItemInput!) {
      restoreDeletedItem(input: $input) {
        team {
          id
        }
        item {
          id
        }
        folder {
          id
          items {
            id
          }
          files {
            id
          }
          subFolders {
            id
          }
          documents {
            id
          }
        }
      }
    }
  `);

  return (
    <FormDialog
      {...others}
      title={`恢复“${data?.deletedItem.name}”`}
      onSubmit={async (formData) => {
        const result = await restoreDeletedItem({
          input: {
            deletedItemId,
            ...formData,
          },
        });
        showMessage({ message: `成功恢复“${data?.deletedItem.name}”` });
        history.push(
          `/${result.restoreDeletedItem.team.id}/${result.restoreDeletedItem.item.id}`,
        );
      }}
      fields={[
        {
          name: "folderId",
          label: "目标目录",
          inputComponent: FolderInput,
          required: true,
        },
      ]}
    />
  );
}
