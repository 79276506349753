import React from "react";
import { gql } from "@apollo/client";
import { Avatar } from "@mui/material";

import ObjectCard from "controls/ObjectCard";
import { NotificationIcon } from "controls/icons";
import DateTime from "controls/DateTime";
import useAction from "hooks/useAction";

export const NotificationCardFragment = gql`
  fragment NotificationCardFragment on Notification {
    id
    content
    url
    createdAt
    dismissed
  }
`;

export default function NotificationCard({ notification }) {
  const dismissNotification = useAction(gql`
    mutation NotificationCard($input: DismissNotificationInput!) {
      dismissNotification(input: $input) {
        userLogin {
          id
          notificationsCount(onlyNew: true)
        }
        notification {
          id
          dismissed
        }
      }
    }
  `);
  return (
    <ObjectCard
      avatar={
        <Avatar>
          <NotificationIcon fontSize="inherit" />
        </Avatar>
      }
      title={notification.content}
      subheader={<DateTime value={notification.createdAt} format="LL" />}
      href={notification.url}
      onClick={() =>
        !notification.dismissed &&
        dismissNotification({ input: { notificationId: notification.id } })
      }
      style={{ ...(notification.dismissed && { opacity: 0.4 }) }}
    />
  );
}
