import React, { useState, useId } from "react";
import { times, isEqual } from "lodash-es";
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { CursorMove } from "mdi-material-ui";

import OutlinedBox from "controls/OutlinedBox";

import "./SortInput.css";

const DATA_TRANSFER_MIME = "text/x-fms-sort-input";

export default function SortInput({
  label,
  value: values,
  onChange,
  helperText,
  name,
  error,
  readOnly,
  variant = "list",
  items = [],
  ...others
}) {
  items = (values || [])
    .map(
      (value) =>
        items.find((i) => i.value === value) || {
          value,
          label: `未知项(${value})`,
        },
    )
    .map((item, index) => ({ ...item, index }));
  const id = useId();
  const [sorting, sortingSet] = useState(false);
  const [from, fromSet] = useState(false);
  const [to, toSet] = useState(false);
  const [dragOffset, dragOffsetSet] = useState(0);

  let displayItems = null;
  if (!sorting) {
    displayItems = [...items];
  } else {
    let newIndexes = times(items.length);
    newIndexes[from] = null;
    newIndexes = [
      //
      ...newIndexes.slice(0, to),
      from,
      ...newIndexes.slice(to),
    ];
    newIndexes = newIndexes.filter((i) => i !== null);
    displayItems = newIndexes.map((i) => items[i]);
  }

  return (
    <OutlinedBox size="small" fullWidth label={label} {...others}>
      <Container variant={variant}>
        {displayItems.map((item) => (
          <Item
            key={item.value}
            variant={variant}
            item={item}
            style={{
              ...(!readOnly && {
                cursor: "move",
              }),
              ...(sorting &&
                item.index === from && {
                  opacity: 0.5,
                }),
            }}
            draggable={!readOnly}
            onDragStart={(event) => {
              event.dataTransfer.setData(
                DATA_TRANSFER_MIME,
                JSON.stringify({
                  id,
                }),
              );
              event.dataTransfer.effectAllowed = "move";
              const rect = event.currentTarget.getBoundingClientRect();
              dragOffsetSet(
                variant === "list"
                  ? event.clientY - rect.top
                  : event.clientX - rect.left,
              );
              sortingSet(true);
              fromSet(item.index);
              toSet(item.index);
            }}
            onDragEnd={() => {
              sortingSet(false);
            }}
            onDragOver={(event) => {
              if (!event.dataTransfer.types.includes(DATA_TRANSFER_MIME))
                return;

              event.preventDefault();
              event.dataTransfer.dropEffect = "move";

              if (from === item.index) return;
              const rect = event.currentTarget.getBoundingClientRect();
              const head =
                variant === "list"
                  ? event.clientY - rect.top < dragOffset
                  : event.clientX - rect.left < dragOffset;
              toSet(head ? item.index : item.index + 1);
            }}
            onDrop={(event) => {
              event.preventDefault();
              const dropped = JSON.parse(
                event.dataTransfer.getData(DATA_TRANSFER_MIME),
              );
              if (dropped.id !== id) return;
              const valuesNew = displayItems.map((i) => i.value);
              if (isEqual(valuesNew, values)) return;
              onChange(valuesNew);
            }}
          />
        ))}
      </Container>
    </OutlinedBox>
  );
}
function Container({ children, variant, ...others }) {
  if (variant === "list")
    return (
      <List
        disablePadding
        {...others}
        style={{ padding: "3px 2px", ...others.style }}
      >
        {children}
      </List>
    );
  if (variant === "grid")
    return (
      <div
        {...others}
        style={{
          display: "flex",
          flexFlow: "row wrap",
          padding: 5,
          ...others.style,
        }}
      >
        {children}
      </div>
    );
  return null;
}

function Item({ item, variant, ...others }) {
  if (variant === "list")
    return (
      <ListItem {...others}>
        <ListItemIcon>
          <CursorMove />
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.label} />
      </ListItem>
    );
  if (variant === "grid")
    return (
      <div {...others} style={{ ...others.style }}>
        <Card style={{ pointerEvents: "none", margin: 5 }}>{item.content}</Card>
      </div>
    );
}
