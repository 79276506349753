import React, { useEffect, useState } from "react";
import { useComponentSize } from "react-use-size";
import {
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Modal,
  Fab,
} from "@mui/material";
import { ChevronLeft, Fullscreen } from "mdi-material-ui";

import { useBooleanRouteState } from "hooks/useRouteState";

const TOOLBAR_WIDTH = 300;

export default function TabContent({ children, actions = [], toolbar }) {
  const { ref, width } = useComponentSize();
  const dense = width < 800 + TOOLBAR_WIDTH;
  const [open, openSet] = useState(false); // used in dense mode
  const [hidden, hiddenSet] = useState(false); // used in normal mode
  const [fullscreen, fullscreenSet] = useBooleanRouteState("fullscreen");

  useEffect(() => {
    if (dense && open) openSet(false);
    if (!dense && !open) openSet(true);
  }, [dense]);

  return (
    <div
      ref={ref}
      style={{
        marginRight: -5,
        marginBottom: -5,
        flex: "1 0 auto",
        display: "flex",
        flexFlow: "row-reverse nowrap",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "relative",
          zIndex: 10,
          flex: "0 0 auto",
          display: "flex",
          transition: dense ? "margin ease .2s, width ease .2s" : undefined,
          width: TOOLBAR_WIDTH,
          ...(!dense
            ? {
                ...(hidden && {
                  width: 0,
                }),
              }
            : {
                ...(open
                  ? {
                      marginLeft: -TOOLBAR_WIDTH,
                    }
                  : {
                      width: 0,
                    }),
              }),
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          style={{
            position: "absolute",
            zIndex: 1,
            top: 15,
            right: "100%",
            minWidth: "auto",
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: "5px 0 0 5px",
          }}
          onClick={() => (dense ? openSet((o) => !o) : hiddenSet((h) => !h))}
        >
          <ChevronLeft
            style={{
              transform: (dense ? open : !hidden)
                ? "rotate(180deg)"
                : undefined,
              transition: "transform ease .3s",
            }}
          />
        </Button>
        <Paper
          variant="outlined"
          style={{
            marginTop: 5,
            flex: "1 0 auto",
            display: "flex",
            flexFlow: "column nowrap",
            position: "relative",
            width: TOOLBAR_WIDTH,
            borderStyle: "solid none none solid",
            borderRadius: "5px 0 0 0",
          }}
        >
          {toolbar && (
            <div
              style={{
                padding: 5,
                flexFlow: "column nowrap",
              }}
            >
              {toolbar}
            </div>
          )}
          <List dense disablePadding>
            <ListItem disablePadding>
              <ListItemButton href="#" onClick={() => fullscreenSet(true)}>
                <ListItemIcon>
                  <Fullscreen />
                </ListItemIcon>
                <ListItemText primary="全屏显示" />
              </ListItemButton>
            </ListItem>
            {actions.map((action) => (
              <ListItem
                button
                key={action.title}
                component="a"
                target={action.target}
                href={action.href || "#"}
                disabled={action.disabled}
                onClick={action.onClick}
              >
                {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                <ListItemText primary={action.title} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </div>
      {!fullscreen && (
        <div
          style={{
            flex: "1 1 auto",
            overflowX: "auto",
            display: "flex",
            flexFlow: "column nowrap",
            marginRight: 5,
            marginBottom: 5,
          }}
        >
          {children}
        </div>
      )}
      {fullscreen && <div></div>}
      <Modal open={fullscreen} onClose={() => fullscreenSet(false)}>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#fff",
            display: "flex",
            flexFlow: "column nowrap",
            flex: "1 1 auto",
          }}
        >
          {fullscreen && children}
          <Fab
            title="退出全屏"
            onClick={() => fullscreenSet(false)}
            style={{
              position: "absolute",
              right: 10,
              bottom: 10,
            }}
          >
            <Fullscreen />
          </Fab>
        </div>
      </Modal>
    </div>
  );
}
