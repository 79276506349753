import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import { gql } from "@apollo/client";

import { teamRoute, getDialogProps } from "helpers";
import { useData, useRoute } from "hooks";
import { TeamIcon, Message } from "controls";
import { ListLoader } from "loaders";
import DialogFooter from "views/viewport/DialogFooter";

SelectTeamDialog.propTypes = {
  onClose: PropTypes.func,
};

export default function SelectTeamDialog({ onClose }) {
  const data = useData(
    gql`
      query SelectTeamDialog {
        teams {
          name
          id
        }
        userLogin {
          id
          isMobileUser
          isEmailUser
          login
        }
      }
    `,
    {},
  );
  const { teamId } = useRoute(teamRoute, { optional: true });

  return (
    <>
      <Dialog open onClose={onClose} {...getDialogProps()}>
        <DialogTitle>请选择一个团队</DialogTitle>
        <DialogContent>
          <Message
            message={
              <>
                如果此处没有显示您的团队，请与团队管理员核实您当前登录用的
                {data?.userLogin?.isMobileUser && <>手机号</>}
                {data?.userLogin?.isEmailUser && <>电子邮箱</>}(
                {data?.userLogin?.login})是否已正确录入团队。
              </>
            }
          />
          {data?.teams.length === 0 && <Message message="您没有加入任何团队" />}
          {!data && <ListLoader avatars subheaders />}
          <List>
            {data?.teams.map((team) => (
              <ListItem
                button
                component="a"
                href={`/${team.id}`}
                key={team.id}
                onClick={onClose}
                selected={teamId === team.id}
              >
                <ListItemAvatar>
                  <Avatar>
                    <TeamIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={team.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        {onClose && (
          <DialogActions>
            <Button onClick={onClose}>取消</Button>
          </DialogActions>
        )}
        <DialogFooter />
      </Dialog>
    </>
  );
}
