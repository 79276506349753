import React, { useState } from "react";
import { gql } from "@apollo/client";
import {
  FileSearch,
  Pencil,
  FileEdit,
  Printer,
  Database,
  Cog,
  SwapVertical,
  Plus,
} from "mdi-material-ui";

import {
  useProgressBar,
  useRoute,
  useData,
  useModeSwitcher,
  useFormDialog,
  useAction,
  useDialogOpener,
} from "hooks";
import { documentRoute, teamRoute, printUrl, showMessage } from "helpers";
import { TabContent, Message } from "controls";
import { documentContentContext } from "contexts";
import { BooleanInput, SuggestiveTextInput } from "inputs";

import DocumentContent from "./DocumentContent";
import SortPagesDialog from "./SortPagesDialog";
import AddWorkflowDialog from "views/workflows/AddWorkflowDialog";
import WorkflowCard, {
  WorkflowCardFragment,
} from "views/workflows/WorkflowCard";

export default function DocumentContentTab() {
  const progressBar = useProgressBar();
  const { documentId } = useRoute(documentRoute);
  const { teamId } = useRoute(teamRoute);
  const formDialog = useFormDialog();
  const openSortPagesDialog = useDialogOpener(SortPagesDialog);
  const openAddWorkflowDialog = useDialogOpener(AddWorkflowDialog);

  const [data, dataMeta] = useData(
    gql`
      query DocumentContentTab($documentId: ID!, $teamId: ID!) {
        document(id: $documentId) {
          id
          dataRowName
          canFill
          canModifyDocument
          canViewDocument
          canAddWorkflow
          tWorkflows {
            id
            name
          }
          workflows {
            id
            ...WorkflowCardFragment
          }
        }
        team(id: $teamId) {
          id
          dataRowNameCounts {
            value
            count
          }
        }
      }
      ${WorkflowCardFragment}
    `,
    { documentId, teamId },
    { returnMeta: true },
  );

  const updateDocument = useAction(gql`
    mutation DocumentContentTab($input: UpdateDocumentInput!) {
      updateDocument(input: $input) {
        document {
          id
          dataRowName
        }
      }
    }
  `);

  const [documentContentMode, documentContentModeSwitcher] = useModeSwitcher(
    "documentContentMode",
    [
      {
        name: "view",
        title: "查看",
        icon: <FileSearch />,
      },
      {
        name: "fill",
        title: "填写",
        icon: <Pencil />,
        disabled: !data?.document.canFill,
      },
      {
        name: "edit",
        title: "编辑",
        icon: <FileEdit />,
        disabled: !data?.document.canModifyDocument,
      },
    ],
  );

  const [dragPref, dragPrefSet] = useState({
    snap: true,
    multiDrag: false,
  });

  return (
    <documentContentContext.Provider
      value={{ documentContentMode, printing: false, dragPref }}
    >
      <TabContent
        toolbar={<>{documentContentModeSwitcher}</>}
        actions={[
          {
            title: "打印",
            icon: <Printer />,
            onClick: () =>
              progressBar(async () => {
                await printUrl(
                  `/canvas/${documentId}`,
                  (iframe) =>
                    !iframe.document.querySelector("body").dataset.fmsLoading &&
                    iframe.document.querySelector("body").dataset.fmsInited,
                );
              }),
          },
          {
            title: `数据表: ${data?.document.dataRowName}`,
            icon: <Database />,
            disabled: documentContentMode !== "edit",
            onClick: () =>
              formDialog({
                title: "设置数据表",
                fields: [
                  {
                    name: "dataRowName",
                    label: "数据表",
                    value: data?.document.dataRowName,
                    inputComponent: SuggestiveTextInput,
                    options: { suggestions: data?.team.dataRowNameCounts },
                  },
                ],
                onSubmit: async (formData) => {
                  await updateDocument({
                    input: { documentId, ...formData },
                  });
                  showMessage({ message: "成功设置数据表" });
                },
              }),
          },
          {
            title: "拖拽选项",
            icon: <Cog />,
            disabled: documentContentMode !== "edit",
            onClick: () =>
              formDialog({
                title: "拖拽选项",
                fields: [
                  {
                    name: "snap",
                    label: "自动对齐",
                    value: dragPref.snap,
                    inputComponent: BooleanInput,
                  },
                  {
                    name: "multiDrag",
                    label: "自动拖拽所有对齐边框",
                    value: dragPref.multiDrag,
                    inputComponent: BooleanInput,
                  },
                ],
                onSubmit: (formData) => {
                  dragPrefSet((dragPref) => ({
                    ...dragPref,
                    ...formData,
                  }));
                  showMessage({ message: "已更新拖拽选项" });
                },
              }),
          },
          {
            title: "调整页顺序",
            icon: <SwapVertical />,
            disabled: documentContentMode !== "edit",
            onClick: () => openSortPagesDialog({ documentId }),
          },
        ]}
      >
        {data?.document.workflows.map((workflow) => (
          <WorkflowCard
            key={workflow.id}
            workflow={workflow}
            dataMeta={dataMeta}
          />
        ))}
        {data?.document.tWorkflows.map((tWorkflow) => (
          <Message
            key={tWorkflow.id}
            actions={[
              {
                icon: <Plus />,
                title: "创建审核流程",
                disabled: !data?.document.canAddWorkflow,
                onClick: () =>
                  openAddWorkflowDialog({
                    documentId,
                    tWorkflowId: tWorkflow.id,
                  }),
              },
            ]}
          >
            该表单的审核流程<b>{tWorkflow.name}</b>尚未创建。
          </Message>
        ))}
        {data?.document.canViewDocument === false && (
          <Message type="warning">没有权限查看该表单内容</Message>
        )}
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            padding: 5,
          }}
        >
          <div
            style={{
              flex: "1 0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ flex: "0 0 auto", width: "fit-content" }}>
              <DocumentContent documentId={documentId} />
            </div>
          </div>
        </div>
      </TabContent>
    </documentContentContext.Provider>
  );
}
