import { gql } from "@apollo/client";

import {
  useActionFragment,
  useConfirmDialog,
  useData,
  useDialogOpener,
} from "hooks";
import { CardsList, Message, ObjectCard, WizardDialog } from "controls";
import { ItemPathFragment } from "blocks";
import { Import, Plus } from "mdi-material-ui";
import { ProjectCard } from "blocks";
import { ProjectCardFragment } from "blocks";
import { every, noop } from "lodash-es";
import { UserAvatar } from "blocks";
import { UserAvatarFragment } from "blocks";

import AddProjectCompanyDialog from "views/projects/AddProjectCompanyDialog";
import AddPermissionsDialog from "views/folders/AddPermissionsDialog";
import { ItemPath } from "blocks";

export default function CompanyRegistrationImportWizard({
  companyRegistrationId,
  onSubmit = noop,
  ...others
}) {
  const openAddPermissionsDialog = useDialogOpener(AddPermissionsDialog);
  const openAddProjectCompanyDialog = useDialogOpener(AddProjectCompanyDialog);
  const confirmDialog = useConfirmDialog();
  const companyRegistrationImport = useActionFragment(
    "companyRegistrationImport",
    "companyRegistration { id imported company { id } }",
  );
  const companyRegistrationProcess = useActionFragment(
    "companyRegistrationProcess",
    "companyRegistration { id processedAt }",
  );
  const data = useData(
    gql`
      query CompanyRegistrationImportWizard($companyRegistrationId: ID!) {
        companyRegistration(id: $companyRegistrationId) {
          id
          name
          imported
          projects
          company {
            id
            projectCompanies {
              id
              role
              project {
                id
                ...ProjectCardFragment
              }
            }
            users {
              id
              ...UserAvatarFragment
              permissions {
                id
                folder {
                  id
                  ...ItemPathFragment
                }
              }
            }
          }
        }
      }
      ${ItemPathFragment}
      ${UserAvatarFragment}
      ${ProjectCardFragment}
    `,
    { companyRegistrationId },
  );

  const company = data?.companyRegistration.company;

  return (
    <WizardDialog
      {...others}
      disabled={!data}
      title="导入单位注册"
      onSubmit={async () => {
        await companyRegistrationProcess({
          input: {
            companyRegistrationId,
          },
        });
        await onSubmit();
      }}
      steps={[
        {
          title: "导入单位和成员",
          completed: !!company && data.companyRegistration.imported,
          content: (
            <Message
              type={data?.companyRegistration.imported ? "success" : "info"}
              actions={[
                !data?.companyRegistration.imported && {
                  title: "导入单位和成员",
                  icon: <Import />,
                  onClick: async () => {
                    await confirmDialog();
                    await companyRegistrationImport({
                      input: {
                        companyRegistrationId,
                      },
                    });
                  },
                },
              ]}
            >
              {!company ? (
                <>
                  该单位<b>{data?.companyRegistration.name}</b>
                  尚未存在，请以注册表单中的信息导入单位
                </>
              ) : !data.companyRegistration.imported ? (
                <>
                  单位<b>{data.companyRegistration.name}</b>
                  已存在，您可以将注册表单中的信息更新到单位中。
                </>
              ) : (
                <>
                  单位<b>{data.companyRegistration.name}</b>
                  已导入
                </>
              )}
            </Message>
          ),
        },
        {
          title: "添加项目",
          disabled: !company,
          completed: !!company?.projectCompanies.length,
          content: (
            <>
              <Message
                actions={[
                  {
                    icon: <Plus />,
                    title: "添加项目",
                    onClick: () =>
                      openAddProjectCompanyDialog({ companyId: company.id }),
                  },
                ]}
              >
                相关项目: <b>{data?.companyRegistration.projects}</b>
                <br />
                请将单位添加到这些项目中。
              </Message>
              <p>已添加项目:</p>
              <CardsList
                cards={company?.projectCompanies.map((projectCompany) => (
                  <ProjectCard
                    key={projectCompany.id}
                    project={projectCompany.project}
                    href={undefined}
                    variant="outlined"
                  />
                ))}
              />
            </>
          ),
        },
        {
          title: "添加成员目录权限",
          disabled: !company,
          completed: !!every(company?.users, (user) => user.permissions.length),
          content: (
            <>
              <Message
                actions={[
                  {
                    title: "添加目录权限",
                    icon: <Plus />,
                    onClick: () =>
                      openAddPermissionsDialog({
                        userIds: company?.users.map((user) => user.id),
                      }),
                  },
                ]}
              >
                请为单位成员添加目录权限。
              </Message>
              <p>已添加权限:</p>
              <CardsList>
                {company?.users.map((user) => (
                  <ObjectCard
                    key={user.id}
                    variant="outlined"
                    title={user.name}
                    avatar={<UserAvatar user={user} />}
                  >
                    {!user.permissions.length && (
                      <Message type="warning">
                        该成员尚未添加任何目录访问权限。
                      </Message>
                    )}
                    {user.permissions.map((permission) => (
                      <div key={permission.id}>
                        <ItemPath item={permission.folder} />
                      </div>
                    ))}
                  </ObjectCard>
                ))}
              </CardsList>
            </>
          ),
        },
      ]}
    ></WizardDialog>
  );
}
