import React from "react";
import gql from "graphql-tag";

import { useRoute, useFilterBar, useData, useList } from "hooks";
import { teamRoute } from "helpers";
import { AppView, ProjectIcon, Message, TabContent } from "controls";
import { ProjectCard, ProjectCardFragment } from "blocks";
import ProjectsTable, { ProjectsTableFragment } from "lists/ProjectsTable";

export default function ProjectsView() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query ProjectsView($teamId: ID!) {
        team(id: $teamId) {
          id
          propertyLabelCounts(ownerType: "Project") {
            value
          }
        }
      }
    `,
    {
      teamId,
    },
  );

  const [{ searchTerm, folderId, propertyFilters, createdAtRange }, filterBar] =
    useFilterBar({ enableSearchTerm: true }, [
      { name: "folderId", type: "folder", label: "位置" },
      {
        type: "date_range",
        label: "创建日期",
        name: "createdAtRange",
      },
      {
        name: "propertyFilters",
        type: "property_filters",
        labels: data?.team.propertyLabelCounts.map((e) => e.value),
        ownerType: "Project",
      },
    ]);

  const [projectsData, projectsDataMeta] = useData(
    gql`
      query ProjectsView(
        $teamId: ID!
        $searchTerm: String
        $folderId: ID
        $propertyFilters: [FieldFilter!]
        $createdAtRange: DateRange
        $offset: Int
      ) {
        team(id: $teamId) {
          id
          projects(
            searchTerm: $searchTerm
            folderId: $folderId
            propertyFilters: $propertyFilters
            offset: $offset
            createdAtRange: $createdAtRange
            limit: 20
          ) {
            id
            ...ProjectsTableFragment
            ...ProjectCardFragment
          }
          projectsCount(
            searchTerm: $searchTerm
            folderId: $folderId
            propertyFilters: $propertyFilters
            createdAtRange: $createdAtRange
          )
        }
      }
      ${ProjectsTableFragment}
      ${ProjectCardFragment}
    `,
    {
      teamId,
      searchTerm,
      folderId,
      createdAtRange,
      propertyFilters,
    },
    {
      returnMeta: true,
    },
  );

  const [list, listModeSwitcher] = useList("project", {
    rows: projectsData?.team.projects,
    rowsCount: projectsData?.team.projectsCount,
    fetchMore: projectsDataMeta.fetchMore,
    card: <ProjectCard />,
    table: <ProjectsTable />,
  });

  return (
    <AppView
      title="所有项目"
      crumbs={[
        {
          icon: <ProjectIcon />,
          label: "所有项目",
          href: `/${teamId}/projects`,
        },
      ]}
    >
      <TabContent toolbar={listModeSwitcher}>
        <Message message="这里可以找到团队内的所有项目，创建新项目请到指定目录里进行操作。" />
        {filterBar}
        {list}
      </TabContent>
    </AppView>
  );
}
