import { gql } from "@apollo/client";

import GridList from "controls/GridList";
import filterBlobUrl from "helpers/filterBlobUrl";
import useDialogOpener from "hooks/useDialogOpener";
import PreviewDialog from "views/previewable/PreviewDialog";

export const RegistrationFilesGridListFragment = gql`
  fragment RegistrationFilesGridListFragment on RegistrationFile {
    id
    name
    thumbnailUrl
  }
`;

export default function RegistrationFilesGridList({ registrationFiles }) {
  const openPreviewDialog = useDialogOpener(PreviewDialog);

  return (
    <GridList
      rows={registrationFiles?.map((registrationFile) => ({
        id: registrationFile.id,
        name: registrationFile.name,
        onClick: () =>
          openPreviewDialog({ previewableId: registrationFile.id }),
        thumbnailUrl: filterBlobUrl(registrationFile.thumbnailUrl),
      }))}
    />
  );
}
