import { gql } from "@apollo/client";
import { uniq, flatten } from "lodash-es";
import { Tag } from "mdi-material-ui";

import { Table, UsersCell, CompanyIcon } from "controls";
import { useRoute } from "hooks";
import { teamRoute } from "helpers";

export const CompaniesTableFragment = gql`
  fragment CompaniesTableFragment on Company {
    id
    name
    users {
      id
    }
    properties {
      id
      label
      values
    }
  }
`;

export default function CompaniesTable({ rows, ...others }) {
  const labels = uniq(
    flatten(
      (rows || []).map((company) => company.properties.map((pf) => pf.label)),
    ),
  );
  const { teamId } = useRoute(teamRoute);

  return (
    <Table
      columns={[
        {
          label: "名称",
          sortable: true,
        },
        {
          label: "成员",
          cellComponent: UsersCell,
        },
        ...labels.map((label) => ({
          icon: <Tag />,
          label: label,
        })),
      ].filter((c) => c)}
      rows={rows?.map((company) => ({
        id: company.id,
        title: company.name,
        icon: <CompanyIcon />,
        href: `/${teamId}/${company.id}`,
        values: [
          company.name,
          company.users.map((u) => u.id),
          ...labels
            .map((label) => company.properties.find((f) => f.label === label))
            .map((property) =>
              property ? property.values.join(",") || "(未填写)" : "",
            ),
        ],
      }))}
      {...others}
    />
  );
}
