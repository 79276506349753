import React, { useState } from "react";
import PropTypes from "prop-types";

import UserError from "helpers/UserError";
import sleep from "helpers/sleep";
import { formContext } from "contexts";

AppForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default React.memo(AppForm);

function AppForm({ onSubmit, children, ...others }) {
  const [fields, fieldsSet] = useState([]);
  const [submitting, submittingSet] = useState(false);

  return (
    <formContext.Provider
      value={{
        fields,
        fieldsSet,
        submitting,
      }}
    >
      <form
        {...others}
        onSubmit={async (event) => {
          event.preventDefault();
          event.stopPropagation();
          const formData = {};
          submittingSet(true);
          for (const field of fields) {
            try {
              await field.validateValue(field.value);
              field.error = null;
            } catch (error) {
              field.error = error;
            }
            Object.assign(formData, { [field.name]: field.value });
          }

          const errorFields = fields.filter((f) => f.error);

          try {
            if (errorFields.length)
              throw new UserError(
                `${errorFields.length} 个错误：${errorFields
                  .map((f) => f.error.message)
                  .join(", ")}`,
              );
            await sleep(1);
            await onSubmit(formData);
          } finally {
            submittingSet(false);
          }
        }}
      >
        {children}
      </form>
    </formContext.Provider>
  );
}
