function getDialogProps() {
  return {
    ...((window.innerWidth < 480 || window.innerHeight < 480) && {
      fullScreen: true,
    }),
    PaperProps: {
      style: {
        paddingBottom: `env(safe-area-inset-bottom)`,
      },
    },
  };
}

export default getDialogProps;
