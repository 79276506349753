import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import { FormDialog } from "controls";
import { showMessage } from "helpers";
import { FolderInput } from "inputs";
import { useAction, useData } from "hooks";

export default React.memo(AddSubFolderDialog);

AddSubFolderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  folderId: PropTypes.string.isRequired,
};

function AddSubFolderDialog({ onClose, folderId }) {
  const data = useData(
    gql`
      query AddSubFolderDialog($folderId: ID!) {
        folder(id: $folderId) {
          id
          name
        }
      }
    `,
    {
      folderId,
    },
  );
  const addSubFolder = useAction(gql`
    mutation AddSubFolderDialog($input: AddSubFolderInput!) {
      addSubFolder(input: $input) {
        folder {
          id
          items {
            id
          }
        }
        addedSubFolder {
          id
          name
          size
        }
      }
    }
  `);
  return (
    <FormDialog
      title="添加子目录"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addSubFolder({
          input: {
            folderId: data.folder.id,
            ...formData,
          },
        });
        showMessage({ message: `成功创建子目录${formData.name}` });
      }}
      fields={[
        {
          name: "folderId",
          label: "父目录",
          value: folderId,
          required: true,
          inputComponent: FolderInput,
        },
        {
          name: "name",
          value: "新目录",
          required: true,
          label: "新目录名称",
        },
      ]}
    />
  );
}
