import React, { useRef } from "react";

import { documentActionsContext } from "contexts";
import DocumentActions from "views/viewport/DocumentActions";

export default React.memo(DocumentActionsProvider);

function DocumentActionsProvider({
  children,
  renderDocumentActions = (actions) => <>{actions}</>,
}) {
  const documentActionRef = useRef();
  const documentActionsSet = (...args) =>
    documentActionRef.current?.documentActionsSet(...args);

  return (
    <documentActionsContext.Provider
      value={{
        documentActionsSet,
      }}
    >
      <DocumentActions
        renderDocumentActions={renderDocumentActions}
        stateRef={documentActionRef}
      />
      {children}
    </documentActionsContext.Provider>
  );
}
