import React from "react";
import { gql } from "@apollo/client";

import { Message } from "controls";
import { showMessage } from "helpers";
import { useAction, useHistory, useFormDialog } from "hooks";

export default function CreateTeam() {
  const history = useHistory();
  const formDialog = useFormDialog();
  const createTeam = useAction(
    gql`
      mutation CreateTeam($input: CreateTeamInput!) {
        createTeam(input: $input) {
          team {
            id
            name
          }
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: gql`
            query {
              teams {
                id
              }
            }
          `,
        },
      ],
    },
  );

  return (
    <Message
      actions={[
        {
          title: "创建团队",
          onClick: () =>
            formDialog({
              title: "创建团队",
              onSubmit: async (formData) => {
                const result = await createTeam({
                  input: {
                    ...formData,
                  },
                });
                showMessage({
                  message: `成功创建新团队${formData.teamName}`,
                });
                history.push(`/${result.createTeam.team.id}`);
              },
              fields: [
                {
                  name: "teamName",
                  label: "团队名称",
                  required: true,
                },
                {
                  name: "userName",
                  label: "您的姓名",
                  required: true,
                  helperText: "您在新团队里的“成员”姓名",
                },
              ],
            }),
        },
      ]}
    >
      创建团队
      <br />
      创建一个新团队，您可以创建团队后将权限“转移”为新的团队所有者。
    </Message>
  );
}
