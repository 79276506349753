import React from "react";
import { gql } from "@apollo/client";

import { teamRoute, showMessage } from "helpers";
import { useRoute, useData, useAction, useFormDialog } from "hooks";
import { FormCard } from "controls";
import { UserAvatar, UserAvatarFragment } from "blocks";
import { FileUploadInput } from "inputs";

export default function ProfileTab() {
  const { teamId } = useRoute(teamRoute);
  const formDialog = useFormDialog();

  const data = useData(
    gql`
      query ProfileTab($teamId: ID!) {
        team(id: $teamId) {
          id
          user {
            id
            name
            mobile
            email
            ...UserAvatarFragment
          }
        }
      }
      ${UserAvatarFragment}
    `,
    { teamId },
  );
  const updateProfile = useAction(gql`
    mutation ProfileTab($input: UpdateProfileInput!) {
      updateProfile(input: $input) {
        user {
          id
          name
          email
          mobile
          avatarBlobUrl
        }
      }
    }
  `);
  return (
    <>
      <FormCard
        avatar={
          <UserAvatar
            user={data?.team.user}
            href="#"
            component="a"
            onClick={() =>
              formDialog({
                title: "设置头像",
                fields: [
                  {
                    name: "upload",
                    label: "头像文件",
                    inputComponent: FileUploadInput,
                    options: {
                      accept: "image/*",
                    },
                  },
                ],
                onSubmit: async ({ upload }) => {
                  updateProfile({
                    input: {
                      teamId,
                      avatarEtag: upload?.etag || null,
                    },
                  });
                  showMessage({ message: "成功设置头像" });
                },
              })
            }
          />
        }
        title="我的资料"
        fields={[
          {
            label: "姓名",
            name: "name",
            value: data?.team.user.name,
            required: true,
          },
          {
            label: "电子邮箱",
            name: "email",
            value: data?.team.user.email,
            helperText: "",
          },
          {
            label: "手机号码",
            name: "mobile",
            value: data?.team.user.mobile,
            helperText: "",
          },
        ]}
        onSubmit={async (formData) => {
          await updateProfile({
            input: {
              teamId,
              ...formData,
            },
          });
          showMessage({ message: "成功修改我的资料" });
        }}
      />
    </>
  );
}
