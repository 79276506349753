import { gql } from "@apollo/client";
import { flatten, uniq } from "lodash-es";
import { Tag } from "mdi-material-ui";

import { Table, DateTimeCell, ItemPathCell, ProjectIcon } from "controls";
import { useRoute } from "hooks";
import { teamRoute } from "helpers";

export const ProjectsTableFragment = gql`
  fragment ProjectsTableFragment on Project {
    id
    name
    properties {
      id
      label
      values
    }
    filesCount
    documentsCount
    createdAt
    folder {
      id
    }
  }
`;

export default function ProjectsTable({ rows, ...others }) {
  const labels = uniq(
    flatten(
      (rows || []).map((project) => project.properties.map((pf) => pf.label)),
    ),
  );
  const { teamId } = useRoute(teamRoute);

  return (
    <Table
      {...others}
      columns={[
        {
          label: "名称",
        },
        { label: "创建时间", cellComponent: DateTimeCell },
        ...labels.map((label) => ({
          icon: <Tag />,
          label: label,
        })),
        {
          label: "内容",
        },
        { label: "位置", cellComponent: ItemPathCell },
      ]}
      rows={rows?.map((project) => ({
        id: project.id,
        title: project.name,
        icon: <ProjectIcon />,
        href: `/${teamId}/${project.folder.id}`,
        values: [
          //
          project.name,
          project.createdAt,
          ...labels
            .map((label) => project.properties.find((f) => f.label === label))
            .map((property) =>
              property ? property.values.join(",") || "(未填写)" : "",
            ),
          `文档:${project.filesCount} 表单:${project.documentsCount}`,
          project.folder.id,
        ],
      }))}
    />
  );
}
