import { gql } from "@apollo/client";
import { Cancel, Restore } from "mdi-material-ui";

import {
  showMessage,
  userRoute,
  UserPermissionsFragment,
  USER_PERMISSION_TYPES,
} from "helpers";
import {
  useRoute,
  useData,
  useActionFragment,
  useAction,
  useConfirmDialog,
} from "hooks";
import { Message, FormCard, TabContent } from "controls";
import { CompaniesInput, BooleanInput } from "inputs";

export default function UserProfileTab() {
  const { userId } = useRoute(userRoute);
  const confirmDialog = useConfirmDialog();

  const data = useData(
    gql`
      query UserProfileTab($userId: ID!) {
        user(id: $userId) {
          id
          enabled
          canToggleEnabled
        }
      }
    `,
    { userId },
  );

  const userToggleEnabled = useActionFragment(
    "userToggleEnabled",
    "user { id enabled warningMessages }",
  );

  return (
    <TabContent
      actions={[
        {
          icon: data?.user.enabled ? <Cancel /> : <Restore />,
          title: data?.user.enabled ? "禁用该成员" : "恢复该成员",
          disabled: !data?.user.canToggleEnabled,
          onClick: async () => {
            await confirmDialog();
            await userToggleEnabled({
              input: {
                userId,
                enabled: !data?.user.enabled,
              },
            });
          },
        },
      ]}
    >
      <Messages />
      <Profile />
      <Companies />
      <Permissions />
    </TabContent>
  );
}

function Messages() {
  const { userId } = useRoute(userRoute);
  const data = useData(
    gql`
      query Messages($userId: ID!) {
        user(id: $userId) {
          id
          warningMessages
        }
      }
    `,
    { userId },
  );

  if (!data) return null;

  return (
    <>
      {data.user.warningMessages.map((warningMessage, warningMessageIndex) => (
        <Message key={warningMessageIndex} type="warning">
          {warningMessage}
        </Message>
      ))}
    </>
  );
}

function Profile() {
  const { userId } = useRoute(userRoute);
  const data = useData(
    gql`
      query Profile($userId: ID!) {
        user(id: $userId) {
          id
          name
          mobile
          email
          canEditProfile
        }
      }
    `,
    { userId },
  );
  const updateUserProfile = useActionFragment(
    "updateUserProfile",
    "user { id name email mobile warningMessages }",
  );

  return (
    <FormCard
      disabled={!data?.user.canEditProfile}
      title="基本资料"
      fields={[
        {
          label: "姓名",
          name: "name",
          value: data?.user.name,
          required: true,
        },
        {
          label: "电子邮箱",
          name: "email",
          value: data?.user.email,
          helperText: "该成员可以通过该电子邮箱登录",
        },
        {
          label: "手机号码",
          name: "mobile",
          value: data?.user.mobile,
          helperText: "该成员可以通过该手机邮箱登录",
        },
      ]}
      onSubmit={async (formData) => {
        await updateUserProfile({
          input: {
            userId,
            ...formData,
          },
        });
        showMessage({ message: "成功修改成员资料" });
      }}
    />
  );
}

function Companies() {
  const { userId } = useRoute(userRoute);
  const data = useData(
    gql`
      query Companies($userId: ID!) {
        user(id: $userId) {
          id
          canUpdateCompanies
          companies {
            id
          }
        }
      }
    `,
    { userId },
  );
  const userUpdateCompanies = useActionFragment(
    "userUpdateCompanies",
    "user { id companies { id } }",
  );

  return (
    <FormCard
      disabled={!data?.user.canUpdateCompanies}
      title="所属单位"
      fields={[
        {
          label: "单位",
          name: "companyIds",
          value: data?.user.companies.map((c) => c.id),
          inputComponent: CompaniesInput,
          helperText:
            "团队成员可以同时属于多个单位；您可以在单位里设置成员在单位内的角色",
        },
      ]}
      onSubmit={async (formData) => {
        await userUpdateCompanies({
          input: {
            userId,
            ...formData,
          },
        });
      }}
    />
  );
}

function Permissions() {
  const { userId } = useRoute(userRoute);
  const data = useData(
    gql`
      query Permissions($userId: ID!) {
        user(id: $userId) {
          id
          enabled
          canToggleEnabled
          canEditPermissions
          ...UserPermissionsFragment
          team {
            id
          }
        }
      }
      ${UserPermissionsFragment}
    `,
    {
      userId,
    },
  );
  const updateUserPermissions = useAction(gql`
    mutation ($input: UpdateUserPermissionsInput!) {
      updateUserPermissions(input: $input) {
        user {
          id
          ...UserPermissionsFragment
        }
      }
    }
    ${UserPermissionsFragment}
  `);
  return (
    <FormCard
      disabled={!data?.user.canEditPermissions}
      title="团队权限"
      fields={[
        ...USER_PERMISSION_TYPES.map(({ label, name }) => ({
          label: `允许${label}`,
          name,
          value: data?.user[name] || false,
          inputComponent: BooleanInput,
        })),
      ]}
      onSubmit={async (formData) => {
        await updateUserPermissions({
          input: {
            userId: data.user.id,
            ...formData,
          },
        });
        showMessage({ message: "成功修改成员权限" });
      }}
    />
  );
}
