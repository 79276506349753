import React from "react";
import { Route, Switch } from "react-router-dom";

import { canvasDocumentRoute, canvasPreviewableRoute } from "helpers";
import { RouteNotFound } from "controls";
import DocumentCanvas from "views/canvas/DocumentCanvas";
import PreviewableCanvas from "views/canvas/PreviewableCanvas";

export default function CanvasView() {
  return (
    <>
      <Switch>
        <Route exact path={canvasDocumentRoute} component={DocumentCanvas} />
        <Route
          exact
          path={canvasPreviewableRoute}
          component={PreviewableCanvas}
        />
        <Route component={RouteNotFound} />
      </Switch>
    </>
  );
}
