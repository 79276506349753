import React, { useContext } from "react";

import { desktopStateContext } from "contexts";
import { DESKTOP_BUILD } from "helpers/config";
import logoImage from "images/logo_button.png";

export default function DialogFooter() {
  const desktopState = useContext(desktopStateContext);

  return (
    <div
      style={{
        padding: "10px 5px 0 5px",
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center",
      }}
    >
      <img
        style={{ margin: 5, height: "2em", verticalAlign: "middle" }}
        src={logoImage}
        alt="优建云"
      />
      {(DESKTOP_BUILD
        ? [<>优建云客户端 {desktopState?.version}</>]
        : [
            <>
              版权所有 &nbsp;
              <span role="img" aria-label="c">
                ©️
              </span>
              &nbsp;
              <a href="https://www.ublib.com/" style={{ color: "inherit" }}>
                优建云 {new Date().getFullYear()}
              </a>
            </>,
            <>粤ICP备17112001号</>,
          ]
      ).map((content, contentIndex) => (
        <span
          key={contentIndex}
          style={{
            margin: "0 10px",
            color: "inherit",
            opacity: 0.4,
            display: "inline-block",
          }}
        >
          {content}
        </span>
      ))}
    </div>
  );
}
