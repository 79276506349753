import React from "react";
import { gql } from "@apollo/client";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  Avatar,
  Checkbox,
} from "@mui/material";
import { Plus } from "mdi-material-ui";

import UserAvatar, { UserAvatarFragment } from "blocks/UserAvatar";
import useData from "hooks/useData";
import useRoute from "hooks/useRoute";
import useDialogOpener from "hooks/useDialogOpener";
import useFilterBar from "hooks/useFilterBar";
import { teamRoute } from "helpers/routes";
import TextLoader from "loaders/TextLoader";
import ListLoader from "loaders/ListLoader";
import FetchMoreButton from "controls/FetchMoreButton";
import AddUserDialog from "views/users/AddUserDialog";
import toggleArray from "helpers/toggleArray";

import MultiInput from "./MultiInput";
import SingleInput from "./SingleInput";

export default function UserInput({ ...others }) {
  return (
    <SingleInput
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemDialogContent}
      {...others}
    />
  );
}

function ItemAvatar({ item, ...others }) {
  return <UserAvatar userId={item} {...others} />;
}

function ItemLabel({ item }) {
  const data = useData(
    gql`
      query ItemLabel($userId: ID!) {
        user(id: $userId) {
          id
          name
        }
      }
    `,
    { userId: item },
    { fetchPolicy: "cache-first" },
  );
  return <>{data?.user.name || <TextLoader />}</>;
}

export function UsersInput({ ...others }) {
  return (
    <MultiInput
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderSelectDialogContent={SelectDialogContent}
      {...others}
    />
  );
}

function ItemDialogContent({ item: userId, itemSubmit: userIdSubmit }) {
  return <UsersList selectedUserIds={[userId]} onUserClick={userIdSubmit} />;
}

function SelectDialogContent({ items: userIds, itemsSet: userIdsSet }) {
  return (
    <UsersList
      selectedUserIds={userIds}
      onUserClick={(userId) => userIdsSet(toggleArray(userIds, userId))}
      renderUserSecondaryAction={(userId) => (
        <Checkbox
          edge="end"
          checked={userIds.includes(userId)}
          onChange={() => userIdsSet(toggleArray(userIds, userId))}
        />
      )}
    />
  );
}

function UsersList({
  selectedUserIds,
  onUserClick,
  renderUserSecondaryAction,
}) {
  const [{ searchTerm, companyId }, filterBar] = useFilterBar(
    {
      enableSearchTerm: true,
      noUrl: true,
    },
    [
      {
        type: "company",
        label: "单位",
        name: "companyId",
        alwaysEnabled: true,
      },
    ],
  );
  const openAddUserDialog = useDialogOpener(AddUserDialog);
  const { teamId } = useRoute(teamRoute);

  const [data, dataMeta] = useData(
    gql`
      query UsersList(
        $teamId: ID!
        $searchTerm: String
        $companyId: ID
        $offset: Int
      ) {
        team(id: $teamId) {
          id
          users(
            searchTerm: $searchTerm
            companyId: $companyId
            offset: $offset
            limit: 20
          ) {
            id
            name
            companies {
              id
              name
            }
            ...UserAvatarFragment
          }
          usersCount(searchTerm: $searchTerm, companyId: $companyId)
        }
      }
      ${UserAvatarFragment}
    `,
    { teamId, searchTerm, companyId },
    { returnMeta: true },
  );
  return (
    <>
      {filterBar}
      {!data && <ListLoader avatars subheaders listSubheader />}
      <List dense>
        {data?.team.users.map((user) => (
          <ListItem
            button
            key={user.id}
            disablePadding
            secondaryAction={
              !renderUserSecondaryAction
                ? undefined
                : renderUserSecondaryAction(user.id)
            }
          >
            <ListItemButton
              selected={selectedUserIds.includes(user.id)}
              onClick={() => onUserClick(user.id)}
              component="button"
            >
              <ListItemAvatar>
                <UserAvatar user={user} />
              </ListItemAvatar>
              <ListItemText
                primary={user.name}
                secondary={<>{user.companies.map((c) => c.name).join(",")}</>}
              />
              {renderUserSecondaryAction && (
                <ListItemSecondaryAction>
                  {renderUserSecondaryAction(user.id)}
                </ListItemSecondaryAction>
              )}
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => openAddUserDialog({ name: searchTerm })}
            component="button"
          >
            <ListItemAvatar>
              <Avatar>
                <Plus />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="邀请新成员" />
          </ListItemButton>
        </ListItem>
      </List>
      <FetchMoreButton
        rows={data?.team.users}
        rowsCount={data?.team.usersCount}
        fetchMore={dataMeta.fetchMore}
      />
    </>
  );
}
