import React, { useState, useEffect } from "react";
import { defer } from "lodash-es";
import { CloseCircle } from "mdi-material-ui";
import { IconButton, ClickAwayListener, Popper, Paper } from "@mui/material";

import usePortal from "./usePortal";

export default function useDocumentOverlay({
  width,
  minWidth = 300,
  minHeight,
  enabled = true,
  overlay,
}) {
  const { mount, unmount } = usePortal();
  const [anchorEl, anchorElSet] = useState(null);
  const [focused, focusedSet] = useState(false);
  const [overlayFocused, overlayFocusedSet] = useState(false);

  const handleFocus = (event) => {
    if (!enabled) return;
    focusedSet(true);
    anchorElSet(event.currentTarget);
  };
  const handleBlur = () => defer(() => focusedSet(false));
  const open = (focused || overlayFocused) && !!overlay;

  useEffect(() => {
    if (open)
      mount(
        <Popper
          open
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 10 }}
        >
          <ClickAwayListener onClickAway={() => overlayFocusedSet(false)}>
            <div
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                width: width || anchorEl.offsetWidth,
                minWidth,
                minHeight,
              }}
            >
              <Paper
                onFocus={() => overlayFocusedSet(true)}
                onClick={() => overlayFocusedSet(true)}
                elevation={10}
                style={{
                  padding: 5,
                }}
              >
                {overlay}
              </Paper>
              <IconButton
                size="small"
                style={{ position: "absolute", bottom: 5, right: 5 }}
                title="关闭该工具栏"
                onClick={() => overlayFocusedSet(false)}
              >
                <CloseCircle />
              </IconButton>
            </div>
          </ClickAwayListener>
        </Popper>,
      );
    else unmount();
  }, [open, overlay]);

  return { handleBlur, handleFocus, open };
}
