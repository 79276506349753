import React, { useId, useState } from "react";
import { compact, flatten, isEqual } from "lodash-es";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  MenuItem,
} from "@mui/material";

export default function AppSelect({
  label,
  value,
  onChange,
  sections = [],
  helperText,
  options,
  ...others
}) {
  const id = useId();
  const labelId = `${id}-label`;
  sections = [...sections, options && { label, options }].filter((s) => s);

  return (
    <FormControl size="small" {...others}>
      <InputLabel id={labelId} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id}
        labelId={labelId}
        label={label}
        value={value || ""}
        onChange={(event) => onChange && onChange(event.target.value)}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        renderValue={(value) => {
          if (!value) return null;
          const { label, icon } = flatten(sections.map((s) => s.options)).find(
            (o) => o.value === value,
          ) || {
            label: `(${value})`,
          };

          return (
            <>
              {icon &&
                React.cloneElement(icon, {
                  fontSize: "inherit",
                  style: {
                    marginRight: ".2em",
                  },
                })}
              {label}
            </>
          );
        }}
      >
        {compact(
          flatten(
            sections
              .filter((s) => s)
              .map((section, sectionIndex) => [
                [
                  section.label && (
                    <ListSubheader key={["section", sectionIndex]}>
                      {section.label}
                    </ListSubheader>
                  ),
                ],
                section.options?.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    component="a"
                    href="#"
                  >
                    {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                    <ListItemText
                      primary={option.label}
                      secondary={option.description}
                    />
                  </MenuItem>
                )),
              ]),
          ),
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export const AppMultiSelect = React.memo(function AppMultiSelect({
  label,
  values,
  onChange,
  helperText,
  options,
  ...others
}) {
  const id = useId();
  const labelId = `${id}-label`;
  const [open, openSet] = useState(false);
  // include unspecified options
  const allOptions = [
    ...(options || []),
    ...(values || [])
      .filter((v) => !options.map((o) => o.value).includes(v))
      .map((value) => ({
        label: value,
        value,
      })),
  ];

  const selectedOptions = allOptions.filter((o) => values.includes(o.value));

  return (
    <FormControl {...others}>
      <InputLabel id={labelId} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id}
        labelId={labelId}
        label={label}
        multiple
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        open={open}
        value={open ? selectedOptions.map((o) => o.value) : values || []}
        onOpen={() => openSet(true)}
        onClose={() => openSet(false)}
        onChange={(event) => {
          const newValues = allOptions
            .filter((option) => event.target.value.includes(option.value))
            .map((o) => o.value);
          if (onChange && !isEqual(newValues, values)) onChange(newValues);
        }}
        renderValue={(values) => (
          <div style={{ display: "flex", flexFlow: "row nowrap" }}>
            <div
              style={{
                flex: "1 1 auto",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 10,
              }}
            >
              {allOptions
                .filter((o) => values.includes(o.value))
                .map((option, optionIndex) => (
                  <span key={option.value}>
                    {!!optionIndex && ", "}
                    {option.icon &&
                      React.cloneElement(option.icon, {
                        fontSize: "inherit",
                        style: {
                          marginRight: ".2em",
                        },
                      })}
                    {option.label}
                  </span>
                ))}
            </div>
            <div style={{ flex: "0 0 auto" }}>
              ({options?.filter((o) => values.includes(o.value)).length}/
              {options?.length})
            </div>
          </div>
        )}
      >
        {allOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            component="a"
            href="#"
          >
            <Checkbox
              id={`${id}-${option.value}`}
              checked={values.includes(option.value)}
            />
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            <ListItemText
              primary={
                <label htmlFor={`${id}-${option.value}`}>{option.label}</label>
              }
              secondary={option.description}
            />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
