import { useEffect } from "react";
import { defer } from "lodash-es";

export default function useDOMEvent(
  eventName,
  handler,
  deps = [],
  getElement = () => window,
  options = {},
) {
  useEffect(() => {
    const element = getElement();
    if (!element) return;
    const listener = (...args) => defer(() => handler(...args));
    element.addEventListener(eventName, listener, options);

    return () => {
      element.removeEventListener(eventName, listener, options);
    };
  }, deps);
}
