import React, { Suspense } from "react";
import { gql } from "@apollo/client";
import { FilePlus, Server } from "mdi-material-ui";
import { filesize } from "filesize";

import { ActivityCard, ActivityCardFragment } from "blocks";
import { useData, useRoute } from "hooks";
import { teamRoute } from "helpers";
import {
  Markdown,
  ActivityIcon,
  ProjectIcon,
  NoticeIcon,
  AppView,
  CardsList,
} from "controls";
import NumberWidget from "dashboard/NumberWidget";
import Widget from "dashboard/Widget";
import DASHBOARD_COLORS from "dashboard/DASHBOARD_COLORS";
import Dashboard from "dashboard/Dashboard";

const ActivityChartWidget = React.lazy(() => import("./ActivityChartWidget"));

export default function TeamDashboard() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query TeamDashboard($teamId: ID!) {
        team(id: $teamId) {
          id
          notices {
            id
            title
            content
          }
          dashboardData {
            id
            weekNewFilesDocumentsCount
            monthNewActivitiesCount
            projectsCount
            itemsTotalMegabytes
          }
        }
      }
    `,
    { teamId },
  );

  const [activitiesData, activitiesDataMeta] = useData(
    gql`
      query TeamDashboard($teamId: ID!, $offset: Int) {
        team(id: $teamId) {
          id
          activities(offset: $offset, limit: 20) {
            id
            createdAt
            ...ActivityCardFragment
          }
          activitiesCount
        }
      }
      ${ActivityCardFragment}
    `,
    { teamId },
    { returnMeta: true },
  );

  return (
    <AppView title="团队首页">
      <Dashboard>
        {data?.team.notices.map((notice) => (
          <Widget
            key={notice.id}
            icon={<NoticeIcon />}
            title={notice.title}
            color="#9eabad"
          >
            <Markdown
              source={notice.content}
              style={{
                padding: "10px 20px",
              }}
            />
          </Widget>
        ))}
        <NumberWidget
          color={DASHBOARD_COLORS[0]}
          title="本周增加文件"
          number={data?.team.dashboardData.weekNewFilesDocumentsCount}
          unit="个文档/表单"
          icon={<FilePlus />}
        />
        <NumberWidget
          color={DASHBOARD_COLORS[1]}
          title="本月动态"
          number={data?.team.dashboardData.monthNewActivitiesCount}
          unit="条动态"
          icon={<ActivityIcon />}
        />
        <NumberWidget
          icon={<ProjectIcon />}
          color={DASHBOARD_COLORS[2]}
          title="所有项目"
          number={data?.team.dashboardData.projectsCount}
          unit="个项目"
        />
        <NumberWidget
          icon={<Server />}
          color={DASHBOARD_COLORS[3]}
          title="所有文档"
          number={
            data &&
            filesize(
              data.team.dashboardData.itemsTotalMegabytes * 1024 * 1024,
            ).match(/[\d.]+/)
          }
          unit={
            data &&
            filesize(
              data.team.dashboardData.itemsTotalMegabytes * 1024 * 1024,
              {
                base: 0,
              },
            ).match(/[^\d.]+/)
          }
        />
        <Suspense
          fallback={
            <Widget style={{ width: 500 }} color={DASHBOARD_COLORS[4]}>
              <div style={{ height: 200 }} />
            </Widget>
          }
        >
          <ActivityChartWidget
            style={{ width: 500 }}
            color={DASHBOARD_COLORS[4]}
          />
        </Suspense>
        <Widget
          color={DASHBOARD_COLORS[5]}
          icon={<ActivityIcon />}
          title="团队最新动态"
          style={{ width: "100%" }}
        >
          <CardsList
            style={{ margin: 5 }}
            rows={activitiesData?.team.activities}
            rowsCount={activitiesData?.team.activitiesCount}
            fetchMore={activitiesDataMeta.fetchMore}
            cardComponent={<ActivityCard />}
            cardPropName="activity"
            getRowDate={(activity) => activity.createdAt}
          />
        </Widget>
      </Dashboard>
    </AppView>
  );
}
