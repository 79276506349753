import React from "react";
import { gql } from "@apollo/client";

import { FormDialog } from "controls";
import { showMessage } from "helpers";
import { FolderUploadInput, FolderInput } from "inputs";
import { useAction } from "hooks";

export default function UploadFolderDialog({ folderId, ...others }) {
  const uploadFolder = useAction(gql`
    mutation UploadFolderDialog($input: UploadFolderInput!) {
      uploadFolder(input: $input) {
        folder {
          id
          items {
            id
          }
        }
      }
    }
  `);
  return (
    <FormDialog
      {...others}
      title="添加目录"
      onSubmit={async (formData) => {
        await uploadFolder({
          input: {
            ...formData,
          },
        });
        showMessage({
          message: "成功上传目录内容",
        });
      }}
      fields={[
        {
          label: "上传当前目录的内容",
          name: "folderId",
          value: folderId,
          inputComponent: FolderInput,
          required: true,
        },
        {
          label: "本地目录",
          name: "folderUpload",
          inputComponent: FolderUploadInput,
          required: true,
        },
      ]}
    />
  );
}
