import React from "react";
import ContentLoader from "react-content-loader";

export default React.memo(TextLoader);

function TextLoader({ width = 200, ...others }) {
  return (
    <ContentLoader
      {...others}
      style={{
        display: "inline-block",
        height: "1em",
        ...others.style,
      }}
      width={width}
      height="1em"
      speed={2}
      foregroundColor="#d9d9d9"
      backgroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width={width} height="1em" />
    </ContentLoader>
  );
}
