import AppSelect from "controls/AppSelect";

export default function SelectFilter({ options, value, label, valueSet }) {
  return (
    <AppSelect
      size="small"
      options={options}
      value={value}
      label={label}
      onChange={valueSet}
      style={{ margin: 5 }}
    />
  );
}
