import React, { createContext } from "react";

export const appSettingsContext = createContext();

// function useAppSetting({ name, serialize, deserialize }) {
//   const [state, stateSet] = useState(() =>
//     deserialize(localStorage.getItem(name) || ""),
//   );
//
//   useEffect(() => {
//     localStorage.setItem(name, serialize(state));
//   }, [state]);
//
//   return [state, stateSet];
// }

export default function AppSettingsProvider({ children }) {
  return (
    <appSettingsContext.Provider value={{}}>
      {children}
    </appSettingsContext.Provider>
  );
}
