import React from "react";
import { gql } from "@apollo/client";

import { teamRoute } from "helpers";
import { useData, useRoute } from "hooks";
import SessionsTable, { SessionsTableFragment } from "lists/SessionsTable";

export default function SessionsTab() {
  const { teamId } = useRoute(teamRoute);
  const [sessionsData, sessionsDataMeta] = useData(
    gql`
      query SessionsTab($teamId: ID!, $offset: Int) {
        team(id: $teamId) {
          id
          user {
            id
            sessions(limit: 20, offset: $offset) {
              id
              ...SessionsTableFragment
            }
            sessionsCount
          }
        }
      }
      ${SessionsTableFragment}
    `,
    { teamId },
    { returnMeta: true },
  );

  return (
    <>
      <SessionsTable
        rows={sessionsData?.team.user.sessions}
        rowsCount={sessionsData?.team.user.sessionsCount}
        fetchMore={sessionsDataMeta.fetchMore}
      />
    </>
  );
}
