import { useMutation, gql } from "@apollo/client";
import { defer } from "lodash-es";
import { classify } from "underscore.string";

import getApolloErrors from "helpers/getApolloErrors";
import UserError from "helpers/UserError";
import apolloClient from "helpers/apolloClient";
import useProgressBar from "hooks/useProgressBar";

export default function useAction(mutation, defaultOptions) {
  const [mutate, { loading, error }] = useMutation(mutation);
  const progressBar = useProgressBar();
  const action = async (variables, actionOptions) => {
    const { progressBarOptions, ...options } = {
      ...(defaultOptions?.constructor === Function
        ? defaultOptions(variables)
        : defaultOptions),
      ...actionOptions,
    };

    return await progressBar({ ...progressBarOptions }, async () => {
      await new Promise(defer);
      let result;
      try {
        result = await mutate({
          variables,
          client: apolloClient,
          ...options,
        });
      } catch (apolloError) {
        const errors = getApolloErrors(apolloError);
        if (!errors.length) throw apolloError;
        for (const error of errors)
          defer(() => {
            throw error;
          });

        throw new UserError("进行操作时发生错误", { isAbortError: true });
      }

      return result.data;
    });
  };

  action.loading = loading;
  action.error = error;
  return action;
}

export function useActionFragment(actionName, fragment) {
  const actionClassName = classify(actionName);
  fragment ||= `
    clientMutationId
  `;

  return useAction(gql`
    mutation($input: ${actionClassName}Input!) {
      ${actionName}(input: $input) {
        ${fragment}
      }
    }
  `);
}
