import React from "react";
import { times } from "lodash-es";
import { Paper } from "@mui/material";
import ContentLoader from "react-content-loader";

export default function PreviewLoader({ ...others }) {
  return (
    <Paper
      variant="outlined"
      square
      {...others}
      style={{
        flex: "1 0 auto",
        height: 0,
        margin: 5,
        padding: 5,
        position: "relative",
        overflow: "hidden",
        ...others.style,
      }}
    >
      <ContentLoader
        style={{ margin: "0 auto", display: "block" }}
        speed={2}
        width={800}
        height={1000}
        viewBox="0 0 800 1000"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="200" y="20" rx="3" ry="3" width="400" height="50" />
        {times(15).map((i) => (
          <React.Fragment key={i}>
            <rect
              x="50"
              y={110 + 40 * i}
              rx="3"
              ry="3"
              width="150"
              height="25"
            />
            <rect
              x="220"
              y={110 + 40 * i}
              rx="3"
              ry="3"
              width="500"
              height="25"
            />
          </React.Fragment>
        ))}
      </ContentLoader>
    </Paper>
  );
}
