import { useEffect } from "react";

import { AppTabs } from "controls";
import { useRoute } from "hooks";
import { teamRoute } from "helpers";
import Navbar from "views/viewport/Navbar";

import SyncsTab from "./SyncsTab";

export default function DesktopView() {
  const { teamId } = useRoute(teamRoute);
  useEffect(() => {
    if (teamId) localStorage.setItem("visitedTeam", teamId);
  }, [teamId]);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexFlow: "column nowrap",
      }}
    >
      <Navbar />
      <main
        style={{
          flex: "1 0 auto",
          padding: 5,
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        <AppTabs
          tabs={[
            {
              name: "syncs",
              title: "备份",
              content: <SyncsTab />,
            },
          ]}
        />
      </main>
    </div>
  );
}
