import { gql } from "@apollo/client";

import { Table, DateTimeCell, ChipsCell, CompanyIcon } from "controls";
import { UserAvatar, UserAvatarFragment } from "blocks";
import { useRoute } from "hooks";
import { teamRoute, wrapInParen } from "helpers";
import { Alert } from "mdi-material-ui";

export const UsersTableFragment = gql`
  fragment UsersTableFragment on User {
    id
    name
    email
    mobile
    createdAt
    warningMessagesCount
    companyRoles {
      id
      title
      company {
        id
        name
      }
    }
    ...UserAvatarFragment
  }
  ${UserAvatarFragment}
`;

export default function UsersTable({ rows, enableCompanies, ...others }) {
  const { teamId } = useRoute(teamRoute);

  return (
    <Table
      columns={[
        {
          label: "姓名",
          sortable: true,
        },
        {
          label: "单位",
          cellComponent: ChipsCell,
          hidden: !enableCompanies,
        },
        {
          label: "手机/电子邮箱",
          width: 400,
          sortable: true,
        },
        { label: "邀请时间", cellComponent: DateTimeCell },
      ].filter((c) => c)}
      rows={rows?.map((user) => ({
        id: user.id,
        title: user.name,
        icon: (
          <UserAvatar
            user={user}
            style={{ width: 32, height: 32, margin: -4 }}
          />
        ),
        href: `/${teamId}/${user.id}`,
        values: [
          <>
            {user.name}
            {!!user.warningMessagesCount && (
              <Alert color="warning" style={{ verticalAlign: "middle" }} />
            )}
          </>,
          user.companyRoles.map((companyRole) => ({
            icon: <CompanyIcon />,
            label: `${companyRole.company.name}${wrapInParen(
              companyRole.title,
            )}`,
            href: `/${teamId}/${companyRole.company.id}`,
          })),
          [(user.mobile, user.email)].filter((s) => s).join(" / "),
          user.createdAt,
        ],
      }))}
      {...others}
    />
  );
}
