import React from "react";
import { gql } from "@apollo/client";

import { useRoute, useData, useAction } from "hooks";
import { showMessage, picklistRoute } from "helpers";
import { PicklistIcon, FormCard } from "controls";

export default function PicklistView() {
  const { picklistId } = useRoute(picklistRoute);

  const updatePicklist = useAction(gql`
    mutation PicklistsView($input: UpdatePicklistInput!) {
      updatePicklist(input: $input) {
        picklist {
          id
          name
          updatedAt
        }
      }
    }
  `);

  const data = useData(
    gql`
      query PicklistView($picklistId: ID!) {
        picklist(id: $picklistId) {
          id
          name
          canUpdate
        }
      }
    `,
    { picklistId },
  );

  return (
    <>
      <FormCard
        title="基本信息"
        disabled={!data?.picklist.canUpdate}
        icon={<PicklistIcon />}
        fields={[
          {
            name: "name",
            label: "名称",
            value: data?.picklist.name,
          },
        ]}
        onSubmit={async (formData) => {
          await updatePicklist({
            input: {
              picklistId,
              ...formData,
            },
          });
          await showMessage({ message: "成功更新数据列表" });
        }}
      />
    </>
  );
}
