import { gql } from "@apollo/client";

import apolloClient from "helpers/apolloClient";
import printPdf from "helpers/printPdf";
import UserError from "helpers/UserError";

import useProgressBar from "./useProgressBar";

export default function usePrintPreviewable() {
  const progressBar = useProgressBar();

  const previewModePrintable = (previewMode) => previewMode === "pdf";
  const printPreviewable = ({ previewableId }) =>
    progressBar(async () => {
      const { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        query: gql`
          query usePrintPreviewable($previewableId: ID!) {
            previewable(id: $previewableId) {
              id
              previewMode
              previewUrl
              previewStatus
            }
          }
        `,
        variables: { previewableId },
      });
      if (data.previewable.previewMode !== "pdf")
        throw new UserError("无法直接打印该文件，请下载后使用其他软件进行打印");
      if (
        data.previewable.previewStatus !== "ready" ||
        !data.previewable.previewUrl
      )
        throw new UserError("无法读取该文件，请下载后使用其他软件打开进行打印");
      await printPdf(data.previewable.previewUrl);
    });

  return [previewModePrintable, printPreviewable];
}
