import React, { useId } from "react";
import { TextField, InputAdornment } from "@mui/material";

import useFormDialog from "hooks/useFormDialog";
import useDocumentOverlay from "hooks/useDocumentOverlay";
import useDocumentActions from "hooks/useDocumentActions";
import showMessage from "helpers/showMessage";
import DebouncedInput from "controls/DebouncedInput";

const SCALES = [
  [0, "1"],
  [1, "0.1"],
  [2, "0.01"],
  [3, "0.001"],
  [4, "0.0001"],
];

FieldNumber.parseString = (stringValue) => {
  const value = Number.parseFloat(stringValue);
  if (Number.isNaN(value)) throw new Error("格式无效");
  return value;
};
FieldNumber.toFormData = (value) => ({ number: value });

export default function FieldNumber({
  field,
  documentContentMode,
  updateField,
  ...others
}) {
  const { id: fieldId, scale, unit } = field;
  // ActionCable might send back a string (from BigDecimal)
  let { number } = field;
  if (number?.constructor === String) number = Number(number);

  const id = useId();
  const formDialog = useFormDialog();
  const documentActions = useDocumentActions(
    "数值字段",
    {
      edit: [
        {
          title: "设置数值单位",
          label: `数值单位:${unit || "(无)"}`,
          onClick: () =>
            formDialog({
              title: "设置数值单位",
              fields: [
                {
                  name: "unit",
                  label: "数值单位",
                  value: unit,
                },
              ],
              onSubmit: async (formData) => {
                await updateField({
                  input: {
                    fieldId,
                    ...formData,
                  },
                });
                showMessage({ message: "成功设置数值单位" });
              },
            }),
        },
        ...SCALES.map(([value, title]) => ({
          group: "设置数值精度",
          dropdown: true,
          title,
          label: `数值精度:${title}`,
          selected: value === scale,
          onClick: async () => {
            await updateField({
              input: {
                fieldId,
                scale: value,
              },
            });
            showMessage({ message: "成功修改数值精度" });
          },
        })),
      ],
    }[documentContentMode] || [],
  );

  const overlay = useDocumentOverlay({
    enabled: documentContentMode === "fill",
    overlay: (
      <>
        <DebouncedInput
          component={TextField}
          label="数值"
          value={number?.toFixed(scale) || ""}
          id={`${id}-TextField`}
          type="number"
          fullWidth
          margin="dense"
          onChange={async (numberString) => {
            let number = Number.parseFloat(numberString);
            if (Number.isNaN(number)) number = null;
            await updateField({
              input: { fieldId, number },
            });
            showMessage({ message: "成功设置数值" });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{unit}</InputAdornment>
            ),
          }}
          inputProps={{
            step: Math.pow(10, -scale),
          }}
        />
      </>
    ),
  });

  return (
    <div
      {...others}
      style={{
        cursor: "text",
        ...(documentContentMode === "fill" && {
          cursor: "pointer",
        }),
        ...others.style,
      }}
      data-field-number
      tabIndex={0}
      onFocus={(event) => {
        overlay.handleFocus(event);
        documentActions.handleFocus(event);
      }}
      onBlur={(event) => {
        overlay.handleBlur(event);
        documentActions.handleBlur(event);
      }}
    >
      {number === null ? "" : number.toFixed(scale)}
      {unit}
    </div>
  );
}
