import React from "react";
import { defer } from "lodash-es";

import { teamRoute } from "helpers";
import { ActionFormDialog } from "controls";
import { UsersInput } from "inputs";
import { useRoute, useActionFragment, useHistory } from "hooks";

export default function AddCompanyDialog({
  name = "",
  userIds = [],
  ...others
}) {
  const history = useHistory();
  const { teamId } = useRoute(teamRoute);

  const addCompany = useActionFragment(
    "addCompany",
    "team { id companies { id } } company { id }",
  );

  return (
    <ActionFormDialog
      {...others}
      title="添加单位"
      fields={[
        {
          label: "名称",
          name: "name",
          value: name,
          required: true,
          helperText: "必填项",
        },
        {
          label: "成员",
          name: "userIds",
          value: userIds,
          inputComponent: UsersInput,
        },
      ]}
      onSubmit={async (formData) => {
        const result = await addCompany({
          input: {
            teamId,
            ...formData,
          },
        });
        defer(() => history.push(`/${teamId}/${result.addCompany.company.id}`));
      }}
    />
  );
}
