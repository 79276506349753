import React, { useLayoutEffect, useState } from "react";
import { Paper } from "@mui/material";

import { checkFetchResponse, filterBlobUrl } from "helpers";

export default function TextViewer({ previewable }) {
  const [content, contentSet] = useState(null);
  const blobUrlFiltered = filterBlobUrl(previewable.blobUrl);

  useLayoutEffect(() => {
    contentSet(null);
    Promise.resolve().then(async () => {
      if (blobUrlFiltered) {
        const response = await fetch(blobUrlFiltered);
        await checkFetchResponse(response);
        const text = await response.text();
        contentSet(text);
      }
    });
  }, [blobUrlFiltered]);

  return (
    <Paper variant="outlined" square style={{ flex: "1 0 0", margin: 5 }}>
      <textarea
        value={content === null ? "加载中..." : content}
        disabled={content === null}
        readOnly
        style={{
          outline: 0,
          padding: 10,
          fontSize: 16,
          width: "100%",
          height: "100%",
          resize: "none",
          border: "none",
        }}
      />
    </Paper>
  );
}
