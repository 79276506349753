import React from "react";
import { times } from "lodash-es";
import gql from "graphql-tag";
import { Plus } from "mdi-material-ui";

import { Message, TabContent } from "controls";
import { CardLoader } from "loaders";
import { teamRoute } from "helpers";
import { useRoute, useDialogOpener, useData } from "hooks";
import { DataQueryCard, DataQueryCardFragment } from "blocks";
import { AddDataQueryDialog } from "dialogs/DataQueryDialogs";

export default function DataQueriesTab() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query DataQueriesTab($teamId: ID!) {
        team(id: $teamId) {
          id
          dataQueries {
            id
            ...DataQueryCardFragment
          }
          canAddDataQuery
        }
      }
      ${DataQueryCardFragment}
    `,
    { teamId },
  );

  const openAddQueryDialog = useDialogOpener(AddDataQueryDialog);

  return (
    <TabContent
      actions={[
        {
          icon: <Plus />,
          title: "创建查询",
          disabled: !data?.team.canAddDataQuery,
          onClick: () => openAddQueryDialog(),
        },
      ]}
    >
      {<Message message="所有的汇总查询都会自动执行！" />}
      {!data && times(5).map((i) => <CardLoader key={i} />)}
      {data?.team.dataQueries.length === 0 && (
        <Message message="没有可访问的查询" />
      )}
      {data?.team.dataQueries.map((dataQuery) => (
        <DataQueryCard key={dataQuery.id} dataQuery={dataQuery} />
      ))}
    </TabContent>
  );
}
