import { gql } from "@apollo/client";
import { Chip } from "@mui/material";

import { ObjectCard, Markdown, DateTime } from "controls";
import { useDialogOpener } from "hooks";

import SupportIssueDialog from "./SupportIssueDialog";

export const SupportIssueCardFragment = gql`
  fragment SupportIssueCardFragment on SupportIssue {
    id
    open
    status
    labels
    title
    createdAt
    description
    supportCommentsCount
  }
`;

export default function SupportIssueCard({ supportIssue, ...others }) {
  const openSupportIssueDialog = useDialogOpener(SupportIssueDialog);

  return (
    <ObjectCard
      onClick={() =>
        openSupportIssueDialog({ supportIssueId: supportIssue.id })
      }
      title={
        <>
          {!supportIssue.open && (
            <Chip label="已关闭" size="small" style={{ marginRight: 5 }} />
          )}
          {supportIssue?.title}
        </>
      }
      fields={[
        {
          label: "状态",
          content: supportIssue.status,
        },
        {
          label: "标签",
          content: supportIssue.labels.map((label) => (
            <Chip
              key={label}
              label={label}
              style={{ margin: "1px 2px" }}
              size="small"
            />
          )),
        },
        {
          label: "内容",
          content: <Markdown source={supportIssue.description} />,
        },
        {
          label: "创建时间",
          content: <DateTime value={supportIssue.createdAt} />,
        },
        {
          label: "评论",
          content: supportIssue.supportCommentsCount,
        },
      ]}
      {...others}
    />
  );
}
