import CompanyInput from "inputs/CompanyInput";

export default function CompanyFilter({ label, value, valueSet }) {
  return (
    <div style={{ margin: 5, minWidth: 150 }}>
      <CompanyInput
        label={label}
        size="small"
        value={value}
        onChange={valueSet}
      />
    </div>
  );
}
