import { gql } from "@apollo/client";
import { Plus, Delete, Pencil } from "mdi-material-ui";

import {
  useData,
  useRoute,
  useActionFragment,
  useDialogOpener,
  useConfirmDialog,
} from "hooks";
import { companyRoute } from "helpers";
import { TabContent, Table } from "controls";
import { UserAvatar, UserAvatarFragment } from "blocks";

import {
  CompanyRoleUpdateDialog,
  CompanyAddCompanyRoleDialog,
} from "./CompanyRoleDialogs";

export default function RolesTab() {
  const { companyId } = useRoute(companyRoute);
  const confirmDialog = useConfirmDialog();
  const openCompanyRoleUpdateDialog = useDialogOpener(CompanyRoleUpdateDialog);
  const openCompanyAddCompanyRoleDialog = useDialogOpener(
    CompanyAddCompanyRoleDialog,
  );

  const companyRoleRemove = useActionFragment("companyRoleRemove");

  const [data, dataMeta] = useData(
    gql`
      query RolesTab($companyId: ID!, $offset: Int) {
        company(id: $companyId) {
          id
          canAddCompanyRole
          companyRoles(offset: $offset, limit: 20) {
            id
            title
            canUpdate
            canRemove
            user {
              id
              mobile
              email
              ...UserAvatarFragment
            }
          }
          companyRolesCount
        }
      }
      ${UserAvatarFragment}
    `,
    { companyId },
    { returnMeta: true },
  );

  return (
    <TabContent
      actions={[
        {
          title: "添加成员",
          icon: <Plus />,
          disabled: !data?.company.canAddCompanyRole,
          onClick: () => openCompanyAddCompanyRoleDialog({ companyId }),
        },
      ]}
    >
      <Table
        rowsCount={data?.company.companyRolesCount}
        fetchMore={dataMeta.fetchMore}
        columns={[
          {
            label: "姓名",
            sortable: true,
          },
          {
            label: "单位角色",
            sortable: true,
          },
          {
            label: "手机/电子邮箱",
            width: 400,
            sortable: true,
          },
        ]}
        rows={data?.company.companyRoles.map((role) => ({
          id: role.id,
          title: role.user.name,
          icon: (
            <UserAvatar
              user={role.user}
              style={{ width: 32, height: 32, margin: -4 }}
              triggerUserDialog
            />
          ),
          values: [
            role.user.name,
            role.title,
            [(role.user.mobile, role.user.email)].filter((s) => s).join(" / "),
          ],
          actions: [
            {
              label: "删除",
              icon: <Delete />,
              disabled: !role.canRemove,
              onClick: async () => {
                await confirmDialog();
                await companyRoleRemove({
                  input: {
                    companyRoleId: role.id,
                  },
                });
                await dataMeta.refetch();
              },
            },
            {
              label: "修改",
              icon: <Pencil />,
              disabled: !role.canUpdate,
              onClick: () =>
                openCompanyRoleUpdateDialog({ companyRoleId: role.id }),
            },
          ],
        }))}
      />
    </TabContent>
  );
}
