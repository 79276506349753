import React, { useState } from "react";
import {
  CircularProgress,
  useTheme,
  Snackbar,
  SnackbarContent,
} from "@mui/material";

import { useDOMEvent } from "hooks";

export default React.memo(SpinnerEventListener);

function SpinnerEventListener() {
  const [spinners, setSpinners] = useState([]);
  const anyObstrusive = !!spinners.find((s) => !s.unobstrusive);
  const anyBlocking = !!spinners.find((s) => s.blocking);
  const theme = useTheme();

  useDOMEvent("spinnerstart", (event) => {
    const { id, unobstrusive, blocking } = event.detail;
    setSpinners((spinners) => [...spinners, { id, unobstrusive, blocking }]);
  });
  useDOMEvent("spinnerstop", (event) => {
    const { id } = event.detail;
    setSpinners((spinners) => spinners.filter((s) => s.id !== id));
  });

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10000,
          pointerEvents: anyBlocking ? "initial" : "none",
          ...(!spinners.length
            ? {
                opacity: 0,
              }
            : {
                opacity: 1,
              }),
        }}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={anyObstrusive}
          autoHideDuration={6000}
        >
          <SnackbarContent
            style={{
              backgroundColor: theme.palette.primary.dark,
            }}
            message="正在执行操作，请稍候……"
          />
        </Snackbar>
        <CircularProgress
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        />
      </div>
    </>
  );
}
