import React from "react";
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Paper,
} from "@mui/material";

import ButtonMenu from "./ButtonMenu";

export default function ObjectCard({
  title,
  subheader,
  icon,
  avatarProps,
  avatar = icon && (
    <Avatar
      style={{
        backgroundColor: "transparent",
        border: "solid 1px #efefef",
        color: "inherit",
      }}
    >
      {React.cloneElement(icon, { fontSize: "inherit" })}
    </Avatar>
  ),
  children,
  thumbnailUrl,
  fields = [],
  href,
  onClick,
  disabled,
  target,
  actions = [],
  ...others
}) {
  const hasHeader = !!(avatar || title || subheader || actions.length);
  const cardChildren = (
    <div
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "flex-start",
      }}
    >
      <div style={{ flex: "1 1 auto" }}>
        {hasHeader && (
          <CardHeader
            style={{
              padding: 10,
            }}
            avatar={avatar && React.cloneElement(avatar, avatarProps)}
            title={title}
            subheader={subheader}
            action={
              !actions.length ? undefined : <ButtonMenu actions={actions} />
            }
          />
        )}
        <CardContent
          style={{
            padding: "0 10px 10px 10px",
          }}
        >
          {fields.map((field) => (
            <div
              key={field.label}
              style={{ position: "relative", fontSize: "0.8em" }}
            >
              <span
                style={{
                  opacity: 0.4,
                }}
              >
                {field.label}:
              </span>
              <span style={{ marginLeft: 10 }}>{field.content}</span>
            </div>
          ))}
          {children}
        </CardContent>
      </div>
      {thumbnailUrl && (
        <Paper
          component="img"
          variant="outlined"
          square
          src={thumbnailUrl}
          alt={title}
          style={{
            flex: "0 0 auto",
            margin: 5,
            width: 100,
            border: "1px solid #ccc",
          }}
        />
      )}
    </div>
  );

  return (
    <>
      <Card
        {...others}
        style={{
          margin: 5,
          flex: "0 1 auto",
          display: "flex",
          flexFlow: "column nowrap",
          ...others.style,
        }}
      >
        {href || onClick ? (
          <CardActionArea
            href={href}
            onClick={onClick}
            disabled={disabled}
            target={target}
            style={{ flex: "1 0 auto" }}
          >
            {cardChildren}
          </CardActionArea>
        ) : (
          cardChildren
        )}
      </Card>
    </>
  );
}
