import React from "react";
import gql from "graphql-tag";

import { useRoute, useData, useList } from "hooks";
import { companyRoute } from "helpers";
import { TabContent } from "controls";
import { ProjectCard, ProjectCardFragment } from "blocks";
import ProjectsTable, { ProjectsTableFragment } from "lists/ProjectsTable";

export default function ProjectsTab() {
  const { companyId } = useRoute(companyRoute);

  const [projectsData, projectsDataMeta] = useData(
    gql`
      query ProjectsTab($companyId: ID!, $offset: Int) {
        company(id: $companyId) {
          id
          projects(limit: 20, offset: $offset) {
            id
            ...ProjectsTableFragment
            ...ProjectCardFragment
          }
          projectsCount
        }
      }
      ${ProjectsTableFragment}
      ${ProjectCardFragment}
    `,
    {
      companyId,
    },
    {
      returnMeta: true,
    },
  );

  const [list, listModeSwitcher] = useList("project", {
    rows: projectsData?.company.projects,
    rowsCount: projectsData?.company.projectsCount,
    fetchMore: projectsDataMeta.fetchMore,
    card: <ProjectCard />,
    table: <ProjectsTable />,
  });

  return <TabContent toolbar={listModeSwitcher}>{list}</TabContent>;
}
