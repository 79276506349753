import React from "react";
import { gql } from "@apollo/client";
import { AppBar, Button, Toolbar } from "@mui/material";
import { Plus, ArrowRight, HelpCircleOutline } from "mdi-material-ui";

import {
  useData,
  useFormDialog,
  useFilterBar,
  useDialogOpener,
  useActionFragment,
} from "hooks";
import { AppTabs, CardsList, TabContent } from "controls";
import logoImage from "images/logo_button.png";

import SupportIssueCard, { SupportIssueCardFragment } from "./SupportIssueCard";
import SupportIssueDialog from "./SupportIssueDialog";

export default function SupportView() {
  const formDialog = useFormDialog();
  const openSupportIssueDialog = useDialogOpener(SupportIssueDialog);
  const createSupportIssue = useActionFragment(
    "createSupportIssue",
    "supportIssue { id }",
  );

  const filterData = useData(gql`
    query SupportView {
      supportIssueStatuses
    }
  `);

  const [{ status }, filterBar] = useFilterBar([
    {
      type: "select",
      label: "状态",
      defaultValue: "all",
      options: [
        {
          label: "(所有)",
          value: "all",
        },
        ...(filterData?.supportIssueStatuses || []).map((status) => ({
          label: status,
          value: status,
        })),
      ],
      name: "status",
    },
  ]);

  const [data, dataMeta] = useData(
    gql`
      query SupportDialog($offset: Int, $status: String) {
        userLogin {
          id
          supportIssues(status: $status, offset: $offset) {
            id
            createdAt
            ...SupportIssueCardFragment
          }
          supportIssuesCount(status: $status)
        }
      }
      ${SupportIssueCardFragment}
    `,
    { status: status === "all" ? null : status },
    { returnMeta: true },
  );

  return (
    <>
      <AppBar position="static" elevation={2}>
        <Toolbar>
          <a
            href="/support"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <HelpCircleOutline
              style={{ verticalAlign: "bottom", marginRight: 10 }}
            />
            优建云帮助支持
          </a>
          <div style={{ flexGrow: 1 }}></div>
          <Button href="/" variant="outlined" color="inherit">
            <img
              alt="优建云"
              style={{
                width: 28,
                height: 28,
                verticalAlign: "middle",
                marginRight: 10,
              }}
              src={logoImage}
            />
            返回优建云
            <ArrowRight />
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ padding: 5 }}>
        <AppTabs
          tabs={[
            {
              name: "supportTickets",
              title: "工单",
              content: (
                <TabContent
                  actions={[
                    {
                      icon: <Plus />,
                      title: "创建工单",
                      onClick: () =>
                        formDialog({
                          title: "创建工单",
                          fields: [
                            {
                              name: "title",
                              label: "标题 (可留空)",
                            },
                            {
                              name: "description",
                              label: "问题描述",
                              options: {
                                multiline: true,
                                minRows: 5,
                              },
                              required: true,
                              helperText:
                                "请尽可能详细地描述问题以及重现步骤，您可以在创建工单后再添加评论或附件",
                            },
                          ],
                          onSubmit: async (formData) => {
                            const result = await createSupportIssue({
                              input: formData,
                            });
                            const supportIssueId =
                              result.createSupportIssue.supportIssue.id;
                            await dataMeta.refetch();
                            openSupportIssueDialog({ supportIssueId });
                          },
                        }),
                    },
                  ]}
                >
                  {filterBar}
                  <CardsList
                    rows={data?.userLogin?.supportIssues}
                    rowsCount={data?.userLogin?.supportIssuesCount}
                    fetchMore={dataMeta.fetchMore}
                    cardPropName="supportIssue"
                    cardComponent={<SupportIssueCard />}
                    getRowDate={(r) => r.createdAt}
                  />
                </TabContent>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}
