import React, { useEffect, useState, useRef } from "react";
import { gql } from "@apollo/client";
import { every } from "lodash-es";
import { useLocation } from "react-router";
import {
  Paper,
  Table as MUITable,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Checkbox,
  TableCell,
  TableSortLabel,
  TablePagination,
  IconButton,
  Popper,
  Button,
  useTheme,
} from "@mui/material";
import { Sort, Cog, EyeOff, MicrosoftExcel } from "mdi-material-ui";

import apolloClient from "helpers/apolloClient";
import useConfirmDialog from "hooks/useConfirmDialog";
import useProgressBar from "hooks/useProgressBar";
import TableLoader from "loaders/TableLoader";
import compare from "helpers/compare";
import useFormDialog from "hooks/useFormDialog";
import BooleanInput from "inputs/BooleanInput";
import SortInput from "inputs/SortInput";
import ButtonMenu from "controls/ButtonMenu";

import FetchMoreButton from "./FetchMoreButton";
import { TextCell, ItemPathCell, UsersCell, TEXT_PADDING } from "./tableCells";

const ROWS_PER_PAGE = 20;
const COLUMNS_ORDER_PREFIX = "FMS_TABLE_COLUMNS_ORDER_";
const COLUMNS_HIDDEN_PREFIX = "FMS_TABLE_COLUMNS_HIDDEN_";

export default function Table({
  columns,
  rows,
  batchActions = [],
  header,
  footer,
  fetchMore,
  rowsCount,
}) {
  const ref = useRef();
  const formDialog = useFormDialog();
  const confirmDialog = useConfirmDialog();
  const progressBar = useProgressBar();
  const theme = useTheme();

  const [batchAnchor, batchAnchorSet] = useState(null);
  const [selectedRowIndexes, selectedRowIndexesSet] = useState([]);
  const tableSelectable = !!batchActions?.length;
  const location = useLocation();

  columns = columns
    .map((column, index) => ({
      ...column,
      index,
    }))
    .filter((c) => !c.hidden);

  rows = rows?.map(({ values = [], ...row }, index) => ({
    ...row,
    values: [...values],
    index,
    selectable: !!row.batchActionNames?.length,
  }));

  // sort
  const [sortByIndex, sortByIndexSet] = useState(-1);
  const [sortByDesc, sortByDescSet] = useState(false);

  if (rows && rows[sortByIndex] && !fetchMore) {
    rows.sort((row1, row2) => {
      if (row1.sortIndex !== row2.sortIndex)
        return row1.sortIndex - row2.sortIndex;

      const getSortValue = (row) => {
        const value = row.values[sortByIndex];
        return value.sortValue === undefined ? value : value.sortValue;
      };

      const result = compare(getSortValue(row1), getSortValue(row2));

      return sortByDesc ? 0 - result : result;
    });
  }

  // paging
  const [page, pageSet] = useState(0);
  const pageRows = fetchMore
    ? rows
    : rows?.slice(ROWS_PER_PAGE * page, ROWS_PER_PAGE * (page + 1));

  // selecting
  const rowsSelected = pageRows?.filter((r) =>
    selectedRowIndexes.includes(r.index),
  );

  useEffect(() => {
    if (page !== 0) pageSet(0);
  }, [location.pathname]);

  // display columns
  const columnsHashKey = columns.map((c) => c.label).join("_");
  const [columnsOrderLabels, columnsOrderLabelsSet] = useState([]);
  const [columnsHiddenLabels, columnsHiddenLabelsSet] = useState([]);

  let columnsDisplayed = columns.filter(
    (c) => !columnsHiddenLabels.includes(c.label),
  );
  columnsDisplayed = [
    ...columnsOrderLabels
      .map((label) => columnsDisplayed.find((c) => c.label === label))
      .filter((c) => c),
    ...columnsDisplayed.filter((c) => !columnsOrderLabels.includes(c.label)),
  ];

  useEffect(() => {
    columnsOrderLabelsSet(
      (
        window.localStorage[`${COLUMNS_ORDER_PREFIX}${columnsHashKey}`] || ""
      ).split(","),
    );
    columnsHiddenLabelsSet(
      (
        window.localStorage[`${COLUMNS_HIDDEN_PREFIX}${columnsHashKey}`] || ""
      ).split(","),
    );
  }, [columnsHashKey]);

  return (
    <>
      <Paper
        variant="outlined"
        style={{
          margin: 5,
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "stretch",
        }}
        ref={ref}
      >
        {header}
        {rows && (
          <TableContainer
            style={{
              flex: "1 1 0",
              minHeight: "min(calc(100vh - 48px), 480px)",
              overflowY: "auto",
            }}
          >
            <MUITable
              style={{
                tabelLayout: "fixed",
                width: "max-content",
                minWidth: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="none"
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1,
                    }}
                  ></TableCell>
                  {columnsDisplayed.map((column) => (
                    <TableCell key={column.index}>
                      {!column.sortable || fetchMore ? (
                        <span
                          style={{
                            width: column.labelWidth,
                            display: "inline-block",
                          }}
                        >
                          {column.icon &&
                            React.cloneElement(column.icon, {
                              fontSize: "inherit",
                            })}
                          {column.label}
                        </span>
                      ) : (
                        <TableSortLabel
                          active={column.index === sortByIndex}
                          direction={sortByDesc ? "desc" : "asc"}
                          onClick={() => {
                            if (column.index === sortByIndex)
                              return sortByDescSet((d) => !d);
                            sortByDescSet(false);
                            sortByIndexSet(column.index);
                          }}
                        >
                          {column.icon &&
                            React.cloneElement(column.icon, {
                              fontSize: "inherit",
                            })}
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                  <TableCell
                    align="right"
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      backgroundColor: theme.palette.background.paper,
                    }}
                    padding="checkbox"
                  >
                    <ButtonMenu
                      icon={<Cog />}
                      actions={[
                        {
                          icon: <EyeOff />,
                          title: "选择显示的列",
                          description: "您可以隐藏表格中的一些列",
                          onClick: () =>
                            formDialog({
                              title: "选择显示的列",
                              fields: columns.map((column) => ({
                                label: column.label,
                                name: column.label,
                                inputComponent: BooleanInput,
                                value: !columnsHiddenLabels.includes(
                                  column.label,
                                ),
                              })),
                              onSubmit: (formData) => {
                                const columnsHiddenLabelsNew = Object.entries(
                                  formData,
                                )
                                  .filter(([, checked]) => !checked)
                                  .map(([label]) => label);
                                columnsHiddenLabelsSet(columnsHiddenLabelsNew);
                                window.localStorage[
                                  `${COLUMNS_HIDDEN_PREFIX}${columnsHashKey}`
                                ] = columnsHiddenLabelsNew.join(",");
                              },
                            }),
                        },
                        {
                          icon: <Sort />,
                          title: "调整列顺序",
                          description: "您可以调整表格中列的显示顺序",
                          onClick: () =>
                            formDialog({
                              title: "调整列顺序",
                              fields: [
                                {
                                  label: "列顺序",
                                  name: "labels",
                                  inputComponent: SortInput,
                                  value: columnsDisplayed.map((c) => c.label),
                                  options: {
                                    items: columnsDisplayed.map((c) => ({
                                      icon: c.icon,
                                      value: c.label,
                                      label: c.label,
                                    })),
                                  },
                                },
                              ],
                              onSubmit: ({ labels }) => {
                                const columnsOrderLabelsNew = labels;
                                columnsOrderLabelsSet(columnsOrderLabelsNew);
                                window.localStorage[
                                  `${COLUMNS_ORDER_PREFIX}${columnsHashKey}`
                                ] = columnsOrderLabelsNew.join(",");
                              },
                            }),
                        },
                        {
                          icon: <MicrosoftExcel />,
                          disabled: !rows,
                          title: "导出为Excel文件",
                          description: <>将已加载的{rows?.length}条记录导出</>,
                          onClick: async () => {
                            if (rowsCount !== null && rows.length < rowsCount)
                              await confirmDialog({
                                message: (
                                  <>还有{rowsCount - rows.length}条记录未加载</>
                                ),
                              });
                            await progressBar(() =>
                              exportExcel({ rows, columnsDisplayed }),
                            );
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && (
                  <>
                    {!rows.length && (
                      <TableRow>
                        <TableCell colSpan={99}>没有项目可以显示</TableCell>
                      </TableRow>
                    )}
                    {pageRows.map((row) => (
                      <TableRow
                        key={row.index}
                        selected={selectedRowIndexes.includes(row.index)}
                      >
                        <TableCell
                          padding="none"
                          style={{
                            width: 0,
                            position: "sticky",
                            left: 0,
                            backgroundColor: theme.palette.background.paper,
                            zIndex: 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "row nowrap",
                              alignItems: "center",
                            }}
                          >
                            {tableSelectable && (
                              <Checkbox
                                component="button"
                                style={{
                                  visibility: row.selectable
                                    ? "visible"
                                    : "hidden",
                                }}
                                checked={selectedRowIndexes.includes(row.index)}
                                title={`选择${row.title}`}
                                onChange={(event) => {
                                  selectedRowIndexesSet([
                                    ...selectedRowIndexes.filter(
                                      (i) => i !== row.index,
                                    ),
                                    ...(event.target.checked
                                      ? [row.index]
                                      : []),
                                  ]);
                                  batchAnchorSet(event.currentTarget);
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                        {columnsDisplayed
                          .map(({ cellComponent = TextCell, ...column }) => ({
                            ...column,
                            value: row.values[column.index],
                            cellComponent,
                          }))
                          .map(({ value, ...column }) => ({
                            ...column,
                            value:
                              value?.constructor === Object &&
                              Object.keys(value).includes("value")
                                ? value.value
                                : value,
                          }))
                          .map((column) => ({
                            ...column,
                            content: ![null, undefined].includes(
                              column.value,
                            ) && (
                              <column.cellComponent
                                key={column.index}
                                value={column.value}
                              />
                            ),
                          }))
                          .map((column, columnIndex) => (
                            <TableCell
                              key={column.index}
                              padding="none"
                              style={{
                                minHeight: 48,
                                maxWidth: "max(50vw, 360px)",
                              }}
                            >
                              {!columnIndex ? (
                                <SpanOrAnchor
                                  href={row.href}
                                  onClick={row.onClick}
                                  style={{
                                    display: "inline-flex",
                                    flexFlow: "row nowrap",
                                    alignItems: "center",
                                  }}
                                >
                                  {row.icon && (
                                    <span
                                      style={{
                                        display: "inline-block",
                                        marginRight: -TEXT_PADDING,
                                      }}
                                    >
                                      <IconButton
                                        disableRipple
                                        component="span"
                                      >
                                        {row.icon}
                                      </IconButton>
                                    </span>
                                  )}
                                  {column.content}
                                </SpanOrAnchor>
                              ) : (
                                column.content
                              )}
                            </TableCell>
                          ))}
                        <TableCell
                          align="right"
                          style={{
                            position: "sticky",
                            right: 0,
                            backgroundColor: theme.palette.background.paper,
                            zIndex: 1,
                          }}
                          padding="checkbox"
                        >
                          {!!row.actions?.length && (
                            <ButtonMenu
                              title={`更多操作(${row.title})`}
                              actions={row.actions}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </MUITable>
            {!!fetchMore && (
              <FetchMoreButton
                fetchMore={fetchMore}
                rows={rows}
                rowsCount={rowsCount}
                style={{ position: "sticky", left: 0 }}
              />
            )}
          </TableContainer>
        )}
        {!rows && <TableLoader />}
        {rows && !fetchMore && (
          <TablePagination
            component="div"
            style={{
              overflowX: "auto",
            }}
            count={rows.length}
            onPageChange={(event, newPage) => pageSet(newPage)}
            page={page}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            labelDisplayedRows={({ from, to, count }) =>
              `从${from}到${to}（共${count}）`
            }
            labelRowsPerPage="每页"
          />
        )}
        {footer}
      </Paper>
      <Popper
        open={!!rowsSelected?.length}
        anchorEl={batchAnchor}
        placement={batchAnchor === ref.current ? "top-start" : "right"}
        style={{
          zIndex: 1000,
        }}
      >
        <Paper
          style={{
            padding: 5,
            display: "flex",
            flexFlow: "row wrap",
          }}
        >
          {[
            {
              title: "全选",
              disabled: !pageRows,
              onClick: () =>
                selectedRowIndexesSet(
                  pageRows.filter((r) => r.selectable).map((r) => r.index),
                ),
            },
            {
              title: "反选",
              disabled: !pageRows,
              onClick: () =>
                selectedRowIndexesSet((selectedRowIndexes) =>
                  pageRows
                    .filter((r) => r.selectable)
                    .filter((r) => !selectedRowIndexes.includes(r.index))
                    .map((r) => r.index),
                ),
            },
            ...batchActions.map(({ disabled, onClick, ...action }) => ({
              ...action,
              disabled:
                disabled ||
                !rowsSelected?.length ||
                !every(
                  // selected row
                  rowsSelected,
                  (r) => r.batchActionNames?.includes(action.name),
                ),
              onClick: () => {
                onClick(rowsSelected.map((r) => r.id));
                selectedRowIndexesSet([]);
              },
            })),
            {
              title: "取消",
              onClick: () => selectedRowIndexesSet([]),
            },
          ].map((action) => (
            <Button
              key={action.title}
              disabled={action.disabled}
              style={{ margin: 5 }}
              onClick={action.onClick}
            >
              {action.icon}
              {action.title}
            </Button>
          ))}
        </Paper>
      </Popper>
    </>
  );
}

function SpanOrAnchor({ href, onClick, children, ...others }) {
  return href || onClick ? (
    <a
      href={href || "#"}
      onClick={onClick}
      {...others}
      style={{ color: "inherit", textDecoration: "none", ...others.style }}
    >
      {children}
    </a>
  ) : (
    <span {...others}>{children}</span>
  );
}

async function exportExcel({ rows, columnsDisplayed }) {
  const exportName = `优建云导出_${new Date()
    .toLocaleDateString()
    .replace(/[^\d]/g, "_")}`;
  const ExcelJS = await import("exceljs");
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(exportName);
  worksheet.columns = columnsDisplayed.map((column) => ({
    header: column.label,
  }));

  for (const row of rows) {
    const excelRow = [];
    for (const column of columnsDisplayed) {
      excelRow.push(await getExcelValue(row.values[column.index], column));
    }
    worksheet.addRow(excelRow);
  }

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.setAttribute(
    "download",
    `优建云导出_${new Date().toLocaleDateString().replace(/[^\d]/g, "_")}.xlsx`,
  );
  anchor.click();
}

async function getExcelValue(value, column) {
  let excelValue = [Number, String].includes(value?.constructor) ? value : "";

  if (column.cellComponent === ItemPathCell && value) {
    const { data } = await apolloClient.query({
      query: gql`
        query getExcelValue($itemId: ID!) {
          item(id: $itemId) {
            id
            path {
              id
              name
            }
          }
        }
      `,
      variables: {
        itemId: value,
      },
    });
    excelValue = data.item.path.map((f) => f.name).join("/");
  }

  if (column.cellComponent === UsersCell && value) {
    const userNames = [];
    for (const userId of value) {
      const { data } = await apolloClient.query({
        query: gql`
          query getExcelValue($userId: ID!) {
            user(id: $userId) {
              id
              name
            }
          }
        `,
        variables: {
          userId,
        },
      });

      userNames.push(data.user.name);
    }
    excelValue = userNames.join(", ");
  }
  return excelValue;
}
