import React from "react";
import gql from "graphql-tag";

import { DataQueryIcon } from "controls/icons";
import ObjectCard from "controls/ObjectCard";
import DateTime from "controls/DateTime";
import { useRecordContentChannel } from "hooks/useChannel";

import ItemPath, { ItemPathFragment } from "./ItemPath";
import UsersLabel, { UsersLabelFragment } from "./UsersLabel";

export const DataQueryCardFragment = gql`
  fragment DataQueryCardFragment on DataQuery {
    status
    name
    path
    columns
    createdAt
    queriedAt
    projectsCount
    projectsNoDataCount
    viewableUsers {
      id
      ...UsersLabelFragment
    }
    item {
      id
      ...ItemPathFragment
    }
    team {
      id
    }
  }
  ${ItemPathFragment}
  ${UsersLabelFragment}
`;

export default function DataQueryCard({ dataQuery }) {
  useRecordContentChannel("DataQuery", dataQuery.id);

  return (
    <ObjectCard
      key={dataQuery.id}
      icon={<DataQueryIcon />}
      title={dataQuery.name}
      subheader={
        <>
          创建于：
          <DateTime value={dataQuery.createdAt} />
        </>
      }
      href={`/${dataQuery.team.id}/${dataQuery.id}`}
      fields={[
        {
          label: "位置",
          content: dataQuery.item && <ItemPath item={dataQuery.item} />,
        },
        {
          label: "数据列",
          content: (
            <>
              “{dataQuery.columns[0]}”等{dataQuery.columns.length}列
            </>
          ),
        },
        {
          label: "状态",
          content: (
            <>
              {{
                initial: "未运行",
                querying: "查询运行中",
                queried: "查询完成",
                errored: "错误",
              }[dataQuery.status] || dataQuery.status}
              {dataQuery.status === "queried" && (
                <>
                  (完成于
                  <DateTime value={dataQuery.queriedAt} />,{" "}
                  {dataQuery.projectsCount}条项目数据)
                </>
              )}
            </>
          ),
        },
        {
          label: "可查看成员",
          content: <UsersLabel users={dataQuery.viewableUsers} />,
        },
      ]}
    />
  );
}
