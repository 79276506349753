import React from "react";
import gql from "graphql-tag";

import { companyRoute } from "helpers";
import { useRoute, useData, useActivitiesFilterBar, useList } from "hooks";
import { ActivityCard, ActivityCardFragment } from "blocks";
import { TabContent, Message, CardsList } from "controls";
import ActivitiesTable, {
  ActivitiesTableFragment,
} from "lists/ActivitiesTable";

export default function ActivitiesTab() {
  const { companyId } = useRoute(companyRoute);
  const [filter, filterBar] = useActivitiesFilterBar();
  const [data, { fetchMore }] = useData(
    gql`
      query ActivitiesTab(
        $companyId: ID!
        $offset: Int
        $filter: ActivitiesFilter!
      ) {
        company(id: $companyId) {
          id
          activities(offset: $offset, filter: $filter, limit: 10) {
            id
            createdAt
            ...ActivityCardFragment
            ...ActivitiesTableFragment
          }
          activitiesCount(filter: $filter)
        }
      }
      ${ActivityCardFragment}
      ${ActivitiesTableFragment}
    `,
    { companyId, filter },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("activity", {
    rows: data?.company.activities,
    rowsCount: data?.company.activitiesCount,
    fetchMore,
    card: <ActivityCard />,
    table: <ActivitiesTable />,
    cardsList: <CardsList getRowDate={(activity) => activity.createdAt} />,
  });

  return (
    <TabContent toolbar={<>{listModeSwitcher}</>}>
      <Message>这里列出了单位成员发起的所有操作</Message>
      {filterBar}
      {list}
    </TabContent>
  );
}
