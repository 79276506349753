import { useState, useEffect, Fragment } from "react";
import { gql } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { DatabaseSearch, ChevronDown, ChevronUp } from "mdi-material-ui";

import toggleArray from "helpers/toggleArray";
import useRoute from "hooks/useRoute";
import useModeSwitcher from "hooks/useModeSwitcher";
import useData from "hooks/useData";
import { teamRoute } from "helpers/routes";
import getDialogProps from "helpers/getDialogProps";
import {
  PicklistIcon,
  ProjectIcon,
  DocumentIcon,
  CompanyIcon,
} from "controls/icons";
import Message from "controls/Message";
import ProjectInput from "inputs/ProjectInput";
import CompanyInput from "inputs/CompanyInput";

export default function UsePropertyDialog({
  onClose,
  onSubmit,
  parseString = (str) => str,
  propertyPath = "",
  fieldId,
  ...others
}) {
  const [openGroupIds, openGroupIdsSet] = useState([]);
  const { teamId } = useRoute(teamRoute);

  const [category, categorySwitcher] = useModeSwitcher(
    "category",
    [
      {
        name: "propertyPath",
        title: "自动引用",
        icon: <DatabaseSearch />,
      },
      {
        name: "project",
        title: "所属项目",
        icon: <ProjectIcon />,
      },
      {
        name: "picklists",
        title: "选项列表",
        icon: <PicklistIcon />,
      },
      {
        name: "selectedProject",
        title: "其他项目",
        icon: <ProjectIcon />,
      },
      {
        name: "selectedCompany",
        title: "其他单位",
        icon: <CompanyIcon />,
      },
    ],
    { noUrl: true },
  );

  const groups = [];

  const data = useData(
    gql`
      query UsePropertyDialog($fieldId: ID!, $teamId: ID!) {
        field(id: $fieldId) {
          id
          project {
            id
            name
            properties {
              id
              label
              value
            }
            documents {
              id
              basename
              dataRowName
              fields {
                id
                label
                stringValue
              }
            }
            projectCompanies {
              id
              role
              company {
                id
                name
                properties {
                  id
                  label
                  value
                }
              }
            }
          }
        }
        team(id: $teamId) {
          id
          picklists {
            id
            name
            options
          }
        }
      }
    `,
    { fieldId, teamId },
  );

  const fieldProject = data?.field.project;
  if (fieldProject) {
    groups.push(
      {
        id: fieldProject.id,
        category: "project",
        icon: <ProjectIcon />,
        title: "项目",
        subheader: fieldProject.name,
        properties: [
          {
            id: "name",
            label: "项目名称",
            stringValue: fieldProject.name,
          },
          ...fieldProject.properties.map((property) => ({
            id: property.id,
            label: property.label,
            stringValue: property.value,
          })),
        ],
      },
      ...fieldProject.documents
        .filter((d) => d.dataRowName)
        .map((doc) => ({
          id: doc.id,
          category: "project",
          icon: <DocumentIcon />,
          title: `表单/${doc.dataRowName}`,
          subheader: doc.basename,
          properties: doc.fields
            .filter((f) => f.label)
            .map((field) => ({
              id: field.id,
              label: field.label,
              stringValue: field.stringValue,
            })),
        })),
      ...fieldProject.projectCompanies.map((projectCompany) => ({
        id: projectCompany.id,
        category: "project",
        icon: <CompanyIcon />,
        title: `单位/${projectCompany.role}`,
        subheader: projectCompany.company.name,
        properties: [
          {
            id: "name",
            label: "单位名称",
            stringValue: projectCompany.company.name,
          },
          ...projectCompany.company.properties.map((property) => ({
            id: property.id,
            label: property.label,
            stringValue: property.value,
          })),
        ],
      })),
    );
  }

  // picklists
  if (data)
    groups.push(
      ...data.team.picklists.map((picklist) => ({
        category: "picklists",
        icon: <PicklistIcon />,
        id: picklist.id,
        title: `选项列表/${picklist.name}`,
        properties: picklist.options.map((option) => ({
          id: option,
          stringValue: option,
        })),
      })),
    );

  // selectedProject
  const [selectedProjectId, selectedProjectIdSet] = useState(null);
  const dataSelectedProject = useData(
    gql`
      query UsePropertyDialog($selectedProjectId: ID!) {
        project(id: $selectedProjectId) {
          id
          name
          properties {
            id
            label
            value
          }
        }
      }
    `,
    { selectedProjectId },
    { skip: !selectedProjectId },
  );
  if (dataSelectedProject)
    groups.push({
      category: "selectedProject",
      id: dataSelectedProject.project.id,
      icon: <ProjectIcon />,
      title: dataSelectedProject.project.name,
      properties: [
        {
          id: "name",
          label: "项目名称",
          stringValue: dataSelectedProject.project.name,
        },
        ...dataSelectedProject.project.properties.map((property) => ({
          id: property.id,
          label: property.label,
          stringValue: property.value,
        })),
      ],
    });

  // selectedCompany
  const [selectedCompanyId, selectedCompanyIdSet] = useState(null);
  const dataSelectedCompany = useData(
    gql`
      query UsePropertyDialog($selectedCompanyId: ID!) {
        company(id: $selectedCompanyId) {
          id
          name
          properties {
            id
            label
            value
          }
        }
      }
    `,
    { selectedCompanyId },
    { skip: !selectedCompanyId },
  );
  if (dataSelectedCompany)
    groups.push({
      category: "selectedCompany",
      id: dataSelectedCompany.company.id,
      icon: <CompanyIcon />,
      title: dataSelectedCompany.company.name,
      properties: [
        {
          id: "name",
          label: "单位名称",
          stringValue: dataSelectedCompany.company.name,
        },
        ...dataSelectedCompany.company.properties.map((property) => ({
          id: property.id,
          label: property.label,
          stringValue: property.value,
        })),
      ],
    });

  if (propertyPath) {
    const propertyPathGroup = {
      category: "propertyPath",
      id: "propertyPath",
      icon: <DatabaseSearch />,
      title: "自动引用的值",
      subheader: propertyPath,
      properties: [],
    };

    groups.push(propertyPathGroup);

    for (const group of groups)
      for (const property of group.properties) {
        const path = `${group.title}/${property.label || ""}`;
        if (path.startsWith(propertyPath))
          propertyPathGroup.properties.push({
            id: property.id,
            stringValue: property.stringValue,
            label: path,
          });
      }
  }

  const categoryGroups = groups.filter((g) => g.category === category);

  useEffect(() => {
    if (categoryGroups.length === 1) {
      openGroupIdsSet([categoryGroups[0].id]);
    }
  }, [categoryGroups.map((g) => g.id).join(",")]);

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={onClose}
      {...getDialogProps()}
      {...others}
    >
      <DialogTitle>引用值</DialogTitle>
      <DialogContent>
        {categorySwitcher}
        {!!data && !fieldProject && category === "project" && (
          <Message>没有所属项目。</Message>
        )}
        {category === "selectedProject" && (
          <div style={{ padding: 5 }}>
            <ProjectInput
              label="选择项目"
              value={selectedProjectId}
              onChange={selectedProjectIdSet}
            />
          </div>
        )}
        {category === "selectedCompany" && (
          <div style={{ padding: 5 }}>
            <CompanyInput
              label="选择单位"
              value={selectedCompanyId}
              onChange={selectedCompanyIdSet}
            />
          </div>
        )}
        {category === "propertyPath" && propertyPath && (
          <Message>
            该字段已设置自动引用路径“{propertyPath}”，以下是匹配的字段。
          </Message>
        )}
        {category === "propertyPath" && !propertyPath && (
          <Message type="warning">
            该字段未设置自动引用路径，请点击其他类别的值引用。
          </Message>
        )}

        <List disablePadding dense>
          {categoryGroups
            .map((group) => ({
              ...group,
              open: openGroupIds.includes(group.id),
              toggle: () =>
                openGroupIdsSet((openGroupIds) =>
                  toggleArray(openGroupIds, group.id),
                ),
            }))
            .map((group) => (
              <Fragment key={group.id}>
                <ListItemButton component="a" href="#" onClick={group.toggle}>
                  <ListItemIcon>{group.icon}</ListItemIcon>
                  <ListItemText
                    primary={group.title}
                    secondary={group.subheader}
                  />
                  {group.open ? <ChevronUp /> : <ChevronDown />}
                </ListItemButton>
                {group.open && (
                  <>
                    {!group.properties.length && (
                      <Message style={{ marginLeft: "3em" }} type="warning">
                        没有相关值
                      </Message>
                    )}
                    {group.properties
                      .map((property) => {
                        let disabled = false,
                          value = null,
                          errorMessage = null;
                        try {
                          value = parseString(property.stringValue);
                        } catch (error) {
                          disabled = true;
                          errorMessage = error.message;
                        }
                        return {
                          ...property,
                          value,
                          disabled,
                          errorMessage,
                        };
                      })
                      .map((property) => (
                        <ListItemButton
                          component="a"
                          href="#"
                          key={property.id}
                          disabled={property.disabled}
                          onClick={async () => {
                            await onSubmit(property.value);
                            onClose();
                          }}
                          style={{ paddingLeft: "5em" }}
                        >
                          <ListItemText
                            primary={property.label}
                            secondary={
                              <>
                                {property.stringValue || "(未填写)"}
                                {property.errorMessage &&
                                  `(${property.errorMessage})`}
                              </>
                            }
                          />
                        </ListItemButton>
                      ))}
                  </>
                )}
              </Fragment>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
      </DialogActions>
    </Dialog>
  );
}
