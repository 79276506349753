import { ActionFormDialog } from "controls";
import { useRoute } from "hooks";
import { FolderInput, LocalPathInput } from "inputs";
import { teamRoute } from "helpers";

export default function AddSyncEntryDialog({ ...others }) {
  const { teamId } = useRoute(teamRoute);

  return (
    <ActionFormDialog
      {...others}
      title="增加备份位置"
      fields={[
        {
          name: "itemId",
          label: "目录",
          required: true,
          inputComponent: FolderInput,
        },
        {
          name: "localPath",
          label: "本地目录",
          required: true,
          inputComponent: LocalPathInput,
        },
      ]}
      onSubmit={async (formData) => {
        await window.fmsDesktop.command("addSyncEntry", {
          ...formData,
          teamId,
        });
      }}
    />
  );
}
