import React from "react";
import { times } from "lodash-es";
import { gql } from "@apollo/client";
import { CardActionArea, Card, CardHeader, Avatar } from "@mui/material";
import { Star } from "mdi-material-ui";

import { CardLoader } from "loaders";
import { useData, useRoute } from "hooks";
import { ObjectIcon } from "blocks";
import { teamRoute } from "helpers";
import { Message } from "controls";

export default React.memo(FavoritablesDrawer);

function FavoritablesDrawer() {
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query FavoritablesDrawer($teamId: ID!) {
        team(id: $teamId) {
          id
          favoriteItems {
            id
            __typename
            name
          }
        }
      }
    `,
    { teamId },
  );

  return (
    <div style={{ padding: 5 }}>
      <Message
        message={
          <>
            您可以在任何位置点击“收藏
            <Star fontSize="inherit" />
            ”按钮添加至此以便快速访问。
          </>
        }
      />
      {!data && times(5).map((i) => <CardLoader key={i} />)}
      {data?.team.favoriteItems.map((item) => (
        <Card style={{ margin: 5 }} key={item.id}>
          <CardActionArea
            style={{ height: "100%" }}
            href={`/${teamId}/${item.id}`}
          >
            <CardHeader
              title={item.name}
              avatar={
                <Avatar>
                  <ObjectIcon
                    fontSize="inherit"
                    objectType={item.__typename}
                    objectId={item.id}
                  />
                </Avatar>
              }
              subheaderTypographyProps={{
                style: {
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: 200,
                },
              }}
            />
          </CardActionArea>
        </Card>
      ))}
    </div>
  );
}
