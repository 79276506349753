import React, { useId } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

import OutlinedBox from "controls/OutlinedBox";

export default function RadiosInput({
  label,
  name,
  value,
  onChange,
  required,
  helperText,
  readOnly,
  options = [],
}) {
  const id = useId();
  return (
    <OutlinedBox
      labelId={id}
      label={label}
      required={required}
      helperText={helperText}
      size="small"
      fullWidth
    >
      {!options.length && (
        <div style={{ lineHeight: "35px", marginLeft: 10, opacity: 0.4 }}>
          无可选项
        </div>
      )}
      <RadioGroup
        aria-labelledby={id}
        name={name}
        value={value}
        readOnly={readOnly}
        onChange={(event) => onChange(event.target.value)}
        style={{ marginLeft: 11 }}
      >
        {options
          .map((option) =>
            option.constructor === String
              ? {
                  value: option,
                  label: option,
                }
              : option,
          )
          .map((option) => (
            <React.Fragment key={option.value}>
              <FormControlLabel
                value={option.value}
                control={
                  <Radio id={`${id}-${option.value}`} required={required} />
                }
                htmlFor={`${id}-${option.value}`}
                label={
                  <>
                    {option.icon &&
                      React.cloneElement(option.icon, {
                        fontSize: "inherit",
                        style: { verticalAlign: "middle" },
                      })}
                    {option.label}
                  </>
                }
              />
              {option.thumbnail}
            </React.Fragment>
          ))}
      </RadioGroup>
    </OutlinedBox>
  );
}
