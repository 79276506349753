import React from "react";
import { gql } from "@apollo/client";
import {
  DesktopClassic,
  FaceAgent,
  Cellphone,
  Cog,
  Web,
  Delete,
} from "mdi-material-ui";

import { showMessage } from "helpers";
import { DateTimeCell, Table, UsersCell } from "controls";
import { useAction, useConfirmDialog } from "hooks";

export const SessionsTableFragment = gql`
  fragment SessionsTableFragment on Session {
    id
    user(teamId: $teamId) {
      id
    }
    sessionType
    userAgentDescription
    ipAddressMasked
    ipAddressLocation
    createdAt
    updatedAt
  }
`;

export default React.memo(SessionsTable);

function SessionsTable({ rows, ...others }) {
  const confirmDialog = useConfirmDialog();
  const revokeSession = useAction(gql`
    mutation SessionsTab($input: RevokeSessionInput!) {
      revokeSession(input: $input) {
        team {
          id
          sessions {
            id
          }
        }
        user {
          id
          sessions {
            id
          }
        }
      }
    }
  `);

  return (
    <Table
      {...others}
      columns={[
        { label: "浏览器", sortable: true },
        {
          label: "成员",
          sortable: true,
          cellComponent: UsersCell,
        },
        {
          label: "会话类型",
          sortable: true,
        },
        {
          label: "上次在线时间",
          sortable: true,
          cellComponent: DateTimeCell,
        },
        {
          label: "登录时间",
          sortable: true,
          cellComponent: DateTimeCell,
        },
        {
          label: "IP地址段",
          sortable: true,
        },
        {
          label: "IP地址所属地",
          sortable: true,
        },
      ]}
      rows={rows?.map((session) => ({
        id: session.id,
        title: `设备${session.id}`,
        icon: {
          support: <FaceAgent />,
          system: <Cog />,
          mobile: <Cellphone />,
          desktop: <DesktopClassic />,
        }[session.sessionType] || <Web />,
        values: [
          session.userAgentDescription,
          [session.user.id],
          {
            persistent: "固定会话",
            oneoff: "临时会话",
            support: "客服登录会话",
            system: "系统内部会话",
            mobile: "移动客户端",
            desktop: "桌面客户端",
          }[session.sessionType] || session.sessionType,
          session.updatedAt,
          session.createdAt,
          session.ipAddressMasked,
          session.ipAddressLocation,
        ],
        actions: [
          {
            icon: <Delete />,
            label: "注销会话",
            onClick: async () => {
              await confirmDialog();
              await revokeSession({
                input: { sessionId: session.id, userId: session.user.id },
              });
              showMessage({ message: "成功注销会话" });
            },
          },
        ],
      }))}
    />
  );
}
