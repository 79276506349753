import React from "react";
import { gql } from "@apollo/client";

import { Message, TabContent, FileIcon } from "controls";
import { useData, useList, useDialogOpener } from "hooks";
import CreateDocumentDialog from "views/folders/CreateDocumentDialog";
import { DocumentCard, DocumentCardFragment } from "blocks";
import DocumentsTable, { DocumentsTableFragment } from "lists/DocumentsTable";
import ItemsGrid, { ItemsGridFragment } from "lists/ItemsGrid";

export default function DocumentsTab({ projectId }) {
  const openCreateDocumentDialog = useDialogOpener(CreateDocumentDialog);
  const [data, { fetchMore }] = useData(
    gql`
      query DocumentsTab($projectId: ID!, $offset: Int) {
        project(id: $projectId) {
          id
          documentsCount
          documents(offset: $offset, limit: 20) {
            id
            ...DocumentsTableFragment
            ...DocumentCardFragment
            ...ItemsGridFragment
          }
          folder {
            id
            canAddDocument
          }
        }
      }
      ${DocumentsTableFragment}
      ${DocumentCardFragment}
      ${ItemsGridFragment}
    `,
    { projectId },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("doc", {
    rows: data?.project.documents,
    rowsCount: data?.project.documentsCount,
    fetchMore,
    card: <DocumentCard />,
    table: <DocumentsTable enablePath />,
    grid: <ItemsGrid />,
  });

  return (
    <TabContent
      toolbar={<>{listModeSwitcher}</>}
      actions={[
        {
          title: "创建表单",
          icon: <FileIcon />,
          disabled: !data?.project.folder.canAddDocument,
          onClick: () =>
            openCreateDocumentDialog({ folderId: data?.project.folder.id }),
        },
      ]}
    >
      <Message message="这里列出了项目目录内所有的表单，创建表单请在项目目录内进行操作。" />
      {list}
    </TabContent>
  );
}
