import { get } from "lodash-es";
import { print as printGraphQL } from "graphql/language/printer";

import ExtendedError from "./ExtendedError";

function getApolloErrors(apolloError) {
  const operation = apolloError.operation && {
    query: printGraphQL(apolloError.operation.query),
    variables: apolloError.operation.variables,
  };

  const errors = [];
  for (const graphQLError of apolloError.graphQLErrors || []) {
    const fmsErrorCode = get(graphQLError, "extensions.code", "unknown");
    const path = get(graphQLError, "path") || [];

    errors.push(
      new ExtendedError(graphQLError.message, {
        apolloErrorType: "NetworkError",
        operation,
        fmsErrorCode,
        path: path.join(" -> "),
      }),
    );
  }

  if (apolloError.networkError) {
    const { statusCode, bodyText, name, message } = apolloError.networkError;
    let errorMessage = null;
    if (statusCode && bodyText) {
      let titleMatch;
      if (bodyText.length < 100) errorMessage = `${statusCode} ${bodyText}`;
      else if ((titleMatch = bodyText.match(/<title>(.+)<\/title>/)))
        errorMessage = `${statusCode} ${titleMatch[1]}`;
      else errorMessage = `${statusCode} ${bodyText.slice(0, 100)}...`;
    }

    errors.push(
      new ExtendedError(errorMessage || message, {
        apolloErrorType: "NetworkError",
        operation,
        statusCode,
        bodyText,
        errorMessage: message,
        errorName: name,
      }),
    );
  }

  return errors;
}

export default getApolloErrors;
