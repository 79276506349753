import React, { useContext, useState } from "react";
import { gql } from "@apollo/client";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from "@mui/material";
import { matchPath, useLocation } from "react-router-dom";
import { Home, Cog, DeskLamp } from "mdi-material-ui";

import logoImage from "images/logo_button.png";
import {
  UserIcon,
  CompanyIcon,
  FolderIcon,
  DocumentIcon,
  TeamIcon,
  ProjectIcon,
  DataQueryIcon,
  ChatIcon,
} from "controls";
import {
  userRoute,
  companyRoute,
  teamRoute,
  itemRoute,
  dataQueryRoute,
  picklistRoute,
  chatRoute,
} from "helpers";
import { viewportContext } from "contexts";
import { useRoute, useData } from "hooks";

import { NAVBAR_HEIGHT } from "./Navbar";

export const DRAWER_WIDTH = 240;
export const DENSE_DRAWER_WIDTH = 56;

export default function AppDrawer() {
  const { teamId } = useRoute(teamRoute);
  const location = useLocation();

  const data = useData(gql`
    query AppDrawer {
      userLogin {
        id
        isSuperAdmin
      }
    }
  `);

  const { denseView, drawerOpen, drawerOpenSet } = useContext(viewportContext);

  const drawerProps = {
    style: {},
    ...(!denseView
      ? {
          variant: "permanent",
        }
      : {
          variant: "temporary",
          open: drawerOpen,
          onClose: () => drawerOpenSet(false),
        }),
    PaperProps: {
      style: {
        whiteSpace: "nowrap",
        width: denseView ? DRAWER_WIDTH : DENSE_DRAWER_WIDTH,
        zIndex: 1000,
        overflow: "visible",
        paddingTop: `env(safe-area-inset-top)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
      },
    },
  };

  return (
    <Drawer {...drawerProps}>
      <div
        style={{
          height: NAVBAR_HEIGHT,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="优建云"
          style={{
            width: 32,
            height: 32,
          }}
          src={logoImage}
        />
      </div>
      <DrawerMenu
        items={[
          {
            icon: <Home />,
            title: "团队首页",
            href: `/${teamId}`,
            selected: matchPath(location.pathname, {
              path: teamRoute,
              exact: true,
            }),
          },
          {
            icon: <DeskLamp />,
            title: "工作台",
            href: `/${teamId}/my`,
            selected: matchPath(location.pathname, {
              path: `${teamRoute}/my`,
            }),
          },
          {
            icon: <FolderIcon />,
            title: "目录",
            href: `/${teamId}/folders`,
            selected:
              matchPath(location.pathname, {
                path: `${teamRoute}/folders`,
              }) ||
              matchPath(location.pathname, {
                path: itemRoute,
              }),
          },
          {
            icon: <ProjectIcon />,
            title: "项目",
            href: `/${teamId}/projects`,
            selected: matchPath(location.pathname, {
              path: `${teamRoute}/projects`,
            }),
          },
          {
            icon: <DocumentIcon />,
            title: "表单",
            href: `/${teamId}/documents`,
            selected: matchPath(location.pathname, {
              path: `${teamRoute}/documents`,
            }),
          },
          {
            icon: <ChatIcon />,
            title: "讨论",
            href: `/${teamId}/chats`,
            selected:
              matchPath(location.pathname, {
                path: `${teamRoute}/chats`,
              }) ||
              matchPath(location.pathname, {
                path: chatRoute,
              }),
          },
          {
            icon: <DataQueryIcon />,
            title: "统计",
            href: `/${teamId}/data_queries`,
            selected:
              matchPath(location.pathname, {
                path: `${teamRoute}/data_queries`,
              }) ||
              matchPath(location.pathname, {
                path: dataQueryRoute,
              }),
          },
          {
            icon: <CompanyIcon />,
            title: "单位",
            href: `/${teamId}/companies`,
            selected:
              matchPath(location.pathname, {
                path: `${teamRoute}/companies`,
              }) ||
              matchPath(location.pathname, {
                path: companyRoute,
              }),
          },
          {
            icon: <UserIcon />,
            title: "成员",
            href: `/${teamId}/users`,
            selected:
              matchPath(location.pathname, {
                path: `${teamRoute}/users`,
              }) ||
              matchPath(location.pathname, {
                path: userRoute,
              }),
          },
        ]}
      />
      <div style={{ flex: "1 0 auto" }} />
      <DrawerMenu
        items={[
          data?.userLogin?.isSuperAdmin && {
            icon: <Cog />,
            title: "超级管理员工具",
            href: `/${teamId}/superadmin`,
            selected: matchPath(location.pathname, {
              path: `${teamRoute}/superadmin`,
            }),
          },
          {
            icon: <TeamIcon />,
            title: "团队设置",
            href: `/${teamId}/configure`,
            selected:
              matchPath(location.pathname, {
                path: `${teamRoute}/configure`,
              }) ||
              matchPath(location.pathname, {
                path: picklistRoute,
              }),
          },
          {
            icon: <Cog />,
            title: "我的设置",
            href: `/${teamId}/user_settings`,
            selected: matchPath(location.pathname, {
              path: `${teamRoute}/user_settings`,
            }),
          },
        ].filter((t) => t)}
      />
    </Drawer>
  );
}

function DrawerMenu({ items }) {
  const [hovering, hoveringSet] = useState(false);
  const { denseView } = useContext(viewportContext);

  return (
    <Paper
      style={{
        position: "relative",
        borderRadius: 0,
        ...(hovering && {
          width: DRAWER_WIDTH,
          border: "inherit",
        }),
        ...(denseView && {
          width: DRAWER_WIDTH,
        }),
      }}
      elevation={0}
      onMouseEnter={() => hoveringSet(true)}
      onMouseLeave={() => hoveringSet(false)}
    >
      <Divider />
      <List disablePadding>
        {items.map((item) => (
          <ListItem
            button
            dense
            key={item.key || item.title}
            component="a"
            href={item.href || "#"}
            onClick={item.onClick}
            disabled={item.disabled}
            selected={!!item.selected}
            style={{
              borderLeft: `solid 2px ${
                item.selected ? "currentcolor" : "transparent"
              }`,
              overflow: "hidden",
            }}
          >
            <ListItemIcon style={{ minWidth: 48 }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} style={{ margin: 0 }} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Paper>
  );
}
