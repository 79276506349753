import React from "react";
import { gql } from "@apollo/client";
import { Plus } from "mdi-material-ui";

import { UserIcon, AppView, TabContent } from "controls";
import { teamRoute } from "helpers";
import {
  useRoute,
  useList,
  useData,
  useDialogOpener,
  useFilterBar,
} from "hooks";
import UsersTable, { UsersTableFragment } from "lists/UsersTable";

import AddUserDialog from "./AddUserDialog";

export default function UsersView() {
  const [{ searchTerm, companyId, showDisabled }, filterBar] = useFilterBar(
    { enableSearchTerm: true },
    [
      {
        type: "company",
        label: "单位",
        name: "companyId",
      },
      {
        type: "boolean",
        label: "显示已禁用成员",
        name: "showDisabled",
      },
    ],
  );
  const { teamId } = useRoute(teamRoute);
  const [data, dataMeta] = useData(
    gql`
      query UsersView(
        $teamId: ID!
        $searchTerm: String
        $companyId: ID
        $enabled: Boolean!
        $offset: Int
      ) {
        team(id: $teamId) {
          id
          canInviteUser
          users(
            searchTerm: $searchTerm
            companyId: $companyId
            enabled: $enabled
            offset: $offset
            limit: 20
          ) {
            id
            ...UsersTableFragment
          }
          usersCount(
            searchTerm: $searchTerm
            companyId: $companyId
            enabled: $enabled
          )
        }
      }
      ${UsersTableFragment}
    `,
    { teamId, companyId, searchTerm, enabled: !showDisabled },
    { returnMeta: true },
  );

  const openAddUserDialog = useDialogOpener(AddUserDialog);
  const [list, listModeSwitcher] = useList("user", {
    table: <UsersTable enableCompanies />,
    rows: data?.team.users,
    rowsCount: data?.team.usersCount,
    fetchMore: dataMeta.fetchMore,
  });

  return (
    <AppView
      title="成员"
      crumbs={[
        {
          icon: <UserIcon />,
          href: `/${teamId}/users`,
          label: "成员",
        },
      ]}
    >
      <TabContent
        toolbar={<>{listModeSwitcher}</>}
        actions={[
          {
            title: "邀请新成员",
            icon: <Plus />,
            disabled: !data?.team.canInviteUser,
            onClick: () => openAddUserDialog(),
          },
        ]}
      >
        {filterBar}
        {list}
      </TabContent>
    </AppView>
  );
}
