import React, { useRef, useLayoutEffect, createContext, useState } from "react";
import { defer } from "lodash-es";
import { Snackbar, Alert } from "@mui/material";

export const progressBarContext = createContext();

export function ProgressBarRenderer({ stateRef }) {
  const [progressBars, progressBarsSet] = useState([]);

  const anyLoading = !!progressBars.length;
  const anyObstrusive = !!progressBars.find((s) => !s.unobstrusive);
  const anyBlocking = !!progressBars.find((s) => s.blocking);

  for (const [state, stateName] of [
    [anyLoading, "loading"],
    [anyBlocking, "blocking"],
    [anyObstrusive, "obstrusive"],
  ])
    useLayoutEffect(() => {
      const bodyElement = document.querySelector("body");
      if (state) bodyElement.setAttribute(`data-fms-${stateName}`, "true");
      else bodyElement.removeAttribute(`data-fms-${stateName}`);
    }, [state]);

  useLayoutEffect(() => {
    stateRef.current = { progressBarsSet };
    return () => {
      stateRef.current = null;
    };
  }, []);

  return (
    <>
      {anyObstrusive && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10000,
            pointerEvents: "none",
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
          >
            <Alert severity="info">正在执行操作，请稍候……</Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
}

export default function ProgressBarProvider({ children }) {
  const stateRef = useRef();
  const addProgressBar = (progressBar) =>
    stateRef.current.progressBarsSet((p) => [...p, progressBar]);
  const removeProgressBar = (progressBar) =>
    defer(() =>
      stateRef.current.progressBarsSet((p) =>
        p.filter((b) => b.id !== progressBar.id),
      ),
    );

  return (
    <progressBarContext.Provider value={{ addProgressBar, removeProgressBar }}>
      <ProgressBarRenderer stateRef={stateRef} />
      {children}
    </progressBarContext.Provider>
  );
}
