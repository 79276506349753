import React, { useId } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@mui/material";
import dasherize from "dasherize";

export default React.memo(TextInput);

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  error: PropTypes.instanceOf(Error),
  readOnly: PropTypes.bool,
  helperText: PropTypes.any,
};

function TextInput({
  label,
  value,
  onChange,
  helperText,
  name,
  error,
  unit,
  prefix,
  ...others
}) {
  const id = useId();

  return (
    <>
      <TextField
        size="small"
        fullWidth
        label={label}
        name={dasherize(name)}
        error={!!error}
        helperText={(error && error.message) || helperText}
        value={[null, undefined].includes(value) ? "" : String(value)}
        onChange={(event) => {
          let value = event.target.value;
          value = value === "" ? null : Number(value);
          if (Number.isNaN(value)) value = null;
          onChange(value);
        }}
        id={id}
        type="number"
        {...others}
        InputProps={{
          ...(unit && {
            endAdornment: (
              <InputAdornment position="end">{unit}</InputAdornment>
            ),
          }),
          ...(prefix && {
            startAdornment: (
              <InputAdornment position="start">{prefix}</InputAdornment>
            ),
          }),
          ...others.InputProps,
        }}
      />
    </>
  );
}
