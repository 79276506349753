import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";

export const hooksPortalContext = createContext();

export function HooksPortalRenderer() {
  const [elements, elementsSet] = useState([]);
  const { stateRef } = useContext(hooksPortalContext);

  useLayoutEffect(() => {
    stateRef.current = { elementsSet };
    return () => {
      stateRef.current = null;
    };
  }, []);

  return (
    <>
      {elements.map((element) => (
        <React.Fragment key={element.id}>{element.content}</React.Fragment>
      ))}
    </>
  );
}

export default function HooksPortalProvider({ children }) {
  const stateRef = useRef();
  const elementsSet = (...args) => stateRef.current?.elementsSet(...args);

  return (
    <>
      <hooksPortalContext.Provider
        value={{
          elementsSet,
          stateRef,
        }}
      >
        {children}
      </hooksPortalContext.Provider>
    </>
  );
}
