import getApolloErrors from "helpers/getApolloErrors";

function forwardError(event) {
  var error = event.error || event.reason;
  if (!error) return;
  if (error.isAbortError) event.preventDefault();

  let errors = getApolloErrors(error);
  if (!errors.length) errors = [error];

  for (const error of errors)
    window.dispatchEvent(
      new CustomEvent("fmserror", { detail: { error: error } }),
    );
}

export default function errorHandlePatch() {
  window.addEventListener("error", forwardError);
  window.addEventListener("unhandledrejection", forwardError);
  window.addEventListener("fmserror", (event) => {
    const error = event.detail.error;
    if (!error) return;

    if (!error.isUserError && !error.fmsErrorCode)
      Promise.resolve().then(async () => {
        const rollbar = (await import("helpers/rollbar")).default;
        rollbar.error(error, (error, data) => {
          const msg = error
            ? `Error while reporting error to Rollbar: ${error.message}`
            : `Error successfully reported to Rollbar. https://rollbar.com/occurrence/uuid/?uuid=${data.result.uuid}`;
          // eslint-disable-next-line no-console
          console.log(msg);
        });
      });
    if (error.metadata) {
      // eslint-disable-next-line no-console
      console.error(error.message, error.metadata);
    }
  });
}
