import React from "react";

import useFilterBar from "hooks/useFilterBar";

import Browser from "./Browser";
import SearchResult from "./SearchResult";

export default function FolderInputDialogContent({
  item,
  itemSet,
  itemSubmit,
  topFolderId,
}) {
  const [{ searchTerm }, filterBar] = useFilterBar({
    enableSearchTerm: true,
    searchTermAutofocus: true,
    noUrl: true,
  });

  return (
    <>
      {filterBar}
      {!searchTerm && (
        <Browser
          topFolderId={topFolderId}
          folderId={item}
          onFolderSelect={(folderId) => itemSet(folderId)}
        />
      )}
      {!!searchTerm && (
        <SearchResult
          topFolderId={topFolderId}
          searchTerm={searchTerm}
          selectedItemId={item}
          onItemSelect={(itemId) => itemSubmit(itemId)}
          itemTypes={["Folder"]}
        />
      )}
    </>
  );
}
