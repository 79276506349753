import { gql } from "@apollo/client";
import { Download, Link } from "mdi-material-ui";

import { useRoute, useData } from "hooks";
import { shareRoute, filterBlobUrl } from "helpers";
import { TabContent } from "controls";
import PreviewFrame, {
  PreviewFrameFragment,
} from "views/previewable/PreviewFrame";

import usePath from "./usePath";

export default function Items() {
  const { shareToken } = useRoute(shareRoute);
  const [path] = usePath();

  const data = useData(
    gql`
      query Items($shareToken: ID!, $path: [String!]!) {
        share(shareToken: $shareToken) {
          id
          item(path: $path) {
            id
            blobUrl
            blobDownloadUrl
            previewMode
            ...PreviewFrameFragment
          }
        }
      }
      ${PreviewFrameFragment}
    `,
    {
      shareToken,
      path,
    },
  );

  return (
    <TabContent
      actions={[
        {
          title: "在新窗口中打开",
          icon: <Link />,
          href: filterBlobUrl(data?.share.item.blobUrl),
          target: "_blank",
        },
        {
          title: "下载该文件",
          icon: <Download />,
          href: filterBlobUrl(data?.share.item.blobDownloadUrl),
        },
      ]}
    >
      <PreviewFrame previewable={data?.share.item} />
    </TabContent>
  );
}
