import React from "react";
import { gql } from "@apollo/client";
import { Magnify } from "mdi-material-ui";

import { useRoute, useData, useDialogOpener, useFilterBar } from "hooks";
import { teamRoute } from "helpers";
import { Table, DateTimeCell } from "controls";
import { TODAY } from "filters/DateRangeFilter";

import ViewAuditRequestDialog from "./ViewAuditRequestDialog";

export default function AuditView() {
  const { teamId } = useRoute(teamRoute);
  const [{ createdAtRange }, filterBar] = useFilterBar([
    {
      type: "date_range",
      name: "createdAtRange",
      label: "日期",
      defaultValue: TODAY,
      alwaysEnabled: true,
    },
  ]);

  const [data, { fetchMore }] = useData(
    gql`
      query AuditView($teamId: ID!, $createdAtRange: DateRange!, $offset: Int) {
        team(id: $teamId) {
          id
          name
          auditRequests(
            createdAtRange: $createdAtRange
            offset: $offset
            limit: 20
          ) {
            id
            createdAt
            login
            ipAddressMasked
          }
          auditRequestsCount(createdAtRange: $createdAtRange)
        }
      }
    `,
    { teamId, createdAtRange },
    { returnMeta: true },
  );
  const openViewAuditRequestDialog = useDialogOpener(ViewAuditRequestDialog);

  return (
    <>
      {filterBar}
      <Table
        fetchMore={fetchMore}
        columns={[
          {
            label: "时间",
            cellComponent: DateTimeCell,
          },
          {
            label: "操作人",
          },
          {
            label: "IP地址段",
          },
        ]}
        rowsCount={data?.team.auditRequestsCount}
        rows={data?.team.auditRequests.map((auditRequest) => ({
          title: `审计事件#${auditRequest.id}`,
          values: [
            auditRequest.createdAt,
            auditRequest.login,
            auditRequest.ipAddressMasked,
          ],
          actions: [
            {
              label: "查看",
              icon: <Magnify />,
              onClick: () =>
                openViewAuditRequestDialog({ auditRequestId: auditRequest.id }),
            },
          ],
        }))}
      />
    </>
  );
}
