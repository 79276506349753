import { FolderInput } from "inputs/ItemInput";

export default function FolderFilter({ label, value, valueSet }) {
  return (
    <div style={{ margin: 5, minWidth: 150 }}>
      <FolderInput
        label={label}
        size="small"
        value={value}
        onChange={valueSet}
      />
    </div>
  );
}
