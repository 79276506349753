import QueryString from "query-string";

export const ENVIRONMENT = process.env["NODE_ENV"];
export const IS_PRODUCTION = ENVIRONMENT === "production";
export const API_ORIGIN = process.env["REACT_APP_API_ORIGIN"];
export const API_VERSION = process.env["REACT_APP_API_VERSION"];
export const IS_TEST = !!process.env["REACT_APP_TEST"];
export const PERSIST_CACHE = !!process.env["REACT_APP_PERSIST_CACHE"];
export const PUBLIC_URL = process.env["PUBLIC_URL"] || "";
export const ENABLE_SERVICE_WORKER =
  !!process.env["REACT_APP_ENABLE_SERVICE_WORKER"];

if (!IS_PRODUCTION) {
  const { mockDesktopState = null } = QueryString.parse(
    window.location.search.replace(/^\?/, ""),
  );
  window._fmsMockDesktopState =
    mockDesktopState && JSON.parse(mockDesktopState);
}

export const DESKTOP_BUILD =
  !!process.env["REACT_APP_DESKTOP_BUILD"] || !!window._fmsMockDesktopState;
export const ENABLE_GA = !!process.env["REACT_APP_ENABLE_GA"];
