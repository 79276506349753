import React, { Fragment, useState, useLayoutEffect } from "react";
import { uniq, every } from "lodash-es";
import { MenuDown } from "mdi-material-ui";
import {
  ButtonGroup,
  Paper,
  Button,
  List,
  ListItem,
  Popper,
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function DocumentActions({ stateRef, renderDocumentActions }) {
  const [documentActions, documentActionsSet] = useState([]);
  useLayoutEffect(() => {
    stateRef.current = { documentActionsSet };
    return () => {
      stateRef.current = null;
    };
  }, []);

  return (
    <>
      {!!documentActions.length &&
        renderDocumentActions(
          <div
            elevation={5}
            style={{
              display: "flex",
              flexFlow: "row wrap",
            }}
            onMouseDown={(event) => event.preventDefault()}
          >
            {uniq(documentActions.map((a) => a.category))
              .sort()
              .map((category) => ({
                category,
                documentActions: documentActions.filter(
                  (a) => a.category === category,
                ),
              }))
              .map(({ category, documentActions }) => (
                <div
                  key={category}
                  style={{
                    flex: "0 1 auto",
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                    borderLeft: "double 3px #ccc",
                    padding: "0 5px",
                    margin: 2,
                  }}
                >
                  <span>{category}</span>
                  {uniq(documentActions.map((a) => a.group))
                    .map((group) => ({
                      group,
                      documentActions: documentActions.filter(
                        (a) => a.group === group,
                      ),
                    }))
                    .map(({ documentActions, ...others }) => ({
                      ...others,
                      documentActions,
                      dropdown: every(documentActions, (a) => a.dropdown),
                    }))
                    .map(({ group, documentActions, dropdown }) => (
                      <ButtonGroup
                        key={group}
                        style={{
                          margin: "0 2px",
                        }}
                      >
                        {!dropdown &&
                          documentActions.map((documentAction) => (
                            <Button
                              key={documentAction.title}
                              title={documentAction.title}
                              size="small"
                              style={{ minWidth: "auto" }}
                              variant={
                                documentAction.selected
                                  ? "contained"
                                  : undefined
                              }
                              disabled={documentAction.disabled}
                              onClick={documentAction.onClick}
                            >
                              {documentAction.icon}
                              {documentAction.label}
                            </Button>
                          ))}
                        {dropdown && (
                          <DropdownButton
                            group={group}
                            documentActions={documentActions}
                          />
                        )}
                      </ButtonGroup>
                    ))}
                </div>
              ))}
          </div>,
        )}
    </>
  );
}

function DropdownButton({ group, documentActions }) {
  const [dropdownAnchorEl, dropdownAnchorElSet] = useState();

  return (
    <>
      <ClickAwayListener onClickAway={() => dropdownAnchorElSet(null)}>
        <div>
          <Button
            size="small"
            style={{ minWidth: "auto" }}
            variant="outlined"
            title={group}
            disabled={every(documentActions, (a) => a.disabled)}
            onClick={(event) => {
              dropdownAnchorElSet((el) =>
                el === event.currentTarget ? null : event.currentTarget,
              );
            }}
          >
            {documentActions
              .filter((a) => a.selected)
              .map((a) => (
                <Fragment key={a.title}>
                  {a.icon}
                  {a.label}
                </Fragment>
              ))}
            <MenuDown />
          </Button>
          <Popper
            anchorEl={dropdownAnchorEl}
            placement="bottom"
            open={!!dropdownAnchorEl}
            style={{ zIndex: 10 }}
          >
            <Paper
              style={{
                maxHeight: 400,
                overflowY: "auto",
              }}
              onMouseDown={(event) => event.preventDefault()}
            >
              <List disablePadding>
                {documentActions.map((documentAction) => (
                  <ListItem
                    dense
                    button
                    component="button"
                    key={documentAction.title}
                    onClick={() => {
                      documentAction.onClick();
                      dropdownAnchorElSet(null);
                    }}
                    selected={documentAction.selected}
                  >
                    <ListItemIcon>{documentAction.icon}</ListItemIcon>
                    <ListItemText primary={documentAction.title} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
}
