import React from "react";
import { gql } from "@apollo/client";

import { CompanyIcon, AppView, AppTabs } from "controls";
import { teamRoute } from "helpers";
import { useData, useRoute } from "hooks";

import CompaniesTab from "./CompaniesTab";
import RegistrationsTab from "./RegistrationsTab";

export default function CompaniesView() {
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query CompaniesView($teamId: ID!) {
        team(id: $teamId) {
          id
          companiesCount
          companyRegistrationsCount(processed: false)
        }
      }
    `,
    { teamId },
  );

  return (
    <AppView
      title="单位"
      crumbs={[
        {
          icon: <CompanyIcon />,
          href: `/${teamId}/companies`,
          label: "单位",
        },
      ]}
    >
      <AppTabs
        tabs={[
          {
            title: "单位",
            name: "companies",
            content: <CompaniesTab />,
            badgeContent: data?.team?.companiesCount,
          },
          {
            title: "单位注册",
            name: "registrations",
            content: <RegistrationsTab />,
            badgeContent: data?.team?.companyRegistrationsCount,
          },
        ]}
      />
    </AppView>
  );
}
