import React from "react";
import { gql } from "@apollo/client";

import { documentRoute } from "helpers";
import { useRoute, useData } from "hooks";
import { AppTabs } from "controls";
import RevisionsTab from "views/items/RevisionsTab";
import ActivitiesTab from "views/items/ActivitiesTab";

import DocumentContentTab from "./DocumentContentTab";
import DataTab from "./DataTab";
import AttachmentsTab from "./AttachmentsTab";

export default function DocumentView() {
  const { documentId } = useRoute(documentRoute);
  const data = useData(
    gql`
      query DocumentView($documentId: ID!) {
        document(id: $documentId) {
          id
          attachments {
            id
          }
        }
      }
    `,
    { documentId },
  );

  return (
    <AppTabs
      tabs={[
        {
          title: "文档内容",
          name: "content",
          content: <DocumentContentTab documentId={documentId} />,
        },
        {
          title: "数据",
          name: "data",
          content: <DataTab />,
        },
        {
          title: "历史版本",
          name: "revisions",
          content: <RevisionsTab itemId={documentId} />,
        },
        {
          title: "附件",
          name: "attachments",
          content: <AttachmentsTab />,
          badgeContent: data?.document.attachments.length,
        },
        {
          title: "动态",
          name: "activities",
          content: <ActivitiesTab />,
        },
      ].filter((t) => t)}
    />
  );
}
