export default function findAndMerge(
  arr,
  id,
  attributes,
  { idKey = "id", create = false } = {},
) {
  let arrNew = arr.map((item) => ({
    ...item,
    ...(item[idKey] === id && attributes),
  }));

  if (create && !arrNew.find((i) => i[idKey] === id)) {
    arrNew = [
      ...arrNew,
      {
        [idKey]: id,
        ...create,
        ...attributes,
      },
    ];
  }

  return arrNew;
}
