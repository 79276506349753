import React from "react";
import { times } from "lodash-es";
import { Paper, CircularProgress } from "@mui/material";

import { FileIcon } from "./icons";
import FetchMoreButton from "./FetchMoreButton";

export default function GridList({
  fetchMore,
  rows,
  rowsCount,
  defaultIcon = <FileIcon />,
}) {
  return (
    <>
      <div
        style={{
          padding: 5,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
          gridGap: 10,
        }}
      >
        {!rows &&
          times(5).map((i) => (
            <Paper
              key={i}
              elevation={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                aspectRatio: "1",
              }}
            >
              <CircularProgress />
            </Paper>
          ))}
        {rows?.map((row) => (
          <Paper
            component="a"
            key={row.id}
            href={row.href || "#"}
            onClick={row.onClick}
            elevation={row.selected ? 10 : 2}
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              textDecoration: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                aspectRatio: "1",
              }}
            >
              {row.thumbnailUrl ? (
                <img
                  alt={row.title}
                  src={row.thumbnailUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <span style={{ fontSize: 72, color: "#888" }}>
                  {React.cloneElement(row.icon || defaultIcon, {
                    fontSize: "inherit",
                  })}
                </span>
              )}
            </div>
            <div
              style={{
                margin: 5,
                wordBreak: "break-all",
                textAlign: "center",
                overflow: "hidden",
                fontSize: 12,
              }}
            >
              {row.title}
            </div>
          </Paper>
        ))}
      </div>
      {fetchMore && (
        <FetchMoreButton
          fetchMore={fetchMore}
          rows={rows}
          rowsCount={rowsCount}
        />
      )}
    </>
  );
}
