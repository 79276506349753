import {
  SortClockAscendingOutline,
  SortClockDescendingOutline,
  SortAscending,
  SortDescending,
} from "mdi-material-ui";

export const ORDER_BY_REVISIONED_AT = {
  label: "修改时间(最旧)",
  icon: <SortClockAscendingOutline />,
  value: "ORDER_BY_REVISIONED_AT",
};

export const ORDER_BY_REVISIONED_AT_DESC = {
  label: "修改时间(最新)",
  icon: <SortClockDescendingOutline />,
  value: "ORDER_BY_REVISIONED_AT_DESC",
};

export const ORDER_BY_CREATED_AT = {
  label: "创建时间(最旧)",
  icon: <SortClockAscendingOutline />,
  value: "ORDER_BY_CREATED_AT",
};

export const ORDER_BY_CREATED_AT_DESC = {
  label: "创建时间(最新)",
  icon: <SortClockDescendingOutline />,
  value: "ORDER_BY_CREATED_AT_DESC",
};

export const ORDER_BY_NAME = {
  label: "名称",
  icon: <SortAscending />,
  value: "ORDER_BY_NAME",
};

export const ORDER_BY_SIZE = {
  label: "尺寸(最小)",
  icon: <SortAscending />,
  value: "ORDER_BY_SIZE",
};

export const ORDER_BY_SIZE_DESC = {
  label: "尺寸(最大)",
  icon: <SortDescending />,
  value: "ORDER_BY_SIZE_DESC",
};
