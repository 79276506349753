import React from "react";

import TextArea from "../TextArea";

FieldText.parseString = (str) => str;
FieldText.toFormData = (value) => ({ content: value });

export default function FieldText({
  field,
  updateField,
  documentContentMode,
  ...others
}) {
  return (
    <TextArea
      data-field-text
      readOnly={documentContentMode !== "fill"}
      value={field.content}
      onChange={(content) =>
        updateField({
          input: {
            fieldId: field.id,
            content,
          },
        })
      }
      {...others}
    />
  );
}
