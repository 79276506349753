import React from "react";
import { gql } from "@apollo/client";

import { DocumentIcon } from "controls/icons";
import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";
import DateTime from "controls/DateTime";
import ObjectCard from "controls/ObjectCard";
import filterBlobUrl from "helpers/filterBlobUrl";

import ItemPath, { ItemPathFragment } from "./ItemPath";
import UsersLabel, { UsersLabelFragment } from "./UsersLabel";

export const DocumentCardFragment = gql`
  fragment DocumentCardFragment on Document {
    id
    name
    dataRowName
    revisions {
      id
    }
    createdAt
    revisionedAt
    revisionedByUsers {
      ...UsersLabelFragment
    }
    thumbnailUrl
    ...ItemPathFragment
  }
  ${UsersLabelFragment}
  ${ItemPathFragment}
`;

export default function DocumentCard({ document, doc = document }) {
  const { teamId } = useRoute(teamRoute);

  return (
    <ObjectCard
      icon={<DocumentIcon />}
      title={doc.name}
      subheader={<ItemPath item={doc} />}
      href={`/${teamId}/${doc.id}`}
      thumbnailUrl={filterBlobUrl(doc.thumbnailUrl)}
      fields={[
        {
          label: "创建时间",
          content: <DateTime value={doc.createdAt} />,
        },
        {
          label: "修改成员",
          content: <UsersLabel users={doc.revisionedByUsers} />,
        },
        {
          label: "数据表",
          content: doc.dataRowName,
        },
        {
          label: "最后修订",
          content: (
            <>
              <DateTime value={doc.revisionedAt} />
              (共{doc.revisions.length + 1}个版本)
            </>
          ),
        },
      ]}
    />
  );
}
