// These must be the first lines in src/index.js
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "element-closest-polyfill";
import ResizeObserverPolyfill from "resize-observer-polyfill";
import isNaNPolyfill from "number.isnan";

if (typeof window.ResizeObserver == "undefined")
  window.ResizeObserver = ResizeObserverPolyfill;
if (typeof Number.isNaN == "undefined") Number.isNaN = isNaNPolyfill;
