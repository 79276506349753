import { gql } from "@apollo/client";
import { SwapVertical, Pencil, Delete, Plus, Cog } from "mdi-material-ui";

import {
  TabContent,
  AppView,
  Table,
  PropertyIcon,
  FieldsContainer,
  CheckboxCell,
} from "controls";
import {
  useData,
  useFormDialog,
  useActionFragment,
  useRoute,
  useConfirmDialog,
} from "hooks";
import { pTemplateRoute, teamRoute } from "helpers";
import { OptionsInput, SortInput, BooleanInput } from "inputs";

export default function PTemplateView() {
  const formDialog = useFormDialog();
  const confirmDialog = useConfirmDialog();
  const { pTemplateId, teamId } = useRoute(pTemplateRoute);

  const data = useData(
    gql`
      query PTemplateView($pTemplateId: ID!) {
        pTemplate(id: $pTemplateId) {
          id
          name
          canManage
          pTemplateItems {
            id
            label
            multi
            readOnly
            picklist {
              id
              name
            }
          }
        }
      }
    `,
    { pTemplateId },
  );

  const pTemplateItemUpdate = useActionFragment(
    "pTemplateItemUpdate",
    `
    pTemplateItem { id label multi readOnly picklist { id } } 
    pTemplate { id pTemplateItems { id } }
    `,
  );

  const pTemplateItemRemove = useActionFragment(
    "pTemplateItemRemove",
    "pTemplate { id pTemplateItems { id } }",
  );

  const pTemplateItemSort = useActionFragment(
    "pTemplateItemSort",
    "pTemplate { id pTemplateItems { id } }",
  );

  return (
    <AppView
      title={data?.pTemplate.name}
      crumbs={[
        {
          icon: <Cog />,
          label: "团队设置",
          href: `/${teamId}/configure`,
        },
        {
          label: data?.pTemplate.name,
          icon: <PropertyIcon />,
          href: `/${teamId}/${pTemplateId}`,
        },
      ]}
    >
      <TabContent
        actions={[
          {
            icon: <Plus />,
            title: "添加属性",
            disabled: !data?.pTemplate.canManage,
            onClick: () =>
              formDialog({
                title: "添加属性",
                fieldsContainer: <PTemplateItemFieldsContainer />,
                onSubmit: async (formData) => {
                  await pTemplateItemUpdate({
                    input: {
                      pTemplateId,
                      ...formData,
                    },
                  });
                },
              }),
          },
          {
            title: "调整属性顺序",
            icon: <SwapVertical />,
            disabled: !data?.pTemplate.canManage,
            onClick: () =>
              formDialog({
                title: "属性顺序",
                fields: [
                  {
                    name: "pTemplateItemIds",
                    required: true,
                    inputComponent: SortInput,
                    value: data?.pTemplate.pTemplateItems.map((p) => p.id),
                    options: {
                      items: data?.pTemplate.pTemplateItems.map(
                        (pTemplateItem) => ({
                          value: pTemplateItem.id,
                          icon: <PropertyIcon />,
                          label: pTemplateItem.label,
                        }),
                      ),
                    },
                  },
                ],
                onSubmit: async (formData) => {
                  await pTemplateItemSort({
                    input: {
                      pTemplateId,
                      ...formData,
                    },
                  });
                },
              }),
          },
        ]}
      >
        <Table
          columns={[
            { label: "标签" },
            { label: "使用选项列表" },
            { label: "多个值", cellComponent: CheckboxCell },
            { label: "只读", cellComponent: CheckboxCell },
          ]}
          rows={data?.pTemplate.pTemplateItems.map((pTemplateItem) => ({
            id: pTemplateItem.id,
            title: pTemplateItem.label,
            icon: <PropertyIcon />,
            values: [
              pTemplateItem.label,
              pTemplateItem.picklist?.name,
              { checked: pTemplateItem.multi, disabled: true },
              { checked: pTemplateItem.readOnly, disabled: true },
            ],
            actions: [
              {
                icon: <Delete />,
                label: "删除属性",
                disabled: !data?.pTemplate.canManage,
                onClick: async () => {
                  await confirmDialog();
                  await pTemplateItemRemove({
                    input: { pTemplateItemId: pTemplateItem.id },
                  });
                },
              },
              {
                icon: <Pencil />,
                label: "修改属性设置",
                disabled: !data?.pTemplate.canManage,
                onClick: () =>
                  formDialog({
                    title: "修改属性设置",
                    fieldsContainer: (
                      <PTemplateItemFieldsContainer
                        pTemplateItem={pTemplateItem}
                      />
                    ),
                    onSubmit: async (formData) => {
                      await pTemplateItemUpdate({
                        input: {
                          pTemplateId,
                          label: pTemplateItem.label,
                          ...formData,
                        },
                      });
                    },
                  }),
              },
            ],
          }))}
        />
      </TabContent>
    </AppView>
  );
}

function PTemplateItemFieldsContainer({
  pTemplateItem = {
    label: "",
    picklist: null,
    multi: false,
    readOnly: false,
  },
  ...others
}) {
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query PropertyFieldsContainer($teamId: ID!) {
        team(id: $teamId) {
          id
          picklists {
            id
            name
          }
        }
      }
    `,
    { teamId },
  );

  return (
    <FieldsContainer
      fields={[
        {
          name: "label",
          value: pTemplateItem.label,
          label: "属性名称",
          required: true,
          readOnly: !!pTemplateItem.id,
        },
        {
          name: "picklistId",
          value: pTemplateItem.picklist?.id,
          label: "选项列表",
          inputComponent: OptionsInput,
          helperText: "可选：可以强制填写时从选项列表里选择值",
          options: {
            options: data?.team.picklists.map((picklist) => ({
              label: picklist.name,
              name: picklist.id,
            })),
          },
        },
        {
          name: "multi",
          value: pTemplateItem.multi,
          label: "可填写/选择多个值",
          inputComponent: BooleanInput,
        },
        {
          name: "readOnly",
          value: pTemplateItem.readOnly,
          label: "只读",
          inputComponent: BooleanInput,
        },
      ]}
      {...others}
    />
  );
}
