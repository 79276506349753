import React, { useState, useEffect } from "react";
import { defer } from "lodash-es";
import { gql } from "@apollo/client";
import { List } from "@mui/material";

import useData from "hooks/useData";
import useFilterBar from "hooks/useFilterBar";

import Browser from "./Browser";
import FolderResult from "./FolderResult";
import SearchResult from "./SearchResult";

export default function ItemInputDialogContent({
  item: itemId,
  itemSet: itemIdSet,
  itemSubmit: itemIdSubmit,
  itemTypes = ["Document", "File", "Folder"],
  topFolderId,
}) {
  const [{ searchTerm }, filterBar] = useFilterBar({
    enableSearchTerm: true,
    searchTermAutofocus: true,
    noUrl: true,
  });
  const [folderId, folderIdSet] = useState(topFolderId);
  const data = useData(
    gql`
      query ItemInputDialogContent($itemId: ID!) {
        item(id: $itemId) {
          id
          parentFolder {
            id
          }
        }
      }
    `,
    { itemId },
    { skip: !itemId },
  );

  useEffect(() => {
    if (data)
      if (data.item.__typename === "Folder") defer(() => folderIdSet(itemId));
      else defer(() => folderIdSet(data.item.parentFolder?.id));
  }, [!!data]);

  return (
    <>
      {filterBar}
      <List>
        {!searchTerm && (
          <div style={{ display: "flex", flexFlow: "row nowrap" }}>
            <div style={{ flex: "0 0 auto" }}>
              <Browser
                topFolderId={topFolderId}
                folderId={folderId}
                onFolderSelect={(folderId) => {
                  folderIdSet(folderId);
                  if (itemTypes.includes("Folder")) itemIdSet(folderId);
                }}
              />
            </div>
            <div style={{ flex: "1 1 auto" }}>
              {!!folderId && (
                <FolderResult
                  selectedItemId={itemId}
                  folderId={folderId}
                  itemTypes={itemTypes}
                  onItemSelect={(itemId) => itemIdSubmit(itemId)}
                />
              )}
            </div>
          </div>
        )}
        {!!searchTerm && (
          <SearchResult
            topFolderId={topFolderId}
            searchTerm={searchTerm}
            selectedItemId={itemId}
            onItemSelect={(itemId) => itemIdSubmit(itemId)}
            itemTypes={itemTypes}
          />
        )}
      </List>
    </>
  );
}
