import React from "react";
import { Loading } from "mdi-material-ui";

import "./IconLoader.css";

export default React.memo(IconLoader);

function IconLoader({ ...others }) {
  return (
    <Loading
      {...others}
      style={{
        animationDuration: "1s",
        animationName: "iconloader",
        animationIterationCount: "infinite",
        transformOrigin: "50% 50%",
        ...others.style,
      }}
    />
  );
}
