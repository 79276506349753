import React from "react";
import gql from "graphql-tag";
import { ChevronRight } from "mdi-material-ui";

import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";

import ItemObjectIcon from "./ItemObjectIcon";

export default React.memo(ItemPath);

export const ItemPathFragment = gql`
  fragment ItemPathFragment on Item {
    id
    path {
      id
      name
      canAccess
    }
  }
`;

const iconStyle = {
  opacity: 0.6,
  verticalAlign: "text-bottom",
  fontSize: "1.3em",
  marginTop: "-1em",
};

function ItemPath({ item, clickable = false, ...others }) {
  const { teamId } = useRoute(teamRoute);
  return (
    <span {...others}>
      {item?.path
        .map((parent, parentIndex) => [
          parent,
          parentIndex >= item.path.length - 2,
        ])
        .map(([parent, displayLabel], parentIndex) => (
          <React.Fragment key={parent.id}>
            <SpanOrAnchor
              href={clickable ? `/${teamId}/${parent.id}` : undefined}
              title={displayLabel ? undefined : parent.name}
              disabled={!parent.canAccess}
            >
              {parentIndex < item.path.length - 2 ? (
                <ParentIcon parent={parent} />
              ) : (
                <>
                  <ParentIcon parent={parent} />
                  <span
                    style={{
                      maxWidth: 150,
                      overflow: "hidden",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      verticalAlign: "bottom",
                    }}
                  >
                    {parent.name}
                  </span>
                </>
              )}
            </SpanOrAnchor>
            {parentIndex < item.path.length - 1 && (
              <ChevronRight fontSize="inherit" style={{ ...iconStyle }} />
            )}
          </React.Fragment>
        ))}
    </span>
  );
}

function ParentIcon({ parent }) {
  return (
    <ItemObjectIcon
      fontSize="inherit"
      itemId={parent.id}
      style={{ ...iconStyle }}
    />
  );
}

function SpanOrAnchor({ href, children, disabled, ...others }) {
  return href ? (
    <a
      href={href}
      {...others}
      {...(disabled && {
        href: null,
      })}
      style={{
        color: "inherit",
        textDecoration: "none",
        ...(disabled && {
          opacity: 0.25,
        }),
        ...others.style,
      }}
    >
      {children}
    </a>
  ) : (
    <span {...others}>{children}</span>
  );
}
