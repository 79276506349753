import React from "react";
import { IconButton } from "@mui/material";
import gql from "graphql-tag";
import { Star, StarBorder } from "@mui/icons-material";

import useAction from "hooks/useAction";
import showMessage from "helpers/showMessage";

export default React.memo(FavoritableIconButton);

export const FavoritableIconButtonFragment = gql`
  fragment FavoritableIconButtonFragment on Favoritable {
    id
    name
    isFavorite
  }
`;

function FavoritableIconButton({ favoritable }) {
  const toggleFavorite = useAction(gql`
    mutation AppView($input: ToggleFavoriteInput!) {
      toggleFavorite(input: $input) {
        team {
          id
          favoriteItems {
            id
          }
        }
        favoritable {
          id
          name
          isFavorite
        }
      }
    }
  `);

  return (
    <IconButton
      disabled={!favoritable}
      onClick={async () => {
        await toggleFavorite({
          input: {
            favoritableId: favoritable?.id,
            enable: !favoritable?.isFavorite,
          },
        });
        if (favoritable.isFavorite) {
          showMessage({
            message: `已取消收藏${favoritable.name}`,
          });
        } else {
          showMessage({
            message: `已收藏${favoritable.name}`,
          });
        }
      }}
      size="large"
      title={favoritable?.isFavorite ? "取消收藏" : "收藏"}
    >
      <>{favoritable?.isFavorite ? <Star /> : <StarBorder />}</>
    </IconButton>
  );
}
