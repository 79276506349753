import { gql } from "@apollo/client";
import React, { useEffect, useState } from "react";

import useData from "hooks/useData";
import { authContext } from "contexts";
import { DESKTOP_BUILD } from "helpers/config";

const SESSION_POLL_INTERVAL = 300 * 1000;

export const AuthProviderQuery = gql`
  query AuthProvider {
    userLogin {
      id
      newToken
    }
  }
`;

export default function AuthProvider({ children }) {
  const [token, tokenSetRaw] = useState(window._fmsToken);
  const tokenSet = (token) => {
    tokenSetRaw(token);
    window._fmsToken = token;

    if (!window._fmsTokenPersonified) {
      if (token) localStorage.setItem("token", token);
      else localStorage.removeItem("token");
    }
  };

  // refresh
  const [data, { refetch }] = useData(AuthProviderQuery, null, {
    returnMeta: true,
    pollInterval: SESSION_POLL_INTERVAL,
  });

  const newToken = data?.userLogin?.newToken;

  useEffect(() => {
    if (newToken) {
      tokenSet(newToken);
    }
  }, [newToken]);

  // auth actions
  const authenticate = async ({ token }) => {
    tokenSet(token);
    await refetch();
  };

  const authenticated = data ? !!data.userLogin : !!token;

  const deauthenticate = async () => {
    tokenSet(null);
    await refetch();
  };

  // set desktop state
  if (DESKTOP_BUILD)
    useEffect(() => {
      window.fmsDesktop?.command("setToken", {
        token,
        userAgent: navigator.userAgent,
      });
    }, [token]);

  return (
    <authContext.Provider
      value={{
        token,
        authenticated,
        authenticate,
        deauthenticate,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
