import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

import UserError from "helpers/UserError";

import usePortal from "./usePortal";

export default function useConfirmDialog(defaultOptions = {}) {
  const { mount, unmount } = usePortal();

  return async (options = {}) => {
    const { message = "请确认进行操作", title = "确认操作" } = {
      ...defaultOptions,
      ...options,
    };

    try {
      await new Promise((resolve, reject) =>
        mount(
          <Dialog open fullWidth maxWidth="xs">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  reject(new UserError("已取消", { isAbortError: true }))
                }
              >
                取消
              </Button>
              <Button onClick={() => resolve()} variant="contained" autoFocus>
                确定
              </Button>
            </DialogActions>
          </Dialog>,
        ),
      );
    } finally {
      unmount();
    }
  };
}
