import React from "react";

import {
  ActivityIcon,
  ProjectIcon,
  DocumentIcon,
  DataQueryIcon,
} from "controls/icons";
import IconLoader from "loaders/IconLoader";

import FileObjectIcon from "./FileObjectIcon";
import FolderObjectIcon from "./FolderObjectIcon";
import UserAvatar from "./UserAvatar";

export default function ObjectIcon({ objectType, objectId, ...others }) {
  return (
    <>
      {!(objectType && objectId) && <IconLoader />}
      {objectType === "Folder" && (
        <FolderObjectIcon {...others} folderId={objectId} />
      )}
      {objectType === "File" && (
        <FileObjectIcon {...others} fileId={objectId} />
      )}
      {objectType === "Activity" && <ActivityIcon {...others} />}
      {objectType === "Project" && <ProjectIcon {...others} />}
      {objectType === "Document" && <DocumentIcon {...others} />}
      {objectType === "DataQuery" && <DataQueryIcon {...others} />}
      {objectType === "User" && (
        <UserAvatar
          userId={objectId}
          style={{
            width: "1.3em",
            height: "1.3em",
            fontSize: ".8em",
          }}
          {...others}
        />
      )}
    </>
  );
}
