import React from "react";
import { gql } from "@apollo/client";

import { TabContent, Message, ChatIcon, FetchMoreButton } from "controls";
import { useData, useRoute } from "hooks";
import { teamRoute } from "helpers";

import ChatMessage, { ChatMessageFragment } from "views/chats/ChatMessage";

export default function MessagesTabProjectCompaniesTab({ projectId }) {
  const { teamId } = useRoute(teamRoute);
  const [data, dataMeta] = useData(
    gql`
      query MessagesTab($projectId: ID!, $offset: Int) {
        project(id: $projectId) {
          id
          chat {
            id
            messagesCount
            messages(offset: $offset, limit: 50) {
              id
              ...ChatMessageFragment
            }
          }
        }
      }
      ${ChatMessageFragment}
    `,
    { projectId },
    { returnMeta: true },
  );

  return (
    <TabContent
      actions={[
        {
          title: "跳转到讨论",
          icon: <ChatIcon />,
          disabled: !data,
          href: `/${teamId}/${data?.project.chat.id}`,
        },
      ]}
    >
      <Message
        actions={[
          {
            title: "跳转到讨论",
            icon: <ChatIcon />,
            disabled: !data,
            href: `/${teamId}/${data?.project.chat.id}`,
          },
        ]}
      >
        这里显示了该项目的最新相关讨论，发送消息请点击<b>跳转到讨论</b>。
      </Message>
      {data?.project.chat.messages.map((message) => (
        <ChatMessage key={message.id} message={message} />
      ))}
      <FetchMoreButton
        fetchMore={dataMeta.fetchMore}
        rows={data?.project.chat.messages}
        rowsCount={data?.project.chat.messagesCount}
      />
    </TabContent>
  );
}
