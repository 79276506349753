import { useRef, useEffect } from "react";
import { useComponentSize } from "react-use-size";
import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import SigPad from "signature_pad";

import { ActionFormDialog } from "controls";
import { useRoute, useAction } from "hooks";
import { teamRoute, uploadBlob } from "helpers";
import { SIGNATURE_WIDTH, SIGNATURE_HEIGHT } from "inputs/SignatureInput";

export default function AddSignatureDialog({ ...others }) {
  const signaturePadRef = useRef();
  const { teamId } = useRoute(teamRoute);

  const addSignature = useAction(gql`
    mutation ($input: AddSignatureInput!) {
      addSignature(input: $input) {
        team {
          id
          signatures {
            id
          }
        }
      }
    }
  `);

  return (
    <ActionFormDialog
      {...others}
      title="添加签名"
      onSubmit={async ({ signature, ...formData }) => {
        const dataURL = signaturePadRef.current.toDataURL("image/png");
        const blob = await (await fetch(dataURL)).blob();
        const { etag } = await uploadBlob({
          blob,
        });
        await addSignature({
          input: {
            teamId,
            etag,
            filename: "signature.png",
            ...formData,
          },
        });
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: "签名名称",
          helperText: "仅自己可见",
          value: "新签名",
        },
        {
          name: "signature",
          inputComponent: InputComponent,
          options: {
            signaturePadRef,
          },
        },
      ]}
    ></ActionFormDialog>
  );
}

function InputComponent({ signaturePadRef }) {
  const componentSize = useComponentSize();
  const { teamId } = useRoute(teamRoute);
  const url = `${window.location.origin}/${teamId}/add_signature`;

  const scale = Math.min(componentSize.width / SIGNATURE_WIDTH, 2);

  return (
    <div ref={componentSize.ref}>
      {!!scale && (
        <SignaturePad
          key={scale}
          signaturePadRef={signaturePadRef}
          width={SIGNATURE_WIDTH * scale}
          height={SIGNATURE_HEIGHT * scale}
          style={{
            cursor: "crosshair",
            display: "block",
          }}
          options={{
            minWidth: 0.4,
            maxWidth: 1.5,
          }}
        />
      )}
      <p>
        您可以通过该链接在手机上输入签名：
        <br />
        <a href={url}>{url}</a>
      </p>
    </div>
  );
}

function SignaturePad({ signaturePadRef, options, width, height, ...others }) {
  const ref = useRef();
  const devicePixelRatio = Math.max(window.devicePixelRatio || 1, 1);

  useEffect(() => {
    const canvas = ref.current;
    const signaturePad = new SigPad(canvas, options);
    signaturePad.on();

    canvas.getContext("2d").scale(devicePixelRatio, devicePixelRatio);
    signaturePadRef.current = signaturePad;

    return () => {
      signaturePad.off();
      signaturePadRef.current = null;
    };
  }, []);

  return (
    <Paper
      component="canvas"
      ref={ref}
      {...others}
      width={width * devicePixelRatio}
      height={height * devicePixelRatio}
      style={{
        ...others.style,
        width,
        height,
      }}
    />
  );
}
