import React from "react";

import { TextLoader } from "loaders";

import Widget from "./Widget";

export default React.memo(NumberWidget);

function NumberWidget({ number: numberString, label, unit, ...others }) {
  const number = Math.round(Number(numberString));
  const hasNumber = number !== null && !Number.isNaN(number);

  return (
    <Widget
      {...others}
      style={{
        ...others.style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: 20,
        }}
      >
        <div style={{ fontSize: 32, margin: "5px 10px" }}>
          {!hasNumber ? <TextLoader width={50} /> : number}
        </div>
        <div>{unit}</div>
      </div>
    </Widget>
  );
}
