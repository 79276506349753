export const canvasDocumentRoute = `/canvas/:documentId(D\\w{12})`;
export const canvasPreviewableRoute = `/canvas/previewable/:previewableId`;
export const teamRoute = `/:teamId(T\\w{12})`;
export const itemRoute = `${teamRoute}/:itemId([IDF]\\w{12})`;
export const folderRoute = `${teamRoute}/:folderId(F\\w{12})`;
export const fileRoute = `${teamRoute}/:fileId(I\\w{12})`;
export const documentRoute = `${teamRoute}/:documentId(D\\w{12})`;
export const groupRoute = `${teamRoute}/:groupId(G\\w{12})`;
export const userRoute = `${teamRoute}/:userId(M\\w{12})`;
export const dataQueryRoute = `${teamRoute}/:dataQueryId(DQ\\w{12})`;
export const picklistRoute = `${teamRoute}/:picklistId(PICKLIST\\w{12})`;
export const chatRoute = `${teamRoute}/:chatId(CHAT\\w{12})`;
export const companyRoute = `${teamRoute}/:companyId(COMPANY\\w{12})`;
export const pTemplateRoute = `${teamRoute}/:pTemplateId(PTEMPLATE\\w{12})`;
export const companyRegistrationRoute = `${teamRoute}/:companyRegistrationId(COMPANYREG\\w{12})`;
export const shareRoute = "/shares/:shareToken";
export const companyRegisterRoute = "/company_register/:token";
