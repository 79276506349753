import { createConsumer } from "@rails/actioncable";

import { API_ORIGIN } from "./config";

let _cable;

const cable = () => {
  if (_cable) return _cable;
  const token = window._fmsToken;
  _cable = createConsumer(
    `${API_ORIGIN.replace(/^http/, "ws")}/cable?token=${encodeURIComponent(
      token,
    )}`,
  );

  return _cable;
};

export default cable;
