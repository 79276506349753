import { useLocation, matchPath } from "react-router-dom";

export default function useRoute(route, { optional = false } = {}) {
  const { pathname } = useLocation();
  const routeMatch = matchPath(pathname, { path: route });

  if (!routeMatch && !optional)
    throw new Error(`${pathname} does not match route: ${route}`);

  return routeMatch?.params || {};
}
