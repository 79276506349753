import React from "react";

export default React.memo(Rect);
function Rect({
  left,
  top,
  width,
  height,
  borderStyle = "solid",
  borderColor,
  borderWidth = 2,
  showSize = false,
  ...others
}) {
  return (
    <div
      data-rect-top={top}
      data-rect-left={left}
      data-rect-width={width}
      data-rect-height={height}
      {...others}
      style={{
        position: "absolute",
        left: `calc(${left}mm - ${borderWidth}px / 2)`,
        top: `calc(${top}mm - ${borderWidth}px / 2)`,
        width: `calc(${width}mm - ${borderWidth}px)`,
        height: `calc(${height}mm - ${borderWidth}px)`,
        boxSizing: "content-box",
        borderWidth: `${borderWidth}px`,
        borderStyle,
        borderColor,
        ...others.style,
      }}
    >
      {showSize && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            userSelect: "none",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 10,
          }}
        >
          <span
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            {left}mm,{top}mm
          </span>
          <span>
            {width}mm x {height}mm
          </span>
        </div>
      )}
      {others.children}
    </div>
  );
}
