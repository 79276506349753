import React from "react";
import { Tabs, Tab, Badge } from "@mui/material";

import useRouteState from "hooks/useRouteState";
import { IS_PRODUCTION } from "helpers/config";

export default function AppTabs({ tabs, loading, noUrl = false }) {
  const [currentTabName, currentTabNameSet] = useRouteState("tab", { noUrl });
  let currentTab = currentTabName
    ? tabs.find((t) => t.name === currentTabName)
    : tabs[0];
  if (IS_PRODUCTION || loading) currentTab ||= tabs[0];
  if (!currentTab) throw new Error(`Invalid tab: ${currentTabName}`);

  return (
    <>
      <Tabs
        value={currentTab.name}
        onChange={(event, value) => currentTabNameSet(value)}
        variant="scrollable"
        style={{ marginBottom: 5 }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.name}
            disabled={tab.disabled}
            label={
              <>
                {[undefined, null].includes(tab.badgeContent) ? (
                  <>
                    {tab.icon}
                    {tab.title}
                  </>
                ) : (
                  <Badge color="primary" badgeContent={tab.badgeContent}>
                    {tab.icon}
                    {tab.title}
                  </Badge>
                )}
              </>
            }
            value={tab.name}
          />
        ))}
      </Tabs>
      {currentTab.content}
    </>
  );
}
