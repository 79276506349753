import React from "react";
import { gql } from "@apollo/client";
import { Tag } from "mdi-material-ui";

import { useRoute, useData } from "hooks";
import { documentRoute } from "helpers";
import { Table, Message, TabContent } from "controls";

import { getFieldType } from "./FIELD_TYPES";

export default function DataTab() {
  const { documentId } = useRoute(documentRoute);
  const data = useData(
    gql`
      query DataTab($documentId: ID!) {
        document(id: $documentId) {
          id
          canFill
          canViewDocument
          fields {
            id
            label
            fieldType
            stringValue
          }
        }
      }
    `,
    { documentId },
  );

  return (
    <TabContent>
      {data?.document.canViewDocument === false ? (
        <Message type="warning">没有权限查看该文档内容</Message>
      ) : (
        <>
          <Message message="这里包含该文档中的所有数据字段，您也可以切换到“填写”模式后在此处填改数据。" />
          <Table
            columns={[
              {
                label: "名称",
                sortable: true,
              },
              {
                label: "值",
                width: 400,
              },
              {
                label: "字段类型",
              },
            ]}
            rows={data?.document.fields?.map((field) => ({
              id: field.id,
              title: field.label,
              icon: <Tag />,
              values: [
                field.label,
                field.stringValue,
                getFieldType(field.fieldType)?.label,
              ],
            }))}
          />
        </>
      )}
    </TabContent>
  );
}
