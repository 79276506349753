import React from "react";
import gql from "graphql-tag";

import ObjectCard from "controls/ObjectCard";

import ObjectIcon from "./ObjectIcon";
import ActivityCard, { ActivityCardFragment } from "./ActivityCard";
import ItemCard, { ItemCardFragment } from "./ItemCard";

export const SearchableCardFragment = gql`
  fragment SearchableCardFragment on Searchable {
    id
    name
    selfUnion {
      ... on Activity {
        ...ActivityCardFragment
      }
      ... on Item {
        ...ItemCardFragment
      }
      ... on Record {
        id
      }
    }
  }
  ${ItemCardFragment}
  ${ActivityCardFragment}
`;

export default function SearchableCard({ searchable, ...others }) {
  if (searchable.__typename === "Activity")
    return <ActivityCard activity={searchable.selfUnion} {...others} />;
  if (["File", "Folder", "Document"].includes(searchable.__typename))
    return <ItemCard item={searchable.selfUnion} {...others} />;
  return (
    <ObjectCard
      icon={
        <ObjectIcon
          objectId={searchable.id}
          objectType={searchable.__typename}
        />
      }
      title={searchable.name}
      {...others}
    />
  );
}
