import React from "react";
import { gql } from "@apollo/client";

import useData from "hooks/useData";

import Items, { ItemInput_ItemsFragment } from "./Items";

export default function FolderResult({
  folderId,
  itemTypes,
  onItemSelect,
  selectedItemId,
}) {
  const data = useData(
    gql`
      query ItemsList($folderId: ID!, $itemTypes: [String!]) {
        folder(id: $folderId) {
          id
          items(itemTypes: $itemTypes) {
            id
            ...ItemInput_ItemsFragment
          }
        }
      }
      ${ItemInput_ItemsFragment}
    `,
    { folderId, itemTypes },
  );

  return (
    <Items
      items={data?.folder.items}
      onItemSelect={onItemSelect}
      selectedItemId={selectedItemId}
    />
  );
}
