import React from "react";
import gql from "graphql-tag";
import { Pencil, Delete } from "mdi-material-ui";

import { documentRoute, showMessage } from "helpers";
import {
  useRoute,
  useData,
  useAction,
  useFormDialog,
  useDialogOpener,
} from "hooks";
import {
  Table,
  FileSizeCell,
  DateTimeCell,
  UsersCell,
  TabContent,
  FileIcon,
  Message,
} from "controls";
import { ItemObjectIcon } from "blocks";
import { ItemInput, FileUploadsInput } from "inputs";
import RenameItemDialog from "views/folders/RenameItemDialog";
import RemoveItemsDialog from "views/folders/RemoveItemsDialog";

export default function AttachmentsTab() {
  const openRenameItemDialog = useDialogOpener(RenameItemDialog);
  const openRemoveItemsDialog = useDialogOpener(RemoveItemsDialog);
  const { documentId, teamId } = useRoute(documentRoute);
  const formDialog = useFormDialog();

  const data = useData(
    gql`
      query AttachmentsTab($documentId: ID!) {
        document(id: $documentId) {
          id
          attachments {
            id
            name
            size
            canRemove
            canRename
            revisionedAt
            revisionedBy {
              id
            }
          }
          canAddDocumentAttachment
        }
      }
    `,
    { documentId },
  );

  const addDocumentAttachments = useAction(gql`
    mutation AttachmentsTab($input: AddDocumentAttachmentsInput!) {
      addDocumentAttachments(input: $input) {
        document {
          id
          attachments {
            id
          }
        }
      }
    }
  `);

  return (
    <TabContent
      actions={[
        {
          title: "上传表单附件",
          icon: <FileIcon />,
          disabled: !data?.document.canAddDocumentAttachment,
          onClick: () =>
            formDialog({
              title: "上传表单附件",
              fields: [
                {
                  name: "documentId",
                  label: "表单",
                  value: documentId,
                  required: true,
                  inputComponent: ItemInput,
                  options: {
                    itemTypes: ["Document"],
                  },
                },
                {
                  name: "fileUploads",
                  value: [],
                  label: "附件",
                  required: true,
                  inputComponent: FileUploadsInput,
                },
              ],
              onSubmit: async (formData) => {
                await addDocumentAttachments({
                  input: {
                    ...formData,
                  },
                });
                showMessage({ message: "成功上传表单附件" });
              },
            }),
        },
      ]}
    >
      <Message>您可以上传多个任何类型的文档作为该表单的附件。</Message>
      <Table
        columns={[
          {
            label: "名称",
            sortable: true,
          },
          {
            label: "尺寸",
            cellComponent: FileSizeCell,
            sortable: true,
          },
          {
            label: "修订时间",
            cellComponent: DateTimeCell,
            sortable: true,
          },
          {
            label: "修订成员",
            cellComponent: UsersCell,
            width: 150,
          },
        ]}
        rows={data?.document.attachments.map((attachment) => ({
          id: attachment.id,
          title: attachment.name,
          href: `/${teamId}/${attachment.id}`,
          icon: <ItemObjectIcon itemId={attachment.id} />,
          values: [
            attachment.name,
            attachment.size,
            attachment.revisionedAt,
            attachment.revisionedBy && [attachment.revisionedBy.id],
          ],
          actions: [
            {
              label: "更改名称",
              icon: <Pencil />,
              disabled: !attachment.canRename,
              onClick: () => openRenameItemDialog({ itemId: attachment.id }),
            },
            {
              label: "删除",
              icon: <Delete />,
              disabled: !attachment.canRemove,
              onClick: () =>
                openRemoveItemsDialog({ itemIds: [attachment.id] }),
            },
          ],
        }))}
      />
    </TabContent>
  );
}
