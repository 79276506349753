import React from "react";
import { gql } from "@apollo/client";
import { Help } from "mdi-material-ui";
import {
  Avatar,
  Dialog,
  CardHeader,
  DialogActions,
  Button,
} from "@mui/material";

import useRoute from "hooks/useRoute";
import useData from "hooks/useData";
import useOpenChat from "hooks/useOpenChat";
import useDialogOpener from "hooks/useDialogOpener";
import getDialogProps from "helpers/getDialogProps";
import { teamRoute } from "helpers/routes";
import AvatarLoader from "loaders/AvatarLoader";
import embedInMobile from "helpers/embedInMobile";
import FieldsContainer from "controls/FieldsContainer";
import wrapInParen from "helpers/wrapInParen";

export const UserAvatarFragment = gql`
  fragment UserAvatarFragment on User {
    id
    name
    avatarBlobUrl
    avatarColor
  }
`;

export default function UserAvatar({
  userId,
  user,
  triggerUserDialog = false,
  nullUser = false,
  ...others
}) {
  const { teamId } = useRoute(teamRoute);
  const openUserDialog = useDialogOpener(UserDialog);

  const data = useData(
    gql`
      query UserAvatar($userId: ID!) {
        user(id: $userId) {
          id
          ...UserAvatarFragment
        }
      }
      ${UserAvatarFragment}
    `,
    { userId },
    {
      skip: !userId || !!user,
    },
  );

  user ||= data?.user;
  userId ||= user?.id;

  // null-user
  if (nullUser)
    return (
      <Avatar
        {...others}
        style={{
          ...others.style,
        }}
      >
        <Help fontSize="inherit" />
      </Avatar>
    );

  // loading
  if (!user) return <AvatarLoader {...others} />;

  return (
    <Avatar
      {...(triggerUserDialog && {
        component: "a",
        ...(embedInMobile
          ? {
              href: `/${teamId}/${userId || user?.id}`,
            }
          : {
              href: "#",
              onClick: () => openUserDialog({ userId: user.id }),
            }),
      })}
      {...others}
      src={user.avatarBlobUrl}
      style={{
        backgroundColor: user.avatarColor,
        textDecoration: "none",
        ...others.style,
      }}
    >
      {!user.avatarBlobUrl && (
        <>{user.name[0] || <Help fontSize="inherit" />}</>
      )}
    </Avatar>
  );
}

function UserDialog({ userId, onClose }) {
  const openChat = useOpenChat();
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query ($userId: ID!) {
        user(id: $userId) {
          id
          name
          email
          mobile
          companyRoles {
            id
            title
            company {
              id
              name
            }
          }
        }
      }
    `,
    {
      userId,
    },
  );

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      {...getDialogProps()}
    >
      <CardHeader
        avatar={<UserAvatar userId={userId} />}
        title={data?.user.name}
        subheader={data?.user.companyRoles
          .map(
            (companyRole) =>
              `${companyRole.company.name}${wrapInParen(companyRole.title)}`,
          )
          .join(", ")}
      />
      <FieldsContainer
        style={{ margin: 5 }}
        readOnly
        fields={[
          {
            label: "职务",
            value: data?.user.title,
          },
          {
            label: "手机号码",
            value: data?.user.mobile,
          },
          {
            label: "电子邮箱",
            value: data?.user.email,
          },
        ]}
      />
      <DialogActions>
        <Button
          onClick={async () => {
            await openChat({ userIds: [userId] });
          }}
        >
          发送消息
        </Button>
        <Button component="a" href={`/${teamId}/${userId}`}>
          更多信息
        </Button>
        <Button onClick={onClose}>确定</Button>
      </DialogActions>
    </Dialog>
  );
}
