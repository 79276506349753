import React from "react";
import { gql } from "@apollo/client";

import { teamRoute } from "helpers";
import { useData, useRoute } from "hooks";
import SessionsTable, { SessionsTableFragment } from "lists/SessionsTable";

export default function SessionsTab() {
  const { teamId } = useRoute(teamRoute);
  const [sessionsData, sessionsDataMeta] = useData(
    gql`
      query SessionsTab($teamId: ID!, $offset: Int) {
        team(id: $teamId) {
          id
          sessions(offset: $offset, limit: 20) {
            id
            ...SessionsTableFragment
          }
          sessionsCount
        }
      }
      ${SessionsTableFragment}
    `,
    { teamId },
    { returnMeta: true },
  );

  return (
    <SessionsTable
      rows={sessionsData?.team.sessions}
      rowsCount={sessionsData?.team.sessionsCount}
      fetchMore={sessionsDataMeta.fetchMore}
    />
  );
}
