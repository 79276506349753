import React, { useState } from "react";
import { gql } from "@apollo/client";

import { showMessage } from "helpers";
import { useData, useAction } from "hooks";
import { FormDialog } from "controls";
import { FileUploadInput, ItemInput } from "inputs";

export default function ReviseFileDialog({ fileId: fileIdDefault, ...others }) {
  const [fileId, fileIdSet] = useState(fileIdDefault);
  const dataWithFile = useData(
    gql`
      query ReviseFileDialog($fileId: ID!) {
        file(id: $fileId) {
          id
          name
          fileType
        }
      }
    `,
    {
      fileId,
    },
    {
      skip: !fileId,
    },
  );

  const reviseFile = useAction(gql`
    mutation ReviseFileDialog($input: ReviseFileInput!) {
      reviseFile(input: $input) {
        file {
          id
          blobUrl
          blobDownloadUrl
          size
          previewUrl
          previewMode
          revisionedAt
          revisionedBy {
            id
          }
          revisions {
            id
          }
        }
      }
    }
  `);

  return (
    <FormDialog
      {...others}
      title="上传新版本"
      onSubmit={async (formData) => {
        await reviseFile({
          input: {
            fileId,
            ...formData,
          },
        });
        showMessage({
          message: "成功上传新版本",
        });
      }}
      fields={[
        {
          name: "fileId",
          label: "文档",
          inputComponent: ItemInput,
          required: true,
          value: fileIdDefault,
          onChange: fileIdSet,
          options: {
            itemTypes: ["File"],
          },
        },
        {
          name: "fileUpload",
          label: "文件新版本",
          inputComponent: FileUploadInput,
          options: {
            accept: dataWithFile?.file.name.match(/\.[A-z]+$/).toString(),
          },
        },
      ]}
    />
  );
}
