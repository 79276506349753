import React from "react";
import { gql } from "@apollo/client";
import { Paper } from "@mui/material";

import { RadiosInput } from "inputs";
import { teamRoute } from "helpers";
import { useRoute, useData, useDialogOpener } from "hooks";
import AddSignatureDialog from "views/signatures/AddSignatureDialog";

export const SIGNATURE_WIDTH = 250;
export const SIGNATURE_HEIGHT = 80;

export default function SignatureInput({ ...others }) {
  const openAddSignatureDialog = useDialogOpener(AddSignatureDialog);
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query SignatureInput($teamId: ID!) {
        team(id: $teamId) {
          id
          signatures {
            id
            name
            etag
            blobUrl
          }
        }
      }
    `,
    { teamId },
  );

  return (
    <RadiosInput
      {...others}
      options={[
        {
          value: "",
          label: "(无签名/留空)",
        },
        ...(data?.team.signatures.map((signature) => ({
          value: signature.etag,
          label: signature.name,
          thumbnail: (
            <Paper
              component="img"
              src={signature.blobUrl}
              style={{
                width: SIGNATURE_WIDTH,
                height: SIGNATURE_HEIGHT,
              }}
            />
          ),
        })) || []),
      ]}
      helperText={
        <>
          您可以
          <a href="#" onClick={() => openAddSignatureDialog()}>
            增加新签名
          </a>
          <br />
          {others.helperText}
        </>
      }
    />
  );
}
