import { gql } from "@apollo/client";

import { AppView, AppTabs, CompanyIcon } from "controls";
import { useData, useRoute } from "hooks";
import { companyRoute } from "helpers";

import ActivitiesTab from "./ActivitiesTab";
import RolesTab from "./RolesTab";
import InfoTab from "./InfoTab";
import ProjectsTab from "./ProjectsTab";
import CompanyDashboard from "./CompanyDashboard";

export default function CompanyView() {
  const { teamId, companyId } = useRoute(companyRoute);
  const data = useData(
    gql`
      query CompanyView($companyId: ID!) {
        company(id: $companyId) {
          id
          name
        }
      }
    `,
    { companyId },
  );

  return (
    <AppView
      title={data?.company.name}
      crumbs={[
        {
          icon: <CompanyIcon />,
          href: `/${teamId}/companies`,
          label: "单位",
        },
        {
          href: `/${teamId}/${data?.company.name}`,
          label: data?.company.name,
        },
      ]}
    >
      <AppTabs
        tabs={[
          {
            name: "dashboard",
            title: "首页",
            content: <CompanyDashboard />,
          },
          {
            name: "info",
            title: "单位信息",
            content: <InfoTab />,
          },
          {
            name: "activities",
            title: "单位动态",
            content: <ActivitiesTab />,
          },
          {
            name: "roles",
            title: "成员",
            content: <RolesTab />,
          },
          {
            name: "projects",
            title: "项目",
            content: <ProjectsTab />,
          },
        ]}
      />
    </AppView>
  );
}
