import React, { useState, useContext, useRef } from "react";
import { flatMap, noop } from "lodash-es";
import { gql } from "@apollo/client";
import {
  IconButton,
  ClickAwayListener,
  Popper,
  Button,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import {
  Pencil,
  DatabaseSearch,
  InformationVariant,
  Database,
} from "mdi-material-ui";

import { teamRoute } from "helpers/routes";
import Markdown from "controls/Markdown";
import { useRecordContentChannel } from "hooks/useChannel";
import useFormDialog from "hooks/useFormDialog";
import useRoute from "hooks/useRoute";
import showMessage from "helpers/showMessage";
import useAction from "hooks/useAction";
import useData from "hooks/useData";
import { documentContentContext } from "contexts";
import UsePropertyDialog from "views/properties/UsePropertyDialog";
import RadiosInput from "inputs/RadiosInput";
import SuggestiveTextInput from "inputs/SuggestiveTextInput";

import FIELD_TYPES, { getFieldType } from "./FIELD_TYPES";

export const FieldFragment = gql`
  fragment FieldFragment on Field {
    id
    label
    extraOption
    extraOptionEnabled
    options
    content
    selectedOptions
    date
    number

    unit
    scale
    fieldType

    photoBlobUrl
    photoEtag
    photoName
    photoSize

    signatureBlobUrl

    canEdit
    canFill
  }
`;

export default function Field({ field, onUpdate = noop, ...others }) {
  const ref = useRef();
  const { documentContentMode } = useContext(documentContentContext);
  let documentContentModeActual = documentContentMode;
  if (!field.canFill && documentContentMode === "fill")
    documentContentModeActual = "view";
  if (!field.canEdit && documentContentMode === "edit")
    documentContentModeActual = "view";

  useRecordContentChannel("Field", field.id);

  const updateField = useAction(
    gql`
      mutation Field($input: UpdateFieldInput!) {
        updateField(input: $input) {
          field {
            ...FieldFragment
          }
        }
      }
      ${FieldFragment}
    `,
    ({ input: { fieldId, ...fieldAttributes } }) => ({
      progressBarOptions: { unobstrusive: true },
      onCompleted: onUpdate,
      optimisticResponse: {
        updateField: {
          __typename: "UpdateFieldPayload",
          field: {
            ...field,
            ...fieldAttributes,
          },
        },
      },
    }),
  );

  const fieldType = getFieldType(field.fieldType);
  const FieldComponent = fieldType?.component;
  const FieldComponentProps = {
    documentContentMode: documentContentModeActual,
    field,
    updateField,
    ...others,
  };

  const glow = documentContentMode !== "view";

  const [infoOpen, infoOpenSet] = useState(false);

  return (
    <>
      <ClickAwayListener onClickAway={() => infoOpenSet(false)}>
        <div
          data-field-id={field.id}
          ref={ref}
          style={{
            height: "100%",
            whiteSpace: "pre-wrap",
            backgroundColor: glow ? "rgba(0, 255, 255, 0.15)" : undefined,
          }}
          onFocus={() => infoOpenSet(true)}
          tabIndex={0}
        >
          <Popper open={infoOpen} anchorEl={ref.current} placement="top-start">
            <Paper
              elevation={10}
              style={{
                margin: 5,
                width: ref.current?.offsetWidth,
                minWidth: 400,
              }}
            >
              <InfoOverlay fieldId={field.id} />
            </Paper>
          </Popper>
          {FieldComponent && <FieldComponent {...FieldComponentProps} />}
        </div>
      </ClickAwayListener>
    </>
  );
}

function InfoOverlay({ fieldId }) {
  const formDialog = useFormDialog();
  const { documentContentMode } = useContext(documentContentContext);
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query InfoOverlay($fieldId: ID!, $teamId: ID!) {
        field(id: $fieldId) {
          id
          label
          fieldType
          notes
          propertyPath
        }
        team(id: $teamId) {
          id
          picklists {
            id
            name
          }
          projectsPTemplate: pTemplate(ownerType: "Project") {
            id
            pTemplateItems {
              id
              label
            }
          }
          companiesPTemplate: pTemplate(ownerType: "Company") {
            id
            pTemplateItems {
              id
              label
            }
          }
          projectCompanyRoleCounts {
            value
          }
          dataStats {
            id
            tableName
            labels
          }
        }
      }
    `,
    { fieldId, teamId },
  );

  const fieldType = getFieldType(data?.field.fieldType);
  const FieldComponent = fieldType?.component;

  const updateField = useAction(gql`
    mutation ($input: UpdateFieldInput!) {
      updateField(input: $input) {
        field {
          id
          fieldType
          notes
          label
          propertyPath
          ...FieldFragment
        }
      }
    }
    ${FieldFragment}
  `);

  const propertyPathSuggestions = data && [
    "项目/项目名称",
    ...data.team.projectsPTemplate.pTemplateItems.map(
      (pTemplateItem) => `项目/${pTemplateItem.label}`,
    ),
    ...flatMap(data.team.dataStats, (dataStat) =>
      dataStat.labels.map((label) => `表单/${dataStat.tableName}/${label}`),
    ),
    ...flatMap(
      data.team.projectCompanyRoleCounts.map((c) => c.value),
      (role) => [
        `单位/${role}/单位名称`,
        ...data.team.companiesPTemplate.pTemplateItems.map(
          (pTemplateItem) => `单位/${role}/${pTemplateItem.label}`,
        ),
      ],
    ),
    ...data.team.picklists.map((picklist) => `选项列表/${picklist.name}`),
  ];

  return (
    <Table size="small">
      <TableBody>
        {[
          [
            "字段类型",
            fieldType?.icon,
            fieldType?.label,
            "选择字段类型",
            () =>
              formDialog({
                title: "选择字段类型",
                fields: [
                  {
                    name: "fieldType",
                    label: "字段类型",
                    value: fieldType?.name,
                    required: true,
                    inputComponent: RadiosInput,
                    options: {
                      autoFocus: true,
                      options: FIELD_TYPES.map((fieldType) => ({
                        icon: fieldType.icon,
                        label: fieldType.label,
                        value: fieldType.name,
                      })),
                    },
                  },
                ],
                onSubmit: async (formData) => {
                  await updateField({
                    input: { fieldId, ...formData },
                  });
                },
              }),
          ],
          [
            "数据列",
            <Database />,
            data?.field.label || "(无)",
            "修改数据列",
            () =>
              formDialog({
                title: "设置单元格数据列",
                fields: [
                  {
                    name: "label",
                    label: "数据列",
                    value: data?.field.label,
                    helperText: "如果留空则不会被提取到数据表里",
                    options: {
                      autoFocus: true,
                    },
                  },
                ],
                onSubmit: async (formData) => {
                  await updateField({
                    input: { fieldId, ...formData },
                  });
                  showMessage({ message: "成功修改数据列" });
                },
              }),
          ],
          [
            "备注",
            <InformationVariant />,
            <Markdown
              style={{ whiteSpace: "pre-wrap" }}
              source={data?.field.notes || "(无)"}
            />,
            "设置备注",
            () =>
              formDialog({
                title: "设置备注",
                fields: [
                  {
                    label: "备注",
                    name: "notes",
                    value: data?.field.notes,
                    options: {
                      multiline: true,
                      minRows: 5,
                    },
                  },
                ],
                onSubmit: async (formData) => {
                  await updateField({
                    input: { fieldId, ...formData },
                  });
                  showMessage({ message: "成功设置备注" });
                },
              }),
          ],
          [
            "引用路径",
            <DatabaseSearch />,
            data?.field.propertyPath,
            "设置引用路径",
            () =>
              formDialog({
                title: "设置引用路径",
                fields: [
                  {
                    label: "引用路径",
                    name: "propertyPath",
                    value: data?.field.propertyPath,
                    inputComponent: SuggestiveTextInput,
                    options: {
                      suggestions: propertyPathSuggestions,
                    },
                    helperText: (
                      <>
                        支持以下格式
                        <br />
                        项目/[项目属性]
                        <br />
                        表单/[数据表]/[数据字段]
                        <br />
                        单位/[单位类型]/[单位属性]
                        <br />
                        选项列表/[选项列表名称]
                        <br />
                      </>
                    ),
                  },
                ],
                onSubmit: async (formData) => {
                  await updateField({
                    input: { fieldId, ...formData },
                  });
                  showMessage({ message: "成功设置备注" });
                },
              }),
          ],
        ].map(([label, icon, content, editTitle, editOnClick]) => (
          <TableRow key={label}>
            <TableCell style={{ width: 0 }}>{icon}</TableCell>
            <TableCell>{label}</TableCell>
            <TableCell>{content}</TableCell>
            {documentContentMode === "edit" && (
              <TableCell padding="none">
                <IconButton
                  size="small"
                  title={editTitle}
                  onClick={editOnClick}
                >
                  <Pencil />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        ))}
        {documentContentMode === "fill" && (
          <TableRow>
            <TableCell colSpan={100}>
              <Button
                variant="contained"
                fullWidth
                disabled={
                  !FieldComponent?.parseString || !FieldComponent?.toFormData
                }
                onClick={() =>
                  formDialog({
                    formDialogComponent: UsePropertyDialog,
                    fieldId,
                    propertyPath: data?.field.propertyPath,
                    parseString: FieldComponent.parseString,
                    onSubmit: async (value) => {
                      await updateField({
                        input: {
                          fieldId,
                          ...FieldComponent.toFormData(value),
                        },
                      });
                    },
                  })
                }
              >
                <DatabaseSearch />
                引用字段
              </Button>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
