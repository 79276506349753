import { gql } from "@apollo/client";
import { IconButton } from "@mui/material";

import { UserIcon, ChatIcon, AppView, AppTabs } from "controls";
import { userRoute } from "helpers";
import { useRoute, useData, useOpenChat } from "hooks";

import UserProfileTab from "./UserProfileTab";
import FolderPermissionsTab from "./FolderPermissionsTab";
import SessionsTab from "./SessionsTab";
import ActivitiesTab from "./ActivitiesTab";

export default function UserView() {
  const openChat = useOpenChat();
  const { userId, teamId } = useRoute(userRoute);
  const data = useData(
    gql`
      query ($userId: ID!) {
        user(id: $userId) {
          id
          name
          permissions {
            id
          }
          canViewSessions
        }
      }
    `,
    {
      userId,
    },
  );
  return (
    <>
      <AppView
        title={data?.user.name}
        crumbs={[
          {
            icon: <UserIcon />,
            href: `/${teamId}/users`,
            label: "成员",
          },
          {
            href: `/${teamId}/${userId}`,
            label: data?.user.name,
          },
        ]}
        tools={
          <>
            <IconButton
              title="发送消息"
              onClick={() => openChat({ userIds: [userId] })}
              size="large"
            >
              <ChatIcon />
            </IconButton>
          </>
        }
      >
        <AppTabs
          tabs={[
            {
              name: "profile",
              title: "基本资料",
              content: <UserProfileTab />,
            },
            {
              name: "folder-permissions",
              title: "目录权限",
              content: <FolderPermissionsTab />,
              badgeContent: data?.user.permissions.length,
            },
            {
              title: "登录设备",
              name: "sessions",
              content: <SessionsTab />,
              disabled: !data?.user.canViewSessions,
            },
            {
              title: "成员动态",
              name: "activities",
              content: <ActivitiesTab />,
            },
          ]}
        />
      </AppView>
    </>
  );
}
