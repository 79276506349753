import React from "react";
import { gql } from "@apollo/client";
import { Paper, Avatar, AvatarGroup, IconButton } from "@mui/material";
import { Send, Plus } from "mdi-material-ui";

import {
  useData,
  useRoute,
  useActionFragment,
  useOpenChat,
  useFormDialog,
} from "hooks";
import { chatRoute } from "helpers";
import { TextLoader, AvatarLoader } from "loaders";
import { UserAvatar, UserAvatarFragment } from "blocks";
import { ProjectIcon } from "controls";
import { UsersInput } from "inputs";

import MessageForm from "./MessageForm";
import ChatMessages from "./ChatMessages";

export default function Chat({ ...others }) {
  const { chatId, teamId } = useRoute(chatRoute);
  const formDialog = useFormDialog();
  const openChat = useOpenChat();

  const [data] = useData(
    gql`
      query Chat($chatId: ID!) {
        chat(id: $chatId) {
          id
          name
          users {
            id
            name
            ...UserAvatarFragment
          }
          project {
            id
            name
            folder {
              id
            }
          }
        }
      }
      ${UserAvatarFragment}
    `,
    {
      chatId,
    },
    { returnMeta: true },
  );

  const chatAddMessage = useActionFragment("chatAddMessage");

  return (
    <div
      {...others}
      style={{
        ...others.style,
        display: "flex",
        flexFlow: "column nowrap",
      }}
    >
      <Paper
        variant="outlined"
        square
        style={{
          borderStyle: "none none solid none",
          padding: 5,
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
        }}
      >
        <AvatarGroup style={{ margin: 5 }}>
          {!data && <AvatarLoader />}
          {data?.chat.users.map((user) => (
            <UserAvatar key={user.id} user={user} triggerUserDialog />
          ))}
          {data?.chat.project && (
            <Avatar
              key={data?.chat.project.id}
              component="a"
              href={`/${teamId}/${data.chat.project.folder.id}`}
            >
              <ProjectIcon />
            </Avatar>
          )}
        </AvatarGroup>
        <div
          style={{
            flex: "1 1 auto",
            width: 0,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {!data && <TextLoader />}
          {data?.chat.name}
          {!!data?.chat.users.length && (
            <IconButton
              title="邀请其他成员加入讨论"
              onClick={() =>
                formDialog({
                  title: "邀请其他成员加入讨论",
                  fields: [
                    {
                      name: "userIds",
                      label: "成员",
                      value: [],
                      required: true,
                      inputComponent: UsersInput,
                    },
                  ],
                  onSubmit: async ({ userIds }) => {
                    openChat({
                      userIds: [
                        ...data.chat.users.map((u) => u.id),
                        ...userIds,
                      ],
                    });
                  },
                })
              }
            >
              <Plus />
            </IconButton>
          )}
        </div>
      </Paper>
      <ChatMessages style={{ flex: "1 1 auto" }} />
      <MessageForm
        style={{ margin: 10 }}
        submitIcon={<Send />}
        submitLabel="发送"
        onSubmit={(message) =>
          chatAddMessage(
            {
              input: {
                chatId,
                ...message,
              },
            },
            {
              progressBarOptions: { unobstrusive: true },
            },
          )
        }
      />
    </div>
  );
}
