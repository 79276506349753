import React from "react";
import { gql } from "@apollo/client";

import { fileRoute } from "helpers";
import { useRoute, useData } from "hooks";
import { AppTabs } from "controls";
import RevisionsTab from "views/items/RevisionsTab";
import ActivitiesTab from "views/items/ActivitiesTab";

import ContentTab from "./ContentTab";

export default function FileView() {
  const { fileId } = useRoute(fileRoute);
  const data = useData(
    gql`
      query ($fileId: ID!) {
        file(id: $fileId) {
          id
          revisions {
            id
          }
        }
      }
    `,
    { fileId },
  );

  return (
    <AppTabs
      tabs={[
        {
          title: "内容",
          name: "content",
          content: <ContentTab />,
        },
        {
          title: "历史版本",
          name: "revisions",
          content: <RevisionsTab itemId={fileId} />,
          badgeContent: data?.file.revisions.length,
        },
        {
          title: "文件动态",
          name: "activities",
          content: <ActivitiesTab itemId={fileId} />,
        },
      ].filter((t) => t)}
    />
  );
}
