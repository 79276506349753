import { remove, defer } from "lodash-es";

import UserError from "./UserError";

class Queue {
  constructor({ concurrency = 1 } = {}) {
    Object.assign(this, { concurrency, runningList: [], waitingList: [] });
  }

  addTask(task) {
    if (this.runningList.length >= this.concurrency) {
      task.abortFunc = () => {
        remove(this.waitingList, task);
        task.reject(new UserError("已取消", { isAbortError: true }));
      };
      task.abortFuncs.push(task.abortFunc);
      return this.waitingList.push(task);
    }

    // run task
    this.runningList.push(task);
    remove(task.abortFuncs, (f) => f === task.abortFunc);
    Promise.resolve()
      .then(async () => await task.func())
      .then((result) => defer(() => task.resolve(result)))
      .catch((error) => defer(() => task.reject(error)))
      .then(() => {
        remove(this.runningList, task);
        const waitingTask = this.waitingList.shift();
        if (!waitingTask) return;
        this.addTask(waitingTask);
      });
  }

  async execute({ abortFuncs = [], func }) {
    return await new Promise((resolve, reject) => {
      this.addTask({
        resolve,
        reject,
        abortFuncs,
        func,
      });
    });
  }
}

export default Queue;
