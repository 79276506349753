import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

DateTime.propTypes = {
  value: PropTypes.string,
};

export function formatTimeStamp(value) {
  if (!value) return value;
  const valueM = moment(value);
  return valueM.isSame(new Date(), "year")
    ? valueM.calendar(null, {
        sameDay: "LT",
        nextDay: "[明天]LT",
        nextWeek: "ddddLT",
        lastDay: "[昨天]LT",
        lastWeek: "ddddLT",
        sameElse: "MMMDoLT",
      })
    : valueM.format("LL");
}

export function formatFromNow(value) {
  if (!value) return value;
  const valueM = moment(value);
  return valueM.fromNow();
}

export function formatDate(value) {
  if (!value) return value;
  const valueM = moment(value);
  return valueM.isSame(new Date(), "year")
    ? valueM.calendar(null, {
        sameDay: "今天",
        nextDay: "[明天]",
        nextWeek: "dddd",
        lastDay: "[昨天]",
        lastWeek: "dddd",
        sameElse: "MMMDo",
      })
    : valueM.format("LL");
}

export default function DateTime({ value, variant = "timestamp", ...others }) {
  if (!value)
    return (
      <span {...others}>
        <>---</>
      </span>
    );

  let brev;
  let full = moment(value).format("LLL");
  if (variant === "timestamp") brev = formatTimeStamp(value);
  else if (variant === "fromNow") brev = formatFromNow(value);
  else if (variant === "date") {
    brev = formatDate(value);
    full = moment(value).format("LL");
  }

  return (
    <span title={full} {...others}>
      {brev || full}
    </span>
  );
}
