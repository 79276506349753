import React from "react";
import { gql } from "@apollo/client";
import { Delete, FileRestore } from "mdi-material-ui";

import { useRoute, useFilterBar, useData, useDialogOpener } from "hooks";
import { teamRoute } from "helpers";
import { Table, DateTimeCell, UsersCell, LinkCell } from "controls";
import { TODAY } from "filters/DateRangeFilter";

import RestoreDeletedItemDialog from "./RestoreDeletedItemDialog";

export default React.memo(TrashbinTab);

function TrashbinTab() {
  const { teamId } = useRoute(teamRoute);
  const openRestoreDeletedItemDialog = useDialogOpener(
    RestoreDeletedItemDialog,
  );
  const [{ deletedAtRange }, filterBar] = useFilterBar([
    {
      type: "date_range",
      name: "deletedAtRange",
      label: "删除日期",
      defaultValue: TODAY,
      alwaysEnabled: true,
    },
  ]);

  const data = useData(
    gql`
      query TrashbinTab($teamId: ID!, $deletedAtRange: DateRange!) {
        team(id: $teamId) {
          id
          deletedItems(deletedAtRange: $deletedAtRange) {
            id
            name
            user {
              id
            }
            folderLinkPath
            folderLinkTitle
            deletedAt
          }
        }
      }
    `,
    {
      teamId,
      deletedAtRange,
    },
  );

  return (
    <>
      {filterBar}
      <Table
        columns={[
          {
            label: "名称",
          },
          {
            label: "删除时间",
            cellComponent: DateTimeCell,
          },
          {
            label: "成员",
            cellComponent: UsersCell,
          },
          {
            label: "原路径",
            cellComponent: LinkCell,
          },
        ]}
        rows={data?.team.deletedItems.map((deletedItem) => ({
          title: deletedItem.name,
          icon: <Delete />,
          values: [
            deletedItem.name,
            deletedItem.deletedAt,
            [deletedItem.user?.id].filter((i) => i),
            {
              title: deletedItem.folderLinkTitle,
              href: `/${teamId}${deletedItem.folderLinkPath}`,
            },
          ],
          actions: [
            {
              icon: <FileRestore />,
              label: "恢复",
              onClick: () =>
                openRestoreDeletedItemDialog({ deletedItemId: deletedItem.id }),
            },
          ],
        }))}
      />
    </>
  );
}
