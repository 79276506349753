// eslint-disable-next-line import/order
import "./patches/polyfills";
import "./patches/consolePatch";

import React from "react";
import ReactDOM from "react-dom/client";
import moment from "moment";
import QueryString from "query-string";
import "moment/locale/zh-cn";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-datepicker/dist/react-datepicker.css";

import App from "./App";
import { DESKTOP_BUILD, PUBLIC_URL } from "./helpers/config";
import "./styles/index.css";
import "./styles/loader.css";
import "./styles/fonts.css";
import "./styles/markdown.css";
import reportWebVitals from "./reportWebVitals";
import testPatch from "./patches/testPatch";
import errorHandlePatch from "./patches/errorHandlePatch";
import { embedInMobile } from "helpers";

moment.locale("zh-cn");

testPatch();
errorHandlePatch();

window._fmsToken = localStorage.getItem("token");

const params = QueryString.parse(window.location.search);
if (params.token) {
  window._fmsToken = params.token;
  window._fmsTokenPersonified = true;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

if (embedInMobile)
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      ready: true,
    }),
  );

if (!DESKTOP_BUILD && !embedInMobile) {
  reportWebVitals();
  if (
    window.location.origin.toLowerCase() !== PUBLIC_URL &&
    PUBLIC_URL.startsWith("http") // invalid PUBLIC_URL, e.g. in yarn start
  ) {
    const newUrl =
      PUBLIC_URL +
      window.location.href.substring(window.location.origin.length);
    window.location.href = newUrl;
  }
}
