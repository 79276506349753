import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

export default React.memo(DebouncedInput);

function noop() {}

function DebouncedInput({
  component: Component = "input",
  value,
  onChange,
  delay = 300,
  inputRef,
  onFocus = noop,
  onBlur = noop,
  ...others
}) {
  const [focused, focusedSet] = useState(false);
  const [valueInput, valueInputSet] = useState(value || "");
  const [valueInputDebounced] = useDebounce(valueInput, delay);

  useEffect(() => {
    if (focused && value !== valueInputDebounced) onChange(valueInputDebounced);
  }, [focused, value, valueInputDebounced]);

  useEffect(() => {
    if (!focused && value !== valueInput) valueInputSet(value);
  }, [focused, value, valueInput]);

  return (
    <Component
      {...others}
      value={valueInput}
      onChange={(event) => valueInputSet(event.currentTarget.value)}
      ref={inputRef}
      onFocus={(...args) => {
        focusedSet(true);
        onFocus(...args);
      }}
      onBlur={(...args) => {
        focusedSet(false);
        onBlur(...args);
      }}
    />
  );
}
