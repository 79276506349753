import { useState } from "react";
import { useLocation } from "react-router-dom";
import QueryString from "query-string";

import { IS_PRODUCTION } from "helpers/config";

import useHistory from "./useHistory";

export default function useRouteState(
  propName,
  {
    defaultValue = null,
    serialize = (v) => v,
    deserialize = (v) => v,
    validator = () => true,
    noUrl = false,
    push = false,
  } = {},
) {
  if (noUrl) return useState(defaultValue);
  const location = useLocation();
  const history = useHistory();

  const params = QueryString.parse(location.search.replace(/^\?/, ""));
  let value = params[propName];
  value = [undefined, null].includes(value) ? defaultValue : deserialize(value);
  if (!validator(value)) {
    if (!IS_PRODUCTION) throw new Error(`${propName} of ${value} is invalid.`);
    value = defaultValue;
  }

  const getUrl = (newValue) => {
    const newParams = {
      ...QueryString.parse(window.location.search.replace(/^\?/, "")),
    };
    if ([undefined, null, defaultValue].includes(newValue)) {
      delete newParams[propName];
    } else {
      if (!validator(newValue)) {
        if (!IS_PRODUCTION)
          throw new Error(`${propName} of ${newValue} is invalid.`);
        return;
      }
      const newValueString = serialize(newValue);
      newParams[propName] = newValueString;
    }

    const pathnameNew = !Object.keys(newParams).length
      ? location.pathname
      : `${location.pathname}?${QueryString.stringify(newParams)}`;

    return pathnameNew;
  };

  const setter = (newValue) => {
    const pathnameNew = getUrl(newValue);
    if (push) history.push(pathnameNew);
    else history.replace(pathnameNew);
  };

  return [value, setter, getUrl];
}

export function useBooleanRouteState(propName, options) {
  return useRouteState(propName, {
    defaultValue: false,
    serialize: (v) => (v ? "yes" : ""),
    deserialize: (v) => !!v,
    validator: (v) => [true, false].includes(v),
    ...options,
  });
}
