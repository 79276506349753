import React from "react";
import { times, flatMap } from "lodash-es";
import { Divider } from "@mui/material";

import CardLoader from "loaders/CardLoader";
import Message from "controls/Message";

import DateTime, { formatDate } from "./DateTime";
import FetchMoreButton from "./FetchMoreButton";

export default function CardsList({
  fetchMore,
  rows,
  rowsCount,
  cardPropName,
  cardComponent,
  cards,
  cardWidth = 400,
  getRowDate = () => null,
  children,
  ...others
}) {
  let entries = cards?.map((card) => ({
    card,
  }));

  if (cardPropName && cardComponent) {
    entries = rows?.map((row) => ({
      date: getRowDate(row) || null,
      card: React.cloneElement(cardComponent, {
        key: row.id,
        [cardPropName]: row,
      }),
      row,
    }));
  }

  let dateStrCurrent = null;
  return (
    <div
      {...others}
      style={{
        flex: "1 1 auto",
        ...others.style,
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, minmax(${cardWidth}px, 1fr))`,
          gridAutoFlow: "row dense",
        }}
      >
        {!entries && !children && times(5).map((i) => <CardLoader key={i} />)}
        {children}
        {entries?.length === 0 && (
          <Message
            style={{
              gridColumnStart: 1,
              gridColumnEnd: -1,
            }}
          >
            没有可显示的项目
          </Message>
        )}
        {flatMap(entries, ({ card, date, row }) => {
          const dateStr = formatDate(date);
          if (dateStr === dateStrCurrent) return [card];

          dateStrCurrent = dateStr;
          return [
            date && (
              <div
                key={`${row.id}_date`}
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: -1,
                  padding: 10,
                }}
              >
                <DateTime
                  value={date}
                  variant="date"
                  style={{
                    fontSize: "1.4em",
                    lineHeight: 2,
                    opacity: 0.5,
                  }}
                />
                <Divider />
              </div>
            ),
            card,
          ];
        })}
        {fetchMore && (
          <FetchMoreButton
            style={{
              gridColumnStart: 1,
              gridColumnEnd: -1,
            }}
            fetchMore={fetchMore}
            rows={rows}
            rowsCount={rowsCount}
          />
        )}
      </div>
    </div>
  );
}
