import { useContext } from "react";
import { gql } from "@apollo/client";
import { Badge } from "@mui/material";
import {
  Power,
  Update,
  HelpCircleOutline,
  LocationExit,
  SwapHorizontal,
  Cog,
  Gift,
  Android,
  AppleIos,
  AppleFinder,
  MicrosoftWindows,
} from "mdi-material-ui";

import SelectTeamDialog from "dialogs/SelectTeamDialog";
import { viewportContext, authContext, desktopStateContext } from "contexts";
import { UserAvatar } from "blocks";
import { showMessage, teamRoute, DESKTOP_BUILD } from "helpers";
import { useRoute, useDialogOpener, useData } from "hooks";
import { ButtonMenu } from "controls";

import UISettingsDialog from "./UISettingsDialog";
import ChangelogDialog from "./ChangelogDialog";

function versionGt(version1, version2) {
  const v1 = version1.split(".").map((v) => parseInt(v));
  const v2 = version2.split(".").map((v) => parseInt(v));
  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    if (v1[i] > v2[i]) return true;
    if (v1[i] < v2[i]) return false;
  }
  return false;
}

export default function UserMenu() {
  const openUISettingsDialog = useDialogOpener(UISettingsDialog);
  const openChangelogDialog = useDialogOpener(ChangelogDialog);
  const { deauthenticate } = useContext(authContext);
  const { teamId } = useRoute(teamRoute);
  const { denseView } = useContext(viewportContext);
  const openSelectTeamDialog = useDialogOpener(SelectTeamDialog);
  const desktopState = useContext(desktopStateContext);

  let platform = "unknown";
  if (navigator.platform.match(/mac/i)) platform = "mac";
  if (navigator.platform.match(/win/i)) platform = "win";
  const data = useData(
    gql`
      query UserMenu($teamId: ID!, $platform: String!) {
        userLogin {
          id
          login
          supportIssuesOpenCount: supportIssuesCount(open: true)
        }
        team(id: $teamId) {
          id
          name
          user {
            id
          }
        }
        unreadChangelogPostsCount: changelogPostsCount(unread: true)
        resources {
          id
          href
          title
        }
        clientApps {
          platform
          version
          url
        }
        clientApp(platform: $platform) {
          version
          url
        }
      }
    `,
    { teamId, platform },
  );

  const newDesktopVersion =
    desktopState?.version &&
    data?.clientApp?.version &&
    versionGt(data.clientApp.version, desktopState.version)
      ? data.clientApp.version
      : null;

  const updateAction = {
    icon: <Update />,
    title: "桌面客户端已是最新",
    disabled: true,
    description: (
      <>
        当前版本: {desktopState?.version}
        {newDesktopVersion && <> (最新版本: {newDesktopVersion})</>}
      </>
    ),
  };

  if (desktopState?.autoUpdateStatus === "checking-for-update")
    Object.assign(updateAction, {
      title: "正在检查更新",
    });
  else if (desktopState?.autoUpdateStatus === "update-available")
    Object.assign(updateAction, {
      title: "已有更新版本，正在下载",
    });
  else if (desktopState?.autoUpdateStatus === "update-downloaded")
    Object.assign(updateAction, {
      title: "新版本已下载，点此安装",
      badgeContent: 1,
      onClick: () => window.fmsDesktop.command("installAutoUpdate"),
      disabled: false,
    });
  else if (newDesktopVersion)
    Object.assign(updateAction, {
      title: "桌面客户端已更新，点此下载",
      badgeContent: 1,
      href: data?.clientApp?.url,
    });

  return (
    <ButtonMenu
      size={denseView ? "small" : undefined}
      edge="end"
      color="inherit"
      title="当前用户"
      actionGroups={[
        data?.resources.map((resource) => ({
          title: resource.title,
          href: resource.href,
          description: new URL(resource.href).hostname,
        })),
        !DESKTOP_BUILD &&
          data?.clientApps.map((clientApp) => ({
            icon: {
              android: <Android />,
              ios: <AppleIos />,
              win: <MicrosoftWindows />,
              mac: <AppleFinder />,
            }[clientApp.platform],
            title: {
              android: "安卓App",
              ios: "iOS App",
              win: "Windows 桌面客户端",
              mac: "Mac 桌面客户端",
            }[clientApp.platform],
            href: clientApp.url,
            description: <>版本: {clientApp.version}</>,
          })),
        [
          {
            icon: <Gift />,
            title: "产品日志",
            badgeContent: data?.unreadChangelogPostsCount,
            onClick: () => openChangelogDialog(),
          },
          !DESKTOP_BUILD && {
            icon: <HelpCircleOutline />,
            title: "帮助支持",
            badgeContent: data?.userLogin?.supportIssuesOpenCount,
            href: "/support",
          },
          {
            icon: <Cog />,
            title: "用户设置",
            onClick: () => openUISettingsDialog(),
          },
          {
            icon: <SwapHorizontal />,
            title: "切换团队",
            description: <>当前团队: {data?.team.name}</>,
            onClick: () => openSelectTeamDialog(),
          },
          {
            icon: <LocationExit />,
            title: "登出",
            description: <>当前登录为: {data?.userLogin?.login}</>,
            onClick: async () => {
              await deauthenticate();
              showMessage({ message: "已登出" });
            },
          },
        ],
        DESKTOP_BUILD && [
          updateAction,
          {
            icon: <Power />,
            title: "退出桌面客户端",
            onClick: () => window.fmsDesktop?.command("quit"),
          },
        ],
      ]}
    >
      <Badge
        badgeContent={
          (updateAction.badgeContent || 0) +
          (data?.unreadChangelogPostsCount || 0) +
          (data?.userLogin?.supportIssuesOpenCount || 0)
        }
        color="secondary"
      >
        <UserAvatar
          style={{ width: 24, height: 24, fontSize: 16 }}
          userId={data?.team.user.id}
        />
      </Badge>
    </ButtonMenu>
  );
}
