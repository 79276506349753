import { useLayoutEffect, useState } from "react";
import { uniq } from "lodash-es";
import {
  Dialog,
  DialogContent,
  Chip,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { Plus } from "mdi-material-ui";
import { gql } from "@apollo/client";

import { useData, useFormDialog, useActionFragment, useRoute } from "hooks";
import { getDialogProps, teamRoute } from "helpers";
import { ListLoader } from "loaders";
import { SuggestiveTextInput } from "inputs";

export default function MultiInputDialog({ propertyId, onClose, ...others }) {
  const [values, valuesSet] = useState([]);
  const formDialog = useFormDialog();
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query MultiInputDialog($propertyId: ID!) {
        property(id: $propertyId) {
          id
          label
          values
          owner {
            id
            __typename
          }
        }
      }
    `,
    { propertyId },
  );

  const suggestData = useData(
    gql`
      query MultiInputDialog(
        $teamId: ID!
        $label: String!
        $ownerType: String!
      ) {
        team(id: $teamId) {
          id
          propertyValueCounts(label: $label, ownerType: $ownerType) {
            value
            count
          }
        }
      }
    `,
    {
      teamId,
      label: data?.property.label,
      ownerType: data?.property.owner.__typename,
    },
    { skip: !data },
  );

  useLayoutEffect(() => {
    if (data) valuesSet(data.property.values);
  }, [data]);

  const propertyFill = useActionFragment(
    "propertyFill",
    "property { id values }",
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      {...others}
      {...getDialogProps()}
    >
      <DialogTitle>填写“{data?.property.label}”</DialogTitle>
      {!data && <ListLoader subheaders />}
      <DialogContent>
        {values.map((value) => (
          <Chip
            style={{ maginTop: 2, marginRight: 5 }}
            key={value}
            onDelete={() => valuesSet(values.filter((v) => v !== value))}
            label={value}
          />
        ))}
        <Chip
          component="a"
          href="#"
          style={{ maginTop: 2, marginRight: 5 }}
          icon={<Plus />}
          label="新增值"
          onClick={() =>
            formDialog({
              title: "新增值",
              fields: [
                {
                  label: "新值",
                  name: "value",
                  required: true,
                  inputComponent: SuggestiveTextInput,
                  options: {
                    autoFocus: true,
                    suggestions: suggestData?.team.propertyValueCounts.filter(
                      (c) => !values.includes(c.value),
                    ),
                  },
                },
              ],
              onSubmit: ({ value }) => valuesSet(uniq([...values, value])),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await propertyFill({
              input: { propertyId, values },
            });
            onClose();
          }}
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
