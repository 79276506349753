import { useContext, useId, useState, useLayoutEffect } from "react";

import { documentActionsContext } from "contexts";

const useDocumentActions = function (category, documentActions) {
  const id = useId();
  const { documentActionsSet } = useContext(documentActionsContext);

  const [focused, focusedSet] = useState(false);
  const handleFocus = () => focusedSet(true);
  const handleBlur = () => focusedSet(false);

  const addActions = () => {
    const newActions = documentActions.map((a) => ({
      ...a,
      id,
      category,
      group: a.group || a.title,
    }));
    documentActionsSet((aa) => [
      ...aa.filter(
        (a) => a.id !== id && !newActions.find((na) => na.group === a.group),
      ),
      ...newActions,
    ]);
  };

  const removeActions = () =>
    documentActionsSet((aa) => aa.filter((a) => a.id !== id));

  useLayoutEffect(() => {
    if (focused) addActions();
    else removeActions();
    return () => {
      removeActions();
    };
  }, [focused, category, documentActions]);
  return {
    focused,
    handleFocus,
    handleBlur,
  };
};

export default useDocumentActions;
