import React from "react";
import { ButtonGroup, Button } from "@mui/material";

import useRouteState from "./useRouteState";

const DEFAULT_NAME = "_DEFAULT";

export default function useModeSwitcher(
  paramName,
  modes = [],
  { remember = false, noUrl = false } = {},
) {
  const rememberKey = `_fms_useModeSwitcher_mode_${paramName}`;
  let [modeName, modeNameSet] = useRouteState(paramName, {
    noUrl,
    defaultValue: DEFAULT_NAME,
    validator: (modeName) =>
      [DEFAULT_NAME, ...modes.map((m) => m.name)].includes(modeName),
  });

  if (modeName === DEFAULT_NAME) {
    modeName = null;
    if (remember) modeName = window[rememberKey];
    modeName ||= modes[0]?.name;
  }

  const switcher = (
    <ButtonGroup style={{ margin: 5 }}>
      {modes.map((mode) => (
        <Button
          key={mode.name}
          variant={mode.name === modeName ? "contained" : "outlined"}
          onClick={() => {
            modeNameSet(mode.name);
            if (remember) window[rememberKey] = mode.name;
          }}
          disabled={mode.disabled}
        >
          {mode.icon}
          {mode.title}
        </Button>
      ))}
    </ButtonGroup>
  );

  return [modeName, switcher, modeNameSet];
}
