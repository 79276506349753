import { API_ORIGIN } from "./config";

export default async function downloadFromAPI(apiName, params = {}) {
  const token = window._fmsToken;
  const form = document.createElement("form");
  form.action = `${API_ORIGIN}/api/${apiName}`;
  form.method = "post";
  document.body.appendChild(form);

  for (const [key, value] of Object.entries({ ...params, token })) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  form.submit();
  document.body.removeChild(form);
}
