import React from "react";
import gql from "graphql-tag";

import { teamRoute } from "helpers";
import { useRoute, useData, useActivitiesFilterBar, useList } from "hooks";
import { ActivityCard, ActivityCardFragment } from "blocks";
import { TabContent, CardsList } from "controls";
import ActivitiesTable, {
  ActivitiesTableFragment,
} from "lists/ActivitiesTable";

export default function ActivitiesTab() {
  const { teamId } = useRoute(teamRoute);
  const [filter, filterBar] = useActivitiesFilterBar({ hideUserFilter: true });
  const [data, { fetchMore }] = useData(
    gql`
      query ActivitiesTab(
        $teamId: ID!
        $offset: Int
        $filter: ActivitiesFilter!
      ) {
        team(id: $teamId) {
          id
          user {
            id
            activities(offset: $offset, limit: 10, filter: $filter) {
              id
              createdAt
              ...ActivityCardFragment
              ...ActivitiesTableFragment
            }
            activitiesCount(filter: $filter)
          }
        }
      }
      ${ActivityCardFragment}
      ${ActivitiesTableFragment}
    `,
    { teamId, filter },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("activity", {
    rows: data?.team.user.activities,
    rowsCount: data?.team.user.activitiesCount,
    fetchMore,
    card: <ActivityCard />,
    table: <ActivitiesTable />,
    cardsList: <CardsList getRowDate={(activity) => activity.createdAt} />,
  });

  return (
    <TabContent toolbar={<>{listModeSwitcher}</>}>
      {filterBar}
      {list}
    </TabContent>
  );
}
