import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import useHistory from "hooks/useHistory";

export default function AppErrorBoundary({ children }) {
  const history = useHistory();
  const location = useLocation();

  return (
    <ErrorBoundary
      resetKeys={[location.pathname]}
      onReset={() => history.goBack()}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <Dialog open fullWidth maxWidth="sm">
          <DialogTitle>错误</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {error.isUserError ? (
                <>{error.message}</>
              ) : (
                <>
                  发生了一个未知错误({error.message}
                  )，该错误的相关信息已经提交给开发人员。
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => resetErrorBoundary()}>后退</Button>
          </DialogActions>
        </Dialog>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
