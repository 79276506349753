import { gql } from "@apollo/client";
import { fileRoute } from "helpers";
import { useData, useRoute } from "hooks";
import PreviewLoader from "views/previewable/PreviewLoader";

import { Message } from "controls";
import WebOfficeView from "./WebOfficeView";
import TextEditorView from "./TextEditorView";

export default function EditView() {
  const { fileId } = useRoute(fileRoute);

  const data = useData(
    gql`
      query WebOfficeView($fileId: ID!) {
        file(id: $fileId) {
          id
          webOfficeUrl
          isTextFile
        }
      }
    `,
    { fileId },
  );
  let editComponent = null;
  if (!data) return <PreviewLoader />;

  if (data.file.webOfficeUrl)
    editComponent = <WebOfficeView webOfficeUrl={data.file.webOfficeUrl} />;
  if (data.file.isTextFile) editComponent = <TextEditorView fileId={fileId} />;

  return (
    <>
      {editComponent}
      {!editComponent && (
        <Message type="error">
          该文档格式暂不支持编辑，请下载到本地编辑然后上传新版本。
        </Message>
      )}
    </>
  );
}
