import React from "react";
import gql from "graphql-tag";

import CardLoader from "loaders/CardLoader";

import FileCard, { FileCardFragment } from "./FileCard";
import FolderCard, { FolderCardFragment } from "./FolderCard";
import DocumentCard, { DocumentCardFragment } from "./DocumentCard";

export const ItemCardFragment = gql`
  fragment ItemCardFragment on Item {
    id
    selfUnion {
      ... on File {
        ...FileCardFragment
      }
      ... on Document {
        ...DocumentCardFragment
      }
      ... on Folder {
        ...FolderCardFragment
      }
    }
  }
  ${FileCardFragment}
  ${DocumentCardFragment}
  ${FolderCardFragment}
`;

export default function ItemCard({ item }) {
  if (item?.__typename === "File") return <FileCard file={item.selfUnion} />;
  if (item?.__typename === "Folder")
    return <FolderCard folder={item.selfUnion} />;
  if (item?.__typename === "Document")
    return <DocumentCard doc={item.selfUnion} />;
  return <CardLoader content />;
}
