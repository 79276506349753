import React from "react";

import { Message } from "controls";
import { teamRoute } from "helpers";
import { useActionFragment, useRoute, useFormDialog } from "hooks";
import { UserInput } from "inputs";

export default function PersonifyUser() {
  const { teamId } = useRoute(teamRoute);
  const formDialog = useFormDialog();
  const personifyUser = useActionFragment("personifyUser", "token");

  return (
    <Message
      actions={[
        {
          title: "以成员身份登陆",
          onClick: () =>
            formDialog({
              title: "以成员身份登陆",
              fields: [
                {
                  name: "userId",
                  label: "成员",
                  required: true,
                  inputComponent: UserInput,
                },
              ],
              onSubmit: async (formData) => {
                const result = await personifyUser({
                  input: {
                    ...formData,
                  },
                });
                window.location.href = `/${teamId}?token=${encodeURIComponent(
                  result.personifyUser.token,
                )}`;
              },
            }),
        },
      ]}
    >
      以成员身份登陆
      <br />
      您可以临时以选择成员的身份登陆，以调试团队配置问题。
    </Message>
  );
}
