import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

import getDialogProps from "helpers/getDialogProps";
import showMessage from "helpers/showMessage";

import Message from "./Message";
import AppForm from "./AppForm";
import FieldsContainer from "./FieldsContainer";

export default function FormDialog({
  title,
  message,
  submitLabel,
  icon,
  children,
  onSubmit,
  onClose,
  disabled,
  fields,
  fieldsContainer = null,
  ...others
}) {
  const [submitting, submittingSet] = useState(false);
  const dialogProps = getDialogProps();
  if (fields) fieldsContainer = <FieldsContainer fields={fields} />;

  return (
    <Dialog
      {...others}
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      {...dialogProps}
      PaperProps={{
        component: AppForm,
        onSubmit: async (formData) => {
          if (disabled) return;
          submittingSet(true);
          try {
            if (onSubmit) await onSubmit(formData);
          } finally {
            submittingSet(false);
          }
          if (onClose) onClose();
        },
        ...others.PaperProps,
        ...dialogProps.PaperProps,
      }}
    >
      <DialogTitle>
        {icon}
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
        {message && <Message>{message}</Message>}
        {fieldsContainer &&
          React.cloneElement(fieldsContainer, {
            key: disabled,
            disabled: disabled || submitting,
          })}
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button
            style={{ marginRight: 10 }}
            disabled={submitting}
            onClick={onClose}
          >
            取消
          </Button>
        )}
        <Button
          variant="contained"
          type="submit"
          disabled={disabled || submitting}
        >
          {submitLabel || "确定"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ActionFormDialog({
  title,
  fields,
  onSubmit,
  children = <>请确认{title}</>,
  ...others
}) {
  return (
    <FormDialog
      {...others}
      title={title}
      fields={fields}
      onSubmit={async (formData) => {
        if (onSubmit) await onSubmit(formData);
        showMessage({ message: `成功${title}` });
      }}
    >
      {children}
    </FormDialog>
  );
}
