import { useState, useRef, Fragment } from "react";
import {
  Badge,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { DotsHorizontal } from "mdi-material-ui";

export default function ButtonMenu({
  actions = [],
  actionGroups = [],
  children = <DotsHorizontal />,
  ...others
}) {
  const [open, openSet] = useState(false);
  const ref = useRef();
  if (actions.length) actionGroups.unshift(actions);
  actionGroups = actionGroups
    .filter((g) => g)
    .map((g) => g.filter((a) => a))
    .filter((g) => g?.length);

  return (
    <>
      <IconButton
        title="更多操作"
        {...others}
        ref={ref}
        disabled={!actionGroups?.length || others.disabled}
        onClick={() => openSet(true)}
      >
        {children}
      </IconButton>

      <Menu
        variant="menu"
        open={open}
        onClose={() => openSet(false)}
        anchorEl={ref.current}
        MenuListProps={{ component: "div" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {actionGroups.map((group, groupIndex) =>
          [
            groupIndex ? <Divider /> : null,
            group.map((action) => (
              <MenuItem
                key={action.title || action.label}
                component="a"
                dense
                href={action.href || "#"}
                disabled={action.disabled}
                onClick={async () => {
                  openSet(false);
                  if (action.onClick) action.onClick();
                }}
              >
                <ListItemIcon>
                  {action.icon && (
                    <Badge badgeContent={action.badgeContent} color="secondary">
                      {action.icon}
                    </Badge>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={action.title || action.label}
                  secondary={action.description}
                />
              </MenuItem>
            )),
          ].filter((t) => t),
        )}
      </Menu>
    </>
  );
}
