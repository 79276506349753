import { gql } from "@apollo/client";

import UserAvatars, { UserAvatarsFragment } from "./UserAvatars";

export const UsersLabelFragment = gql`
  fragment UsersLabelFragment on User {
    ...UserAvatarsFragment
  }
  ${UserAvatarsFragment}
`;

export default function UsersLabel({ users, ...others }) {
  return (
    <UserAvatars
      max={10}
      {...others}
      style={{
        display: "inline-flex",
        ...others.style,
      }}
      userAvatarProps={{
        style: {
          width: "1.6em",
          height: "1.6em",
          fontSize: "inherit",
          ...others.userAvatarProps?.style,
        },
        ...others.userAvatarProps,
      }}
      users={users}
    />
  );
}
