import { gql } from "@apollo/client";

import { useRoute, useFilterBar, useList, useData } from "hooks";
import { shareRoute } from "helpers";
import { TabContent } from "controls";
import { ShareItemCard, ShareItemCardFragment } from "blocks";
import ShareItemsTable, {
  ShareItemsTableFragment,
} from "lists/ShareItemsTable";
import ShareItemsGrid, { ShareItemsGridFragment } from "lists/ShareItemsGrid";
import DialogFooter from "views/viewport/DialogFooter";

import usePath from "./usePath";

export default function Items() {
  const { shareToken } = useRoute(shareRoute);
  const [path] = usePath();

  const [{ searchTerm, revisionedAtRange, includeDescendants }, filterBar] =
    useFilterBar(
      {
        enableSearchTerm: true,
      },
      [
        {
          type: "date_range",
          label: "修订日期",
          name: "revisionedAtRange",
        },
        {
          type: "boolean",
          label: "包含子目录内容",
          name: "includeDescendants",
        },
      ],
    );

  const [data, dataMeta] = useData(
    gql`
      query Items(
        $shareToken: ID!
        $path: [String!]!
        $searchTerm: String
        $revisionedAtRange: DateRange
        $offset: Int
        $includeDescendants: Boolean
      ) {
        share(shareToken: $shareToken) {
          id
          items(
            path: $path
            searchTerm: $searchTerm
            revisionedAtRange: $revisionedAtRange
            offset: $offset
            includeDescendants: $includeDescendants
          ) {
            id
            ...ShareItemsTableFragment
            ...ShareItemCardFragment
            ...ShareItemsGridFragment
          }
          itemsCount(
            path: $path
            searchTerm: $searchTerm
            revisionedAtRange: $revisionedAtRange
            includeDescendants: $includeDescendants
          )
        }
      }
      ${ShareItemCardFragment}
      ${ShareItemsTableFragment}
      ${ShareItemsGridFragment}
    `,
    {
      shareToken,
      path,
      searchTerm,
      revisionedAtRange,
      includeDescendants,
    },
    {
      returnMeta: true,
    },
  );

  const [list, listModeSwitcher] = useList("shareItem", {
    defaultMode: "table",
    rows: data?.share.items,
    rowsCount: data?.share.itemsCount,
    fetchMore: dataMeta.fetchMore,
    card: <ShareItemCard />,
    table: <ShareItemsTable enablePath={includeDescendants} />,
    grid: <ShareItemsGrid />,
  });

  return (
    <TabContent toolbar={<>{listModeSwitcher}</>}>
      {filterBar}
      {list}
      <DialogFooter />
    </TabContent>
  );
}
