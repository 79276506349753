import React from "react";
import { gql } from "@apollo/client";
import { Badge, Avatar, Dialog, DialogTitle } from "@mui/material";
import { Gift } from "mdi-material-ui";

import { useAction, useData } from "hooks";
import { ObjectCard, DateTime, FetchMoreButton } from "controls";
import { getDialogProps } from "helpers";

export default function ChangelogDialog({ ...others }) {
  const [data, dataMeta] = useData(
    gql`
      query ChangelogDialog($offset: Int) {
        changelogPosts(offset: $offset, limit: 10) {
          id
          url
          html
          title
          read
          publishedAt
        }
      }
    `,
    {},
    { returnMeta: true },
  );

  const markPostRead = useAction(
    gql`
      mutation ChangelogDrawer($input: MarkPostReadInput!) {
        markPostRead(input: $input) {
          changelogPost {
            id
            read
          }
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: gql`
            query ChangelogDialog {
              changelogPostsCount(unread: true)
            }
          `,
        },
      ],
    },
  );

  return (
    <Dialog open {...others} {...getDialogProps()}>
      <DialogTitle>产品日志</DialogTitle>
      <div style={{ padding: 5, display: "flex", flexFlow: "column nowrap" }}>
        {data?.changelogPosts.map((changelogPost) => (
          <ObjectCard
            key={changelogPost.id}
            title={<>{changelogPost.title}</>}
            subheader={<DateTime value={changelogPost.publishedAt} />}
            avatar={
              <Avatar style={{ overflow: "visible" }}>
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={changelogPost.read}
                >
                  <Gift />
                </Badge>
              </Avatar>
            }
            href={changelogPost.url}
            onClick={() =>
              !changelogPost.read &&
              markPostRead({ input: { changelogPostId: changelogPost.id } })
            }
            target="_blank"
          >
            <div
              style={{
                maxHeight: 200,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                className="markdown"
                dangerouslySetInnerHTML={{ __html: changelogPost.html }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1))",
                }}
              />
            </div>
          </ObjectCard>
        ))}
        <FetchMoreButton
          fetchMore={dataMeta.fetchMore}
          rows={data?.changelogPosts}
          rowsCount={data?.changelogPostsCount}
        />
      </div>
    </Dialog>
  );
}
