import React, { useState, useEffect } from "react";
import { Paper, Tabs, Tab } from "@mui/material";

import { checkFetchResponse, filterBlobUrl } from "helpers";

import { Message } from "controls";
import { useProgressBar } from "hooks";
import PreviewLoader from "./PreviewLoader";

export default function WorkbooksViewer({ previewable }) {
  const workbooks = previewable.previewMeta;

  const [currentWorkbook, currentWorkbookSet] = useState(null);
  const [workbookData, workbookDataSet] = useState(null);

  useEffect(() => {
    if (workbooks) currentWorkbookSet(workbooks[0]);
  }, [workbooks]);

  const workbookBlobUrlFiltered = filterBlobUrl(currentWorkbook?.blob_url);

  useProgressBar(!workbookBlobUrlFiltered);

  useEffect(() => {
    workbookDataSet(null);
    if (workbookBlobUrlFiltered) {
      Promise.resolve().then(async () => {
        const response = await fetch(workbookBlobUrlFiltered);
        await checkFetchResponse(response);
        const workbookData = await response.json();
        workbookDataSet(workbookData);
      });
    }
  }, [workbookBlobUrlFiltered]);

  if (!workbooks) return <PreviewLoader />;

  return (
    <>
      {!workbookData && <Message type="info">（空白工作簿）</Message>}
      {workbookData && (
        <Paper
          square
          variant="outlined"
          style={{
            flex: "1 0 0",
            overflow: "auto",
            margin: 5,
          }}
          key={workbookBlobUrlFiltered}
        >
          <table
            border="0"
            cellSpacing="0"
            size="small"
            style={{
              width: "fit-content",
              tableLayout: "fixed",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                {workbookData.widths?.map((width, widthIndex) => (
                  <th
                    key={widthIndex}
                    style={{
                      width: width || "auto",
                    }}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {workbookData.rows?.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: row }}
                />
              ))}
            </tbody>
          </table>
        </Paper>
      )}
      <Tabs
        variant="scrollable"
        value={Math.max(workbooks.indexOf(currentWorkbook), 0)}
        onChange={(event, workbookIndex) =>
          currentWorkbookSet(workbooks[workbookIndex])
        }
      >
        {workbooks.map((workbook, workbookIndex) => (
          <Tab
            key={workbookIndex}
            value={workbookIndex}
            label={workbook.name || previewable.name}
          />
        ))}
      </Tabs>
    </>
  );
}
