import React from "react";
import { Button } from "@mui/material";

import { SignatureIcon } from "controls/icons";
import SignatureInput from "inputs/SignatureInput";
import useDocumentOverlay from "hooks/useDocumentOverlay";
import useFormDialog from "hooks/useFormDialog";

export default function FieldSignature({
  field,
  documentContentMode,
  updateField,
  ...others
}) {
  const formDialog = useFormDialog();

  const overlay = useDocumentOverlay({
    enabled: documentContentMode === "fill",
    overlay: (
      <Button
        variant="contained"
        fullWidth
        onClick={() =>
          formDialog({
            title: "使用签名",
            fields: [
              {
                label: "签名",
                name: "signatureEtag",
                inputComponent: SignatureInput,
              },
            ],
            onSubmit: async (formData) => {
              await updateField({
                input: {
                  fieldId: field.id,
                  ...formData,
                },
              });
            },
          })
        }
      >
        <SignatureIcon />
        使用签名
      </Button>
    ),
  });

  return (
    <>
      <div
        data-field-signature
        {...others}
        style={{
          ...others.style,
          ...(documentContentMode === "fill" && {
            cursor: "pointer",
          }),
        }}
        tabIndex={0}
        onFocus={overlay.handleFocus}
        onBlur={overlay.handleBlur}
      >
        {field.signatureBlobUrl && (
          <img
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "left center",
            }}
            src={field.signatureBlobUrl}
            alt="签名"
          />
        )}
      </div>
    </>
  );
}
