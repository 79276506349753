import React from "react";
import { gql } from "@apollo/client";

import { canvasPreviewableRoute } from "helpers/routes";
import { useRoute, useData } from "hooks";
import PreviewFrame, {
  PreviewFrameFragment,
} from "views/previewable/PreviewFrame";

export default function PreviewableCanvas() {
  const { previewableId } = useRoute(canvasPreviewableRoute);
  const data = useData(
    gql`
      query PreviewableCanvas($previewableId: ID!) {
        previewable(id: $previewableId) {
          id
          ...PreviewFrameFragment
        }
      }
      ${PreviewFrameFragment}
    `,
    { previewableId },
  );

  return (
    <div
      style={{ height: "100vh", display: "flex", flexFlow: "column nowrap" }}
    >
      <PreviewFrame previewable={data?.previewable} />
    </div>
  );
}
