import React, { useContext } from "react";

import { pageContext } from "./helpers";
import Line from "./Line";

export default React.memo(Ruler);

function Ruler({ pos }) {
  const { page } = useContext(pageContext);
  const { height, width } = page;

  return (
    <div data-ruler>
      {pos[0] && (
        <>
          <Line pos={[pos[0], 0]} distance={width} strokeStyle="dotted" />
          <span
            style={{
              position: "absolute",
              top: `${pos[0]}mm`,
              left: 0,
            }}
          >
            {pos[0]}mm
          </span>
        </>
      )}
      {pos[1] && (
        <>
          <Line
            vertical
            pos={[0, pos[1]]}
            distance={height}
            strokeStyle="dotted"
          />
          <span
            style={{
              position: "absolute",
              top: 0,
              left: `${pos[1]}mm`,
            }}
          >
            {pos[1]}mm
          </span>
        </>
      )}
    </div>
  );
}
