import { gql } from "@apollo/client";

import { ActionFormDialog } from "controls";
import { useAction } from "hooks";

export default function RemoveItemsDialog({ itemIds, ...others }) {
  const removeItems = useAction(gql`
    mutation RemoveItemsDialog($input: RemoveItemsInput!) {
      removeItems(input: $input) {
        folders {
          id
          items(limit: 20) {
            id
          }
          files {
            id
          }
          subFolders {
            id
          }
          documents {
            id
          }
        }
      }
    }
  `);

  return (
    <ActionFormDialog
      {...others}
      title="删除文件"
      onSubmit={() =>
        removeItems({
          input: {
            itemIds,
          },
        })
      }
    />
  );
}
