import { useLayoutEffect } from "react";
import { gql } from "@apollo/client";
import { parseISO, differenceInMinutes } from "date-fns";

import { useData, useChannel, useRoute, useAction } from "hooks";
import { chatRoute, apolloClient } from "helpers";
import { ListLoader } from "loaders";
import PushPromoMessage from "views/viewport/PushPromoMessage";

import ReverseFlatList from "./ReverseFlatList";
import ChatMessage, { ChatMessageFragment } from "./ChatMessage";

export default function ChatMessages({ ...others }) {
  const { teamId, chatId } = useRoute(chatRoute);
  const [data, dataMeta] = useData(
    gql`
      query ChatMessages($chatId: ID!, $offset: Int) {
        chat(id: $chatId) {
          id
          messagesCount
          messagesCountUnread: messagesCount(unread: true)
          messages(offset: $offset, limit: 50) {
            id
            ...ChatMessageFragment
          }
        }
      }
      ${ChatMessageFragment}
    `,
    {
      chatId,
    },
    { returnMeta: true },
  );

  const chatRead = useAction(
    gql`
      mutation ChatMessages($input: ChatReadInput!) {
        chatRead(input: $input) {
          chat {
            id
            messagesCount(unread: true)
          }
        }
      }
    `,
    ({ input: { chatId } }) => ({
      progressBarOptions: {
        unobstrusive: true,
      },
      optimisticResponse: {
        chatRead: {
          __typename: "ChatReadPayload",
          chat: {
            id: chatId,
            __typename: "Chat",
            messagesCount: 0,
          },
        },
      },
    }),
  );

  useChannel("ChatChannel", { teamId }, ({ chatId: chatIdReceived }) => {
    if (chatIdReceived === chatId) dataMeta.refetch();
  });

  useLayoutEffect(() => {
    if (data?.chat.messagesCountUnread && !chatRead.loading)
      Promise.resolve().then(async () => {
        await chatRead({ input: { chatId } });
        await apolloClient.query({
          query: gql`
            query ChatMessages($teamId: ID!) {
              team(id: $teamId) {
                id
                messagesCount(unread: true)
              }
            }
          `,
          variables: { teamId },
          fetchPolicy: "network-only",
        });
      });
  }, [data?.chat.messagesCountUnread, chatRead.loading]);

  const endOfList =
    data && data?.chat.messagesCount === data?.chat.messages.length;

  return (
    <ReverseFlatList
      {...others}
      style={{ padding: "0 5px", ...others.style }}
      onEndReached={() => {
        if (dataMeta.loading) return;
        if (!data) return;
        dataMeta.fetchMore({
          variables: {
            offset: data.chat.messages.length,
          },
        });
      }}
      data={[
        ...(data?.chat.messages || []).map((message, messageIndex) => ({
          key: message.id,
          content: (
            <ChatMessage
              message={message}
              collapsed={
                message.user?.id ===
                  data?.chat.messages[messageIndex + 1]?.user?.id &&
                differenceInMinutes(
                  parseISO(message.createdAt),
                  parseISO(data?.chat.messages[messageIndex + 1]?.createdAt),
                ) < 5
              }
            />
          ),
        })),
        !endOfList && {
          key: "loader",
          content: <ListLoader items={10} subheaders avatars />,
        },
        {
          key: "push-promo",
          content: <PushPromoMessage />,
        },
        endOfList && {
          key: "start-padding",
          content: <div style={{ height: 5 }} />,
        },
      ].filter((i) => i)}
      keyExtractor={(item) => item.key}
      renderItem={({ item }) => item.content}
      endOfList={endOfList}
    />
  );
}
