import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import { ContentSave, Reload } from "mdi-material-ui";
import { useId, useLayoutEffect, useState } from "react";

import { checkFetchResponse, uploadBlob, filterBlobUrl } from "helpers";
import { useActionFragment, useConfirmDialog, useData } from "hooks";
import PreviewLoader from "views/previewable/PreviewLoader";
import { AppToolbar } from "controls";

export default function TextEditorView({ fileId }) {
  const [fileContent, fileContentSet] = useState(null);
  const [content, contentSet] = useState(null);
  const data = useData(
    gql`
      query TextEditorView($fileId: ID!) {
        file(id: $fileId) {
          id
          name
          blobUrl
        }
      }
    `,
    { fileId },
  );
  const blobUrlFiltered = filterBlobUrl(data?.file.blobUrl);
  useLayoutEffect(() => {
    fileContentSet(null);
    contentSet(null);
    if (blobUrlFiltered)
      Promise.resolve().then(async () => {
        const response = await fetch(blobUrlFiltered);
        await checkFetchResponse(response);
        const fileContent = await response.text();
        fileContentSet(fileContent);
        contentSet(fileContent);
      });
  }, [fileId, !!blobUrlFiltered]);
  const confirmDialog = useConfirmDialog();
  const id = useId();

  const reviseFile = useActionFragment(
    "reviseFile",
    "file { id blobUrl revisions { id } }",
  );

  if (content === null) return <PreviewLoader />;

  return (
    <>
      <AppToolbar
        dense
        iconOnly
        actions={[
          {
            icon: <ContentSave />,
            title: "保存",
            disabled: content === fileContent,
            onClick: async () => {
              const blob = new Blob([content]);
              const { etag } = await uploadBlob({
                id,
                blob,
              });
              await reviseFile({
                input: {
                  fileId,
                  fileUpload: {
                    name: data?.file.name,
                    etag,
                    size: String(blob.size),
                  },
                },
              });
              fileContentSet(content);
            },
          },
          {
            icon: <Reload />,
            title: "重置",
            disabled: content === fileContent,
            onClick: async () => {
              await confirmDialog();
              contentSet(fileContent);
            },
          },
        ]}
      />
      <Paper
        component="textarea"
        variant="outlined"
        style={{
          flex: "1 1 auto",
          margin: 5,
          padding: 10,
          resize: "none",
        }}
        key={fileId}
        value={content}
        onChange={(event) => contentSet(event.currentTarget.value)}
      />
    </>
  );
}
