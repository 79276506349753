import React from "react";
import { gql } from "@apollo/client";

import { PermissionsFragment, PERMISSION_CATEGORIES } from "helpers";
import { ActionFormDialog, AppTabs, FieldsContainer } from "controls";
import { useAction } from "hooks";
import { UsersInput, BooleanInput, FoldersInput } from "inputs";

export default function AddPermissionsDialog({
  folderIds = [],
  userIds = [],
  ...others
}) {
  const addPermission = useAction(gql`
    mutation AddPermissionsDialog($input: AddPermissionInput!) {
      addPermission(input: $input) {
        folders {
          id
          permissions {
            id
          }
        }
        users {
          id
          permissions {
            id
          }
        }
        permissions {
          id
          ...PermissionsFragment
          folder {
            id
          }
          user {
            id
          }
        }
      }
    }
    ${PermissionsFragment}
  `);

  return (
    <ActionFormDialog
      {...others}
      title="增加权限"
      onSubmit={async (formData) => {
        await addPermission({
          input: {
            ...formData,
          },
        });
      }}
    >
      <FieldsContainer
        fields={[
          {
            name: "folderIds",
            inputComponent: FoldersInput,
            label: "位置",
            required: true,
            value: folderIds,
          },
          {
            name: "userIds",
            inputComponent: UsersInput,
            label: "用户",
            required: true,
            value: userIds,
          },
        ]}
      />
      <AppTabs
        noUrl
        tabs={PERMISSION_CATEGORIES.map((permissionCategory) => ({
          icon: permissionCategory.icon,
          title: permissionCategory.title,
          name: permissionCategory.title,
          content: (
            <FieldsContainer
              key={permissionCategory.title}
              fields={permissionCategory.permissionTypes.map(
                (permissionType) => ({
                  name: permissionType.name,
                  inputComponent: BooleanInput,
                  label: permissionType.label,
                  helperText: permissionType.description,
                  value: false,
                }),
              )}
            />
          ),
        }))}
      />
    </ActionFormDialog>
  );
}
