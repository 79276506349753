import { fromPairs } from "lodash-es";
import { gql } from "@apollo/client";
import { Tag } from "mdi-material-ui";

import useRoute from "hooks/useRoute";
import useData from "hooks/useData";
import { teamRoute } from "helpers/routes";
import SuggestiveTextInput from "inputs/SuggestiveTextInput";

function filtersToHash(filters) {
  return fromPairs(filters.map((filter) => [filter.label, filter.value]));
}

function hashToFilters(hash) {
  return Object.entries(hash).map(([label, value]) => ({
    label,
    value,
  }));
}

Object.assign(FieldFiltersFilter, {
  defaultValue: [],
  serialize: (filters) => JSON.stringify(filtersToHash(filters)),
  deserialize: (string) => hashToFilters(JSON.parse(string)),
});

export default function FieldFiltersFilter({
  value: filters,
  valueSet: filtersSet,
  labels,
}) {
  return labels
    ?.filter((l) => l)
    .map((label) => (
      <FieldFilter
        key={label}
        {...{
          label,
          filters,
          filtersSet,
        }}
      />
    ));
}

function FieldFilter({ label, filters, filtersSet }) {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query FieldFilter($teamId: ID!, $label: String!) {
        team(id: $teamId) {
          id
          fieldValueCounts(label: $label) {
            value
            count
          }
        }
      }
    `,
    { teamId, label },
  );

  return (
    <div style={{ margin: 5, minWidth: 150 }}>
      <SuggestiveTextInput
        label={
          <>
            <Tag fontSize="inherit" />
            {label}
          </>
        }
        value={filtersToHash(filters)[label] || ""}
        onChange={(v) => {
          const hashNew = { ...filtersToHash(filters) };
          if (v) hashNew[label] = v;
          else delete hashNew[label];
          const filtersNew = hashToFilters(hashNew);
          filtersSet(filtersNew);
        }}
        suggestions={data?.team.fieldValueCounts}
      />
    </div>
  );
}
