const DASHBOARD_COLORS = [
  "#335c67",
  "#8338ec",
  "#e09f3e",
  "#9e2a2b",
  "#540b0e",
  "#f3722c",
];

export default DASHBOARD_COLORS;
