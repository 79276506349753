import React, { useId } from "react";
import { TextField } from "@mui/material";
import dasherize from "dasherize";

export default function TextInput({
  label,
  value,
  onChange,
  helperText,
  name,
  error,
  readOnly,
  type,
  autoComplete = "off",
  ...others
}) {
  const id = useId();

  return (
    <>
      <TextField
        size="small"
        fullWidth
        label={label}
        name={dasherize(name)}
        error={!!error}
        helperText={(error && error.message) || helperText}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        id={id}
        autoComplete={autoComplete}
        type={type}
        {...(readOnly && {
          InputProps: { readOnly },
          value: value || "-",
          onChange: undefined,
        })}
        {...others}
      />
    </>
  );
}
