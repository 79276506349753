import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { CloseCircle, DotsHorizontal } from "mdi-material-ui";

import getDialogProps from "helpers/getDialogProps";

export default React.memo(SingleInput);

SingleInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Error),
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  renderValueAvatar: PropTypes.any,
  renderValueLabel: PropTypes.any,
  renderItemDialogContent: PropTypes.any.isRequired,
};

function SingleInput({
  renderItemAvatar: ItemAvatar = () => null,
  renderItemLabel: ItemLabel = ({ item }) => <>{item}</>,
  label,
  value,
  onChange,
  error,
  readOnly,
  helperText,
  required,
  renderItemDialogContent: ItemDialogContent,
  DialogProps,
  getItemActions = () => [],
  ...options
}) {
  const [dialogState, dialogStateSet] = useState({
    open: false,
    item: null,
  });

  const itemActions = value ? getItemActions(value) : [];

  return (
    <>
      <TextField
        size="small"
        fullWidth
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!error}
        helperText={(error && error.message) || helperText}
        required={required}
        InputProps={{
          inputComponent: () => (
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                alignItems: "center",
                height: "40px",
                flex: "1 0 auto",
                cursor: readOnly ? undefined : "pointer",
              }}
              onClick={() =>
                !readOnly &&
                dialogStateSet({
                  open: true,
                  item: value,
                })
              }
            >
              {!value && <>(无)</>}
              {!!value && <ItemLabel {...options} item={value} />}
            </div>
          ),
          style: {
            paddingLeft: 10,
            paddingRight: 5,
          },
          startAdornment: (
            <InputAdornment position="start">
              {!!value && (
                <ItemAvatar
                  {...options}
                  item={value}
                  style={{
                    width: 24,
                    height: 24,
                    fontSize: 18,
                  }}
                />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {itemActions.map((itemAction) => (
                <IconButton
                  key={itemAction.title}
                  size="small"
                  title={itemAction.title}
                  onClick={itemAction.onClick}
                >
                  {itemAction.icon}
                </IconButton>
              ))}
              {!readOnly && !!value && (
                <IconButton
                  size="small"
                  title={`清除${label}`}
                  onClick={() => onChange(null)}
                >
                  <CloseCircle />
                </IconButton>
              )}
              {!readOnly && (
                <IconButton
                  size="small"
                  title={`选择${label}`}
                  onClick={() =>
                    dialogStateSet({
                      open: true,
                      item: value,
                    })
                  }
                >
                  <DotsHorizontal />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Dialog
        fullWidth
        maxWidth="xs"
        scroll="paper"
        open={dialogState.open}
        onClose={() => dialogStateSet({ ...dialogState, open: false })}
        {...DialogProps}
        {...getDialogProps()}
      >
        <DialogTitle>选择{label}</DialogTitle>
        <DialogContent style={{ height: "80vh" }}>
          {dialogState.open && (
            <ItemDialogContent
              {...options}
              item={dialogState.item}
              itemSet={(newItem, cb) =>
                dialogStateSet(
                  {
                    ...dialogState,
                    item: newItem,
                  },
                  cb,
                )
              }
              itemSubmit={(newItem) => {
                dialogStateSet({
                  ...dialogState,
                  open: false,
                  item: newItem,
                });
                onChange(newItem);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dialogStateSet({ ...dialogState, open: false })}
          >
            取消
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              dialogStateSet({
                ...dialogState,
                open: false,
              });
              onChange(dialogState.item);
            }}
            disabled={!dialogState.item}
          >
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
