import React from "react";
import { defer } from "lodash-es";
import { gql } from "@apollo/client";

import { showMessage, teamRoute } from "helpers";
import { useRoute, useAction, useData, useHistory } from "hooks";
import { FormDialog, Message } from "controls";

export default function DeleteTeamDialog({ ...others }) {
  const { teamId } = useRoute(teamRoute);
  const history = useHistory();
  const data = useData(
    gql`
      query DeleteTeamDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          name
        }
      }
    `,
    { teamId },
  );
  const deleteTeam = useAction(
    gql`
      mutation DeleteTeamDialog($input: DeleteTeamInput!) {
        deleteTeam(input: $input) {
          clientMutationId
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: gql`
            query {
              teams {
                id
              }
            }
          `,
        },
      ],
    },
  );
  return (
    <FormDialog
      title={`确认删除团队 ${data?.team.name}`}
      {...others}
      onSubmit={async () => {
        await deleteTeam({
          input: {
            teamId,
          },
        });
        showMessage({
          message: `成功删除团队 ${data?.team.name}`,
        });
        defer(() => history.push("/"));
      }}
    >
      <Message
        message={`该操作将删除该团队（${data.team.name}
                ）和团队内的所有成员数据，删除后这些数据将无法恢复，请确认。`}
      />
    </FormDialog>
  );
}
