import React from "react";
import { gql } from "@apollo/client";

import { companyRegistrationRoute } from "helpers";
import {
  useHistory,
  useRoute,
  useData,
  useConfirmDialog,
  useActionFragment,
  useDialogOpener,
} from "hooks";
import {
  AppView,
  CompanyIcon,
  CompanyRegistrationIcon,
  FormCard,
  CardsList,
  Message,
  DateTime,
} from "controls";
import { ComponentInput } from "inputs";
import {
  RegistrationFilesGridList,
  RegistrationFilesGridListFragment,
} from "blocks";
import CompanyRegistrationImportWizard from "./CompanyRegistrationImportWizard";

export default function CompanyRegistrationView() {
  const { teamId, companyRegistrationId } = useRoute(companyRegistrationRoute);
  const data = useData(
    gql`
      query CompanyRegistrationView($companyRegistrationId: ID!) {
        companyRegistration(id: $companyRegistrationId) {
          id
          processedAt
          name
          category
          unifiedCreditCode
          projects
          accountName
          accountNumber
          accountBranch
          accountBulkNumber
          canProcess
          qualification {
            id
            ...RegistrationFilesGridListFragment
          }
          license {
            id
            ...RegistrationFilesGridListFragment
          }
          delegationStatement {
            id
            ...RegistrationFilesGridListFragment
          }
          delegationIddoc {
            id
            ...RegistrationFilesGridListFragment
          }
          contacts {
            name
            email
            mobile
            role
          }
        }
      }
      ${RegistrationFilesGridListFragment}
    `,
    { companyRegistrationId },
  );
  const confirmDialog = useConfirmDialog();
  const companyRegistrationProcess = useActionFragment(
    "companyRegistrationProcess",
  );
  const history = useHistory();
  const openCompanyRegistrationImportWizard = useDialogOpener(
    CompanyRegistrationImportWizard,
  );

  return (
    <AppView
      title={data?.companyRegistration.name}
      crumbs={[
        {
          icon: <CompanyIcon />,
          label: "单位",
          href: `/${teamId}/companies`,
        },
        {
          icon: <CompanyRegistrationIcon />,
          label: data?.companyRegistration.name,
          href: `/${teamId}/${companyRegistrationId}`,
        },
      ]}
    >
      {data && !data.companyRegistration.processedAt && (
        <Message
          actions={[
            {
              title: "导入单位",
              disabled: !data?.companyRegistration.canProcess,
              onClick: () =>
                openCompanyRegistrationImportWizard({
                  companyRegistrationId,
                  onSubmit: () => history.goBack(),
                }),
            },
            {
              title: "忽略",
              disabled: !data?.companyRegistration.canProcess,
              onClick: async () => {
                await confirmDialog();
                await companyRegistrationProcess({
                  input: {
                    companyRegistrationId,
                  },
                });
                history.goBack();
              },
            },
          ]}
        >
          该注册尚未处理。
        </Message>
      )}
      {data?.companyRegistration.processedAt && (
        <Message
          actions={[
            {
              title: "重新导入单位",
              disabled: !data?.companyRegistration.canProcess,
              onClick: () =>
                openCompanyRegistrationImportWizard({
                  companyRegistrationId,
                  onSubmit: () => history.goBack(),
                }),
            },
          ]}
        >
          该注册已于
          <b>
            <DateTime value={data?.companyRegistration.processedAt} />
          </b>
          处理。
        </Message>
      )}
      <CardsList cardWidth={600}>
        <FormCard
          disabled
          title="单位信息"
          fields={[
            { label: "单位名称", value: data?.companyRegistration.name },
            { label: "单位类别", value: data?.companyRegistration.category },
            {
              label: "资质",
              inputComponent: ComponentInput,
              options: {
                component: (
                  <RegistrationFilesGridList
                    registrationFiles={data?.companyRegistration.qualification}
                  />
                ),
              },
            },
            {
              label: "营业执照",
              inputComponent: ComponentInput,
              options: {
                component: (
                  <RegistrationFilesGridList
                    registrationFiles={data?.companyRegistration.license}
                  />
                ),
              },
            },
            {
              label: "统一信用代码",
              value: data?.companyRegistration.unifiedCreditCode,
            },
            {
              label: "相关项目",
              value: data?.companyRegistration.projects,
            },
          ]}
        />
        <FormCard
          disabled
          title="收款信息"
          fields={[
            { label: "账户名称", value: data?.companyRegistration.accountName },
            { label: "账号", value: data?.companyRegistration.accountNumber },
            { label: "开户行", value: data?.companyRegistration.accountBranch },
            {
              label: "大额行号",
              value: data?.companyRegistration.accountBulkNumber,
            },
          ]}
        />
        <FormCard
          disabled
          title="授权书"
          fields={[
            {
              label: "授权书",
              inputComponent: ComponentInput,
              options: {
                component: (
                  <RegistrationFilesGridList
                    registrationFiles={
                      data?.companyRegistration.delegationStatement
                    }
                  />
                ),
              },
            },
            {
              label: "授权人身份证",
              inputComponent: ComponentInput,
              options: {
                component: (
                  <RegistrationFilesGridList
                    registrationFiles={
                      data?.companyRegistration.delegationIddoc
                    }
                  />
                ),
              },
            },
          ]}
        />
        {data?.companyRegistration.contacts.map((contact, contactIndex) => (
          <FormCard
            key={contactIndex}
            disabled
            title="联系人"
            fields={[
              { label: "姓名", value: contact.name },
              { label: "邮箱", value: contact.email },
              { label: "手机", value: contact.mobile },
              { label: "角色", value: contact.role },
            ]}
          />
        ))}
      </CardsList>
    </AppView>
  );
}
