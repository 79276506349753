export const PAGE_SIZE_A4 = {
  value: "a4",
  label: "A4",
  pageAttributes: {
    width: 210,
    height: 297,
  },
};

export const PAGE_SIZE_A4_HORIZONTAL = {
  value: "a4_horizontal",
  label: "A4 (横页)",
  pageAttributes: {
    width: 297,
    height: 210,
  },
};

const PAGE_SIZES = [PAGE_SIZE_A4, PAGE_SIZE_A4_HORIZONTAL];
export default PAGE_SIZES;
