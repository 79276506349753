import BooleanInput from "inputs/BooleanInput";

Object.assign(BooleanFilter, {
  defaultValue: false,
  serialize: (v) => (v ? "yes" : ""),
  deserialize: (v) => !!v,
});

export default function BooleanFilter({ label, value, valueSet, variant }) {
  return (
    <div style={{ margin: 5 }}>
      <BooleanInput
        label={label}
        value={value}
        onChange={valueSet}
        variant={variant}
      />
    </div>
  );
}
