import { useEffect, useContext, useId } from "react";
import { defer } from "lodash-es";

import { progressBarContext } from "providers/ProgressBarProvider";
import newId from "helpers/newId";

export default function useProgressBar(
  display = false,
  { unobstrusive = false, blocking = false } = {},
) {
  const id = useId();
  const { addProgressBar, removeProgressBar } = useContext(progressBarContext);

  useEffect(() => {
    if (display) defer(() => addProgressBar({ id, unobstrusive, blocking }));
    return () => {
      if (display) defer(() => removeProgressBar({ id }));
    };
  }, [display, unobstrusive, blocking]);

  const progressBar = async (...args) => {
    let func = null;
    let options = null;
    if (args[0]?.constructor === Object) [options, func] = args;
    else [func, options] = args;
    const { unobstrusive = false, blocking = false } = options || {};

    const id = newId();
    try {
      addProgressBar({ id, unobstrusive, blocking });
      return await func();
    } finally {
      removeProgressBar({ id });
    }
  };

  return progressBar;
}
