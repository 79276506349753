import React, { useEffect, useState, useLayoutEffect } from "react";
import { Paper } from "@mui/material";
import { useBreakpoint } from "react-use-size";
import { Switch, Route, useLocation } from "react-router";
import { gql } from "@apollo/client";
import moment from "moment";

import { viewportContext } from "contexts";
import { RouteNotFound } from "controls";
import {
  itemRoute,
  userRoute,
  teamRoute,
  dataQueryRoute,
  picklistRoute,
  chatRoute,
  companyRoute,
  pTemplateRoute,
  embedInMobile,
  showMessage,
  companyRegistrationRoute,
} from "helpers";
import { useRoute, useData, useHistory } from "hooks";
import ItemView from "views/items/ItemView";
import UserView from "views/users/UserView";
import UsersView from "views/users/UsersView";
import ConfigureView from "views/configure/ConfigureView";
import TeamDashboard from "views/team_dashboard/TeamDashboard";
import UserSettingsView from "views/user_settings/UserSettingsView";
import DataQueryView from "views/data_queries/DataQueryView";
import DataQueriesView from "views/data_queries/DataQueriesView";
import DocumentActionsProvider from "providers/DocumentActionsProvider";
import ProjectsView from "views/projects/ProjectsView";
import PicklistView from "views/picklists/PicklistView";
import MyView from "views/my/MyView";
import SuperadminView from "views/superadmin/SuperadminView";
import ChatsView from "views/chats/ChatsView";
import DocumentsView from "views/documents/DocumentsView";
import FoldersView from "views/folders/FoldersView";
import CompaniesView from "views/companies/CompaniesView";
import CompanyView from "views/companies/CompanyView";
import PTemplateView from "views/p_templates/PTemplateView";

import AddSignatureDialog from "views/signatures/AddSignatureDialog";

import AcceptTeamDialog from "./AcceptTeamDialog";
import Navbar from "./Navbar";
import AppDrawer, { DENSE_DRAWER_WIDTH } from "./AppDrawer";
import RightDrawer from "./RightDrawer";
import CompanyRegistrationView from "views/companies/CompanyRegistrationView";

export default function Viewport() {
  // Drawer
  const [drawerOpen, drawerOpenSet] = useState(false);
  const history = useHistory();
  const denseView = useBreakpoint(720) || embedInMobile;
  const location = useLocation();

  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query Viewport($teamId: ID!) {
        team(id: $teamId) {
          id
          isTrialEnded
          trialEndsAt
        }
      }
    `,
    { teamId },
  );

  useEffect(() => {
    if (teamId) localStorage.setItem("visitedTeam", teamId);
  }, [teamId]);

  useEffect(() => {
    if (data?.team.trialEndsAt)
      showMessage({
        message: `当前团队为试用状态，试用将于${moment(
          data.team.trialEndsAt,
        ).format("M月D日")}结束，团队数据将变为只读。`,
      });
  }, [teamId, data?.team.trialEndsAt]);

  useEffect(() => {
    if (data?.team.isTrialEnded)
      showMessage({
        message: `当前团队试用已于${moment(
          data.team.trialEndsAt,
        )}结束，如需继续使用请联系优建云。团队数据会在30日后删除。`,
      });
  }, [teamId, data?.team.isTrialEnded]);

  useLayoutEffect(() => {
    if (drawerOpen) drawerOpenSet(false);
  }, [location.pathname]);

  return (
    <viewportContext.Provider
      value={{
        drawerOpen,
        drawerOpenSet,
        denseView,
      }}
    >
      <DocumentActionsProvider
        renderDocumentActions={(actions) => (
          <Paper
            elevation={5}
            style={{
              margin: 10,
              padding: 5,
              position: "fixed",
              top: 64,
              left: denseView ? 0 : DENSE_DRAWER_WIDTH,
              right: 0,
              zIndex: 10,
            }}
          >
            {actions}
          </Paper>
        )}
      >
        <AppDrawer />
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "stretch",
          }}
        >
          {!embedInMobile && <Navbar />}
          <main
            style={{
              flex: "1 0 auto",
              paddingRight: `env(safe-area-inset-right)`,
              display: "flex",
              flexFlow: "column nowrap",
              ...(!denseView && {
                marginLeft: DENSE_DRAWER_WIDTH,
              }),
            }}
          >
            <Switch>
              <Route path="/" exact render={() => <></>} />
              {[
                [teamRoute, TeamDashboard],
                [itemRoute, ItemView],
                [userRoute, UserView],
                [dataQueryRoute, DataQueryView],
                [picklistRoute, PicklistView],
                [companyRoute, CompanyView],
                [pTemplateRoute, PTemplateView],
                [companyRegistrationRoute, CompanyRegistrationView],
                [`${teamRoute}/users`, UsersView],
                [`${teamRoute}/configure`, ConfigureView],
                [`${teamRoute}/projects`, ProjectsView],
                [`${teamRoute}/my`, MyView],
                [`${teamRoute}/data_queries`, DataQueriesView],
                [`${teamRoute}/user_settings`, UserSettingsView],
                [`${teamRoute}/superadmin`, SuperadminView],
                [[`${teamRoute}/chats`, chatRoute], ChatsView],
                [`${teamRoute}/documents`, DocumentsView],
                [`${teamRoute}/folders`, FoldersView],
                [`${teamRoute}/companies`, CompaniesView],
              ].map(([path, Component], index) => (
                <Route
                  key={index}
                  path={path}
                  exact
                  render={({ match }) => <Component {...match.params} />}
                />
              ))}
              <Route path={`${teamRoute}/add_signature`} exact>
                <AddSignatureDialog
                  onClose={() =>
                    history.replace(`/${teamId}/user_settings?tab=signatures`)
                  }
                />
              </Route>
              <Route component={RouteNotFound} />
            </Switch>
          </main>
        </div>
        <RightDrawer />
        <AcceptTeamDialog />
      </DocumentActionsProvider>
    </viewportContext.Provider>
  );
}
