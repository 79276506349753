import React, { useState, useContext } from "react";

import { showMessage } from "helpers";
import { documentContentContext } from "contexts";

import {
  useUpdatePageCells,
  pageContext,
  getEventPos,
  getRect,
  snap,
} from "./helpers";
import Rect from "./Rect";
import Ruler from "./Ruler";

const ADD_CELL_MIN_W = 10;
const ADD_CELL_MIN_H = 8;

export default React.memo(AddCell);
function AddCell({ onExit }) {
  const { tops, lefts, page, pageContentRect, cellStyle } =
    useContext(pageContext);
  const { dragPref } = useContext(documentContentContext);
  const [downPos, downPosSet] = useState(null);
  const [pos, posSet] = useState([
    page.paddingHeight + page.height / 2,
    page.paddingWidth + page.width / 2,
  ]);
  const updatePageCells = useUpdatePageCells();

  return (
    <>
      <Ruler pos={pos} />
      <div
        onMouseMove={(event) => {
          let pos = getEventPos(event);
          pos = snap(pos, {
            tops: dragPref.snap ? tops : [],
            lefts: dragPref.snap ? lefts : [],
            insideRects: [pageContentRect],
          });
          if (!pos) return;
          posSet(pos);
        }}
        onMouseDown={() => {
          if (downPos) return; // double click
          downPosSet(pos);
          posSet([pos[0] + ADD_CELL_MIN_H, pos[1] + ADD_CELL_MIN_W]);
        }}
        onMouseUp={async () => {
          if (!downPos) return;
          downPosSet(null);
          onExit();
          await updatePageCells({
            input: {
              pageId: page.id,
              addCells: [
                {
                  ...getRect(downPos, pos),
                  ...cellStyle,
                },
              ],
            },
          });
          showMessage({ message: "成功增加单元格" });
        }}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          cursor: "crosshair",
        }}
      >
        {downPos && <Rect {...getRect(downPos, pos)} showSize />}
      </div>
    </>
  );
}
