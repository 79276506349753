import gql from "graphql-tag";

const USER_PERMISSION_TYPES = [
  {
    name: "allowManageUsers",
    label: "管理成员",
  },
  {
    name: "allowInviteUsers",
    label: "邀请新成员",
  },
  {
    name: "allowViewAuditRecords",
    label: "查看审计日志",
  },
  { name: "allowViewDeletedItems", label: "查看已删除文件" },
  { name: "allowManageSecurity", label: "管理登录设备" },
  { name: "allowManageDataQueries", label: "管理查询" },
  { name: "allowManagePicklists", label: "管理数据列表" },
  { name: "allowManageShareLinks", label: "管理公开浏览链接" },
  { name: "allowManageNotices", label: "管理团队公告" },
  { name: "allowManageCompanies", label: "管理单位" },
  { name: "allowManagePTemplates", label: "管理属性设置" },
];
export default USER_PERMISSION_TYPES;

export const UserPermissionsFragment = gql`
  fragment UserPermissionsFragment on User {
    allowManageUsers
    allowInviteUsers
    allowViewAuditRecords
    allowViewDeletedItems
    allowManageSecurity
    allowManageDataQueries
    allowManagePicklists
    allowManageShareLinks
    allowManageNotices
    allowManageCompanies
    allowManagePTemplates
  }
`;
