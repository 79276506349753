import { gql } from "@apollo/client";

import { ActionFormDialog } from "controls";
import { useAction, useData } from "hooks";
import { FolderInput, ItemsInput } from "inputs";

export default function CopyItemsDialog({ itemIds, folderId, ...others }) {
  const data = useData(
    gql`
      query CopyItemsDialog($itemId: ID) {
        item(id: $itemId) {
          id
          ancestorFolder {
            id
          }
        }
      }
    `,
    { itemId: itemIds[0] },
  );

  const copyItems = useAction(gql`
    mutation CopyItemsDialog($input: CopyItemsInput!) {
      copyItems(input: $input) {
        destinationFolder {
          id
          items {
            id
          }
        }
      }
    }
  `);

  return (
    <ActionFormDialog
      {...others}
      title="复制文件"
      fields={[
        {
          name: "itemIds",
          label: "复制文件",
          value: itemIds,
          required: true,
          readOnly: true,
          inputComponent: ItemsInput,
        },
        {
          name: "destinationFolderId",
          label: "目标目录",
          required: true,
          inputComponent: FolderInput,
          value: folderId,
          options: { topFolderId: data?.item.ancestorFolder.id },
        },
      ]}
      onSubmit={async (formData) => {
        await copyItems({
          input: {
            itemIds,
            ...formData,
          },
        });
      }}
    />
  );
}
