import { assign } from "lodash-es";

import sleep from "./sleep";

export default async function printPdf(url, callback) {
  const response = await fetch(url);
  if (!response.ok)
    throw new Error(`Fetch error: ${response.status} ${response.statusText}`);
  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);

  await printUrl(objectUrl, callback);
}

export async function printUrl(url, callback) {
  const iframe = document.createElement("iframe");
  assign(iframe.style, {
    position: "fixed",
    bottom: -110,
    right: -110,
    width: 100,
    height: 100,
  });
  document.body.appendChild(iframe);
  await new Promise((resolve) => {
    iframe.addEventListener("load", resolve);
    iframe.setAttribute("src", url);
  });
  iframe.contentWindow.focus();
  await sleep(100);
  while (callback) {
    if (callback(iframe.contentWindow)) break;
    await sleep(100);
  }
  iframe.contentWindow.print();
}
