import React, { createContext, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router";
import { gql } from "@apollo/client";
import { Star } from "mdi-material-ui";

import { NotificationIcon, ChatIcon } from "controls/icons";
import { teamRoute, DESKTOP_BUILD } from "helpers";
import { useRoute, useData, useChannel } from "hooks";
import NewNotificationsDrawer from "views/viewport/NewNotificationsDrawer";
import FavoritablesDrawer from "views/viewport/FavoritablesDrawer";
import ChatsList from "views/chats/ChatsList";

export const rightDrawerContext = createContext();

export default function RightDrawerProvider({ children }) {
  const { teamId } = useRoute(teamRoute, { optional: true });
  const [data, dataMeta] = useData(
    gql`
      query RightDrawerProvider($teamId: ID!) {
        team(id: $teamId) {
          id
          messagesCount(unread: true)
        }
        userLogin {
          id
          notificationsCount(onlyNew: true)
        }
      }
    `,
    {
      teamId,
    },
    {
      skip: !teamId,
      returnMeta: true,
    },
  );

  useChannel(
    "NotificationsChannel",
    {
      teamId,
    },
    () => {
      dataMeta.refetch();
    },
    {
      skip: !teamId,
    },
  );

  useChannel(
    "ChatChannel",
    {
      teamId,
    },
    () => {
      dataMeta.refetch();
    },
    {
      skip: !teamId,
    },
  );

  const rightDrawerTabs = DESKTOP_BUILD
    ? []
    : [
        {
          name: "newNotifications",
          render: () => <NewNotificationsDrawer />,
          title: "通知",
          icon: <NotificationIcon />,
          badgeContent: data?.userLogin?.notificationsCount,
        },
        {
          name: "favoritables",
          render: () => <FavoritablesDrawer />,
          title: "收藏夹",
          icon: <Star />,
        },
        {
          name: "chats",
          render: () => <ChatsList />,
          title: "讨论",
          icon: <ChatIcon />,
          badgeContent: data?.team.messagesCount,
        },
      ];

  const [rightDrawerTab, rightDrawerTabSet] = useState("");

  const rightDrawerOpen = (tab) => rightDrawerTabSet(tab);
  const rightDrawerClose = () => rightDrawerTabSet("");

  const rightDrawerCurrentTab = rightDrawerTabs.find(
    (t) => t.name === rightDrawerTab,
  );

  const location = useLocation();
  useLayoutEffect(() => {
    if (rightDrawerTab) rightDrawerTabSet("");
  }, [location.pathname]);

  return (
    <rightDrawerContext.Provider
      value={{
        rightDrawerCurrentTab,
        rightDrawerOpen,
        rightDrawerClose,
        rightDrawerTabs,
      }}
    >
      {children}
    </rightDrawerContext.Provider>
  );
}
