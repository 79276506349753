import { fromPairs } from "lodash-es";
import { gql } from "@apollo/client";

import useRoute from "hooks/useRoute";
import useData from "hooks/useData";
import { teamRoute } from "helpers/routes";
import SuggestiveTextInput from "inputs/SuggestiveTextInput";
import { PropertyIcon } from "controls/icons";

function filtersToHash(filters) {
  return fromPairs(filters.map((filter) => [filter.label, filter.value]));
}

function hashToFilters(hash) {
  return Object.entries(hash).map(([label, value]) => ({
    label,
    value,
  }));
}

Object.assign(PropertyFiltersFilter, {
  defaultValue: [],
  serialize: (filters) => JSON.stringify(filtersToHash(filters)),
  deserialize: (string) => hashToFilters(JSON.parse(string)),
});

export default function PropertyFiltersFilter({
  value: filters,
  valueSet: filtersSet,
  labels,
  ownerType,
}) {
  return labels
    ?.filter((l) => l)
    .map((label) => (
      <FieldFilter
        key={label}
        {...{
          label,
          filters,
          filtersSet,
          ownerType,
        }}
      />
    ));
}

function FieldFilter({ label, ownerType, filters, filtersSet }) {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query FieldFilter($teamId: ID!, $label: String!, $ownerType: String) {
        team(id: $teamId) {
          id
          propertyValueCounts(label: $label, ownerType: $ownerType) {
            value
            count
          }
        }
      }
    `,
    { teamId, label, ownerType },
  );

  return (
    <div style={{ margin: 5, minWidth: 150 }}>
      <SuggestiveTextInput
        label={
          <>
            <PropertyIcon fontSize="inherit" />
            {label}
          </>
        }
        value={filtersToHash(filters)[label] || ""}
        onChange={(v) => {
          const hashNew = { ...filtersToHash(filters) };
          if (v) hashNew[label] = v;
          else delete hashNew[label];
          const filtersNew = hashToFilters(hashNew);
          filtersSet(filtersNew);
        }}
        suggestions={data?.team.propertyValueCounts}
      />
    </div>
  );
}
