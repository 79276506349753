import { useState } from "react";
import { pick } from "lodash-es";
import { filesize } from "filesize";
import { gql } from "@apollo/client";
import { Paper, TextField, Button, Chip } from "@mui/material";
import { Paperclip } from "mdi-material-ui";

import { uploadBlob, abortUploadBlob, selectFiles } from "helpers";
import newId from "helpers/newId";

export const MessageFormFragment = gql`
  fragment MessageFormFragment on Message {
    content
    filename
    etag
    size
  }
`;

const EMPTY_MESSAGE = {
  content: "",
  filename: "",
  etag: "",
  size: 0,
};

const _messageKeys = ["content", "etag", "filename", "size"];

export default function MessageForm({
  messageDefault = EMPTY_MESSAGE,
  onCancel,
  submitLabel,
  submitIcon,
  onSubmit,
  ...others
}) {
  const [hovered, hoveredSet] = useState(false);
  const [focused, focusedSet] = useState(false);
  const [message, messageSet] = useState(pick(messageDefault, _messageKeys));
  const [uploading, uploadingSet] = useState(null);

  const messageEmpty = !message.content && !message.etag;

  const handleSubmit = () => {
    if (messageEmpty) return;
    onSubmit(message);
    messageSet(pick(messageDefault, _messageKeys));
  };

  const handleBlob = async (blob) => {
    const id = newId();

    messageSet((m) => ({ ...m, etag: "", filename: "", size: 0 }));
    uploadingSet({
      id,
      total: blob.size,
      name: blob.name,
      uploaded: 0,
    });
    try {
      const { etag } = await uploadBlob({
        id,
        blob,
        onProgress: ({ uploaded }) => uploadingSet((u) => ({ ...u, uploaded })),
      });

      messageSet((m) => ({
        ...m,
        etag,
        filename: blob.name,
        size: blob.size,
      }));
    } finally {
      uploadingSet(null);
    }
  };

  return (
    <Paper
      component="form"
      variant="outlined"
      {...others}
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        outline: hovered || focused ? "solid 1px" : null,
        ...others.style,
      }}
      onFocus={() => focusedSet(true)}
      onBlur={() => focusedSet(false)}
      onMouseEnter={() => hoveredSet(true)}
      onMouseLeave={() => hoveredSet(false)}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
      onDragOver={(event) => {
        if (event.dataTransfer.types.includes("Files")) event.preventDefault();
      }}
      onDrop={(event) => {
        const [blob] = event.dataTransfer.files;
        if (!blob) return;
        event.preventDefault();
        handleBlob(blob);
      }}
    >
      <TextField
        multiline
        fullWidth
        placeholder="发送新消息"
        autoFocus
        value={message.content}
        onChange={(event) => {
          const content = event.currentTarget.value;
          messageSet((m) => ({
            ...m,
            content,
          }));
        }}
        onKeyDown={(event) => {
          const ctrlOrMeta = event.ctrlKey || event.metaKey;
          if (event.key === "Enter" && ctrlOrMeta) {
            event.preventDefault();
            handleSubmit(event);
          }
        }}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        style={{ padding: "6px 12px" }}
        onPaste={(event) => {
          const [blob] = event.clipboardData?.files || [];
          if (blob) handleBlob(blob);
        }}
      />
      <div style={{ padding: 3, marginTop: -6 }}>
        {uploading && (
          <Chip
            style={{ margin: 3 }}
            icon={<Paperclip />}
            label={
              <>
                {uploading.name} (已上传{filesize(uploading.uploaded)}/
                {filesize(uploading.total)})
              </>
            }
            onDelete={() => abortUploadBlob({ id: uploading.id })}
          />
        )}
        {message.etag && (
          <Chip
            style={{ margin: 3 }}
            icon={<Paperclip />}
            label={
              <>
                {message.filename} ({filesize(message.size)})
              </>
            }
            onDelete={() =>
              messageSet((m) => ({ ...m, etag: "", filename: "", size: 0 }))
            }
          />
        )}
      </div>
      <Paper
        variant="outlined"
        square
        style={{
          borderStyle: "solid none none none",
          padding: 3,
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
        }}
      >
        {[
          //
          !message.etag &&
            !uploading && [
              <Paperclip />,
              "插入附件",
              () => selectFiles({}, ([blob]) => handleBlob(blob)),
            ],
        ]
          .filter((a) => a)
          .map(([icon, label, onClick]) => (
            <Button
              key={label}
              title={label}
              onClick={onClick}
              size="small"
              style={{ margin: 3, minWidth: "auto" }}
              variant="outlined"
            >
              {icon}
            </Button>
          ))}
        <div style={{ margin: "0 10px", opacity: 0.5 }}>
          按 Ctrl + Enter {submitLabel}
        </div>
        <div style={{ flex: "1 0 auto", textAlign: "right" }}>
          {onCancel && (
            <Button size="small" style={{ margin: 3 }} onClick={onCancel}>
              取消
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
            style={{ margin: 3 }}
            disabled={messageEmpty}
            endIcon={submitIcon}
          >
            {submitLabel}
          </Button>
        </div>
      </Paper>
    </Paper>
  );
}
