import React from "react";
import { gql } from "@apollo/client";

import { teamRoute, showMessage } from "helpers";
import { FormDialog, Message } from "controls";
import { useRoute, useData, useAction } from "hooks";
import { UserInput } from "inputs";

export default function TransferTeamDialog({ onClose }) {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query TransferTeamDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          name
          owner {
            id
          }
        }
      }
    `,
    { teamId },
  );
  const transferTeam = useAction(gql`
    mutation ($input: TransferTeamInput!) {
      transferTeam(input: $input) {
        team {
          id
          owner {
            id
          }
          canConfigure
          canTransfer
          canDelete
          canLeave
        }
      }
    }
  `);
  return (
    <>
      <FormDialog
        title={`更改团队${data?.team.name}的所有者`}
        onClose={onClose}
        onSubmit={async (formData) => {
          await transferTeam({
            input: {
              teamId,
              ...formData,
            },
          });
          showMessage({
            message: "成功更改团队所有者",
          });
        }}
        fields={[
          {
            label: "新的所有者",
            name: "newOwnerUserId",
            value: data?.team.owner?.id,
            required: true,
            inputComponent: UserInput,
          },
        ]}
      >
        <Message
          message={`该操作将转移团队（${data?.team.name}）创始人为其他成员，转移后目标成员将成为新的创始人，该操作不可逆，请谨慎操作。`}
        />
      </FormDialog>
    </>
  );
}
