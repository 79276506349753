import React from "react";
import { gql } from "@apollo/client";
import { Download, Printer } from "mdi-material-ui";
import QueryString from "query-string";

import {
  Table,
  FileSizeCell,
  DateTimeCell,
  FileIcon,
  FolderIcon,
} from "controls";
import { filterBlobUrl, shareRoute } from "helpers";
import { usePrintPreviewable, useRoute, useData } from "hooks";

export const ShareItemsTableFragment = gql`
  fragment ShareItemsTableFragment on ShareItem {
    id
    itemType
    path
    name
    size
    blobUrl
    blobDownloadUrl
    previewMode
    revisionedAt
  }
`;

export default function ShareItemsTable({ rows, enablePath, ...others }) {
  const [previewModePrintable, printPreviewable] = usePrintPreviewable();

  const { shareToken } = useRoute(shareRoute);
  const data = useData(
    gql`
      query ShareView($shareToken: ID!) {
        share(shareToken: $shareToken) {
          id
          rootItem {
            id
            path
          }
        }
      }
    `,
    { shareToken },
  );

  return (
    <Table
      {...others}
      columns={[
        {
          label: "名称",
        },
        enablePath && {
          label: "位置",
        },
        {
          label: "尺寸",
          cellComponent: FileSizeCell,
        },
        {
          label: "修订时间",
          cellComponent: DateTimeCell,
        },
      ].filter((c) => c)}
      rows={rows
        ?.map((item) => ({
          ...item,
          path: data && item.path.slice(data.share.rootItem.path.length - 1),
        }))
        .map(({ path, ...item }) => ({
          id: item.id,
          title: item.name,
          href:
            path &&
            `/shares/${shareToken}?${QueryString.stringify({
              path: path.slice(1).join("/"),
            })}`,
          icon: item.itemType === "folder" ? <FolderIcon /> : <FileIcon />,
          values: [
            item.name,
            ...(enablePath ? [path.join("/")] : []),
            item.size,
            item.revisionedAt,
          ],
          actions: [
            filterBlobUrl(item.blobDownloadUrl) && {
              label: "下载文件",
              icon: <Download />,
              href: filterBlobUrl(item.blobDownloadUrl),
            },
            item.__typename === "File" && {
              label: "打印该文件",
              disabled: !previewModePrintable(item.previewMode),
              icon: <Printer />,
              onClick: () => printPreviewable({ previewableId: item.id }),
            },
          ].filter((a) => a),
        }))}
    />
  );
}
