import { gql } from "@apollo/client";
import { Paper } from "@mui/material";

import Page, { PageFragment } from "./Page";

export const PageThumbnailFragment = gql`
  fragment PageThumbnailFragment on Page {
    ...PageFragment
  }
  ${PageFragment}
`;

export default function PageThumbnail({
  page,
  scale = 0.14,
  children,
  ...others
}) {
  return (
    <Paper
      square
      {...others}
      style={{
        width: `${page.width * scale}mm`,
        height: `${page.height * scale}mm`,
        boxSizing: "content-box",
        position: "relative",
        ...others.style,
      }}
    >
      <Page
        page={page}
        elevation={0}
        style={{
          top: 0,
          left: 0,
          position: "absolute",
          transformOrigin: "top left",
          transform: `scale(${scale})`,
          margin: 0,
        }}
      />
      {children}
    </Paper>
  );
}
