import React from "react";
import { gql } from "@apollo/client";
import { Plus, Delete } from "mdi-material-ui";
import { Chip } from "@mui/material";

import {
  UserAvatar,
  UserAvatarFragment,
  ItemPath,
  ItemPathFragment,
} from "blocks";
import {
  folderRoute,
  showMessage,
  PERMISSION_CATEGORIES,
  PermissionsFragment,
  wrapInParen,
} from "helpers";
import {
  TabContent,
  Table,
  CheckboxCell,
  ChipsCell,
  CompanyIcon,
  ObjectCard,
  CardsList,
} from "controls";
import {
  useRoute,
  useData,
  useAction,
  useConfirmDialog,
  useDialogOpener,
  useFilterBar,
} from "hooks";

import AddPermissionsDialog from "./AddPermissionsDialog";

export default function PermissionsTab() {
  const { teamId, folderId } = useRoute(folderRoute);
  const [{ companyId, categoryTitle, inherited }, filterBar] = useFilterBar([
    {
      type: "select",
      name: "categoryTitle",
      label: "权限类别",
      defaultValue: PERMISSION_CATEGORIES[0].title,
      options: PERMISSION_CATEGORIES.map((permissionCategory) => ({
        label: permissionCategory.title,
        value: permissionCategory.title,
        icon: permissionCategory.icon,
      })),
      alwaysEnabled: true,
    },
    {
      type: "company",
      name: "companyId",
      label: "单位",
    },
    {
      type: "boolean",
      name: "inherited",
      label: "显示继承的权限",
    },
  ]);

  const permissionCategory = PERMISSION_CATEGORIES.find(
    (c) => c.title === categoryTitle,
  );

  const data = useData(
    gql`
      query PermissionsTab($folderId: ID!, $companyId: ID) {
        folder(id: $folderId) {
          id
          canChangePermission
          permissions(companyId: $companyId) {
            id
            ...PermissionsFragment
            user {
              id
              name
              ...UserAvatarFragment
              companyRoles {
                id
                title
                company {
                  id
                  name
                }
              }
            }
          }

          inheritedPermissions {
            id
            ...PermissionsFragment
            folder {
              id
              ...ItemPathFragment
            }
            user {
              id
              name
              ...UserAvatarFragment
              companyRoles {
                id
                title
                company {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ${PermissionsFragment}
      ${UserAvatarFragment}
      ${ItemPathFragment}
    `,
    { folderId, companyId },
  );

  const removePermission = useAction(gql`
    mutation PermissionsTab($input: RemovePermissionInput!) {
      removePermission(input: $input) {
        folder {
          id
          permissions {
            id
            folder {
              id
            }
          }
        }
      }
    }
  `);

  const updatePermission = useAction(gql`
    mutation PermissionsTab($input: UpdatePermissionInput!) {
      updatePermission(input: $input) {
        permission {
          id
          ...PermissionsFragment
        }
      }
    }
    ${PermissionsFragment}
  `);
  const confirmDialog = useConfirmDialog();
  const openAddPermissionsDialog = useDialogOpener(AddPermissionsDialog);

  return (
    <TabContent
      actions={[
        {
          title: "增加成员",
          icon: <Plus />,
          disabled: !data?.folder.canChangePermission,
          onClick: () => openAddPermissionsDialog({ folderIds: [folderId] }),
        },
      ]}
    >
      {filterBar}
      {!inherited && (
        <Table
          columns={[
            {
              label: "成员",
            },
            {
              label: "单位",
              cellComponent: ChipsCell,
            },
            ...permissionCategory.permissionTypes.map((permissionType) => ({
              label: permissionType.label,
              cellComponent: CheckboxCell,
            })),
          ]}
          rows={
            data?.folder.permissions.map((permission) => ({
              id: permission.user.id,
              title: permission.user.name,
              icon: (
                <UserAvatar
                  user={permission.user}
                  style={{ width: 32, height: 32, margin: -4 }}
                  triggerUserDialog
                />
              ),
              values: [
                permission.user.name,
                permission.user.companyRoles.map((companyRole) => ({
                  icon: <CompanyIcon />,
                  label: `${companyRole.company.name}${wrapInParen(
                    companyRole.title,
                  )}`,
                  href: `/${teamId}/${companyRole.company.id}`,
                })),
                ...permissionCategory.permissionTypes.map((permissionType) => ({
                  disabled: !data.folder.canChangePermission,
                  checked: permission[permissionType.name],
                  title: `${permission[permissionType.name] ? "取消" : "授予"}${
                    permission.user.name
                  }权限: ${permissionType.label}`,
                  onChange: async (checked) => {
                    await updatePermission({
                      input: {
                        permissionId: permission.id,
                        attribute: permissionType.name,
                        enable: checked,
                      },
                    });
                    showMessage({
                      message: `成功修改${permission.user.name}的权限`,
                    });
                  },
                })),
              ],
              actions: [
                {
                  icon: <Delete />,
                  label: "删除该权限",
                  disabled: !data.folder.canChangePermission,
                  onClick: async () => {
                    await confirmDialog();
                    await removePermission({
                      input: {
                        permissionId: permission.id,
                      },
                    });
                    showMessage({
                      message: `成功删除${permission.user.name}的权限`,
                    });
                  },
                },
              ],
            })) || []
          }
        />
      )}
      {inherited && (
        <CardsList
          cards={data?.folder.inheritedPermissions.map((permission) => (
            <ObjectCard
              key={permission.id}
              avatar={<UserAvatar user={permission.user} />}
              title={permission.user.name}
              subheader={permission.user.companyRoles
                .map(
                  (companyRole) =>
                    `${companyRole.company.name}${wrapInParen(
                      companyRole.title,
                    )}`,
                )
                .join(",")}
              fields={[
                {
                  label: "设置位置",
                  content: <ItemPath clickable item={permission.folder} />,
                },
                ...PERMISSION_CATEGORIES.map((permissionCategory) => ({
                  ...permissionCategory,
                  permissionTypes: permissionCategory.permissionTypes.filter(
                    (permissionType) => permission[permissionType.name],
                  ),
                }))
                  .filter((c) => c.permissionTypes.length)
                  .map((permissionCategory) => ({
                    label: permissionCategory.title,
                    content: permissionCategory.permissionTypes.map(
                      (permissionType) => (
                        <Chip
                          size="small"
                          style={{ margin: 3 }}
                          key={permissionType.name}
                          icon={permissionCategory.icon}
                          label={permissionType.label}
                        />
                      ),
                    ),
                  })),
              ]}
            />
          ))}
        />
      )}
    </TabContent>
  );
}
