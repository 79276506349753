import { useState } from "react";
import { Paper } from "@mui/material";
import { gql } from "graphql-tag";

import { showMessage, teamRoute, PAGE_SIZE_A4, PAGE_SIZES } from "helpers";
import { FormDialog } from "controls";
import { useAction, useData, useRoute } from "hooks";
import { ItemInput, RadiosInput, BooleanInput } from "inputs";

import PageThumbnail, { PageThumbnailFragment } from "./PageThumbnail";

export default function AddPageDialog({ documentId, afterPageId, ...others }) {
  const [enableTemplate, enableTemplateSet] = useState(false);
  const [templateDocumentId, templateDocumentIdSet] = useState(null);
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query AddPageDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          docTemplatesFolder {
            id
          }
        }
      }
    `,
    { teamId },
  );

  const dataTemplateDocument = useData(
    gql`
      query AddPageDialog_templateDocument($templateDocumentId: ID!) {
        document(id: $templateDocumentId) {
          id
          pages {
            id
            ...PageThumbnailFragment
          }
        }
      }
      ${PageThumbnailFragment}
    `,
    { templateDocumentId },
    { skip: !templateDocumentId },
  );

  const addPage = useAction(gql`
    mutation DocumentContentView($input: AddPageInput!) {
      addPage(input: $input) {
        document {
          id
          pages {
            id
          }
        }
      }
    }
  `);

  return (
    <FormDialog
      disabled={!data}
      {...others}
      title="添加表单页"
      fields={[
        {
          name: "enableTemplate",
          label: "使用表单模版里的页作为模版",
          value: false,
          onChange: enableTemplateSet,
          helperText: "您可以使用现有表单模版里的页，如不使用则创建空白页",
          inputComponent: BooleanInput,
        },
        enableTemplate && {
          name: "templateDocumentId",
          label: "表单模版",
          inputComponent: ItemInput,
          onChange: templateDocumentIdSet,
          required: true,
          options: {
            itemTypes: ["Document"],
            topFolderId: data?.team.docTemplatesFolder.id,
          },
        },
        enableTemplate &&
          dataTemplateDocument && {
            name: "templatePageId",
            label: "模版页",
            inputComponent: RadiosInput,
            value: dataTemplateDocument.document.pages[0]?.id,
            required: true,
            options: {
              options: dataTemplateDocument?.document.pages?.map(
                (page, pageIndex) => ({
                  value: page.id,
                  label: `第${pageIndex + 1}页`,
                  thumbnail: (
                    <PageThumbnail
                      page={page}
                      scale={0.3}
                      style={{
                        margin: 5,
                      }}
                    />
                  ),
                }),
              ),
            },
          },
        !enableTemplate && {
          name: "pageSizeValue",
          label: "页面尺寸",
          inputComponent: RadiosInput,
          value: PAGE_SIZE_A4.value,
          required: true,
          options: {
            options: PAGE_SIZES.map((pageSize) => ({
              ...pageSize,
              thumbnail: (
                <Paper
                  square
                  style={{
                    width: pageSize.pageAttributes.width * 0.5,
                    height: pageSize.pageAttributes.height * 0.5,
                    margin: 10,
                    fontSize: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {`${pageSize.pageAttributes.width} x ${pageSize.pageAttributes.height}mm`}
                </Paper>
              ),
            })),
          },
        },
      ].filter((t) => t)}
      onSubmit={async ({
        pageSizeValue,
        enableTemplate,
        templateDocumentId,
        ...formData
      }) => {
        await addPage({
          input: {
            documentId,
            afterPageId,
            ...formData,
            ...PAGE_SIZES.find((p) => p.value === pageSizeValue)
              ?.pageAttributes,
          },
        });
        showMessage({ message: "成功增加页" });
      }}
    />
  );
}
