import React from "react";
import { gql } from "@apollo/client";

import { useData, useActionFragment } from "hooks";
import { FormCard } from "controls";
import PropertiesCard, {
  PropertiesCardFragment,
} from "views/properties/PropertiesCard";

export default function InfoTab({ projectId }) {
  const data = useData(
    gql`
      query DocumentsTab($projectId: ID!) {
        project(id: $projectId) {
          id
          folder {
            id
            basename
            canRename
          }
          ...PropertiesCardFragment
        }
      }
      ${PropertiesCardFragment}
    `,
    { projectId },
  );

  const renameItem = useActionFragment("renameItem", "item { id name }");

  return (
    <>
      <FormCard
        disabled={!data?.project.folder.canRename}
        title="基本信息"
        fields={[
          {
            label: "名称",
            name: "basename",
            value: data?.project.folder.basename,
            required: true,
          },
        ]}
        onSubmit={async (formData) => {
          await renameItem({
            input: {
              itemId: data.project.folder.id,
              ...formData,
            },
          });
        }}
      />
      <PropertiesCard propertiesOwner={data?.project} />
    </>
  );
}
