import React from "react";
import { times } from "lodash-es";
import { Paper } from "@mui/material";
import ContentLoader from "react-content-loader";

export default function PaperLoader({ ...others }) {
  return (
    <Paper square {...others} style={{ margin: 10, ...others.style }}>
      <ContentLoader
        style={{ margin: "0 auto", display: "block" }}
        speed={2}
        width={800}
        height={1000}
        viewBox="0 0 800 1000"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="200" y="60" rx="3" ry="3" width="400" height="50" />
        {times(15).map((i) => (
          <React.Fragment key={i}>
            <rect
              x="50"
              y={150 + 40 * i}
              rx="3"
              ry="3"
              width="150"
              height="25"
            />
            <rect
              x="220"
              y={150 + 40 * i}
              rx="3"
              ry="3"
              width="500"
              height="25"
            />
          </React.Fragment>
        ))}
      </ContentLoader>
    </Paper>
  );
}
