import React from "react";
import { gql } from "@apollo/client";
import { ClipboardOutline, Pencil, Delete, Plus } from "mdi-material-ui";
import { IconButton, TextField, InputAdornment } from "@mui/material";

import {
  useRoute,
  useData,
  useDialogOpener,
  useAction,
  useConfirmDialog,
} from "hooks";
import { teamRoute, showMessage } from "helpers";
import {
  Table,
  DateTimeCell,
  Message,
  ItemPathCell,
  UsersCell,
  ComponentCell,
  TabContent,
} from "controls";

import EditShareLinkDialog from "./EditShareLinkDialog";
import CreateShareLinkDialog from "./CreateShareLinkDialog";

export default function ShareLinksTab() {
  const { teamId } = useRoute(teamRoute);
  const openEditShareLinkDialog = useDialogOpener(EditShareLinkDialog);
  const openCreateShareLinkDialog = useDialogOpener(CreateShareLinkDialog);
  const confirmDialog = useConfirmDialog();

  const data = useData(
    gql`
      query ShareLinksTab($teamId: ID!) {
        team(id: $teamId) {
          id
          name
          shareLinks {
            id
            name
            createdAt
            expiresAt
            expired
            shareUrl
            item {
              id
            }
            createdByUser {
              id
            }
          }
          canManageShareLinks
        }
      }
    `,
    { teamId },
  );

  const shareLinkRemove = useAction(gql`
    mutation ShareLinksTab($input: ShareLinkRemoveInput!) {
      shareLinkRemove(input: $input) {
        team {
          id
          shareLinks {
            id
          }
        }
      }
    }
  `);

  return (
    <TabContent
      actions={[
        {
          icon: <Plus />,
          title: "创建公开浏览链接",
          disabled: !data?.team.canManageShareLinks,
          onClick: () => openCreateShareLinkDialog(),
        },
      ]}
    >
      <Message>
        你可以创建链接公开指定目录，通过这些链接无需登录即可浏览目录中的内容
      </Message>
      <Table
        columns={[
          {
            label: "备注",
          },
          {
            label: "位置",
            cellComponent: ItemPathCell,
          },
          {
            label: "有效期至",
            cellComponent: DateTimeCell,
          },
          { label: "链接地址", cellComponent: ComponentCell },
          {
            label: "创建成员",
            cellComponent: UsersCell,
          },
          { label: "创建时间", cellComponent: DateTimeCell },
        ]}
        rows={data?.team.shareLinks.map((shareLink) => ({
          title: shareLink.name,
          values: [
            shareLink.name,
            shareLink.item.id,
            shareLink.expiresAt,
            {
              component: (
                <TextField
                  variant="outlined"
                  size="small"
                  value={shareLink.shareUrl}
                  style={{ width: 300 }}
                  error={shareLink.expired}
                  helperText={shareLink.expired && <>(链接已过期)</>}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          title="复制到剪切板"
                          size="small"
                          color="primary"
                          edge="end"
                          disabled={!navigator.clipboard?.writeText}
                          onClick={async () => {
                            await navigator.clipboard.writeText(
                              shareLink.shareUrl,
                            );
                            showMessage({ message: "成功复制链接到剪切板" });
                          }}
                        >
                          <ClipboardOutline />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              ),
            },
            [shareLink.createdByUser?.id],
            shareLink.createdAt,
          ],
          actions: [
            {
              label: "修改",
              icon: <Pencil />,
              onClick: () =>
                openEditShareLinkDialog({ shareLinkId: shareLink.id }),
            },
            {
              label: "删除",
              icon: <Delete />,
              onClick: async () => {
                await confirmDialog();
                await shareLinkRemove({ input: { shareLinkId: shareLink.id } });
                showMessage({ message: "成功删除公开浏览链接" });
              },
            },
          ],
        }))}
      />
    </TabContent>
  );
}
