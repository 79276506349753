import { SvgIcon } from "@mui/material";

export default function ProjectFolderIcon({ ...others }) {
  return (
    <SvgIcon {...others} viewBox="0 0 360 360">
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M149.4,46 L179.5,79.5 L299.9,79.5 C316.455,79.5 330,94.4075 330,113 L330,113 L330,280.5 C330,299.001539 316.523771,314 299.9,314 L299.9,314 L59.1,314 C42.476229,314 29,299.001539 29,280.5 L29,280.5 L29,79.5 C29,60.9075 42.3945,46 59.1,46 L59.1,46 L149.4,46 Z M172.888889,108.456909 C166.773333,110.904531 162.222222,116.289301 159.946667,123.142644 L130.222222,123.142644 C122.367506,123.142644 116,130.448231 116,139.460128 L116,253.682516 C116,262.694414 122.367506,270 130.222222,270 L229.777778,270 C237.632494,270 244,262.694414 244,253.682516 L244,139.460128 C244,130.448231 237.632494,123.142644 229.777778,123.142644 L200.053333,123.142644 C196,110.415007 183.768889,103.888013 172.888889,108.456909 Z M144.285714,140 L144.285714,156.285714 L215.714286,156.285714 L215.714286,140 L230,140 L230,254 L130,254 L130,140 L144.285714,140 Z M201,205 L144,205 L144,221 L201,221 L201,205 Z M216,172 L145,172 L145,188 L216,188 L216,172 Z M180,123 C183.865993,123 187,126.581722 187,131 C187,135.418278 183.865993,139 180,139 C176.134007,139 173,135.418278 173,131 C173,126.581722 176.134007,123 180,123 Z"
          id="Combined-Shape"
          fillRule="nonzero"
        ></path>
      </g>
    </SvgIcon>
  );
}
