import React, { useState } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import { useDOMEvent } from "hooks";

export default React.memo(ErrorEventListener);

function ErrorEventListener() {
  const [errors, setErrors] = useState([]);

  useDOMEvent("fmserror", (event) => {
    const error = event.detail.error;
    if (!error) return;
    setErrors([...errors, error]);
  });

  return (
    <>
      {errors
        .filter((e) => !e.isAbortError)
        .map((error, index) => ({ error, index }))
        .map(({ error, index }) => (
          <ErrorSnackbar key={index} message={error.message} />
        ))}
    </>
  );
}

const ErrorSnackbar = React.memo(function ErrorSnackbar({ message }) {
  const [open, setOpen] = useState(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        onClose={onClose}
      >
        <Alert
          severity="error"
          action={[
            <IconButton
              key="close"
              aria-label="确定"
              color="inherit"
              onClick={onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
});
