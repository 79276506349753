import React from "react";
import { gql } from "@apollo/client";
import { times } from "lodash-es";

import { Message, FetchMoreButton } from "controls";
import { showMessage } from "helpers";
import { NotificationCard, NotificationCardFragment } from "blocks";
import { useData, useAction } from "hooks";
import { CardLoader } from "loaders";
import PushPromoMessage from "views/viewport/PushPromoMessage";

export default function NewNotificationsDrawer() {
  const [data, { fetchMore }] = useData(
    gql`
      query NewNotificationsDrawer($offset: Int) {
        userLogin {
          id
          newNotificationsCount: notificationsCount(onlyNew: true)
          notificationsCount
          notifications(offset: $offset, limit: 10) {
            id
            ...NotificationCardFragment
          }
        }
      }
      ${NotificationCardFragment}
    `,
    {},
    { returnMeta: true },
  );

  const dismissNewNotifications = useAction(gql`
    mutation NewNotificationsDrawer($input: DismissNewNotificationsInput!) {
      dismissNewNotifications(input: $input) {
        userLogin {
          id
          notificationsCount(onlyNew: true)
        }
      }
    }
  `);
  return (
    <div style={{ padding: 5 }}>
      <PushPromoMessage />
      <Message
        message={`${data?.userLogin.newNotificationsCount}条未读通知`}
        actions={[
          {
            title: "全部标记为已读",
            disabled: !data?.userLogin.newNotificationsCount,
            onClick: async () => {
              await dismissNewNotifications({
                input: {},
              });
              showMessage({
                message: `成功标记所有通知为已读`,
              });
            },
          },
        ]}
      />
      {!data && times(5).map((i) => <CardLoader key={i} />)}
      {data?.userLogin.notifications.map((notification) => (
        <NotificationCard key={notification.id} notification={notification} />
      ))}
      <FetchMoreButton
        fetchMore={fetchMore}
        rows={data?.userLogin.notifications}
        rowsCount={data?.userLogin.notificationsCount}
      />
    </div>
  );
}
