import { useEffect } from "react";
import { fromPairs } from "lodash-es";

import cable from "helpers/cable";
import apolloCache from "helpers/apolloCache";

export default function useChannel(
  channelName,
  channelParams,
  onReceived,
  options = {},
) {
  const { skip = false } = options;
  useEffect(() => {
    const subscription = skip
      ? null
      : cable().subscriptions.create(
          {
            channel: channelName,
            ...channelParams,
          },
          {
            received(data) {
              onReceived(data);
            },
          },
        );

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [skip, channelName, JSON.stringify(channelParams || {})]);
}

export function useRecordContentChannel(recordType, recordId, options) {
  return useChannel(
    "RecordContentChannel",
    {
      recordId,
      recordType,
    },
    (data) => {
      if (!data) return;
      const id = apolloCache.identify(data);
      if (!id) return;

      const fields = fromPairs(
        Object.entries(data)
          .filter(([k]) => !["id", "__typename"].includes(k))
          .map(([k, v]) => [k, () => v]),
      );
      apolloCache.modify({
        id,
        fields,
      });
    },
    options,
  );
}
