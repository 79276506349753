import React, { useState, useRef, useEffect } from "react";

import FormDialog from "controls/FormDialog";

export const formDialogsContext = React.createContext();
export default React.memo(FormDialogsProvider);

function FormDialogsProvider({ children }) {
  const stateRef = useRef({});

  return (
    <formDialogsContext.Provider value={stateRef.current}>
      <FormDialogsRenderer stateRef={stateRef} />
      {children}
    </formDialogsContext.Provider>
  );
}

function FormDialogsRenderer({ stateRef }) {
  const [formDialogs, formDialogsSet] = useState([]);

  useEffect(() => {
    Object.assign(stateRef.current, { formDialogs, formDialogsSet });
    return () => {
      Object.assign(stateRef.current, {
        formDialogs: null,
        formDialogsSet: null,
      });
    };
  }, [formDialogs]);

  if (!formDialogs.length) return null;

  const {
    formDialogComponent: FormDialogComponent = FormDialog,
    ...formDialogProps
  } = formDialogs[0];

  return <FormDialogComponent {...formDialogProps} />;
}
