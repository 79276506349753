import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

import OutlinedBox from "controls/OutlinedBox";

Object.assign(MultiSelectFilter, {
  defaultValue: [],
  serialize: (v) => [...v].sort().join(","),
  deserialize: (v) => v.split(",").filter((s) => s),
});

export default function MultiSelectFilter({ label, options, value, valueSet }) {
  return (
    <OutlinedBox
      label={label}
      size="small"
      style={{ flex: "0 0 auto", margin: 5 }}
    >
      <div style={{ display: "flex" }}>
        {options.map((option) => (
          <FormControlLabel
            style={{ marginLeft: 0, marginRight: 10 }}
            control={<Checkbox size="small" style={{ padding: 7.5 }} />}
            key={option.value}
            label={option.label}
            checked={value.includes(option.value)}
            onChange={(event) =>
              valueSet(
                event.target.checked
                  ? [...value, option.value]
                  : value.filter((v) => v !== option.value),
              )
            }
          />
        ))}
      </div>
    </OutlinedBox>
  );
}
