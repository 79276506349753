import React from "react";
import gql from "graphql-tag";

import { useRoute } from "hooks";
import { teamRoute, filterBlobUrl } from "helpers";
import { ItemObjectIcon } from "blocks";
import { GridList } from "controls";

export const ItemsGridFragment = gql`
  fragment ItemsGridFragment on Item {
    id
    name
    selfUnion {
      ... on File {
        id
        thumbnailUrl
      }
      ... on Document {
        id
        thumbnailUrl
      }
    }
  }
`;

export default function ItemsGrid({ fetchMore, rows, rowsCount }) {
  const { teamId } = useRoute(teamRoute);

  return (
    <GridList
      rows={rows?.map((item) => ({
        id: item.id,
        title: item.name,
        href: `/${teamId}/${item.id}`,
        icon: <ItemObjectIcon itemId={item.id} />,
        thumbnailUrl: filterBlobUrl(item.selfUnion.thumbnailUrl),
      }))}
      rowsCount={rowsCount}
      fetchMore={fetchMore}
    />
  );
}
