import React from "react";

import { AppView, DataQueryIcon, AppTabs } from "controls";
import { teamRoute } from "helpers";
import { useRoute } from "hooks";

import DataQueriesTab from "./DataQueriesTab";

export default React.memo(DataQueriesView);

function DataQueriesView() {
  const { teamId } = useRoute(teamRoute);

  return (
    <AppView
      title="统计"
      crumbs={[
        {
          icon: <DataQueryIcon />,
          label: "统计",
          href: `/${teamId}/data_queries`,
        },
      ]}
    >
      <AppTabs
        tabs={[
          {
            name: "data_queries",
            title: "汇总查询",
            content: <DataQueriesTab />,
          },
        ]}
      />
    </AppView>
  );
}
