import { gql } from "@apollo/client";

import { useRoute, useData } from "hooks";
import { shareRoute } from "helpers";
import { AppView, TeamIcon } from "controls";

import Items from "./Items";
import Item from "./Item";
import usePath from "./usePath";

export default function ShareView() {
  const { shareToken } = useRoute(shareRoute);
  const [path, , pathGetUrl] = usePath();

  const data = useData(
    gql`
      query ShareView($shareToken: ID!, $path: [String!]!) {
        share(shareToken: $shareToken) {
          id
          teamName
          rootItem {
            id
            name
            path
          }
          item(path: $path) {
            id
            name
            itemType
            path
          }
        }
      }
    `,
    { shareToken, path },
  );

  const parentItemNames =
    data && data.share.item.path.slice(data.share.rootItem.path.length - 1);

  return (
    <main
      style={{
        minHeight: "100vh",
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "stretch",
        paddingTop: `env(safe-area-inset-top)`,
        paddingRight: `env(safe-area-inset-right)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLet: `env(safe-area-inset-left)`,
      }}
    >
      <AppView
        title={data?.share.teamName || path[path.length - 1]}
        crumbs={[
          {
            icon: <TeamIcon />,
            label: data?.share.teamName,
          },
          ...(parentItemNames || []).map((parentItemName, index) => ({
            label: parentItemName,
            href: pathGetUrl(parentItemNames.slice(1, index + 1)),
          })),
        ]}
      >
        {data?.share.item.itemType === "folder" && <Items />}
        {["file", "document"].includes(data?.share.item.itemType) && <Item />}
      </AppView>
    </main>
  );
}
