import React from "react";
import { Button, Alert } from "@mui/material";

export default function Message({
  message,
  children = message,
  type = "info",
  actions = [],
  ...others
}) {
  actions = actions.filter((a) => a);

  return (
    <Alert
      severity={type}
      {...others}
      style={{
        margin: 5,
        ...others.style,
      }}
    >
      {children}
      {!!actions.length && (
        <div style={{ marginTop: 5 }}>
          {actions.map((action) => (
            <Button
              key={action.title}
              style={{ color: "inherit", marginLeft: 10 }}
              variant="outlined"
              size="small"
              color="inherit"
              disabled={action.disabled}
              onClick={action.onClick}
              href={action.href}
              target={action.target}
            >
              {action.icon}
              {action.title}
            </Button>
          ))}
        </div>
      )}
    </Alert>
  );
}
