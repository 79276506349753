import React from "react";
import { gql } from "@apollo/client";

import useData from "hooks/useData";
import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";

import Items, { ItemInput_ItemsFragment } from "./Items";

export default function SearchResult({
  searchTerm,
  itemTypes,
  topFolderId,
  onItemSelect,
  selectedItemId,
}) {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query SearchResult(
        $topFolderId: ID
        $teamId: ID!
        $searchTerm: String
        $itemTypes: [String!]
      ) {
        team(id: $teamId) {
          items(
            searchTerm: $searchTerm
            itemTypes: $itemTypes
            itemId: $topFolderId
            limit: 20
          ) {
            id
            ...ItemInput_ItemsFragment
          }
        }
      }
      ${ItemInput_ItemsFragment}
    `,
    { teamId, searchTerm, itemTypes, topFolderId },
  );

  return (
    <Items
      enablePath
      items={data?.team.items}
      selectedItemId={selectedItemId}
      onItemSelect={onItemSelect}
    />
  );
}
