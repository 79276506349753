import React from "react";
import { gql } from "@apollo/client";
import { Puzzle, ExitRun, Delete, FolderText } from "mdi-material-ui";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";

import { teamRoute, showMessage, getDialogProps } from "helpers";
import {
  PropertyIcon,
  FormCard,
  Markdown,
  TabContent,
  ObjectCard,
  CardsList,
  Message,
} from "controls";
import { useDialogOpener, useRoute, useData, useActionFragment } from "hooks";
import { UserAvatarFragment, UserAvatar } from "blocks";

import LeaveTeamDialog from "./LeaveTeamDialog";
import TransferTeamDialog from "./TransferTeamDialog";
import DeleteTeamDialog from "./DeleteTeamDialog";

export default function GeneralTab() {
  const openLeaveTeamDialog = useDialogOpener(LeaveTeamDialog);
  const openDeleteTeamDialog = useDialogOpener(DeleteTeamDialog);

  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query GeneralTab($teamId: ID!) {
        team(id: $teamId) {
          id
          canLeave
          canDelete
        }
      }
    `,
    { teamId },
  );

  return (
    <TabContent
      actions={[
        {
          title: "离开团队",
          icon: <ExitRun />,
          disabled: !data?.team.canLeave,
          onClick: () => openLeaveTeamDialog(),
        },
        {
          title: "删除团队",
          icon: <Delete />,
          disabled: !data?.team.canDelete,
          onClick: () => openDeleteTeamDialog(),
        },
      ]}
    >
      <CardsList>
        <InfoCard />
        <OwnerCard />
        <IntegrationCards />
        <TemplateCards />
      </CardsList>
    </TabContent>
  );
}

function InfoCard() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query Info($teamId: ID!) {
        team(id: $teamId) {
          id
          name
          canConfigure
        }
      }
    `,
    { teamId },
  );
  const configureTeam = useActionFragment("configureTeam", "team { id name }");

  return (
    <FormCard
      style={{ grid: "auto-flow 100%" }}
      title="团队设置"
      disabled={!data?.team.canConfigure}
      onSubmit={async (formData) => {
        await configureTeam({
          input: {
            teamId,
            ...formData,
          },
        });
        showMessage({ message: "成功修改团队基本信息" });
      }}
      fields={[
        {
          label: "团队名称",
          name: "name",
          value: data?.team.name,
        },
      ]}
    />
  );
}

function OwnerCard() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query Permissions($teamId: ID!) {
        team(id: $teamId) {
          id
          canTransfer
          owner {
            id
            name
            ...UserAvatarFragment
          }
        }
      }
      ${UserAvatarFragment}
    `,
    { teamId },
  );
  const openTransferTeamDialog = useDialogOpener(TransferTeamDialog);

  return (
    <ObjectCard
      avatar={<UserAvatar user={data?.team.owner} />}
      title="团队所有者"
      subheader={data?.team.owner?.name || "---"}
      actions={[
        {
          title: "更改团队所有者",
          icon: <ExitRun />,
          disabled: !data?.team.canTransfer,
          onClick: () => openTransferTeamDialog(),
        },
      ]}
    >
      <Message type="info">团队所有者可以更改团队设置。</Message>
    </ObjectCard>
  );
}

function IntegrationCards() {
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query IntegrationCards($teamId: ID!) {
        team(id: $teamId) {
          id
          integrations {
            id
            name
          }
        }
      }
    `,
    { teamId },
  );

  const openViewIntegrationDialog = useDialogOpener(ViewIntegrationDialog);

  return (
    <>
      {data?.team.integrations.map((integration) => (
        <ObjectCard
          key={integration.id}
          icon={<Puzzle />}
          title={integration.name}
          subheader="已配置插件"
          style={{
            gridColumn: "auto/span 2",
          }}
          onClick={() =>
            openViewIntegrationDialog({ integrationId: integration.id })
          }
        />
      ))}
    </>
  );
}

function ViewIntegrationDialog({ integrationId, onClose, ...others }) {
  const data = useData(
    gql`
      query ViewIntegrationDialog($integrationId: ID!) {
        integration(id: $integrationId) {
          id
          name
          description
        }
      }
    `,
    { integrationId },
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      {...getDialogProps()}
      onClose={onClose}
      {...others}
    >
      <DialogTitle>{data?.integration.name}</DialogTitle>
      <DialogContent>
        <Markdown source={data?.integration.description} />{" "}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>确定</Button>
      </DialogActions>
    </Dialog>
  );
}

function TemplateCards() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query TemplateCards($teamId: ID!) {
        team(id: $teamId) {
          id
          docTemplatesFolder {
            id
          }
          projectTemplatesFolder {
            id
          }
          fileTemplatesFolder {
            id
          }
          pTemplates {
            id
            name
          }
        }
      }
    `,
    { teamId },
  );
  return (
    <>
      <ObjectCard
        icon={<FolderText />}
        title="表单模版目录"
        subheader="用于存放创建表单时使用的模版"
        href={`/${teamId}/${data?.team.docTemplatesFolder.id}`}
        disabled={!data}
      />
      <ObjectCard
        icon={<FolderText />}
        title="项目模版目录"
        subheader="用于存放创建项目时使用的模版"
        href={`/${teamId}/${data?.team.projectTemplatesFolder.id}`}
        disabled={!data}
      />
      <ObjectCard
        icon={<FolderText />}
        title="新建文档模版目录"
        subheader="用于存放新建文档时使用的模版"
        href={`/${teamId}/${data?.team.fileTemplatesFolder.id}`}
        disabled={!data}
      />
      {data?.team.pTemplates.map((pTemplate) => (
        <ObjectCard
          key={pTemplate.id}
          icon={<PropertyIcon />}
          title={pTemplate.name}
          subheader="设置指定对象的属性列表"
          href={`/${teamId}/${pTemplate.id}`}
        />
      ))}
    </>
  );
}
