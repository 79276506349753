import React from "react";
import { gql } from "@apollo/client";
import {
  Link,
  Download,
  Printer,
  FileArrowLeftRightOutline,
  FileSearch,
  FileEdit,
} from "mdi-material-ui";

import { fileRoute, filterBlobUrl } from "helpers";
import { TabContent, Message } from "controls";
import {
  useRoute,
  useDialogOpener,
  useData,
  usePrintPreviewable,
  useModeSwitcher,
} from "hooks";
import PreviewFrame, {
  PreviewFrameFragment,
} from "views/previewable/PreviewFrame";

import ReviseFileDialog from "./ReviseFileDialog";
import EditView from "./EditView";

export default function ContentTab() {
  const [previewModePrintable, printPreviewable] = usePrintPreviewable();
  const { fileId } = useRoute(fileRoute);
  const data = useData(
    gql`
      query ContentTab($fileId: ID!) {
        file(id: $fileId) {
          id
          name
          blobUrl
          blobDownloadUrl
          previewMode
          canReviseFile
          canViewFile
          ...PreviewFrameFragment
        }
      }
      ${PreviewFrameFragment}
    `,
    { fileId },
  );

  const openReviseFileDialog = useDialogOpener(ReviseFileDialog);
  const [mode, modeSwitcher] = useModeSwitcher("documentContentMode", [
    {
      name: "view",
      title: "查看",
      icon: <FileSearch />,
    },
    {
      name: "edit",
      title: "编辑",
      icon: <FileEdit />,
      disabled: !data?.file.canReviseFile,
    },
  ]);

  return (
    <TabContent
      toolbar={modeSwitcher}
      actions={[
        {
          title: "上传新版本",
          icon: <FileArrowLeftRightOutline />,
          disabled: !data?.file.canReviseFile,
          onClick: () => openReviseFileDialog({ fileId }),
        },
        {
          title: "在新窗口中打开",
          icon: <Link />,
          href: filterBlobUrl(data?.file.blobUrl),
          target: "_blank",
        },
        {
          title: "下载该文件",
          icon: <Download />,
          href: filterBlobUrl(data?.file.blobDownloadUrl),
        },
        {
          title: "打印该文件",
          disabled: !previewModePrintable(data?.file.previewMode),
          icon: <Printer />,
          onClick: () => printPreviewable({ previewableId: fileId }),
        },
      ].filter((a) => a)}
    >
      {data?.file.canViewFile === false ? (
        <Message type="warning">没有权限查看该文档内容</Message>
      ) : (
        <>
          {mode === "view" && <PreviewFrame previewable={data?.file} />}
          {mode === "edit" && <EditView fileId={fileId} />}
        </>
      )}
    </TabContent>
  );
}
