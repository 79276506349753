import React from "react";
import { gql } from "@apollo/client";
import { Plus, Delete } from "mdi-material-ui";

import { TabContent, CompanyIcon, Table, ChipsCell } from "controls";
import {
  useData,
  useDialogOpener,
  useRoute,
  useConfirmDialog,
  useActionFragment,
} from "hooks";
import { teamRoute } from "helpers";

import AddProjectCompanyDialog from "./AddProjectCompanyDialog";

export default function ProjectCompaniesTab({ projectId }) {
  const confirmDialog = useConfirmDialog();
  const { teamId } = useRoute(teamRoute);
  const openAddProjectCompanyDialog = useDialogOpener(AddProjectCompanyDialog);
  const [data] = useData(
    gql`
      query ProjectCompaniesTab($projectId: ID!) {
        project(id: $projectId) {
          id
          canAddProjectCompany
          projectCompanies {
            id
            role
            company {
              id
              name
            }
            canRemove
          }
        }
      }
    `,
    { projectId },
    { returnMeta: true },
  );

  const projectCompanyRemove = useActionFragment(
    "projectCompanyRemove",
    `project { id projectCompanies { id } }
    company { id projects { id } projectCompanies { id } }`,
  );

  return (
    <TabContent
      actions={[
        {
          title: "添加单位",
          icon: <Plus />,
          disabled: !data?.project.canAddProjectCompany,
          onClick: () => openAddProjectCompanyDialog({ projectId }),
        },
      ]}
    >
      <Table
        columns={[
          {
            label: "单位类型",
          },
          {
            label: "单位",
            cellComponent: ChipsCell,
          },
        ]}
        rows={data?.project.projectCompanies.map((projectCompany) => ({
          id: projectCompany.id,
          title: projectCompany.company.name,
          icon: <CompanyIcon />,
          values: [
            projectCompany.role,
            [
              {
                icon: <CompanyIcon />,
                href: `/${teamId}/${projectCompany.company.id}`,
                label: projectCompany.company.name,
              },
            ],
          ],
          actions: [
            {
              title: "移除单位",
              icon: <Delete />,
              disabled: !projectCompany.canRemove,
              onClick: async () => {
                await confirmDialog();
                await projectCompanyRemove({
                  input: { projectCompanyId: projectCompany.id },
                });
              },
            },
          ],
        }))}
      />
    </TabContent>
  );
}
