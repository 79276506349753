import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { gql } from "@apollo/client";
import { Pencil, CursorDefault } from "mdi-material-ui";

import { PropertyIcon } from "controls/icons";
import useDialogOpener from "hooks/useDialogOpener";
import { useRecordContentChannel } from "hooks/useChannel";

import PicklistDialog from "./PicklistDialog";
import InputDialog from "./InputDialog";
import MultiPicklistDialog from "./MultiPicklistDialog";
import MultiInputDialog from "./MultiInputDialog";

const PropertyInputFieldFragment = gql`
  fragment PropertyInputFieldFragment on Property {
    id
    label
    values
    multi
    canFill
    readOnly
    picklist {
      id
    }
  }
`;

export const PropertiesCardFragment = gql`
  fragment PropertiesCardFragment on PropertiesOwner {
    properties {
      id
      ...PropertyInputFieldFragment
    }
  }
  ${PropertyInputFieldFragment}
`;

export default function PropertiesCard({ propertiesOwner, ...others }) {
  return (
    <Card
      {...others}
      style={{
        margin: 5,
        ...others.style,
      }}
    >
      <CardHeader icon={<PropertyIcon />} title="属性" />
      <CardContent>
        {propertiesOwner?.properties.map((property) => (
          <PropertyInputField key={property.id} property={property} />
        ))}
      </CardContent>
    </Card>
  );
}

function PropertyInputField({ property }) {
  const openPicklistDialog = useDialogOpener(PicklistDialog);
  const openInputDialog = useDialogOpener(InputDialog);
  const openMultiPicklistDialog = useDialogOpener(MultiPicklistDialog);
  const openMultiInputDialog = useDialogOpener(MultiInputDialog);

  const disabled = !property.canFill;
  const onClick = () => {
    if (property.picklist)
      if (property.multi) openMultiPicklistDialog({ propertyId: property.id });
      else openPicklistDialog({ propertyId: property.id });
    else if (property.multi) openMultiInputDialog({ propertyId: property.id });
    else openInputDialog({ propertyId: property.id });
  };

  useRecordContentChannel("Property", property.id);

  return (
    <TextField
      style={{ margin: "5px 0" }}
      key={property.id}
      label={property.label}
      value={property.values.join(",") || "(未填写)"}
      readOnly
      fullWidth
      size="small"
      InputProps={{
        inputProps: {
          style: {
            cursor: disabled ? undefined : "pointer",
          },
        },
        onClick: disabled ? undefined : onClick,
        endAdornment: property.readOnly ? null : (
          <InputAdornment position="end">
            <IconButton disabled={disabled} title={`填写${property.label}`}>
              {property.picklist ? <CursorDefault /> : <Pencil />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
