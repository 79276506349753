import React, { useState, useEffect } from "react";
import { defer } from "lodash-es";
import { gql } from "@apollo/client";

import { TabContent, AppView, DocumentIcon, CardsList } from "controls";
import { useData, useList, useFilterBar, useRoute } from "hooks";
import {
  teamRoute,
  ORDER_BY_REVISIONED_AT_DESC,
  ORDER_BY_REVISIONED_AT,
  ORDER_BY_CREATED_AT_DESC,
  ORDER_BY_CREATED_AT,
  ORDER_BY_NAME,
} from "helpers";
import { DocumentCard, DocumentCardFragment } from "blocks";
import DocumentsTable, { DocumentsTableFragment } from "lists/DocumentsTable";
import ItemsGrid, { ItemsGridFragment } from "lists/ItemsGrid";

export default function DocumentsView() {
  const { teamId } = useRoute(teamRoute);
  const [dataRowNameSelected, dataRowNameSelectedSet] = useState(null);

  const data = useData(
    gql`
      query DocumentsView($teamId: ID!) {
        team(id: $teamId) {
          id
          dataRowNameCounts {
            value
            count
          }
        }
      }
    `,
    { teamId },
  );

  const fieldLabelsData = useData(
    gql`
      query DocumentsView($teamId: ID!, $dataRowName: String!) {
        team(id: $teamId) {
          id
          fieldLabelCounts(dataRowName: $dataRowName) {
            value
            count
          }
        }
      }
    `,
    {
      teamId,
      dataRowName: dataRowNameSelected,
    },
    { skip: !dataRowNameSelected },
  );

  const [
    {
      searchTerm,
      folderId,
      approveState,
      dataRowName,
      revisionedAtRange,
      revisionedBy,
      createdBy,
      createdAtRange,
      fieldFilters,
      orderBy,
    },
    filterBar,
  ] = useFilterBar({ enableSearchTerm: true }, [
    {
      type: "select",
      name: "approveState",
      label: "状态",
      defaultValue: "pending",
      options: [
        {
          value: "pending",
          label: "待审核",
        },
        {
          label: "已完成审核",
          value: "approved",
        },
      ],
    },
    {
      name: "folderId",
      type: "folder",
      label: "位置",
    },
    {
      type: "select",
      name: "dataRowName",
      label: "数据表",
      defaultValue: "all",
      options: [
        {
          label: "(所有)",
          value: "all",
        },
        ...(data?.team.dataRowNameCounts || []).map((entry) => ({
          label: entry.value || "(无)",
          value: entry.value,
          description: `${entry.count}个表单`,
        })),
      ],
    },
    {
      type: "user",
      label: "修订成员",
      name: "revisionedBy",
    },
    {
      type: "date_range",
      label: "修订日期",
      name: "revisionedAtRange",
    },
    {
      type: "user",
      label: "创建成员",
      name: "createdBy",
    },
    {
      type: "date_range",
      label: "创建日期",
      name: "createdAtRange",
    },
    {
      type: "field_filters",
      name: "fieldFilters",
      labels: fieldLabelsData?.team.fieldLabelCounts.map((e) => e.value),
    },
    {
      name: "orderBy",
      type: "select",
      label: "排序",
      defaultValue: ORDER_BY_REVISIONED_AT_DESC.value,
      options: [
        ORDER_BY_REVISIONED_AT_DESC,
        ORDER_BY_REVISIONED_AT,
        ORDER_BY_CREATED_AT_DESC,
        ORDER_BY_CREATED_AT,
        ORDER_BY_NAME,
      ],
    },
  ]);

  useEffect(() => {
    if (dataRowName !== dataRowNameSelected)
      defer(() => dataRowNameSelectedSet(dataRowName));
  }, [dataRowNameSelected, dataRowName]);

  const approved = approveState === "approved";

  const [documentsData, documentsDataMeta] = useData(
    gql`
      query DocumentsView(
        $teamId: ID!
        $offset: Int
        $itemId: ID
        $searchTerm: String
        $dataRowName: String
        $revisionedAtRange: DateRange
        $revisionedBy: ID
        $createdAtRange: DateRange
        $createdBy: ID
        $orderBy: ItemsOrderBy
        $fieldFilters: [FieldFilter!]
      ) {
        team(id: $teamId) {
          id
          documentsCount(
            itemId: $itemId
            searchTerm: $searchTerm
            dataRowName: $dataRowName
            revisionedAtRange: $revisionedAtRange
            revisionedBy: $revisionedBy
            createdAtRange: $createdAtRange
            createdBy: $createdBy
            fieldFilters: $fieldFilters
          )
          documents(
            itemId: $itemId
            searchTerm: $searchTerm
            dataRowName: $dataRowName
            revisionedAtRange: $revisionedAtRange
            revisionedBy: $revisionedBy
            createdAtRange: $createdAtRange
            createdBy: $createdBy
            fieldFilters: $fieldFilters

            offset: $offset
            limit: 20
            orderBy: $orderBy
          ) {
            id
            createdAt
            revisionedAt
            ...DocumentsTableFragment
            ...DocumentCardFragment
            ...ItemsGridFragment
          }
        }
      }
      ${DocumentsTableFragment}
      ${DocumentCardFragment}
      ${ItemsGridFragment}
    `,
    {
      teamId,
      itemId: folderId,
      searchTerm,
      approved,
      dataRowName: dataRowName === "all" ? null : dataRowName,
      revisionedAtRange,
      revisionedBy,
      createdBy,
      createdAtRange,
      fieldFilters,
      orderBy,
    },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("doc", {
    rows: documentsData?.team.documents,
    rowsCount: documentsData?.team.documentsCount,
    fetchMore: documentsDataMeta.fetchMore,
    card: <DocumentCard />,
    table: <DocumentsTable enablePath />,
    grid: <ItemsGrid />,
    cardsList: (
      <CardsList
        getRowDate={(row) =>
          ({
            [ORDER_BY_CREATED_AT.value]: row.createdAt,
            [ORDER_BY_CREATED_AT_DESC.value]: row.createdAt,
            [ORDER_BY_REVISIONED_AT.value]: row.revisionedAt,
            [ORDER_BY_REVISIONED_AT_DESC.value]: row.revisionedAt,
          })[orderBy] || row.revisionedAt
        }
      />
    ),
  });

  return (
    <AppView
      title="所有表单"
      crumbs={[
        {
          icon: <DocumentIcon />,
          label: "所有表单",
          href: `/${teamId}/documents`,
        },
      ]}
    >
      <TabContent toolbar={<>{listModeSwitcher}</>}>
        {filterBar}
        {list}
      </TabContent>
    </AppView>
  );
}
