import React from "react";
import { gql } from "@apollo/client";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Avatar,
} from "@mui/material";

import ListLoader from "loaders/ListLoader";
import toggleArray from "helpers/toggleArray";
import useData from "hooks/useData";
import useRoute from "hooks/useRoute";
import useFilterBar from "hooks/useFilterBar";
import { teamRoute } from "helpers/routes";
import TextLoader from "loaders/TextLoader";
import { CompanyIcon } from "controls/icons";
import FetchMoreButton from "controls/FetchMoreButton";

import MultiInput from "./MultiInput";
import SingleInput from "./SingleInput";

export default function CompanyInput({ ...others }) {
  return (
    <SingleInput
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemDialogContent}
      DialogProps={{
        maxWidth: "md",
      }}
      {...others}
    />
  );
}

CompaniesInput.defaultValue = [];

export function CompaniesInput({ ...others }) {
  return (
    <MultiInput
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderSelectDialogContent={SelectDialogContent}
      {...others}
    />
  );
}

function ItemAvatar({ item: companyId, ...others }) {
  return (
    <Avatar {...others}>
      <CompanyIcon fontSize="inherit" />
    </Avatar>
  );
}

function ItemLabel({ item: companyId }) {
  const data = useData(
    gql`
      query ItemLabel($companyId: ID!) {
        company(id: $companyId) {
          id
          name
        }
      }
    `,
    { companyId },
  );
  return <>{data?.company.name || <TextLoader />}</>;
}

function ItemDialogContent({ item: companyId, itemSubmit: companyIdSubmit }) {
  return (
    <ItemsList
      selectedCompanyIds={[companyId]}
      onCompanyClick={companyIdSubmit}
    />
  );
}

function SelectDialogContent({ items: companyIds, itemsSet: companyIdsSet }) {
  return (
    <ItemsList
      selectedCompanyIds={companyIds}
      onCompanyClick={(companyId) =>
        companyIdsSet(toggleArray(companyIds, companyId))
      }
      renderCompanySecondaryAction={(companyId) => (
        <Checkbox
          edge="end"
          checked={companyIds.includes(companyId)}
          onChange={() => companyIdsSet(toggleArray(companyIds, companyId))}
        />
      )}
    />
  );
}

function ItemsList({
  selectedCompanyIds = [],
  onCompanyClick,
  renderCompanySecondaryAction,
}) {
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query ProjectsView($teamId: ID!) {
        team(id: $teamId) {
          id
          propertyLabelCounts(ownerType: "Company") {
            value
          }
        }
      }
    `,
    {
      teamId,
    },
  );

  const [{ searchTerm, propertyFilters }, filterBar] = useFilterBar(
    {
      enableSearchTerm: true,
      noUrl: true,
    },
    [
      {
        name: "propertyFilters",
        type: "property_filters",
        labels: data?.team.propertyLabelCounts.map((e) => e.value),
        ownerType: "Company",
        alwaysEnabled: true,
      },
    ],
  );

  const [companiesData, companiesDataMeta] = useData(
    gql`
      query ItemsList(
        $teamId: ID!
        $propertyFilters: [FieldFilter!]
        $offset: Int
        $searchTerm: String
      ) {
        team(id: $teamId) {
          id
          companies(
            searchTerm: $searchTerm
            propertyFilters: $propertyFilters
            offset: $offset
            limit: 20
          ) {
            id
            name
          }
          companiesCount(
            searchTerm: $searchTerm
            propertyFilters: $propertyFilters
          )
        }
      }
    `,
    { teamId, propertyFilters, searchTerm },
    { returnMeta: true },
  );

  return (
    <>
      {filterBar}
      {!companiesData && <ListLoader avatars subheaders listSubheader />}
      <List dense>
        {companiesData?.team.companies.map((company) => (
          <ListItem
            key={company.id}
            secondaryAction={
              !renderCompanySecondaryAction
                ? undefined
                : renderCompanySecondaryAction(company.id)
            }
            disablePadding
          >
            <ListItemButton
              component="a"
              href="#"
              selected={selectedCompanyIds.includes(company.id)}
              onClick={() => onCompanyClick(company.id)}
            >
              <ListItemIcon>
                <CompanyIcon />
              </ListItemIcon>
              <ListItemText primary={company.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <FetchMoreButton
        rows={companiesData?.team.companies}
        rowsCount={companiesData?.team.companiesCount}
        fetchMore={companiesDataMeta.fetchMore}
      />
    </>
  );
}
