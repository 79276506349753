import React, { useEffect, useContext } from "react";
import { gql } from "@apollo/client";
import { Plus } from "mdi-material-ui";

import { useData, useDialogOpener } from "hooks";
import { documentContentContext } from "contexts";
import { PaperLoader } from "loaders";

import Page, { PageFragment } from "./Page";
import AddPageDialog from "./AddPageDialog";

export default function DocumentContent({ documentId }) {
  const openAddPageDialog = useDialogOpener(AddPageDialog);
  const data = useData(
    gql`
      query DocumentContentView($documentId: ID!) {
        document(id: $documentId) {
          id
          pages {
            id
            ...PageFragment
          }
        }
      }
      ${PageFragment}
    `,
    { documentId },
  );

  const { documentContentMode, printing } = useContext(documentContentContext);

  useEffect(() => {
    if (!printing) return;

    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      @page { 
        margin: 0;
      }
    `;
    document.body.appendChild(styleElement);
    return () => {
      document.body.removeChild(styleElement);
    };
  }, [printing]);

  return (
    <div data-document-content={documentId}>
      {!data && <PaperLoader />}
      {data &&
        [
          [null, null],
          ...(data.document.pages || []).map((page) => [
            page.id,
            <Page page={page} />,
          ]),
        ].map(([afterPageId, content], index) => (
          <React.Fragment key={index}>
            {content}
            {documentContentMode === "edit" && (
              <a
                href="#"
                onClick={() => openAddPageDialog({ documentId, afterPageId })}
                style={{
                  color: "inherit",
                  opacity: 0.5,
                  display: "flex",
                  width: "210mm",
                  height: "15mm",
                  margin: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  border: "dashed 2px",
                }}
              >
                <Plus />
                增加一页
              </a>
            )}
          </React.Fragment>
        ))}
    </div>
  );
}
