import { gql } from "@apollo/client";

import { FormDialog } from "controls";
import { useData, useActionFragment, useRoute } from "hooks";
import { SuggestiveTextInput } from "inputs";
import { teamRoute } from "helpers";

export default function InputDialog({ propertyId, ...others }) {
  const { teamId } = useRoute(teamRoute);

  const data = useData(
    gql`
      query InputDialog($propertyId: ID!) {
        property(id: $propertyId) {
          id
          label
          value
          owner {
            id
            __typename
          }
        }
      }
    `,
    { propertyId },
  );

  const suggestData = useData(
    gql`
      query MultiInputDialog(
        $teamId: ID!
        $label: String!
        $ownerType: String!
      ) {
        team(id: $teamId) {
          id
          propertyValueCounts(label: $label, ownerType: $ownerType) {
            value
            count
          }
        }
      }
    `,
    {
      teamId,
      label: data?.property.label,
      ownerType: data?.property.owner.__typename,
    },
    { skip: !data },
  );

  const propertyFill = useActionFragment(
    "propertyFill",
    "property { id value }",
  );

  return (
    <FormDialog
      title={`修改“${data?.property.label}”`}
      disabled={!data}
      onSubmit={async (formData) => {
        await propertyFill({
          input: {
            propertyId,
            ...formData,
          },
        });
      }}
      {...others}
      fields={[
        {
          name: "value",
          value: data?.property.value,
          label: data?.property.label || "...",
          inputComponent: SuggestiveTextInput,
          options: {
            autoFocus: true,
            suggestions: suggestData?.team.propertyValueCounts,
          },
        },
      ]}
    />
  );
}
