import React from "react";
import { gql } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

import { teamRoute, getDialogProps, showMessage } from "helpers";
import {
  useData,
  useRoute,
  useConfirmDialog,
  useAction,
  useHistory,
} from "hooks";

export default React.memo(function AcceptTeamDialog() {
  const { teamId } = useRoute(teamRoute);
  const confirmDialog = useConfirmDialog();
  const history = useHistory();
  const data = useData(
    gql`
      query AcceptTeamDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          accepted
          name
          invitedByUser {
            id
            name
          }
        }
      }
    `,
    { teamId },
  );

  const acceptTeam = useAction(gql`
    mutation AcceptTeamDialog($input: AcceptTeamInput!) {
      acceptTeam(input: $input) {
        team {
          id
          accepted
        }
      }
    }
  `);

  const leaveTeam = useAction(
    gql`
      mutation ($input: LeaveTeamInput!) {
        leaveTeam(input: $input) {
          clientMutationId
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: gql`
            query {
              teams {
                id
              }
            }
          `,
        },
      ],
    },
  );

  return (
    <>
      <Dialog
        open={!!data && !data.team.accepted}
        maxWidth="md"
        {...getDialogProps()}
      >
        <DialogTitle>欢迎加入{data?.team.name}</DialogTitle>
        <DialogContent>
          {data?.team.invitedByUser?.name}邀请您加入团队{data?.team.name}
          ，接受邀请后团队管理员将可以：
          <ul>
            <li>查看您在团队内的所有操作记录</li>
            <li>查看您登录的IP地址范围和所属地理位置</li>
            <li>删除您登录的会话记录</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={async () => {
              await acceptTeam({ input: { teamId } });
              showMessage({ message: "成功接受邀请" });
            }}
          >
            接受邀请
          </Button>
          <Button
            onClick={async () => {
              await confirmDialog();
              await leaveTeam(
                { input: { teamId } },
                {
                  awaitRefetchQueries: true,
                },
              );
              showMessage({ message: "已拒绝并删除邀请" });
              history.push("/");
            }}
          >
            拒绝并删除邀请
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
