import { gql } from "@apollo/client";
import { uniq } from "lodash-es";
import { AvatarGroup } from "@mui/material";

import UserAvatar, { UserAvatarFragment } from "./UserAvatar";

export const UserAvatarsFragment = gql`
  fragment UserAvatarsFragment on User {
    ...UserAvatarFragment
  }
  ${UserAvatarFragment}
`;

export default function UserAvatars({
  userAvatarProps: { triggerUserDialog, ...userAvatarProps },
  users = [],
  userIds = [],
  ...others
}) {
  return (
    <AvatarGroup
      max={5}
      componentsProps={{
        additionalAvatar: {
          ...userAvatarProps,
        },
      }}
      {...others}
      style={{
        justifyContent: "left",
        ...others.style,
      }}
    >
      {[
        ...uniq(userIds)
          .filter((i) => i)
          .map((userId) => ({ key: userId, userId })),
        ...users.map((user) => ({ key: user.id, user })),
      ].map((userProps) => (
        <UserAvatar
          {...userProps}
          {...userAvatarProps}
          triggerUserDialog={triggerUserDialog}
        />
      ))}
    </AvatarGroup>
  );
}
