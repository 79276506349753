import { useEffect, useId, useContext } from "react";

import { hooksPortalContext } from "providers/HooksPortalProvider";
import findAndMerge from "helpers/findAndMerge";

export default function usePortal() {
  const id = useId();

  const { elementsSet } = useContext(hooksPortalContext);
  const mount = (content) => {
    elementsSet((elements) =>
      findAndMerge(
        elements,
        id,
        { content },
        {
          create: true,
        },
      ),
    );
  };
  const unmount = () => {
    elementsSet((elements) => elements.filter((e) => e.id !== id));
  };

  useEffect(() => {
    return () => {
      unmount();
    };
  }, []);

  return {
    mount,
    unmount,
  };
}
