import newId from "./newId";

export default function showMessage(options) {
  const messageItem = {
    id: newId(),
    ...options,
  };

  window.dispatchEvent(
    new CustomEvent("appmessage", {
      detail: { messageItem },
    }),
  );
}
