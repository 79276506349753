import React from "react";
import { gql } from "@apollo/client";
import { filesize } from "filesize";
import QueryString from "query-string";

import DateTime from "controls/DateTime";
import { FileIcon, FolderIcon } from "controls/icons";
import ObjectCard from "controls/ObjectCard";
import filterBlobUrl from "helpers/filterBlobUrl";
import useRoute from "hooks/useRoute";
import useData from "hooks/useData";
import { shareRoute } from "helpers/routes";

export const ShareItemCardFragment = gql`
  fragment ShareItemCardFragment on ShareItem {
    id
    name
    size
    thumbnailUrl
    revisionedAt
  }
`;

export default function ShareItemCard({ shareItem, ...others }) {
  const { shareToken } = useRoute(shareRoute);
  const data = useData(
    gql`
      query ShareView($shareToken: ID!) {
        share(shareToken: $shareToken) {
          id
          rootItem {
            id
            path
          }
        }
      }
    `,
    { shareToken },
  );

  const path =
    data && shareItem.path.slice(data.share.rootItem.path.length - 1);

  return (
    <ObjectCard
      icon={shareItem.itemType === "folder" ? <FolderIcon /> : <FileIcon />}
      title={shareItem.name}
      {...others}
      href={
        path &&
        `/shares/${shareToken}?${QueryString.stringify({
          path: path.slice(1).join("/"),
        })}`
      }
      fields={[
        {
          label: "尺寸",
          content: filesize(shareItem.size),
        },
        {
          label: "最后修订",
          content: <DateTime value={shareItem.revisionedAt} />,
        },
      ]}
      thumbnailUrl={filterBlobUrl(shareItem.thumbnailUrl)}
    />
  );
}
