import React, { useState } from "react";
import { gql } from "@apollo/client";
import { filesize } from "filesize";
import {
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardActionArea,
} from "@mui/material";
import {
  Pencil,
  ContentSave,
  Fullscreen,
  Download,
  Paperclip,
} from "mdi-material-ui";

import { useAction, useDialogOpener } from "hooks";
import { UserAvatar, UserAvatarFragment } from "blocks";
import { ButtonMenu, DateTime, Markdown } from "controls";
import { filterBlobUrl } from "helpers";
import PreviewDialog from "views/previewable/PreviewDialog";

import MessageForm, { MessageFormFragment } from "./MessageForm";

export const ChatMessageFragment = gql`
  fragment ChatMessageFragment on Message {
    id
    content
    createdAt
    canUpdate
    user {
      id
      name
      ...UserAvatarFragment
    }
    filename
    size
    thumbnailUrl
    blobDownloadUrl
    ...MessageFormFragment
  }
  ${UserAvatarFragment}
  ${MessageFormFragment}
`;

export default function ChatMessage({ message, collapsed = false }) {
  const [hovering, hoveringSet] = useState(false);
  const [editing, editingSet] = useState(false);
  const openPreviewDialog = useDialogOpener(PreviewDialog);

  const messageUpdate = useAction(gql`
    mutation ChatMessage($input: MessageUpdateInput!) {
      messageUpdate(input: $input) {
        message {
          id
          content
          filename
          size
          thumbnailUrl
          blobDownloadUrl
        }
      }
    }
  `);

  const blobDownloadUrlFiltered = filterBlobUrl(message.blobDownloadUrl);

  return (
    <ListItem
      component="div"
      dense
      alignItems="flex-start"
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        position: "relative",
        ...((hovering || editing) && {
          backgroundColor: "#eee",
        }),
      }}
      onMouseEnter={() => hoveringSet(true)}
      onMouseLeave={() => hoveringSet(false)}
    >
      {!collapsed && (
        <ListItemAvatar>
          <UserAvatar
            user={message.user}
            style={{ margin: 5 }}
            triggerUserDialog
          />
        </ListItemAvatar>
      )}
      {collapsed && <ListItemIcon></ListItemIcon>}
      <ListItemText
        style={{ margin: 0 }}
        primary={
          !collapsed && (
            <div style={{ margin: 5 }}>
              <span style={{ fontWeight: "bold" }}>{message.user?.name}: </span>
              &nbsp;
              <DateTime
                value={message.createdAt}
                style={{ opacity: 0.3, fontSize: "0.8em" }}
              />
            </div>
          )
        }
        secondary={
          !editing ? (
            <>
              <Markdown source={message.content} style={{ margin: 5 }} />
              {message.filename && (
                <Card
                  style={{ margin: 5, width: "max-content" }}
                  variant="outlined"
                >
                  <CardHeader
                    avatar={
                      <Avatar>
                        <Paperclip fontSize="inherit" />
                      </Avatar>
                    }
                    title={<>{message.filename}</>}
                    subheader={<>{filesize(message.size)}</>}
                    action={
                      <ButtonMenu
                        actions={[
                          {
                            icon: <Fullscreen />,
                            title: "查看",
                            onClick: () =>
                              openPreviewDialog({ previewableId: message.id }),
                          },
                          {
                            icon: <Download />,
                            title: "下载",
                            href: blobDownloadUrlFiltered,
                          },
                        ]}
                      />
                    }
                  />
                  <CardActionArea
                    onClick={() =>
                      openPreviewDialog({ previewableId: message.id })
                    }
                  >
                    {message.thumbnailUrl && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={message.thumbnailUrl}
                          alt={message.filename}
                        />
                      </div>
                    )}
                  </CardActionArea>
                </Card>
              )}
            </>
          ) : (
            <MessageForm
              style={{ margin: 5 }}
              messageDefault={message}
              submitIcon={<ContentSave />}
              submitLabel="保存"
              onSubmit={async (messageNew) => {
                await messageUpdate({
                  input: {
                    messageId: message.id,
                    ...messageNew,
                  },
                });
                editingSet(false);
              }}
              onCancel={() => editingSet(false)}
            />
          )
        }
        secondaryTypographyProps={{
          component: "div",
        }}
      />
      {hovering && !editing && (
        <ButtonGroup
          style={{
            position: "absolute",
            zIndex: 1,
            top: -24,
            right: 12,
            backgroundColor: "#fefefe",
          }}
          size="small"
        >
          {[
            //
            message.canUpdate && [<Pencil />, "编辑", () => editingSet(true)],
          ]
            .filter((a) => a)
            .map(([icon, title, onClick]) => (
              <Button key={title} title={title} onClick={onClick}>
                {icon}
              </Button>
            ))}
        </ButtonGroup>
      )}
    </ListItem>
  );
}
