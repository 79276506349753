import { gql } from "@apollo/client";

import { useAction, useData } from "hooks";
import { ActionFormDialog } from "controls";
import { SortInput } from "inputs";

import PageThumbail, { PageThumbnailFragment } from "./PageThumbnail";

export default function SortPagesDialog({ documentId, ...others }) {
  const data = useData(
    gql`
      query SortPagesDialog($documentId: ID!) {
        document(id: $documentId) {
          id
          pages {
            id
            ...PageThumbnailFragment
          }
        }
      }
      ${PageThumbnailFragment}
    `,
    { documentId },
  );

  const sortPages = useAction(gql`
    mutation SortPagesDialog($input: SortPagesInput!) {
      sortPages(input: $input) {
        document {
          id
          pages {
            id
          }
        }
      }
    }
  `);

  return (
    <ActionFormDialog
      {...others}
      title="调整文档页顺序"
      disabled={!data}
      fields={[
        {
          label: "顺序",
          name: "pageIds",
          required: true,
          inputComponent: SortInput,
          value: data?.document.pages.map((p) => p.id),
          options: {
            variant: "grid",
            items: data?.document.pages.map((page, pageIndex) => ({
              value: page.id,
              content: (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column nowrap",
                    alignItems: "center",
                  }}
                >
                  <PageThumbail page={page} elevation={0} />
                  <div style={{ fontSize: ".6em", opacity: 0.6 }}>
                    原第{pageIndex + 1}页
                  </div>
                </div>
              ),
            })),
          },
        },
      ]}
      onSubmit={async (formData) => {
        await sortPages({
          input: {
            documentId,
            ...formData,
          },
        });
      }}
    />
  );
}
