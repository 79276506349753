import React from "react";
import { gql } from "@apollo/client";
import { List, ListItem, ListItemText } from "@mui/material";

import ListLoader from "loaders/ListLoader";
import FolderObjectIcon from "blocks/FolderObjectIcon";
import { TeamIcon } from "controls/icons";
import useData from "hooks/useData";
import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";

export default function Browser({ topFolderId, folderId, onFolderSelect }) {
  const { teamId } = useRoute(teamRoute);
  const dataRoot = useData(
    gql`
      query Browser($teamId: ID!) {
        team(id: $teamId) {
          id
          folders {
            id
            name
          }
        }
      }
    `,
    { teamId },
  );
  const dataFolder = useData(
    gql`
      query Browser($folderId: ID!) {
        folder(id: $folderId) {
          id
          name
          path {
            id
            name
            canAccess
          }
          subFolders {
            id
            name
          }
        }
      }
    `,
    { folderId: folderId || topFolderId },
    { skip: !(folderId || topFolderId) },
  );

  const loading = folderId ? !dataFolder : !dataRoot;

  if (loading) return <ListLoader avatars />;

  let path = [...(dataFolder?.folder.path || [])];
  if (topFolderId) {
    let i = path.findIndex((f) => f.id === topFolderId);
    if (i !== -1) path = path.slice(i);
  }

  const subFolders = dataFolder?.folder.subFolders || dataRoot.team.folders;

  return (
    <List>
      {!topFolderId && (
        <ListItem
          component="a"
          href="#"
          button
          selected={!folderId}
          onClick={() => onFolderSelect(null)}
        >
          <TeamIcon />
          <ListItemText primary="所有目录" />
        </ListItem>
      )}
      {path?.map((pathItem, pathItemIndex) => (
        <ListItem
          key={pathItem.id}
          component="a"
          href="#"
          button
          selected={folderId === pathItem.id}
          onClick={() => onFolderSelect(pathItem.id)}
          disabled={!pathItem.canAccess}
        >
          <div
            style={{
              width: 10 * (pathItemIndex + 1),
              flex: "0 0 auto",
            }}
          />
          <FolderObjectIcon folderId={pathItem.id} />
          <ListItemText primary={pathItem.name} />
        </ListItem>
      ))}
      {subFolders.map((subFolder) => (
        <ListItem
          key={subFolder.id}
          button
          component="a"
          href="#"
          onClick={() => onFolderSelect(subFolder.id)}
        >
          <div
            style={{
              width: 10 + 10 * path.length,
              flex: "0 0 auto",
            }}
          />
          <FolderObjectIcon folderId={subFolder.id} />
          <ListItemText primary={subFolder.name} />
        </ListItem>
      ))}
    </List>
  );
}
