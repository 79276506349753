import React, { useId, useState, useLayoutEffect } from "react";
import { defer } from "lodash-es";
import PropTypes from "prop-types";
import {
  ListItemText,
  TextField,
  Popper,
  Paper,
  List,
  ListItem,
} from "@mui/material";
import dasherize from "dasherize";

import literalMatch from "helpers/literalMatch";

SuggestiveTextInput.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        count: PropTypes.number,
      }).isRequired,
    ]).isRequired,
  ),
};

export default function SuggestiveTextInput({
  label,
  value,
  onChange,
  helperText,
  name,
  error,
  readOnly,
  suggestions,
  displaySuggestionsSize = 5,
  ...others
}) {
  const id = useId();
  const [anchorEl, anchorElSet] = useState(null);

  suggestions = suggestions?.map((suggestion) =>
    suggestion.constructor === String
      ? {
          value: suggestion,
          count: null,
        }
      : suggestion,
  );

  const [suggestionsHit, suggestionsHitSet] = useState(null);
  useLayoutEffect(() => {
    Promise.resolve().then(async () => {
      const suggestionsHit = [];
      for (const suggestion of suggestions || []) {
        if (await literalMatch(suggestion.value, value, { initial: true }))
          suggestionsHit.push(suggestion);
      }
      suggestionsHitSet(suggestionsHit);
    });
  }, [value, suggestions?.map((s) => s.value).join(",")]);

  return (
    <>
      <TextField
        onFocus={(event) => anchorElSet(event.currentTarget)}
        onBlur={() => defer(() => anchorElSet(null))}
        size="small"
        fullWidth
        label={label}
        name={dasherize(name)}
        error={!!error}
        helperText={(error && error.message) || helperText}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        id={id}
        type="search"
        autoComplete="off"
        {...(readOnly && {
          value: value || "-",
          onChange: undefined,
        })}
        {...others}
        InputProps={{ readOnly, ...others.InputProps }}
      />
      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl && !!suggestionsHit?.length}
        onClose={() => anchorElSet(null)}
        placement="bottom-start"
        style={{ zIndex: 100000 }}
      >
        <Paper>
          <List disablePadding>
            {suggestionsHit
              ?.slice(0, displaySuggestionsSize)
              .map((suggestion) => (
                <ListItem
                  button
                  key={suggestion.value}
                  onMouseDown={() => onChange(suggestion.value)}
                >
                  <ListItemText
                    primary={suggestion.value || "(空)"}
                    secondary={
                      suggestion.count?.constructor === Number
                        ? `${suggestion.count}条数据`
                        : null
                    }
                  />
                </ListItem>
              ))}
            {suggestionsHit?.length > displaySuggestionsSize && (
              <ListItem disabled>
                (还有{suggestionsHit.length - displaySuggestionsSize}个值)
              </ListItem>
            )}
          </List>
        </Paper>
      </Popper>
    </>
  );
}
