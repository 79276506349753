import { Cog } from "mdi-material-ui";

import { AppView, AppTabs } from "controls";
import { teamRoute } from "helpers";
import { useRoute } from "hooks";

import ProfileTab from "./ProfileTab";
import SessionsTab from "./SessionsTab";
import SignaturesTab from "./SignaturesTab";

export default function UserSettingsView() {
  const { teamId } = useRoute(teamRoute);

  return (
    <>
      <AppView
        title="我的设置"
        crumbs={[
          {
            icon: <Cog />,
            href: `/${teamId}/user_settings`,
            label: "我的设置",
          },
        ]}
      >
        <AppTabs
          tabs={[
            {
              name: "profile",
              title: "我的资料",
              content: <ProfileTab />,
            },
            {
              title: "登录设备",
              name: "sessions",
              content: <SessionsTab />,
            },
            {
              title: "签名",
              name: "signatures",
              content: <SignaturesTab />,
            },
          ]}
        />
      </AppView>
    </>
  );
}
