import React from "react";
import { times } from "lodash-es";
import { List, ListItem, ListItemText, ListItemAvatar } from "@mui/material";

import TextLoader from "./TextLoader";
import AvatarLoader from "./AvatarLoader";

export default React.memo(ListLoader);

function ListLoader({
  items = 5,
  subheaders = false,
  avatars = false,
  listSubheader = false,
  ...others
}) {
  return (
    <List
      padding="none"
      dense
      subheader={listSubheader && <TextLoader height={16} />}
      {...others}
    >
      {times(items).map((i) => (
        <ListItem key={i}>
          {avatars && (
            <ListItemAvatar>
              <AvatarLoader />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={<TextLoader width={250} />}
            secondary={
              !subheaders ? null : <TextLoader height={16} width={150} />
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
