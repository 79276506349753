import React, { Suspense } from "react";
import { gql } from "@apollo/client";

import Message from "controls/Message";
import filterBlobUrl from "helpers/filterBlobUrl";

import WorkbooksViewer from "./WorkbooksViewer";
import PreviewLoader from "./PreviewLoader";
import VideoPlayer from "./VideoPlayer";
import TextViewer from "./TextViewer";

const ImageViewer = React.lazy(() => import("./ImageViewer"));
const DWGMaptiles = React.lazy(() => import("./DWGMaptiles"));
const PdfViewer = React.lazy(() => import("./PdfViewer"));

export const PreviewFrameFragment = gql`
  fragment PreviewFrameFragment on Previewable {
    name
    canView
    blobUrl
    blobContentType
    blobDownloadUrl
    thumbnailUrl
    previewUrl
    previewMode
    previewStatus
    previewMeta
  }
`;

export default function PreviewFrame({ previewable }) {
  if (!previewable) return <PreviewLoader />;

  // types can be previewed without conversion
  let Component = {
    image: ImageViewer,
    text: TextViewer,
    video: VideoPlayer,
  }[previewable.previewMode];
  if (Component)
    return (
      <Suspense fallback={<PreviewLoader />}>
        <Component previewable={previewable} />
      </Suspense>
    );

  if (previewable.previewStatus === "errored")
    return (
      <Message
        type="error"
        actions={[
          {
            title: "打开文件",
            href: filterBlobUrl(previewable.blobUrl),
            target: "_blank",
          },
          {
            title: "下载文件",
            href: filterBlobUrl(previewable.blobDownloadUrl),
          },
        ]}
      >
        {previewable.previewErrorMessage || (
          <>无法预览该文件，您可以直接打开该文件进行查看</>
        )}
      </Message>
    );

  if (previewable.previewStatus === "pending")
    return (
      <Message
        message={
          <>正在生成预览，请稍候再刷新查看，您可以直接打开该文件进行查看。</>
        }
        actions={[
          {
            title: "打开文件",
            href: filterBlobUrl(previewable.blobUrl),
            target: "_blank",
          },
          {
            title: "下载文件",
            href: filterBlobUrl(previewable.blobDownloadUrl),
          },
        ]}
      />
    );

  // types cannot be previewed without conversion
  Component = {
    image: ImageViewer,
    dwg_maptiles: DWGMaptiles,
    pdf: PdfViewer,
    workbooks: WorkbooksViewer,
  }[previewable.previewMode];

  if (Component)
    return (
      <Suspense fallback={<PreviewLoader />}>
        <Component previewable={previewable} />
      </Suspense>
    );

  return (
    <Message
      message={<>无法预览该类型文件，您可以下载该文件进行查看</>}
      actions={[
        {
          title: "下载文件",
          href: filterBlobUrl(previewable.blobDownloadUrl),
        },
      ]}
    />
  );
}
