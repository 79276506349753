import React from "react";
import { flatten } from "lodash-es";
import gql from "graphql-tag";

import { FormDialog } from "controls";
import { teamRoute, showMessage } from "helpers";
import { FolderInput, UsersInput, MultiOptionsInput } from "inputs";
import { useRoute, useData, useAction, useHistory } from "hooks";

function DataQueryDialog({ dataQuery = {}, ...others }) {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query DataQueryDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          propertyLabelCountsForProjects: propertyLabelCounts(
            ownerType: "Project"
          ) {
            value
            count
          }
          dataStats {
            id
            tableName
            labels
          }
        }
      }
    `,
    { teamId },
  );

  if (!data) return null;

  return (
    <FormDialog
      fields={[
        {
          label: "查询名称",
          helperText: "可选项",
          name: "name",
          value: dataQuery.name,
        },
        {
          label: "查询位置",
          name: "itemId",
          required: true,
          inputComponent: FolderInput,
          value: dataQuery.item?.id,
        },
        {
          label: "数据列",
          name: "columns",
          required: true,
          inputComponent: MultiOptionsInput,
          value: dataQuery.columns || [],
          options: {
            allowSort: true,
            options: [
              ...data.team.propertyLabelCountsForProjects.map((count) => ({
                name: `项目概况/${count.value}`,
                label: `项目概况/${count.value}`,
                group: "项目概况",
              })),
              ...flatten(
                data.team.dataStats.map((dataStat) =>
                  dataStat.labels.map((label) => ({
                    name: `${dataStat.tableName}/${label}`,
                    label: `${dataStat.tableName}/${label}`,
                    group: dataStat.tableName,
                  })),
                ),
              ),
            ],
          },
        },
        {
          label: "可查看成员",
          name: "viewableUserIds",
          value: dataQuery.viewableUsers?.map((u) => u.id) || [],
          inputComponent: UsersInput,
        },
      ]}
      {...others}
    />
  );
}

export const AddDataQueryDialog = React.memo(function AddDataQueryDialog({
  ...others
}) {
  const { teamId } = useRoute(teamRoute);
  const history = useHistory();
  const addDataQuery = useAction(gql`
    mutation AddDataQueryDialog($input: AddDataQueryInput!) {
      addDataQuery(input: $input) {
        team {
          id
          dataQueries {
            id
          }
        }
        dataQuery {
          id
        }
      }
    }
  `);

  return (
    <DataQueryDialog
      title="创建查询"
      onSubmit={async (formData) => {
        const result = await addDataQuery({
          input: {
            teamId,
            ...formData,
          },
        });
        showMessage({ message: "成功创建查询" });
        history.push(`/${teamId}/${result.addDataQuery.dataQuery.id}`);
      }}
      {...others}
    />
  );
});

export const UpdateDataQueryDialog = React.memo(function UpdateDataQueryDialog({
  dataQueryId,
  ...others
}) {
  const data = useData(
    gql`
      query UpdateDataQueryDialog($dataQueryId: ID!) {
        dataQuery(id: $dataQueryId) {
          id
          name
          item {
            id
          }
          columns
          viewableUsers {
            id
          }
        }
      }
    `,
    { dataQueryId },
  );

  const updateDataQuery = useAction(gql`
    mutation UpdateDataQueryDialog($input: UpdateDataQueryInput!) {
      updateDataQuery(input: $input) {
        dataQuery {
          id
          name
          item {
            id
          }
          columns
          viewableUsers {
            id
          }
        }
      }
    }
  `);

  if (!data) return null;

  return (
    <DataQueryDialog
      title="修改查询"
      dataQuery={data.dataQuery}
      onSubmit={async (formData) => {
        await updateDataQuery({
          input: {
            dataQueryId,
            ...formData,
          },
        });
        showMessage({ message: "成功修改查询" });
      }}
      {...others}
    />
  );
});
