import React from "react";
import moment from "moment";
import { Button } from "@mui/material";
import { Cancel } from "mdi-material-ui";

import showMessage from "helpers/showMessage";
import useDocumentOverlay from "hooks/useDocumentOverlay";
import DatePicker from "controls/DatePicker";

export default function FieldDate({
  field,
  documentContentMode,
  updateField,
  ...others
}) {
  const { id: fieldId, date } = field;

  const calendarOverlay = useDocumentOverlay({
    width: "auto",
    minWidth: "auto",
    enabled: documentContentMode === "fill",
    overlay: (
      <>
        <DatePicker
          selected={date && Date.parse(date)}
          onChange={async (date) => {
            await updateField({
              input: {
                fieldId,
                date: moment(date).format("YYYY-MM-DD"),
              },
            });
            showMessage({ message: "成功设置日期" });
          }}
          inline
        />
        <Button
          onClick={async () => {
            await updateField({
              input: {
                fieldId,
                date: "",
              },
            });
            showMessage({ message: "成功设置日期" });
          }}
          fullWidth
        >
          <Cancel />
          清除日期
        </Button>
      </>
    ),
  });

  return (
    <>
      <div
        data-field-date
        {...others}
        style={{
          ...others.style,
          ...(documentContentMode === "fill" && {
            cursor: "pointer",
          }),
        }}
        tabIndex={0}
        onFocus={calendarOverlay.handleFocus}
        onBlur={calendarOverlay.handleBlur}
      >
        {date ? moment(date).format("YYYY年MM月DD日") : " "}
      </div>
    </>
  );
}
