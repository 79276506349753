import React from "react";
import { gql } from "@apollo/client";
import { Chip } from "@mui/material";

import { userRoute, PERMISSION_CATEGORIES, PermissionsFragment } from "helpers";
import { useRoute, useData } from "hooks";
import { FolderObjectIcon, ItemPath, ItemPathFragment } from "blocks";
import { ObjectCard, CardsList, TabContent } from "controls";

export default function FolderPermissionsTab() {
  const { teamId, userId } = useRoute(userRoute);
  const data = useData(
    gql`
      query FolderPermissionsTab($userId: ID!) {
        user(id: $userId) {
          id
          isOwner
          permissions {
            id
            ...PermissionsFragment
            folder {
              id
              name
              ...ItemPathFragment
            }
          }
        }
      }
      ${PermissionsFragment}
      ${ItemPathFragment}
    `,
    {
      userId,
    },
  );

  return (
    <TabContent>
      <CardsList
        cards={data?.user.permissions.map((permission) => (
          <ObjectCard
            key={permission.id}
            icon={<FolderObjectIcon folderId={permission.folder.id} />}
            title={permission.folder.name}
            subheader={<ItemPath item={permission.folder} />}
            href={`/${teamId}/${permission.folder.id}`}
            fields={[
              ...PERMISSION_CATEGORIES.map((permissionCategory) => ({
                ...permissionCategory,
                permissionTypes: permissionCategory.permissionTypes.filter(
                  (permissionType) => permission[permissionType.name],
                ),
              }))
                .filter((c) => c.permissionTypes.length)
                .map((permissionCategory) => ({
                  label: permissionCategory.title,
                  content: permissionCategory.permissionTypes.map(
                    (permissionType) => (
                      <Chip
                        size="small"
                        style={{ margin: 3 }}
                        key={permissionType.name}
                        icon={permissionCategory.icon}
                        label={permissionType.label}
                      />
                    ),
                  ),
                })),
            ]}
          />
        ))}
      />
    </TabContent>
  );
}
