import { gql } from "@apollo/client";
import {
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";

import {
  useData,
  useActionFragment,
  useFormDialog,
  useProgressBar,
} from "hooks";
import { getDialogProps, uploadBlob } from "helpers";
import { Markdown, DateTime, Message } from "controls";
import { FileUploadInput } from "inputs";

import screenshotBlob from "./screenshotBlob";

export default function SupportIssueDialog({
  supportIssueId,
  onClose,
  ...others
}) {
  const progressBar = useProgressBar();
  const formDialog = useFormDialog();
  const supportIssueAddComment = useActionFragment(
    "supportIssueAddComment",
    "supportIssue { id supportComments { id } }",
  );
  const supportIssueAddAttachment = useActionFragment(
    "supportIssueAddAttachment",
    "supportIssue { id supportComments { id } }",
  );

  const data = useData(
    gql`
      query ($supportIssueId: ID!) {
        supportIssue(id: $supportIssueId) {
          id
          status
          open
          labels
          title
          description
          createdAt
          supportComments {
            id
            content
            content
            creatorName
            createdAt
            isSubmitted
          }
        }
      }
    `,
    {
      supportIssueId,
    },
  );

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
      {...getDialogProps()}
      {...others}
    >
      <CardHeader title={data?.supportIssue.title} />
      <CardContent>
        <p>问题描述:</p>
        <Paper style={{ padding: "2px 10px" }}>
          <Markdown source={data?.supportIssue.description} />
        </Paper>
        <p>评论:</p>
        {data?.supportIssue.supportComments.map((supportComment) => (
          <div key={supportComment.id} style={{ marginBottom: 10 }}>
            {supportComment.isSubmitted ? (
              <>我</>
            ) : (
              <>{supportComment.creatorName}</>
            )}{" "}
            (
            <DateTime value={supportComment.createdAt} />
            ):
            <Paper style={{ padding: "2px 10px" }}>
              <Markdown source={supportComment.content} />
            </Paper>
          </div>
        ))}
        <Message
          actions={[
            {
              title: "增加评论",
              onClick: () =>
                formDialog({
                  title: "增加评论",
                  fields: [
                    {
                      label: "评论内容",
                      name: "content",
                      options: {
                        multiline: true,
                        minRows: 3,
                      },
                    },
                  ],
                  onSubmit: async (formData) => {
                    await supportIssueAddComment({
                      input: {
                        supportIssueId,
                        ...formData,
                      },
                    });
                  },
                }),
            },
            {
              title: "上传附件",
              onClick: () =>
                formDialog({
                  title: "上传附件",
                  fields: [
                    {
                      label: "附件",
                      name: "fileUpload",
                      inputComponent: FileUploadInput,
                      required: true,
                    },
                  ],
                  onSubmit: async (formData) => {
                    await supportIssueAddAttachment({
                      input: {
                        supportIssueId,
                        ...formData,
                      },
                    });
                  },
                }),
            },
            {
              title: "上传截屏",
              onClick: () =>
                progressBar({ blocking: true }, async () => {
                  const blob = await screenshotBlob();
                  const { etag } = await uploadBlob({
                    blob,
                  });
                  await supportIssueAddAttachment({
                    input: {
                      supportIssueId,
                      fileUpload: {
                        name: "屏幕截图.png",
                        etag,
                        size: blob.size.toString(),
                      },
                    },
                  });
                }),
            },
          ]}
        >
          您可以增加更多描述或界面截图
        </Message>
      </CardContent>
      <DialogActions>
        <Button onClick={onClose}>确定</Button>
      </DialogActions>
    </Dialog>
  );
}
