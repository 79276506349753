import React, { forwardRef } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";

function InputComponent({ children }) {
  return <div style={{ paddingTop: 5, flex: "1 1 auto" }}>{children}</div>;
}

const OutlinedBox = forwardRef(
  (
    { children, helperText, label, shrinkLabel = true, labelId, ...others },
    ref,
  ) => {
    return (
      <FormControl ref={ref} {...others}>
        <InputLabel shrink={shrinkLabel} id={labelId}>
          {label}
        </InputLabel>
        <OutlinedInput
          notched={shrinkLabel}
          label={label}
          inputComponent={InputComponent}
          inputProps={{ children }}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);

export default OutlinedBox;
