import React from "react";
import { Avatar } from "@mui/material";
import ContentLoader from "react-content-loader";

export default React.memo(AvatarLoader);

function AvatarLoader({ ...others }) {
  return (
    <Avatar {...others}>
      <ContentLoader
        width={40}
        height={40}
        speed={2}
        foregroundColor="#d9d9d9"
        backgroundColor="#ecebeb"
      >
        <rect x="0" y="0" width={40} height={40} />
      </ContentLoader>
    </Avatar>
  );
}
