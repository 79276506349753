import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Breadcrumbs, Divider, Toolbar, Link } from "@mui/material";

import embedInMobile from "helpers/embedInMobile";

export default function AppView({ title = "", crumbs, children, tools }) {
  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        title,
      }),
    );
  }, [title]);

  return (
    <>
      <Helmet>
        <title>{`${title} - 优建云`}</title>
      </Helmet>
      {(embedInMobile ? !!tools : !!tools || crumbs) && (
        <>
          <Toolbar
            variant="dense"
            style={{
              flexFlow: "row wrap",
              justifyContent: "stretch",
            }}
          >
            <Breadcrumbs style={{ flex: "1 1 auto" }}>
              {!embedInMobile &&
                crumbs?.map((crumb, crumbIndex) => (
                  <Link
                    key={crumbIndex}
                    color="inherit"
                    href={crumb.href || "#"}
                    onClick={crumb.onClick}
                    {...(crumb.disabled && {
                      href: null,
                      onClick: null,
                    })}
                    style={{
                      display: "flex",
                      flexFlow: "row nowrap",
                      alignItems: "center",
                      textDecoration: "none",
                      fontSize: 14,
                      ...(crumb.disabled && {
                        opacity: 0.25,
                      }),
                    }}
                  >
                    <span
                      style={{
                        marginRight: 5,
                        marginBottom: -5,
                        position: "relative",
                      }}
                    >
                      {crumb.icon}
                      {crumb.badgeIcon && (
                        <span
                          style={{
                            position: "absolute",
                            right: -6,
                            top: -4,
                            fontSize: 14,
                          }}
                        >
                          {crumb.badgeIcon}
                        </span>
                      )}
                    </span>
                    {crumbIndex === crumbs.length - 1 ? (
                      <span>{crumb.label}</span>
                    ) : (
                      <span
                        title={crumb.label || ""}
                        style={{
                          display: "inline-block",
                          maxWidth: 80,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {crumb.label}
                      </span>
                    )}
                  </Link>
                ))}
            </Breadcrumbs>
            {tools}
          </Toolbar>
          <Divider />
        </>
      )}
      <div
        style={{
          flex: "1 0 auto",
          margin: 5,
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "stretch",
        }}
      >
        {children}
      </div>
    </>
  );
}
