import React from "react";
import { gql } from "@apollo/client";
import { uniq } from "lodash-es";
import {
  Pencil,
  ContentCopy,
  Download,
  Delete,
  Printer,
} from "mdi-material-ui";

import {
  Table,
  FileSizeCell,
  DateTimeCell,
  UsersCell,
  FolderIcon,
  ItemPathCell,
} from "controls";
import { ItemObjectIcon } from "blocks";
import { teamRoute, filterBlobUrl } from "helpers";
import { usePrintPreviewable, useRoute, useDialogOpener } from "hooks";
import ReviseFileDialog from "views/files/ReviseFileDialog";
import RenameItemDialog from "views/folders/RenameItemDialog";
import MoveItemsDialog from "views/folders/MoveItemsDialog";
import CopyItemsDialog from "views/folders/CopyItemsDialog";
import RemoveItemsDialog from "views/folders/RemoveItemsDialog";

export const ItemsTableFragment = gql`
  fragment ItemsTableFragment on Item {
    id
    name
    extname
    size
    blobUrl
    blobDownloadUrl
    previewMode
    revisionedAt
    revisionedByUsers {
      id
    }
    parentItem {
      id
    }
    createdAt
    createdBy {
      id
    }
    canRemove
    canRename
    canMove
    canReviseFile
  }
`;

function getFolderId(rows) {
  const parentItemIds = uniq(rows?.map((i) => i.parentItem?.id)).filter(
    (i) => i,
  );
  if (parentItemIds.length !== 1) return null;
  return parentItemIds[0];
}

export default function ItemsTable({ rows, enablePath, ...others }) {
  const [previewModePrintable, printPreviewable] = usePrintPreviewable();
  const openRenameItemDialog = useDialogOpener(RenameItemDialog);
  const openReviseFileDialog = useDialogOpener(ReviseFileDialog);
  const openMoveItemsDialog = useDialogOpener(MoveItemsDialog);
  const openCopyItemsDialog = useDialogOpener(CopyItemsDialog);
  const openRemoveItemsDialog = useDialogOpener(RemoveItemsDialog);
  const { teamId } = useRoute(teamRoute);

  return (
    <Table
      {...others}
      batchActions={[
        {
          name: "removeItems",
          icon: <Delete />,
          title: "删除选择的项目",
          onClick: (itemIds) =>
            openRemoveItemsDialog({
              itemIds,
            }),
        },
        {
          name: "moveItems",
          icon: <FolderIcon />,
          title: "移动选择的项目到其他位置",
          onClick: (itemIds) =>
            openMoveItemsDialog({
              itemIds,
              folderId: getFolderId(
                rows?.filter((r) => itemIds.includes(r.id)),
              ),
            }),
        },
        {
          name: "copyItems",
          icon: <ContentCopy />,
          title: "复制选择的项目到其他位置",
          onClick: (itemIds) =>
            openCopyItemsDialog({
              itemIds,
              folderId: getFolderId(
                rows?.filter((r) => itemIds.includes(r.id)),
              ),
            }),
        },
      ]}
      columns={[
        {
          label: "名称",
        },
        enablePath && {
          label: "位置",
          cellComponent: ItemPathCell,
        },
        {
          label: "尺寸",
          cellComponent: FileSizeCell,
        },
        {
          label: "修订时间",
          cellComponent: DateTimeCell,
        },
        {
          label: "修订成员",
          cellComponent: UsersCell,
        },
        {
          label: "创建时间",
          cellComponent: DateTimeCell,
        },
        {
          label: "创建成员",
          cellComponent: UsersCell,
        },
      ].filter((c) => c)}
      rows={rows?.map((item) => ({
        id: item.id,
        title: item.name,
        href: `/${teamId}/${item.id}`,
        icon: <ItemObjectIcon itemId={item.id} />,
        values: [
          item.name,
          ...(enablePath ? [item.parentItem?.id] : []),
          item.size,
          item.revisionedAt,
          item.revisionedByUsers.map((u) => u.id),
          item.createdAt,
          item.createdBy && [item.createdBy.id],
        ],
        batchActionNames: [
          item.canRemove && "removeItems",
          item.canMove && "moveItems",
          "copyItems",
        ].filter((a) => a),
        actions: [
          {
            label: "更改名称",
            icon: <Pencil />,
            disabled: !item.canRename,
            onClick: () => openRenameItemDialog({ itemId: item.id }),
          },
          {
            label: "移动到其他位置",
            icon: <FolderIcon />,
            onClick: () =>
              openMoveItemsDialog({
                itemIds: [item.id],
                folderId: getFolderId([item]),
              }),
            disabled: !item.canMove,
          },
          {
            label: "复制到其他位置",
            icon: <ContentCopy />,
            onClick: () =>
              openCopyItemsDialog({
                itemIds: [item.id],
                folderId: getFolderId([item]),
              }),
          },
          {
            label: "删除",
            icon: <Delete />,
            disabled: !item.canRemove,
            onClick: () =>
              openRemoveItemsDialog({
                itemIds: [item.id],
              }),
          },
          filterBlobUrl(item.blobDownloadUrl) && {
            label: "下载文件",
            icon: <Download />,
            href: filterBlobUrl(item.blobDownloadUrl),
          },
          item.__typename === "File" && {
            label: "上传新版本",
            icon: <Pencil />,
            disabled: !item.canReviseFile,
            onClick: () => openReviseFileDialog({ fileId: item.id }),
          },
          item.__typename === "File" && {
            label: "打印该文件",
            disabled: !previewModePrintable(item.previewMode),
            icon: <Printer />,
            onClick: () => printPreviewable({ previewableId: item.id }),
          },
        ].filter((a) => a),
      }))}
    />
  );
}
