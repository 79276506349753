import { flatten } from "lodash-es";

export default async function literalMatch(
  source,
  input,
  { initial = false } = {},
) {
  if (!input) return true;
  if (source.includes(input)) return true;

  const {
    default: pinyin,
    MODE_SURNAME,
    STYLE_NORMAL,
  } = await import("pinyin");

  const inputPy = flatten(
    pinyin(input || "", { mode: MODE_SURNAME, style: STYLE_NORMAL }),
  ).join(" ");
  const { default: PinyinMatch } = await import("pinyin-match");

  const match = PinyinMatch.match(String(source), inputPy);
  if (!initial) return !!match;
  return match[0] === 0;
}
