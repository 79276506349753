import React from "react";
import { gql } from "@apollo/client";
import { Modal, Fab } from "@mui/material";
import { Fullscreen } from "mdi-material-ui";

import { useData } from "hooks";

import PreviewFrame, { PreviewFrameFragment } from "./PreviewFrame";

export default function PreviewDialog({ previewableId, onClose }) {
  const data = useData(
    gql`
      query PreviewDialog($previewableId: ID!) {
        previewable(id: $previewableId) {
          id
          ...PreviewFrameFragment
        }
      }
      ${PreviewFrameFragment}
    `,
    { previewableId },
  );

  return (
    <Modal open onClose={onClose}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#fff",
          display: "flex",
          flexFlow: "column nowrap",
          flex: "1 1 auto",
        }}
      >
        <PreviewFrame previewable={data?.previewable} />
        <Fab
          title="退出全屏"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
        >
          <Fullscreen />
        </Fab>
      </div>
    </Modal>
  );
}
