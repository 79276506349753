import { gql } from "@apollo/client";

import { companyRoute } from "helpers";
import { useRoute, useData, useActionFragment } from "hooks";
import { FormCard } from "controls";

import PropertiesCard, {
  PropertiesCardFragment,
} from "views/properties/PropertiesCard";

export default function InfoTab() {
  const { companyId } = useRoute(companyRoute);

  const data = useData(
    gql`
      query InfoTab($companyId: ID!) {
        company(id: $companyId) {
          id
          name
          canUpdateInfo
          ...PropertiesCardFragment
        }
      }
      ${PropertiesCardFragment}
    `,
    { companyId },
  );
  const companyUpdateInfo = useActionFragment(
    "companyUpdateInfo",
    "company { id name }",
  );

  return (
    <>
      <FormCard
        disabled={!data?.company.canUpdateInfo}
        title="基本信息"
        fields={[
          {
            label: "名称",
            name: "name",
            value: data?.company.name,
            required: true,
          },
        ]}
        onSubmit={async (formData) => {
          await companyUpdateInfo({
            input: {
              companyId,
              ...formData,
            },
          });
        }}
      />
      <PropertiesCard propertiesOwner={data?.company} />
    </>
  );
}
