import React, { useContext } from "react";
import { gql } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";

import { getDialogProps, DESKTOP_BUILD } from "helpers";
import { BooleanInput } from "inputs";
import { desktopStateContext } from "contexts";
import { useData, useActionFragment } from "hooks";

import usePushSettings from "./usePushSettings";

export default function UISettingsDialog({ onClose }) {
  const data = useData(gql`
    query UISettingsDialog {
      userLogin {
        id
        login
        sendSms
        sendEmail
        isEmailUser
        isMobileUser
      }
    }
  `);
  const updateNotificationSettings = useActionFragment(
    "updateNotificationSettings",
    "userLogin { id sendSms sendEmail }",
  );

  const pushSettings = usePushSettings();
  const desktopState = useContext(desktopStateContext);

  return (
    <Dialog open fullWidth onClose={onClose} {...getDialogProps()}>
      <DialogTitle>界面设置</DialogTitle>
      <DialogContent style={{ display: "flex", flexFlow: "column nowrap" }}>
        {[
          data?.userLogin?.isMobileUser && [
            "接收短信通知",
            false,
            data?.userLogin?.sendSms,
            (sendSms) => updateNotificationSettings({ input: { sendSms } }),
            <>发送到 {data?.userLogin?.login}</>,
          ],
          data?.userLogin?.isEmailUser && [
            "接收邮件通知",
            false,
            data?.userLogin?.sendEmail,
            (sendEmail) => updateNotificationSettings({ input: { sendEmail } }),
            <>发送到 {data?.userLogin?.login}</>,
          ],
          !DESKTOP_BUILD && [
            "开启桌面推送通知",
            !pushSettings.support,
            pushSettings.isEnabled,
            (checked) =>
              checked ? pushSettings.enable() : pushSettings.disable(),
            !pushSettings.support && <>当前浏览器不支持推送</>,
          ],
          DESKTOP_BUILD && [
            "开机自动运行",
            false,
            desktopState?.autoLaunchEnabled,
            (enabling) =>
              window.fmsDesktop.command("autoLaunchToggle", {
                enabling,
              }),
          ],
        ]
          .filter((e) => e)
          .map(([label, readOnly, value, onChange, helperText]) => (
            <BooleanInput
              key={label}
              label={label}
              readOnly={readOnly || value === null}
              value={value || false}
              onChange={onChange}
              helperText={helperText}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>完成</Button>
      </DialogActions>
    </Dialog>
  );
}
