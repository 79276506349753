import React, { useState } from "react";
import {
  PlaylistPlus,
  PlaylistEdit,
  PlaylistMinus,
  CheckboxOutline,
  CheckboxBlankOutline,
} from "mdi-material-ui";

import showMessage from "helpers/showMessage";
import useDocumentActions from "hooks/useDocumentActions";
import useFormDialog from "hooks/useFormDialog";

export default function FieldOptions({
  field,
  documentContentMode,
  updateField,
  ...others
}) {
  const {
    options,
    selectedOptions,
    id: fieldId,
    extraOption,
    extraOptionEnabled,
  } = field;
  const [optionFocusedIndex, optionFocusedIndexSet] = useState(-1);
  const optionFocused = options[optionFocusedIndex];
  const formDialog = useFormDialog();

  const { handleFocus, handleBlur } = useDocumentActions(
    "选项字段",
    documentContentMode !== "edit"
      ? []
      : [
          {
            icon: <PlaylistPlus />,
            title: "增加选项",
            onClick: () =>
              formDialog({
                title: "增加选项",
                fields: [
                  {
                    name: "option",
                    label: "选项名称",
                    required: true,
                  },
                ],
                onSubmit: async ({ option }) => {
                  await updateField({
                    input: {
                      fieldId,
                      options: [...options, option],
                    },
                  });
                  showMessage({ message: "成功增加选项" });
                },
              }),
          },
          {
            icon: <PlaylistEdit />,
            title: "修改选项",
            disabled: optionFocused === undefined,
            onClick: () =>
              formDialog({
                title: "修改选项",
                fields: [
                  {
                    name: "option",
                    label: "选项名称",
                    value: optionFocused,
                    required: true,
                  },
                ],
                onSubmit: async ({ option }) => {
                  await updateField({
                    input: {
                      fieldId,
                      options: options.map((o, i) =>
                        i !== optionFocusedIndex ? o : option,
                      ),
                    },
                  });
                  showMessage({ message: "成功修改选项" });
                },
              }),
          },
          {
            icon: <PlaylistMinus />,
            title: "删除该选项",
            disabled: optionFocused === undefined,
            onClick: async () => {
              await updateField({
                input: {
                  fieldId,
                  options: options.filter((o, i) => i !== optionFocusedIndex),
                },
              });
              showMessage({ message: "成功删除选项" });
            },
          },
          {
            title: "允许使用其他选项",
            label: `其他选项:${extraOptionEnabled ? "允许" : "关闭"}`,
            selected: extraOptionEnabled,
            onClick: async () => {
              await updateField({
                input: {
                  fieldId,
                  extraOptionEnabled: !extraOptionEnabled,
                },
              });
              showMessage({
                message: extraOptionEnabled
                  ? "已关闭“其他”选项"
                  : "已开启“其他”选项",
              });
            },
          },
        ],
  );

  const changeExtraOption = () =>
    formDialog({
      title: "输入其他选项",
      fields: [
        {
          name: "extraOption",
          value: extraOption,
          label: "其他选项",
        },
      ],
      onSubmit: async (formData) => {
        await updateField({
          input: {
            fieldId,
            ...formData,
          },
        });
        showMessage({ message: "成功设置“其他”选项" });
      },
    });

  return (
    <div
      {...others}
      {...(documentContentMode === "edit" && {
        tabIndex: 0,
        onBlur: handleBlur,
        onFocus: handleFocus,
      })}
    >
      {options.map((option, optionIndex) => (
        <span
          key={optionIndex}
          style={{ marginRight: "1em" }}
          {...(documentContentMode === "edit" && {
            onFocus: () => optionFocusedIndexSet(optionIndex),
            onBlur: () => optionFocusedIndexSet(-1),
            tabIndex: 0,
          })}
        >
          <Checkbox
            checked={selectedOptions.includes(option)}
            {...(documentContentMode === "fill" && {
              title: `选择${option}`,
              onClick: async (checked) => {
                await updateField({
                  input: {
                    fieldId,
                    selectedOptions: checked
                      ? [...selectedOptions, option]
                      : selectedOptions.filter((o) => o !== option),
                  },
                });
                showMessage({ message: "成功更改选项" });
              },
            })}
          />
          {option}
        </span>
      ))}
      {extraOptionEnabled && (
        <>
          <Checkbox
            checked={selectedOptions.includes(extraOption)}
            {...(documentContentMode === "fill" && {
              title: `选择${extraOption}`,
              onClick: async (checked, event) => {
                if (checked) {
                  changeExtraOption();
                } else {
                  await updateField({
                    input: {
                      fieldId,
                      extraOption: "",
                    },
                  });
                  showMessage({ message: "成功更改选项" });
                  event.stopPropagation();
                }
              },
            })}
          />
          <a
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
            {...(documentContentMode === "fill" && {
              title: "使用其他选项",
              href: "#",
              onClick: () => changeExtraOption(),
            })}
          >
            其他：
            <span
              style={{
                minWidth: "5em",
                display: "inline-block",
                borderBottom: "solid .5mm",
                marginBottom: "-.5mm",
              }}
            >
              {extraOption || " "}
            </span>
          </a>
        </>
      )}
    </div>
  );
}

const Checkbox = React.memo(function Checkbox({ checked, onClick, ...others }) {
  return (
    <a
      {...others}
      style={{
        color: "inherit",
        fontSize: "1.3em",
        lineHeight: "1em",
        verticalAlign: "middle",
        ...others.style,
      }}
      {...(onClick && {
        href: "#",
        onClick: (event) => onClick(!checked, event),
      })}
    >
      {checked ? (
        <CheckboxOutline fontSize="inherit" />
      ) : (
        <CheckboxBlankOutline fontSize="inherit" />
      )}
    </a>
  );
});
