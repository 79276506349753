import React, { useContext, useLayoutEffect, useState } from "react";
import { Paper, Button, DialogTitle, DialogActions } from "@mui/material";
import moment from "moment";
import { gql } from "@apollo/client";

import { FieldsContainer, AppForm } from "controls";
import { showMessage, DESKTOP_BUILD } from "helpers";
import { BooleanInput } from "inputs";
import { useAction } from "hooks";
import { authContext } from "contexts";
import logoImage from "images/logo_button.png";
import DialogFooter from "views/viewport/DialogFooter";

export default function Login() {
  const { authenticate } = useContext(authContext);

  const loginAction = useAction(gql`
    mutation ($input: LoginInput!) {
      login(input: $input) {
        token
      }
    }
  `);
  const requestLoginToken = useAction(gql`
    mutation ($input: RequestLoginTokenInput!) {
      requestLoginToken(input: $input) {
        staleAt
      }
    }
  `);

  const [login, loginSet] = useState("");
  const [staleAt, staleAtSet] = useState(null);

  const [fields, submitLabel, onSubmit] = !staleAt
    ? [
        [
          {
            name: "login",
            required: true,
            label: "手机号码或电子邮箱",
            value: login,
            options: {
              autoComplete: "username",
            },
          },
        ],
        "下一步",
        async (formData) => {
          const result = await requestLoginToken({
            input: {
              ...formData,
            },
          });
          loginSet(formData.login);
          staleAtSet(result.requestLoginToken.staleAt);
        },
      ]
    : [
        [
          {
            label: "手机号码或电子邮箱",
            name: "login",
            value: login,
            options: { readOnly: true },
          },
          {
            name: "loginTokenCode",
            required: true,
            label: "验证码",
            options: {
              autoComplete: "off",
            },
            helperText: (
              <LoginTokenHelperText
                staleAt={staleAt}
                onBackClick={() => staleAtSet(null)}
              />
            ),
          },
          !DESKTOP_BUILD && {
            name: "rememberMe",
            label: "在这台设备上记住我",
            value: false,
            inputComponent: BooleanInput,
          },
        ].filter((f) => f),
        "登录",
        async (formData) => {
          const { loginTokenCode, rememberMe } = formData;
          const result = await loginAction({
            input: {
              login,
              loginTokenCode,
              rememberMe,
            },
          });
          const token = result.login.token;
          await authenticate({ token });

          showMessage({ message: "登录成功" });
        },
      ];

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        component={AppForm}
        onSubmit={onSubmit}
        elevation={DESKTOP_BUILD ? 0 : undefined}
        style={{
          flex: "0 1 auto",
          padding: 20,
          margin: 10,
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          width: 480,
        }}
      >
        <img src={logoImage} alt="优建云" style={{ width: 64, height: 64 }} />
        <DialogTitle>登录优建云</DialogTitle>
        <FieldsContainer fields={fields} style={{ width: "100%" }} />
        <DialogActions style={{ width: "100%" }}>
          <Button type="submit" variant="contained" fullWidth>
            {submitLabel}
          </Button>
        </DialogActions>
        <DialogFooter />
      </Paper>
    </div>
  );
}

function LoginTokenHelperText({ staleAt, onBackClick }) {
  const [seconds, secondsSet] = useState(60);

  useLayoutEffect(() => {
    const refreshDisplay = () => {
      const seconds = moment(staleAt).diff(moment(), "seconds");
      secondsSet(seconds);
    };
    const timer = setInterval(refreshDisplay, 1000);
    refreshDisplay();
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      验证码已发送至您输入的手机或电子邮箱，
      {seconds > 0 ? (
        <>
          如果未收到可以在{seconds}
          秒后重新发送。
          <a href="#" onClick={onBackClick}>
            更改手机号码或电子邮箱
          </a>
        </>
      ) : (
        <a href="#" onClick={onBackClick}>
          重新获取
        </a>
      )}
    </>
  );
}
