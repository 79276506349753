import React, { useRef, useId, useState } from "react";
import { parse, format, isValid } from "date-fns";
import {
  TextField,
  IconButton,
  InputAdornment,
  Popover,
  Button,
} from "@mui/material";
import { CloseCircle, Calendar } from "mdi-material-ui";

import DatePicker from "controls/DatePicker";

function isValidDateStr(str) {
  const date = parse(str, "yyyy-MM-dd", new Date());
  return isValid(date);
}

export const TODAY = {
  fromDate: format(new Date(), "yyyy-MM-dd"),
  toDate: format(new Date(), "yyyy-MM-dd"),
};

Object.assign(DateRangeFilter, {
  serialize: (v) => v && `${v.fromDate || ""},${v.toDate || ""}`,
  deserialize: (v) => {
    if (!v) return null;
    v = v.split(",");
    return {
      fromDate: v[0],
      toDate: v[1],
    };
  },
  validator: (v) =>
    !v || (isValidDateStr(v.fromDate) && isValidDateStr(v.toDate)),
});

export default function DateRangeFilter({ value, valueSet, label }) {
  const ref = useRef();
  const id = useId();
  const [dateRangePickerState, dateRangePickerStateSet] = useState(null);

  return (
    <>
      <TextField
        style={{ minWidth: 150, margin: 5 }}
        label={label}
        ref={ref}
        size="small"
        readOnly
        InputLabelProps={{ shrink: !!value }}
        InputProps={{
          inputComponent: () => (
            <div
              style={{
                height: 40,
                flex: "1 0 auto",
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                dateRangePickerStateSet({
                  value: value,
                  onChange: valueSet,
                })
              }
            >
              {(value || "") && `${value.fromDate} - ${value.toDate}`}
            </div>
          ),
          style: {
            paddingLeft: 15,
            paddingRight: 5,
          },
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <IconButton
                  size="small"
                  title={`清除${label}`}
                  onClick={() => valueSet(null)}
                >
                  <CloseCircle />
                </IconButton>
              )}
              <IconButton
                size="small"
                title={`选择${label}`}
                onClick={() =>
                  dateRangePickerStateSet({
                    value: value,
                    onChange: valueSet,
                  })
                }
              >
                <Calendar />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={`${id}-dateRangePicker`}
        open={!!dateRangePickerState}
        anchorEl={ref.current}
        onClose={() => dateRangePickerStateSet(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ style: { padding: 5 } }}
      >
        {!!dateRangePickerState && (
          <DateRangePicker
            value={dateRangePickerState.value}
            onChange={dateRangePickerState.onChange}
          />
        )}
        <Button fullWidth onClick={() => dateRangePickerStateSet(null)}>
          确定
        </Button>
      </Popover>
    </>
  );
}

function DateRangePicker({ value, onChange }) {
  const [startDate, startDateSet] = useState(
    value && parse(value.fromDate, "yyyy-MM-dd", new Date()),
  );
  const [endDate, endDateSet] = useState(
    value && parse(value.toDate, "yyyy-MM-dd", new Date()),
  );

  return (
    <DatePicker
      selected={startDate}
      onChange={([startDate, endDate]) => {
        startDateSet(startDate);
        endDateSet(endDate);
        onChange(
          startDate && endDate
            ? {
                fromDate: format(startDate, "yyyy-MM-dd"),
                toDate: format(endDate, "yyyy-MM-dd"),
              }
            : null,
        );
      }}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
    />
  );
}
