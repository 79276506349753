import React, { useEffect, useState } from "react";

import { initApolloClient } from "helpers/apolloClient";
import useProgressBar from "hooks/useProgressBar";
import { initContext } from "contexts";

export default function InitProvider({ children }) {
  const [inited, initedSet] = useState(false);
  const progressBar = useProgressBar();
  useEffect(() => {
    Promise.resolve().then(async () =>
      progressBar({ unobstrusive: true }, async () => {
        await initApolloClient();
        initedSet(true);
        const bodyElement = document.querySelector("body");
        bodyElement.setAttribute("data-fms-inited", "true");
      }),
    );
  }, []);

  return <initContext.Provider value={inited}>{children}</initContext.Provider>;
}
