import React from "react";
import gql from "graphql-tag";

import { itemRoute } from "helpers";
import { useRoute, useData, useActivitiesFilterBar, useList } from "hooks";
import { ActivityCard, ActivityCardFragment } from "blocks";
import { TabContent, CardsList } from "controls";
import ActivitiesTable, {
  ActivitiesTableFragment,
} from "lists/ActivitiesTable";

export default function ActivitiesTab() {
  const { itemId } = useRoute(itemRoute);
  const [filter, filterBar] = useActivitiesFilterBar({ hideItemFilter: true });
  const [data, { fetchMore }] = useData(
    gql`
      query Activities($itemId: ID!, $offset: Int, $filter: ActivitiesFilter!) {
        item(id: $itemId) {
          id
          activities(offset: $offset, limit: 10, filter: $filter) {
            id
            createdAt
            ...ActivityCardFragment
            ...ActivitiesTableFragment
          }
          activitiesCount(filter: $filter)
        }
      }
      ${ActivityCardFragment}
      ${ActivitiesTableFragment}
    `,
    { itemId, filter },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("activity", {
    rows: data?.item.activities,
    rowsCount: data?.item.activitiesCount,
    fetchMore,
    card: <ActivityCard />,
    table: <ActivitiesTable />,
    cardsList: <CardsList getRowDate={(activity) => activity.createdAt} />,
  });

  return (
    <TabContent toolbar={<>{listModeSwitcher}</>}>
      {filterBar}
      {list}
    </TabContent>
  );
}
