import React, { useState, useContext } from "react";
import { isEqual } from "lodash-es";

import { showMessage } from "helpers";
import { documentContentContext } from "contexts";

import Ruler from "./Ruler";
import Rect from "./Rect";
import {
  snap,
  getEventPos,
  getRect,
  pageContext,
  useUpdatePageCells,
} from "./helpers";

export default React.memo(Resize);

function Resize({ onExit }) {
  const movedCells = [];

  const [waiting, waitingSet] = useState(false);
  const {
    lefts,
    resizeCursor,
    tops,
    page,
    resizeFrom,
    pageContentRect,
    resizeCellId,
  } = useContext(pageContext);
  const { dragPref } = useContext(documentContentContext);
  const [to, toSet] = useState(resizeFrom);
  const updatePageCells = useUpdatePageCells();

  for (const cell of page.cells) {
    const { top, left, height, width } = cell;
    const bottom = top + height;
    const right = left + width;

    if (!dragPref.multiDrag && cell.id !== resizeCellId) continue;

    let newTop = top;
    let newLeft = left;
    let newBottom = bottom;
    let newRight = right;
    if (resizeFrom[0] === top) newTop = to[0];
    if (resizeFrom[0] === bottom) newBottom = to[0];
    if (resizeFrom[1] === left) newLeft = to[1];
    if (resizeFrom[1] === right) newRight = to[1];

    const newRect = getRect([newTop, newLeft], [newBottom, newRight]);
    if (isEqual({ top, left, height, width }, newRect)) continue;
    movedCells.push({
      ...cell,
      ...newRect,
    });
  }

  return (
    <>
      <Ruler
        pos={[
          resizeFrom[0] === null ? null : to[0],
          resizeFrom[1] === null ? null : to[1],
        ]}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          cursor: resizeCursor,
        }}
        onMouseUp={async () => {
          try {
            waitingSet(true);
            await updatePageCells({
              input: {
                pageId: page.id,
                updateCells: movedCells.map((cell) => ({
                  cellId: cell.id,
                  top: cell.top,
                  left: cell.left,
                  height: cell.height,
                  width: cell.width,
                })),
              },
            });
            showMessage({ message: "成功调整边框" });
          } finally {
            onExit();
          }
        }}
        onMouseMove={(event) => {
          if (waiting) return;
          let pos = getEventPos(event);
          pos = snap(pos, {
            tops: !dragPref.snap ? [] : tops.filter((t) => t !== resizeFrom[0]),
            lefts: !dragPref.snap
              ? []
              : lefts.filter((l) => l !== resizeFrom[1]),
            insideRects: [pageContentRect],
          });
          if (!pos) return;
          toSet(pos);
        }}
      >
        {movedCells.map((cell) => (
          <Rect
            showSize
            borderStyle="dashed"
            key={cell.id}
            top={cell.top}
            left={cell.left}
            height={cell.height}
            width={cell.width}
            style={{
              backgroundColor: "#ccc",
              opacity: 0.6,
            }}
          />
        ))}
      </div>
    </>
  );
}
