import React from "react";
import {
  Switch,
  FormHelperText,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";

export default function BooleanInput({
  label,
  value,
  onChange,
  name,
  helperText,
  error,
  readOnly,
  variant = "checkbox",
}) {
  return (
    <FormControl error={!!error} size="small" fullWidth>
      <FormControlLabel
        style={{ marginLeft: 0 }}
        label={label}
        control={
          {
            checkbox: (
              <Checkbox
                size="small"
                name={name}
                disabled={readOnly}
                checked={value || false}
                onChange={(event, checked) => onChange(checked)}
              />
            ),
            switch: (
              <Switch
                size="small"
                name={name}
                disabled={readOnly}
                checked={value || false}
                onChange={(event, checked) => onChange(checked)}
              />
            ),
          }[variant]
        }
      />
      {helperText && <FormHelperText> {helperText}</FormHelperText>}
    </FormControl>
  );
}
