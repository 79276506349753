import React from "react";
import { gql } from "@apollo/client";
import { Plus } from "mdi-material-ui";

import { TabContent } from "controls";
import { teamRoute } from "helpers";
import {
  useRoute,
  useList,
  useData,
  useFilterBar,
  useDialogOpener,
} from "hooks";
import CompaniesTable, { CompaniesTableFragment } from "lists/CompaniesTable";
import AddCompanyDialog from "./AddCompanyDialog";

export default function CompaniesTab() {
  const { teamId } = useRoute(teamRoute);
  const openAddCompanyDialog = useDialogOpener(AddCompanyDialog);

  const data = useData(
    gql`
      query ProjectsView($teamId: ID!) {
        team(id: $teamId) {
          id
          canAddCompany
          propertyLabelCounts(ownerType: "Company") {
            value
          }
        }
      }
    `,
    {
      teamId,
    },
  );

  const [{ searchTerm, propertyFilters }, filterBar] = useFilterBar(
    { enableSearchTerm: true },
    [
      {
        name: "propertyFilters",
        type: "property_filters",
        labels: data?.team.propertyLabelCounts.map((e) => e.value),
        ownerType: "Company",
      },
    ],
  );

  const [companiesData, companiesDataMeta] = useData(
    gql`
      query CompaniesView(
        $teamId: ID!
        $searchTerm: String
        $propertyFilters: [FieldFilter!]
        $offset: Int
      ) {
        team(id: $teamId) {
          id
          companies(
            searchTerm: $searchTerm
            propertyFilters: $propertyFilters
            offset: $offset
            limit: 20
          ) {
            id
            ...CompaniesTableFragment
          }
          companiesCount(
            searchTerm: $searchTerm
            propertyFilters: $propertyFilters
          )
        }
      }
      ${CompaniesTableFragment}
    `,
    { teamId, propertyFilters, searchTerm },
    { returnMeta: true },
  );

  const [list, listModeSwitcher] = useList("company", {
    table: <CompaniesTable />,
    rows: companiesData?.team.companies,
    rowsCount: companiesData?.team.companiesCount,
    fetchMore: companiesDataMeta.fetchMore,
  });

  return (
    <TabContent
      toolbar={<>{listModeSwitcher}</>}
      actions={[
        {
          title: "创建单位",
          icon: <Plus />,
          disabled: !data?.team.canAddCompany,
          onClick: () => openAddCompanyDialog(),
        },
      ]}
    >
      {filterBar}
      {list}
    </TabContent>
  );
}
