import { PUBLIC_URL } from "helpers";
import { Workbox } from "workbox-window";

export async function register({ onWaiting } = {}) {
  // ServiceWorker not available
  if (!window.navigator?.serviceWorker) return;
  // site not served on designated domain
  if (window.location.origin.toLowerCase() !== PUBLIC_URL) return;

  const wb = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`);
  wb.addEventListener("waiting", () => {
    wb.messageSkipWaiting();
    if (onWaiting) onWaiting();
  });
  window.serviceWorkerRegistration = await wb.register();
}

export function unregister() {
  if (!window.navigator?.serviceWorker) return;

  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}
