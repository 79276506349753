import gql from "graphql-tag";

import {
  FileIcon,
  FolderIcon,
  DocumentIcon,
  ProjectIcon,
} from "controls/icons";

const PERMISSION_CATEGORIES = [
  [
    "目录权限",
    <FolderIcon />,
    [
      ["canRename", "重命名", "重命名目录中的子目录、文档和表单"],
      ["canAddFolder", "新增", "在目录中新增子目录"],
      ["canRemoveFolder", "删除", "删除目录中的子目录"],
      ["canChangePermission", "管理权限", "修改或增加其他成员的权限"],
      ["canDownloadFolder", "打包下载", "压缩包的形式打包下载目录中的所有内容"],
    ],
  ],
  [
    "文档权限",
    <FileIcon />,
    [
      ["canViewFile", "查看", "查看文档内容"],
      ["canAddFile", "新增", "创建或上传文档"],
      ["canRemoveFile", "删除", "删除现有文档"],
      ["canReviseFile", "修订", "修订现有文档"],
    ],
  ],
  [
    "表单权限",
    <DocumentIcon />,
    [
      ["canViewDocument", "查看", "查看表单内容"],
      ["canAddDocument", "新增", "创建新的表单"],
      ["canRemoveDocument", "删除", "删除现有表单"],
      ["canAddDocumentAttachment", "新增附件", "在现有表单中上传附件"],
      ["canModifyDocument", "修改", "修改现有表单的结构"],
      ["canFill", "填写", "填写现有表单中的数据字段"],
      ["canAddWorkflow", "发起流程", "创建和表单关联的流程"],
    ],
  ],
  [
    "项目权限",
    <ProjectIcon />,
    [
      ["canAddProject", "新增", "创建项目"],
      ["canFillProjectProperties", "填写属性", "填写项目的属性"],
      ["canManageProjectCompanies", "管理相关单位", "管理项目的相关单位"],
    ],
  ],
].map(([title, icon, entries]) => ({
  title,
  icon,
  permissionTypes: entries.map(([name, label, description]) => ({
    name,
    label,
    description,
  })),
}));

export default PERMISSION_CATEGORIES;

export const PermissionsFragment = gql`
  fragment PermissionsFragment on Permission {
    canRename
    canAddFolder
    canRemoveFolder
    canChangePermission
    canDownloadFolder

    canViewFile
    canAddFile
    canRemoveFile
    canReviseFile

    canViewDocument
    canAddDocument
    canRemoveDocument
    canAddDocumentAttachment
    canModifyDocument
    canFill
    canAddWorkflow

    canAddProject
    canFillProjectProperties
    canManageProjectCompanies
  }
`;
