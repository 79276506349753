import React, { useId } from "react";
import { gql } from "@apollo/client";
import { Delete, Pencil, Plus } from "mdi-material-ui";
import {
  Toolbar,
  List,
  ListItem,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";

import {
  useRoute,
  useData,
  useAction,
  useConfirmDialog,
  useFormDialog,
  useFilterBar,
} from "hooks";
import { showMessage, picklistRoute, literalMatch } from "helpers";
import { Table, TabContent } from "controls";

export default function PicklistView() {
  const { picklistId } = useRoute(picklistRoute);
  const confirmDialog = useConfirmDialog();
  const formDialog = useFormDialog();
  const [{ searchTerm }, filterBar] = useFilterBar({
    enableSearchTerm: true,
  });

  const updatePicklist = useAction(gql`
    mutation PicklistsView($input: UpdatePicklistInput!) {
      updatePicklist(input: $input) {
        picklist {
          id
          options
          updatedAt
        }
      }
    }
  `);

  const data = useData(
    gql`
      query PicklistView($picklistId: ID!) {
        picklist(id: $picklistId) {
          id
          options
          canUpdate
        }
      }
    `,
    { picklistId },
  );

  const removeOptions = async (options) => {
    const optionsDescription =
      options.length === 1
        ? `选项“${options[0]}”`
        : `这${options.length}个选项`;
    await confirmDialog({ message: `确定要删除${optionsDescription}？` });
    await updatePicklist({
      input: {
        picklistId,
        options: data.picklist.options.filter((o) => !options.includes(o)),
      },
    });
    showMessage({ message: `成功删除${optionsDescription}` });
  };

  return (
    <TabContent
      actions={[
        {
          title: "添加选项",
          icon: <Plus />,
          disabled: !data?.picklist.canUpdate,
          onClick: () =>
            formDialog({
              title: "添加选项",
              fields: [
                {
                  label: "新选项",
                  name: "options",
                  value: ["", "", ""],
                  inputComponent: OptionsInput,
                },
              ],
              onSubmit: async (formData) => {
                const newOptions = formData.options.filter((o) => o);
                for (const newOption of newOptions)
                  if (data.picklist.options.includes(newOption))
                    throw new Error(`已包含选项“${newOption}”`);
                await updatePicklist({
                  input: {
                    picklistId,
                    options: [...data.picklist.options, ...newOptions],
                  },
                });
                showMessage({
                  message: `成功添加${newOptions.length}个新选项`,
                });
              },
            }),
        },
      ]}
    >
      {filterBar}
      <Table
        columns={[{ label: "选项值" }]}
        batchActions={[
          {
            name: "remove",
            icon: <Delete />,
            title: "删除选择的选项",
            onClick: async (options) => removeOptions(options),
          },
        ]}
        rows={data?.picklist.options
          .filter((o) => literalMatch(o, searchTerm || ""))
          .map((option) => ({
            id: option,
            title: option,
            values: [option],
            batchActionNames: [
              //
              data?.picklist.canUpdate && "remove",
            ].filter((a) => a),
            actions: [
              {
                label: "删除",
                icon: <Delete />,
                disabled: !data?.picklist.canUpdate,
                onClick: () => removeOptions([option]),
              },
              {
                label: "修改",
                icon: <Pencil />,
                disabled: !data?.picklist.canUpdate,
                onClick: () =>
                  formDialog({
                    title: `修改选项“${option}”`,
                    fields: [
                      {
                        name: "newOption",
                        label: "选项值",
                        validate: (newOption) => {
                          if (newOption === option) return;
                          if (data.picklist.options.includes(newOption))
                            throw new Error(`已包含选项“${newOption}”`);
                        },
                        value: option,
                      },
                    ],
                    onSubmit: async ({ newOption }) => {
                      await updatePicklist({
                        input: {
                          picklistId,
                          options: data.picklist.options.map((o) =>
                            o === option ? newOption : o,
                          ),
                        },
                      });
                      showMessage({ message: "成功修改选项值" });
                    },
                  }),
              },
            ],
          }))}
      />
    </TabContent>
  );
}

function OptionsInput({ value, onChange }) {
  const id = useId();

  const options = value || [];

  return (
    <>
      <List>
        {options?.map((option, index) => (
          <ListItem key={index}>
            <TextField
              id={`${id}-TextField-${index}`}
              fullWidth
              size="small"
              style={{ margin: "5px 0" }}
              label={`选项${index + 1}`}
              value={option}
              onChange={(event) =>
                onChange([
                  ...options.slice(0, index),
                  event.currentTarget.value,
                  ...options.slice(index + 1),
                ])
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  onChange([...options, ""]);
                }
              }}
              helperText={
                index === options.length - 1 ? <>按 Enter 添加新选项</> : null
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      title="删除该选项"
                      size="small"
                      onClick={() =>
                        onChange([
                          ...options.slice(0, index),
                          ...options.slice(index + 1),
                        ])
                      }
                    >
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
        ))}
      </List>
      <Toolbar>
        <Button variant="outlined" onClick={() => onChange([...options, ""])}>
          <Plus />
          添加新选项
        </Button>
      </Toolbar>
    </>
  );
}
