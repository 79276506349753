import { useEffect, useContext } from "react";
import { defer } from "lodash-es";
import { useQuery } from "@apollo/client";

import getApolloErrors from "helpers/getApolloErrors";
import apolloClient from "helpers/apolloClient";
import { initContext } from "contexts";

import useProgressBar from "./useProgressBar";

export default function useData(
  query,
  variables,
  { returnMeta = false, progressBarOptions = {}, ...options } = {},
) {
  const inited = useContext(initContext);
  const { data, loading, error, refetch, fetchMore } = useQuery(query, {
    variables,
    client: apolloClient,
    fetchPolicy: "cache-and-network",
    errorPolicy: "none",
    partialRefetch: true,
    ...options,
    skip: !inited || options.skip,
  });

  useProgressBar(loading, { unobstrusive: true, ...progressBarOptions });

  useEffect(() => {
    if (error) {
      const subErrors = getApolloErrors(error);
      for (const subError of subErrors)
        defer(() => {
          if (["Observable cancelled prematurely"].includes(subError.message))
            return;
          throw subError;
        });
    }
  }, [error]);

  return returnMeta ? [data, { refetch, loading, fetchMore }] : data;
}
