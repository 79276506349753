import React from "react";
import gql from "graphql-tag";
import QueryString from "query-string";

import { useRoute, useData } from "hooks";
import { shareRoute, filterBlobUrl } from "helpers";
import { GridList, FolderIcon, FileIcon } from "controls";

export const ShareItemsGridFragment = gql`
  fragment ShareItemsGridFragment on ShareItem {
    id
    name
    path
    thumbnailUrl
    itemType
  }
`;

export default function ShareItemsGrid({ fetchMore, rows, rowsCount }) {
  const { shareToken } = useRoute(shareRoute);
  const data = useData(
    gql`
      query ShareView($shareToken: ID!) {
        share(shareToken: $shareToken) {
          id
          rootItem {
            id
            path
          }
        }
      }
    `,
    { shareToken },
  );

  return (
    <GridList
      rows={rows
        ?.map((shareItem) => ({
          ...shareItem,
          path:
            data && shareItem.path.slice(data.share.rootItem.path.length - 1),
        }))
        .map(({ path, ...shareItem }) => ({
          id: shareItem.id,
          title: shareItem.name,
          href:
            path &&
            `/shares/${shareToken}?${QueryString.stringify({
              path: path.slice(1).join("/"),
            })}`,
          icon: shareItem.itemType === "folder" ? <FolderIcon /> : <FileIcon />,
          thumbnailUrl: filterBlobUrl(shareItem.thumbnailUrl),
        }))}
      rowsCount={rowsCount}
      fetchMore={fetchMore}
    />
  );
}
