import React from "react";
import { gql } from "@apollo/client";
import { Cog } from "mdi-material-ui";

import { teamRoute } from "helpers";
import { useRoute, useData } from "hooks";
import { AppView, AppTabs } from "controls";

import GeneralTab from "./GeneralTab";
import PermissionsTab from "./PermissionsTab";
import SessionsTab from "./SessionsTab";
import TrashbinTab from "./TrashbinTab";
import AuditTab from "./AuditTab";
import PicklistsTab from "./PicklistsTab";
import ShareLinksTab from "./ShareLinksTab";
import NoticesTab from "./NoticesTab";

export default function ConfigureView() {
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query ConfigureView($teamId: ID!) {
        team(id: $teamId) {
          id
          canViewSessions
          canViewDeletedItems
          canViewAuditEvents
        }
      }
    `,
    { teamId },
  );
  return (
    <>
      <AppView
        title="团队设置"
        crumbs={[
          {
            icon: <Cog />,
            label: "团队设置",
            href: `/${teamId}/configure`,
          },
        ]}
      >
        <AppTabs
          tabs={[
            {
              name: "general",
              title: "基本信息",
              content: <GeneralTab />,
            },
            {
              name: "permissions",
              title: "管理员",
              content: <PermissionsTab />,
            },
            {
              name: "sessions",
              title: "登录设备",
              disabled: !data?.team.canViewSessions,
              content: <SessionsTab />,
            },
            {
              name: "picklists",
              title: "选项列表",
              content: <PicklistsTab />,
            },
            {
              name: "share_links",
              title: "公开浏览链接",
              content: <ShareLinksTab />,
            },
            {
              name: "trashbin",
              title: "回收站",
              disabled: !data?.team.canViewDeletedItems,
              content: <TrashbinTab />,
            },
            {
              name: "audit",
              title: "审计日志",
              disabled: !data?.team.canViewAuditEvents,
              content: <AuditTab />,
            },
            {
              name: "notices",
              title: "公告",
              content: <NoticesTab />,
            },
          ].filter((t) => t)}
        />
      </AppView>
    </>
  );
}
