import React, { useState } from "react";
import { ButtonGroup, Paper, Button } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { noop } from "lodash-es";

export default function AppToolbar({
  actions,
  dense = false,
  iconOnly = false,
  children,
}) {
  return (
    <>
      <Paper
        elevation={dense ? 0 : undefined}
        style={{
          ...(!dense && {
            margin: 5,
            padding: 5,
          }),
        }}
      >
        {(actions || [])
          .filter(Boolean)
          .map((action) => (action.constructor === Array ? action : [action]))
          .map((group, groupIndex) => (
            <ButtonGroup key={groupIndex} style={{ margin: 5 }}>
              {group.map((action) => (
                <AppToolbarButton
                  key={action.key || action.title}
                  component="a"
                  title={action.title}
                  variant={action.selected ? "contained" : "outlined"}
                  href={action.href || "#"}
                  onClick={action.onClick}
                  disabled={action.disabled}
                  target={action.target}
                  {...(action.variant !== undefined && {
                    variant: action.variant,
                  })}
                >
                  {iconOnly ? <>{action.icon || action.title}</> : action.title}
                  {!!action.menuItems && <ArrowDropDown />}
                </AppToolbarButton>
              ))}
            </ButtonGroup>
          ))}
        {children}
      </Paper>
    </>
  );
}

function AppToolbarButton({ onClick = noop, ...others }) {
  const [clicking, clickingSet] = useState(false);
  return (
    <Button
      {...others}
      {...(clicking && {
        disabled: true,
      })}
      onClick={async (event) => {
        clickingSet(true);
        try {
          await onClick(event);
        } finally {
          clickingSet(false);
        }
      }}
    />
  );
}
