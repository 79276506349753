import React, { useState } from "react";
import { Pencil, Check, Close } from "mdi-material-ui";
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";

import AppForm from "controls/AppForm";

import FieldsContainer from "./FieldsContainer";

export default function FormCard({
  onSubmit,
  disabled = false,
  icon,
  avatar = icon && (
    <Avatar>{React.cloneElement(icon, { fontSize: "inherit" })}</Avatar>
  ),
  title,
  subheader,
  fields = [],
  ...others
}) {
  const [editing, editingSet] = useState(false);
  const [submitting, submittingSet] = useState(false);

  return (
    <>
      <Card
        {...others}
        style={{
          margin: 5,
          position: "relative",
          ...(submitting && {
            cursor: "progress",
          }),
          ...others.style,
        }}
      >
        <AppForm
          onSubmit={async (formData) => {
            if (!editing) return;
            try {
              submittingSet(true);
              await onSubmit(formData);
              editingSet(false);
            } finally {
              submittingSet(false);
            }
          }}
        >
          {!!onSubmit && (
            <CardActions style={{ position: "absolute", top: 0, right: 0 }}>
              {!editing && (
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    editingSet(true);
                  }}
                  disabled={disabled}
                  variant="outlined"
                  size="small"
                >
                  <Pencil />
                  修改
                </Button>
              )}
              {editing && (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  <Check />
                  保存修改
                </Button>
              )}
              {editing && (
                <Button
                  onClick={() => editingSet(false)}
                  disabled={submitting}
                  size="small"
                >
                  <Close />
                  取消
                </Button>
              )}
            </CardActions>
          )}
          {(!!avatar || !!title || !!subheader) && (
            <CardHeader
              avatar={avatar}
              title={<>{title}</>}
              subheader={subheader}
            />
          )}
          <CardContent>
            <FieldsContainer
              disabled={submitting}
              fields={fields}
              readOnly={!editing}
            />
          </CardContent>
        </AppForm>
      </Card>
    </>
  );
}
