import React, { useState, useContext, useLayoutEffect } from "react";
import { times } from "lodash-es";
import {
  TablePlus,
  TableEdit,
  TableRemove,
  Label,
  CursorText,
  KeyboardReturn,
} from "mdi-material-ui";
import FontFaceObserver from "fontfaceobserver";

import {
  useDocumentActions,
  useFormDialog,
  useConfirmDialog,
  useProgressBar,
} from "hooks";
import {
  showMessage,
  IS_PRODUCTION,
  SONGTI_FONT_FAMILY,
  HEITI_FONT_FAMILY,
} from "helpers";
import { documentContentContext } from "contexts";
import { NumberInput } from "inputs";
import TextArea from "./TextArea";
import Field from "./Field";

import Rect from "./Rect";
import { round, useUpdatePageCells, pageContext } from "./helpers";

const HANDLE = 1;

export default function Cell({ cell }) {
  const { left, top, width, height } = cell;
  const bottom = top + height;

  const right = left + width;
  const [hover, hoverSet] = useState(false);
  const { documentContentMode } = useContext(documentContentContext);
  const updatePageCells = useUpdatePageCells();
  const formDialog = useFormDialog();
  const confirmDialog = useConfirmDialog();
  const {
    resizeFromSet,
    resizeCellIdSet,
    resizeCursorSet,
    page,
    cellSelectedSet,
  } = useContext(pageContext);

  const cssFontFamily = {
    heiti: HEITI_FONT_FAMILY,
    songti: SONGTI_FONT_FAMILY,
  }[cell.fontFamily];

  const [fontLoaded, fontLoadedSet] = useState(false);
  useLayoutEffect(() => {
    if (!cssFontFamily) fontLoadedSet(true);
    fontLoadedSet(false);
    const fontFaceObserver = new FontFaceObserver(cssFontFamily);
    Promise.resolve().then(async () => {
      try {
        await fontFaceObserver.load("测试", 120000);
        fontLoadedSet(true);
      } catch (error) {
        if (!IS_PRODUCTION) throw error;
      }
    });
  }, [cssFontFamily]);

  useProgressBar(!fontLoaded, { unobstrusive: true });

  const { handleFocus, handleBlur, focused } = useDocumentActions(
    "单元格",
    [
      {
        icon: <TableRemove />,
        title: "删除单元格",
        onClick: async () => {
          await updatePageCells({
            input: { pageId: page.id, deleteCellIds: [cell.id] },
          });
          await showMessage({ message: "成功删除单元格" });
        },
      },
      {
        icon: <TablePlus />,
        title: "拆分单元格",
        onClick: () =>
          formDialog({
            title: "拆分单元格",
            fields: [
              {
                name: "rows",
                label: "拆分行数",
                value: "1",
                required: true,
                options: {
                  type: "number",
                  min: 1,
                  step: 1,
                },
              },
              {
                name: "cols",
                label: "拆分列数",
                value: "1",
                required: true,
                options: {
                  type: "number",
                  min: 1,
                  step: 1,
                },
              },
            ],
            onSubmit: async ({ cols, rows }) => {
              const rowSize = round(height / rows);
              const colSize = round(width / cols);
              const addCells = [];
              for (const rowIndex of times(rows)) {
                for (const colIndex of times(cols)) {
                  addCells.push({
                    top: top + rowIndex * rowSize,
                    left: left + colIndex * colSize,
                    height:
                      // last row takes up the rest space
                      rowIndex === rows - 1
                        ? height - rowSize * (rows - 1)
                        : rowSize,
                    width:
                      colIndex === cols - 1
                        ? width - colSize * (cols - 1)
                        : colSize,
                  });
                }
              }
              await updatePageCells({
                input: {
                  pageId: page.id,
                  addCells,
                  deleteCellIds: [cell.id],
                },
              });
              showMessage({ message: "成功拆分单元格" });
            },
          }),
      },
      {
        icon: <TableEdit />,
        title: "单元格坐标",
        onClick: () =>
          formDialog({
            title: "单元格坐标",
            fields: [
              ["left", "水平坐标"],
              ["top", "垂直坐标"],
              ["width", "宽度"],
              ["height", "高度"],
            ].map(([name, label]) => ({
              name,
              label,
              value: cell[name],
              inputComponent: NumberInput,
              options: {
                min: 0,
                step: 0.5,
                unit: "mm",
              },
            })),
            onSubmit: async (formData) => {
              await updatePageCells({
                input: {
                  pageId: page.id,
                  updateCells: [
                    {
                      cellId: cell.id,
                      ...formData,
                    },
                  ],
                },
              });
              showMessage({ message: "成功更新单元格坐标" });
            },
          }),
      },
      {
        group: "单元格类型",
        dropdown: true,
        icon: <Label />,
        title: "设置为普通单元格",
        selected: !cell.field,
        onClick: async () => {
          if (!cell.field) return;
          await confirmDialog({
            message: "转换为普通单元格将丢失其中的数据。",
          });
          await updatePageCells({
            input: {
              pageId: page.id,
              updateCells: [
                {
                  cellId: cell.id,
                  isField: false,
                },
              ],
            },
          });
          showMessage({ message: "成功转换为普通单元格" });
        },
      },
      {
        group: "单元格类型",
        dropdown: true,
        icon: <CursorText />,
        title: "设置为数据单元格",
        selected: !!cell.field,
        onClick: async () => {
          if (cell.field) return;
          await updatePageCells({
            input: {
              pageId: page.id,
              updateCells: [
                {
                  cellId: cell.id,
                  isField: true,
                },
              ],
            },
          });
        },
      },
      !cell.field && {
        title: "允许换行",
        icon: <KeyboardReturn />,
        selected: cell.multiline,
        disabled: cell.multiline && cell.content.includes("\n"),
        onClick: async () => {
          await updatePageCells({
            input: {
              pageId: page.id,
              updateCells: [
                {
                  cellId: cell.id,
                  multiline: !cell.multiline,
                },
              ],
            },
          });
          showMessage({ message: "成功修改允许换行" });
        },
      },
    ].filter((a) => a),
  );

  return (
    <>
      <Rect
        top={top}
        left={left}
        height={height}
        width={width}
        style={{
          boxShadow: focused || hover ? "0 0 5px 2px #888 inset" : null,
        }}
        borderStyle={cell.borderStyle}
        {...(cell.borderStyle === "none" && {
          borderWidth: 0,
        })}
      />
      <div
        data-cell
        data-cell-id={cell.id}
        {...(documentContentMode === "edit" && {
          tabIndex: 0,
          onFocus: () => {
            handleFocus();
            cellSelectedSet(cell);
          },
          onBlur: () => {
            handleBlur();
            cellSelectedSet(null);
          },
          onMouseEnter: () => hoverSet(true),
          onMouseLeave: () => hoverSet(false),
        })}
        style={{
          position: "absolute",
          top: `${top}mm`,
          left: `${left}mm`,
          height: `${height}mm`,
          width: `${width}mm`,
          outline: "none",
          lineHeight: `${cell.fontSize}mm`,
          fontFamily: cssFontFamily,
          fontSize: `${cell.fontSize / 1.15}mm`,
          textAlign: cell.align,
          color: cell.color,
        }}
      >
        {cell.underlined && (
          <div
            style={{
              pointerEvents: "none",
              position: "absolute",
              top: 0,
              left: `${cell.padding}mm`,
              right: `${cell.padding}mm`,
              bottom: `${cell.padding}mm`,
              borderBottom: "solid 1px",
            }}
          />
        )}
        {cell.field ? (
          <Field
            field={cell.field}
            style={{
              width: "100%",
              height: "100%",
              padding: `${cell.padding}mm`,
              boxSizing: "border-box",
            }}
          />
        ) : (
          <TextArea
            value={cell.content}
            multiline={cell.multiline}
            readOnly={documentContentMode !== "edit"}
            onChange={async (content) => {
              await updatePageCells({
                input: {
                  pageId: page.id,
                  updateCells: [
                    {
                      cellId: cell.id,
                      content,
                    },
                  ],
                },
              });
              showMessage({ message: "成功更新单元格" });
            }}
            style={{
              height: "100%",
              width: "100%",
              padding: `${cell.padding}mm`,
            }}
          />
        )}
      </div>
      {documentContentMode === "edit" &&
        [
          [
            // left
            [top, left, height, HANDLE],
            [null, left],
            "ew-resize",
          ],
          [
            // right
            [top, right - HANDLE, height, HANDLE],
            [null, right],
            "ew-resize",
          ],
          [
            // top
            [top, left, HANDLE, width],
            [top, null],
            "ns-resize",
          ],
          [
            // bottom
            [bottom - HANDLE, left, HANDLE, width],
            [bottom, null],
            "ns-resize",
          ],
          [
            // top-left
            [top, left, HANDLE, HANDLE],
            [top, left],
            "nwse-resize",
          ],
          [
            // top-right
            [top, right - HANDLE, HANDLE, HANDLE],
            [top, right],
            "nesw-resize",
          ],
          [
            // bottom-left
            [bottom - HANDLE, left, HANDLE, HANDLE],
            [bottom, left],
            "nesw-resize",
          ],
          [
            // bottom-right
            [bottom - HANDLE, right - HANDLE, HANDLE, HANDLE],
            [bottom, right],
            "nwse-resize",
          ],
        ].map(([rect, resizeFrom, cursor], boxIndex) => (
          <div
            key={boxIndex}
            data-cell-resize-handle-id={cell.id}
            data-cell-resize-handle-top={String(resizeFrom[0])}
            data-cell-resize-handle-left={String(resizeFrom[1])}
            style={{
              position: "absolute",
              top: `${rect[0]}mm`,
              left: `${rect[1]}mm`,
              height: `${rect[2]}mm`,
              width: `${rect[3]}mm`,
              cursor,
            }}
            onMouseDown={() => {
              resizeFromSet(resizeFrom);
              resizeCellIdSet(cell.id);
              resizeCursorSet(cursor);
            }}
          />
        ))}
    </>
  );
}
