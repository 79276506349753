import React from "react";
import { gql } from "@apollo/client";

import { userRoute } from "helpers";
import { useData, useRoute } from "hooks";
import SessionsTable, { SessionsTableFragment } from "lists/SessionsTable";

export default function SessionsTab() {
  const { userId, teamId } = useRoute(userRoute);
  const [sessionsData, sessionsDataMeta] = useData(
    gql`
      query SessionsTab($userId: ID!, $teamId: ID!, $offset: Int) {
        user(id: $userId) {
          id
          sessions(offset: $offset, limit: 20) {
            id
            ...SessionsTableFragment
          }
          sessionsCount
        }
      }
      ${SessionsTableFragment}
    `,
    { userId, teamId },
    { returnMeta: true },
  );

  return (
    <SessionsTable
      rows={sessionsData?.user.sessions}
      rowsCount={sessionsData?.user.sessionsCount}
      fetchMore={sessionsDataMeta.fetchMore}
    />
  );
}
