import gql from "graphql-tag";
import pmap from "promise.map";
import { remove, throttle } from "lodash-es";

import newId from "./newId";
import retry from "./retry";
import sum from "./sum";
import Queue from "./Queue";
import UserError from "./UserError";
import { API_ORIGIN } from "./config";
import apolloClient from "./apolloClient";

const maxBlockSize = 4 * 1024 * 1024;
const maxChunkSize = maxBlockSize / 4;
const concurrency = 5;
const onProgressThrottleMS = 300;
const upHost = "up-z2.qiniup.com";

const uploaders = [];
const noop = () => {};

const qiniuQueue = new Queue({ concurrency });
const postQueue = new Queue({ concurrency });

async function post(headerAuthorization, url, body, onProgress, uploader) {
  return await postQueue.execute({
    abortFuncs: uploader.abortFuncs,
    func: () =>
      retry(async () => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Authorization", headerAuthorization);
        xhr.responseType = "json";
        const abortFunc = () => xhr.abort();
        let data;
        try {
          data = await new Promise((resolve, reject) => {
            xhr.addEventListener("error", (event) =>
              reject(
                new Error(`上传文件时发生网络错误: ${event.error?.message}`),
              ),
            );
            xhr.addEventListener("abort", () =>
              reject(new UserError("已取消", { isAbortError: true })),
            );
            xhr.addEventListener("load", () => {
              if (xhr.status !== 200)
                return reject(
                  new Error(
                    `上传错误：${xhr.response?.error || `HTTP ${xhr.status}`}`,
                  ),
                );
              resolve(xhr.response);
            });
            if (xhr.upload)
              xhr.upload.addEventListener("progress", (event) =>
                onProgress({ total: event.total, uploaded: event.loaded }),
              );
            xhr.send(body);
            if (uploader) uploader.abortFuncs.push(abortFunc);
          });
        } finally {
          if (uploader) remove(uploader.abortFuncs, (f) => f === abortFunc);
        }

        return data;
      }),
  });
}

function urlSafeBase64(content) {
  const base64 = btoa(content);
  return base64.replace(/\+/g, "-").replace(/\//g, "_");
}

export async function abortUploadBlob({ id }) {
  for (const uploader of uploaders) {
    if (uploader.id !== id) continue;
    for (const abortFunc of uploader.abortFuncs) abortFunc();
  }
}

async function uploadBlock({
  block,
  blob,
  host,
  upToken,
  handleProgress,
  uploader,
}) {
  let data;
  const firstChunkOffset = block.offset;
  const firstChunkSize = Math.min(maxChunkSize, block.end - firstChunkOffset);
  const firstChunkEnd = block.offset + firstChunkSize;
  const firstChunkBlob = blob.slice(firstChunkOffset, firstChunkEnd);
  data = await post(
    `UpToken ${upToken}`,
    `${host}/mkblk/${block.size}`,
    firstChunkBlob,
    ({ uploaded }) => {
      block.uploaded = uploaded;
      handleProgress();
    },
    uploader,
  );
  let ctx = data.ctx;
  host = data.host.replace(/^https?/, "https");

  let chunkOffset = firstChunkEnd;
  while (chunkOffset < block.end) {
    const chunkSize = Math.min(maxChunkSize, block.end - chunkOffset);
    const chunkEnd = chunkOffset + chunkSize;
    const chunkBlob = blob.slice(chunkOffset, chunkEnd);
    data = await post(
      `UpToken ${upToken}`,
      `${host}/bput/${ctx}/${chunkOffset - block.offset}`,
      chunkBlob,
      ({ uploaded }) => {
        block.uploaded = chunkOffset - block.offset + uploaded;
        handleProgress();
      },
      uploader,
    );
    ctx = data.ctx;
    host = data.host.replace(/^https?/, "https");

    chunkOffset = chunkEnd;
  }

  return ctx;
}

export default async function uploadBlob({ ...others }) {
  const result = await apolloClient.mutate({
    mutation: gql`
      mutation getUpToken {
        getUpToken(input: {}) {
          upToken
          blobServiceType
        }
      }
    `,
  });
  const { upToken, blobServiceType } = result.data.getUpToken;
  if (blobServiceType === "qiniu")
    return await qiniuUpload({ upToken, ...others });
  if (blobServiceType === "dev_blob") return await devBlobUpload({ ...others });
  throw new Error(`Unknown blobServiceType: ${blobServiceType}`);
}

async function qiniuUpload({
  blob,
  upToken,
  id = newId(),
  onProgress = noop,
  onStart = noop,
}) {
  let data;
  const host = `https://${upHost}`;
  let blockOffset = 0;
  const uploader = { id, blob, abortFuncs: [] };
  uploaders.push(uploader);

  try {
    return await qiniuQueue.execute({
      abortFuncs: uploader.abortFuncs,
      func: async () => {
        onStart({ id });

        const blocks = [];
        const handleProgress = throttle(() => {
          const total = sum(blocks.map((b) => b.size));
          const uploaded = sum(blocks.map((b) => b.uploaded));
          onProgress({ total, uploaded, id });
        }, onProgressThrottleMS);

        while (blockOffset < blob.size) {
          const blockSize = Math.min(maxBlockSize, blob.size - blockOffset);
          const blockEnd = blockOffset + blockSize;

          blocks.push({
            offset: blockOffset,
            size: blockSize,
            end: blockEnd,
            uploaded: 0,
          });

          blockOffset = blockEnd;
        }
        const ctxs = await pmap(blocks, (block) =>
          uploadBlock({
            block,
            blob,
            host,
            upToken,
            uploader,
            handleProgress,
          }),
        );

        data = await post(
          `UpToken ${upToken}`,
          `https://${upHost}/mkfile/${blob.size}/mimeType/${urlSafeBase64(
            blob.type,
          )}`,
          new Blob([ctxs.join(",")]),
          noop,
          uploader,
        );
        const etag = data.etag;

        return { etag };
      },
    });
  } finally {
    remove(uploaders, uploader);
  }
}

async function devBlobUpload({
  blob,
  id = newId(),
  onProgress = noop,
  onStart = noop,
}) {
  const uploader = { id, blob, abortFuncs: [] };
  uploaders.push(uploader);

  try {
    const token = window._fmsToken;
    const formData = new FormData();
    formData.append("file", blob, blob.name);
    onStart({ id });
    return await post(
      `Bearer ${token}`,
      `${API_ORIGIN}/api/dev_blobs`,
      formData,
      ({ uploaded }) => {
        onProgress({ total: blob.size, uploaded, id });
      },
      uploader,
    );
  } finally {
    remove(uploaders, uploader);
  }
}
