import React, { useState, Fragment } from "react";
import { groupBy } from "lodash-es";
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from "@mui/material";
import {
  CheckboxBlankCircle,
  Help,
  Plus,
  ChevronUp,
  ChevronDown,
} from "mdi-material-ui";

import useFormDialog from "hooks/useFormDialog";

import SingleInput from "./SingleInput";
import MultiInput from "./MultiInput";

export default function OptionsInput({ ...props }) {
  return (
    <SingleInput
      {...props}
      options={props.options?.map((option) =>
        option.constructor === String
          ? {
              name: option,
              label: option,
            }
          : option,
      )}
      {...{
        renderItemAvatar,
        renderItemLabel,
        renderItemDialogContent,
      }}
    />
  );
}

export function MultiOptionsInput({ ...props }) {
  return (
    <MultiInput
      {...props}
      options={props.options?.map((option) =>
        option.constructor === String
          ? {
              name: option,
              label: option,
            }
          : option,
      )}
      {...{
        renderItemAvatar,
        renderItemLabel,
        renderSelectDialogContent,
      }}
    />
  );
}

function renderItemLabel({ item, options }) {
  const option = options?.find((o) => o.name === item);
  return <>{option ? option.label : `未知选项: ${item}`}</>;
}

function renderItemAvatar({ item, options, allowNewOption, ...others }) {
  const option = options?.find((o) => o.name === item);
  if (!option)
    return (
      <Avatar {...others}>
        <Help fontSize="inherit" />
      </Avatar>
    );

  return (
    <Avatar {...others}>
      {React.cloneElement(option.icon || <CheckboxBlankCircle />, {
        fontSize: "inherit",
      })}
    </Avatar>
  );
}

function Options({ options, allowNewOption, onOptionClick }) {
  const formDialog = useFormDialog();
  const [expandedGroups, expandedGroupsSet] = useState([]);

  return (
    <>
      <List>
        {allowNewOption && (
          <ListItem
            button
            component="a"
            href="#"
            onClick={() =>
              formDialog({
                title: "输入其他选项",
                fields: [
                  {
                    label: "其他选项",
                    name: "newOption",
                    required: true,
                  },
                ],
                onSubmit: ({ newOption }) =>
                  onOptionClick({
                    label: newOption,
                    name: newOption,
                  }),
              })
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Plus fontSize="inherit" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="其他选项" />
          </ListItem>
        )}
        {options
          .filter((o) => !o.group)
          .map((option) => (
            <ListItem
              button
              key={option.name}
              component="a"
              href="#"
              selected={option.selected}
              onClick={() => onOptionClick(option)}
            >
              {option.icon && (
                <ListItemAvatar>
                  <Avatar>
                    {React.cloneElement(option.icon, {
                      fontSize: "inherit",
                    })}
                  </Avatar>
                </ListItemAvatar>
              )}
              <ListItemText
                primary={option.label}
                secondary={<>{option.description}</>}
              />
            </ListItem>
          ))}
        {Object.entries(
          groupBy(
            options.filter((o) => o.group),
            (o) => o.group,
          ),
        )
          .map(([group, options]) => ({
            group,
            options,
            expanded: expandedGroups.includes(group),
          }))
          .map(({ group, options, expanded }) => (
            <Fragment key={group}>
              <ListItem
                button
                title={group}
                href="#"
                component="a"
                onClick={() =>
                  expanded
                    ? expandedGroupsSet((gs) => gs.filter((g) => g !== group))
                    : expandedGroupsSet((gs) => [...gs, group])
                }
              >
                <ListItemText
                  primary={group}
                  secondary={`${options.length}个项目`}
                />
                {expanded ? <ChevronUp /> : <ChevronDown />}
              </ListItem>
              <Collapse in={expanded} unmountOnExit>
                <List component="div" disablePadding>
                  {options.map((option) => (
                    <ListItem
                      button
                      key={option.name}
                      component="a"
                      href="#"
                      selected={option.selected}
                      onClick={() => onOptionClick(option)}
                    >
                      {option.icon && (
                        <ListItemAvatar>
                          <Avatar>
                            {React.cloneElement(option.icon, {
                              fontSize: "inherit",
                            })}
                          </Avatar>
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        primary={option.label}
                        secondary={<>{option.description}</>}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          checked={option.selected}
                          disableRipple
                          onClick={() => onOptionClick(option)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ))}
      </List>
    </>
  );
}

function renderItemDialogContent({
  item,
  itemSubmit,
  options = [],
  ...others
}) {
  return (
    <Options
      options={[
        ...options.map((option) => ({
          ...option,
          selected: option.name === item,
        })),
        // if there's value but not one of the options
        ...(item && !options?.find((o) => o.name === item)
          ? [
              {
                icon: <Help />,
                label: `未知选项: ${item}`,
                name: item,
                selected: true,
              },
            ]
          : []),
      ]}
      onOptionClick={(option) => itemSubmit(option.name)}
      {...others}
    />
  );
}

function renderSelectDialogContent({
  items,
  itemsSet,
  options = [],
  ...others
}) {
  return (
    <Options
      options={[
        ...options.map((option) => ({
          ...option,
          selected: items.includes(option.name),
        })),
        // if there's value but not one of the options
        ...items
          .filter((item) => !options.find((o) => o.name === item))
          .map((name) => ({
            icon: <Help />,
            label: `未知选项: ${name}`,
            name,
          })),
      ]}
      onOptionClick={(option) =>
        itemsSet(
          option.selected
            ? items.filter((name) => name !== option.name)
            : [...items, option.name],
        )
      }
      {...others}
    />
  );
}
