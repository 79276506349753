import React from "react";
import { gql } from "@apollo/client";
import { Avatar } from "@mui/material";
import { FileFind } from "mdi-material-ui";

import ItemObjectIcon from "blocks/ItemObjectIcon";
import useData from "hooks/useData";
import useDialogOpener from "hooks/useDialogOpener";
import PreviewDialog from "views/previewable/PreviewDialog";

import SingleInput from "./SingleInput";
import MultiInput from "./MultiInput";
import ItemInputDialogContent from "./item_input/ItemInputDialogContexnt";
import FolderInputDialogContent from "./item_input/FolderInputDialogContent";

export default function ItemInput({ ...others }) {
  const openPreviewDialog = useDialogOpener(PreviewDialog);

  return (
    <SingleInput
      {...others}
      DialogProps={{ maxWidth: "xl" }}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemInputDialogContent}
      getItemActions={(itemId) =>
        [
          !itemId.startsWith("F") && {
            title: "预览",
            icon: <FileFind />,
            onClick: () => openPreviewDialog({ previewableId: itemId }),
          },
        ].filter((a) => a)
      }
    />
  );
}

export function ItemsInput({ ...others }) {
  return (
    <MultiInput
      {...others}
      DialogProps={{ maxWidth: "md" }}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemInputDialogContent}
    />
  );
}

export function FolderInput({ ...others }) {
  return (
    <SingleInput
      {...others}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={FolderInputDialogContent}
    />
  );
}

export function FoldersInput({ ...others }) {
  return (
    <MultiInput
      {...others}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={FolderInputDialogContent}
    />
  );
}

function ItemLabel({ item }) {
  const data = useData(
    gql`
      query ItemLabel($itemId: ID!) {
        item(id: $itemId) {
          id
          name
        }
      }
    `,
    { itemId: item },
  );

  return <>{data?.item.name}</>;
}

function ItemAvatar({ itemTypes, item, topFolderId, ...others }) {
  return (
    <Avatar {...others}>
      <ItemObjectIcon itemId={item} fontSize="inherit" />
    </Avatar>
  );
}
