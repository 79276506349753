let compare;

if (typeof Intl === "undefined" || typeof Intl.Collator === "undefined") {
  compare = (string1, string2) => {
    if (typeof string1 !== "string" || typeof string2 !== "string") {
      return NaN;
    }

    if (string1 === string2) {
      return 0;
    }

    if (string1 < string2) {
      return -1;
    }

    return 1;
  };
} else {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  compare = collator.compare;
}

export default compare;
