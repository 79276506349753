import { set } from "lodash-es";
import { InMemoryCache } from "@apollo/client";

import schema from "../schema.js";

const possibleTypes = {};
const typePolicies = {};

for (const [type, subTypes] of schema.possibleTypes) {
  possibleTypes[type] = subTypes;
}

for (const [type, field] of schema.pagedFields)
  set(typePolicies, `${type}.fields.${field}.merge`, merge);

for (const [type, field] of schema.argedFields)
  set(typePolicies, `${type}.fields.${field}.keyArgs`, keyArgs);

const apolloCache = new InMemoryCache({
  possibleTypes,
  typePolicies,
});

export default apolloCache;

function keyArgs(args, context) {
  const key = [
    context.fieldName,
    JSON.stringify({
      ...args,
      offset: undefined,
      limit: undefined,
    }),
  ].join("");
  return key;
}

export function merge(existing, incoming, { args }) {
  const { offset = 0 } = args || {};

  existing = existing || [];

  // invalid offset, do not try to merge
  if (offset > existing.length) return existing;

  // e.g offset: 1
  // existing: 1, 2, 3, 4
  // incoming:    a, 2, b, 3, c
  // expect:   1, a, 2, b, 3, c, 4

  const head = existing.slice(0, offset);

  const merged = [
    //
    ...head,
    ...incoming,
  ];

  return merged;
}
