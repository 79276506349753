import React from "react";

export default React.memo(Line);

function Line({
  vertical = false,
  pos,
  distance,
  strokeWidth = 1,
  strokeColor = "#000",
  strokeStyle = "solid",
  ...others
}) {
  return (
    <div
      data-line-top={pos[0]}
      data-line-left={pos[0]}
      data-line-vertical={vertical}
      data-line-distance={distance}
      {...others}
      style={{
        position: "absolute",
        // line should not have any interaction area
        pointerEvents: "none",
        ...(vertical
          ? {
              top: `calc(${pos[0]}mm - ${strokeWidth}px / 2)`,
              left: `calc(${pos[1]}mm - ${strokeWidth}px / 2)`,
              height: `calc(${distance}mm + ${strokeWidth}px / 2)`,
              width: 10,
              borderLeft: `${strokeStyle} ${strokeWidth}px ${strokeColor}`,
            }
          : {
              top: `calc(${pos[0]}mm - ${strokeWidth}px / 2)`,
              left: `calc(${pos[1]}mm - ${strokeWidth}px / 2)`,
              height: 10,
              width: `calc(${distance}mm + ${strokeWidth}px / 2)`,
              borderTop: `${strokeStyle} ${strokeWidth}px ${strokeColor}`,
            }),
        ...others.style,
      }}
    />
  );
}
