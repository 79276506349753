import React, { useState } from "react";
import { gql } from "@apollo/client";
import { defer } from "lodash-es";

import { showMessage, teamRoute } from "helpers";
import { FormDialog } from "controls";
import { SuggestiveTextInput, CompaniesInput } from "inputs";
import { useRoute, useData, useAction, useHistory } from "hooks";

export default function AddUserDialog({ name = "", ...others }) {
  const history = useHistory();
  const { teamId } = useRoute(teamRoute);
  const [existingUser, existingUserSet] = useState(null);

  const data = useData(
    gql`
      query AddUserDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          users {
            id
            name
            email
            mobile
            companies {
              id
            }
          }
        }
      }
    `,
    { teamId },
  );

  const addUser = useAction(gql`
    mutation AddUserDialog($input: AddUserInput!) {
      addUser(input: $input) {
        addedUser {
          id
        }
        team {
          id
          users {
            id
          }
        }
      }
    }
  `);
  return (
    <FormDialog
      {...others}
      title="添加成员"
      fields={[
        {
          label: "姓名",
          name: "name",
          inputComponent: SuggestiveTextInput,
          value: name,
          required: true,
          helperText: "必填项",
          onChange: (name) => {
            const existingUser = data?.team.users.find((u) => u.name === name);
            if (existingUser) existingUserSet(existingUser);
          },
          options: {
            suggestions: data?.team.users.map((u) => u.name),
          },
        },
        {
          label: "电子邮件",
          name: "email",
          value: existingUser?.email || "",
          helperText: "如果填写则用户可通过该电子邮箱进行登录",
          key: existingUser?.id,
        },
        {
          label: "手机号码",
          name: "mobile",
          value: existingUser?.mobile || "",
          helperText: "如果填写则用户可通过该手机号码进行登录",
          key: existingUser?.id,
        },
        {
          label: "单位",
          name: "companyIds",
          value: existingUser?.companies.map((c) => c.id) || [],
          key: existingUser?.id,
          inputComponent: CompaniesInput,
          helperText:
            "团队成员可以同时属于多个单位；您可以在单位里设置成员在单位内的角色",
        },
      ]}
      onSubmit={async (formData) => {
        const result = await addUser({
          input: {
            teamId,
            ...formData,
          },
        });
        showMessage({
          message: `成功添加成员${formData.name}`,
        });
        defer(() => history.push(`/${teamId}/${result.addUser.addedUser.id}`));
      }}
    />
  );
}
