import React, { useState, useEffect, useRef } from "react";
import pmap from "promise.map";
import { defer } from "lodash-es";
import { TextField, Button, Avatar, CircularProgress } from "@mui/material";
import { filesize } from "filesize";
import { Check } from "mdi-material-ui";

import newId from "helpers/newId";
import Message from "controls/Message";
import uploadBlob, { abortUploadBlob } from "helpers/uploadBlob";
import selectFiles from "helpers/selectFiles";
import { FileIcon } from "controls/icons";
import useProgressBar from "hooks/useProgressBar";

import SingleInput from "./SingleInput";
import MultiInput from "./MultiInput";

function ItemDialogContent({ itemSubmit, itemSet, accept, multiple = false }) {
  const [blobs, blobsSet] = useState();
  const [etags, etagsSet] = useState();
  const [names, namesSet] = useState();
  const [uploadedSizes, uploadedSizesSet] = useState([]);
  const uploadingIdRef = useRef([]);

  useProgressBar(!!blobs && !etags);

  useEffect(() => {
    return () => {
      uploadingIdRef.current?.map((id) => abortUploadBlob({ id }));
    };
  }, []);

  useEffect(() => {
    if (etags)
      itemSet(
        ...etags.map((etag, etagIndex) => ({
          etag,
          name: names[etagIndex],
          size: String(blobs[etagIndex].size),
        })),
      );
  }, [etags, names]);

  const handleBlobs = async (blobs) => {
    try {
      namesSet(blobs.map((b) => b.name));
      blobsSet(blobs);

      uploadingIdRef.current = blobs.map(() => null);
      uploadedSizesSet(blobs.map(() => 0));
      const etags = await pmap(
        blobs,
        async (blob, blobIndex) => {
          const id = newId();
          uploadingIdRef.current[blobIndex] = id;
          const { etag } = await uploadBlob({
            id,
            blob,
            onProgress: ({ uploaded }) =>
              uploadedSizesSet((uploadedSizes) => [
                ...uploadedSizes.slice(0, blobIndex),
                uploaded,
                ...uploadedSizes.slice(blobIndex + 1),
              ]),
          });
          return etag;
        },
        5,
      );

      etagsSet(etags);
    } catch (error) {
      itemSubmit(null);
      defer(() => {
        throw error;
      });
    }
  };

  if (!blobs)
    return (
      <div
        style={{
          flex: "1 0 auto",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
          alignItems: "center",
          borderStyle: "dashed",
        }}
        onDragOver={(event) => {
          if (event.dataTransfer.types.includes("Files"))
            event.preventDefault();
        }}
        onDrop={(event) => {
          if (!event.dataTransfer.files.length) return;
          event.preventDefault();
          handleBlobs([...event.dataTransfer.files]);
        }}
      >
        请将文件拖拽至此，或
        <Button
          variant="contained"
          onClick={() => selectFiles({ accept, multiple }, handleBlobs)}
        >
          选择文件
        </Button>
      </div>
    );

  return (
    <>
      <Message
        message={`${!etags ? "正在上传" : "已上传"}:${blobs.length}个文件`}
        actions={[
          !etags && {
            title: "取消",
            onClick: () =>
              uploadingIdRef.current?.map((id) => abortUploadBlob({ id })),
          },
        ].filter((a) => a)}
      />
      {blobs.map((blob, blobIndex) => {
        const uploaded = uploadedSizes[blobIndex] || 0;
        return (
          <div
            key={blobIndex}
            style={{
              margin: 10,
              display: "flex",
              flexFlow: "row nowrap",
              alignItems: "flex-start",
            }}
          >
            <TextField
              size="small"
              fullWidth
              label="文件名"
              required
              value={names[blobIndex]}
              onChange={(event) =>
                namesSet((names) =>
                  names.map((n, i) =>
                    i === blobIndex ? event.target.value : n,
                  ),
                )
              }
              helperText={
                <>
                  原文件名：{blob.name} ({filesize(blob.size)})
                </>
              }
            />
            <div
              style={{ position: "relative", display: "flex", marginLeft: 5 }}
            >
              <CircularProgress
                variant="determinate"
                value={(uploaded / blob.size) * 100}
              />
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: ".8em",
                  opacity: 0.8,
                }}
              >
                {uploaded !== blob.size ? (
                  <>{Math.floor((uploaded / blob.size) * 100)}%</>
                ) : (
                  <Check />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

function ItemLabel({ item }) {
  return <>{item.name}</>;
}

function ItemAvatar({ item, ...others }) {
  return (
    <Avatar {...others}>
      <FileIcon fontSize="inherit" />
    </Avatar>
  );
}

export default function FileUploadInput({ ...others }) {
  return (
    <SingleInput
      {...others}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemDialogContent}
    />
  );
}

export function FileUploadsInput({ ...others }) {
  return (
    <MultiInput
      {...others}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={(others) => (
        <ItemDialogContent multiple {...others} />
      )}
    />
  );
}
