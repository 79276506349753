import React from "react";

import decamelize from "helpers/decamelize";
import useRouteState from "hooks/useRouteState";
import DateRangeFilter from "filters/DateRangeFilter";
import MultiSelectFilter from "filters/MultiSelectFilter";
import MultiDropdownFilter from "filters/MultiDropdownFilter";
import FilterBar from "filters/FilterBar";
import FieldFiltersFilter from "filters/FieldFiltersFilter";
import SelectFilter from "filters/SelectFilter";
import FolderFilter from "filters/FolderFilter";
import UserFilter from "filters/UserFilter";
import BooleanFilter from "filters/BooleanFilter";
import CompanyFilter from "filters/CompanyFilter";
import PropertyFiltersFilter from "filters/PropertyFiltersFilter";

export default function useFilterBar(options = {}, filters = []) {
  if (options.constructor === Array) {
    filters = options;
    options = {};
  }
  const {
    enableSearchTerm,
    searchTermAutofocus = false,
    noUrl,
    FilterBarProps = {},
  } = options;

  // state
  const state = {};
  const [searchTerm, searchTermSet] = useRouteState("search_term", {
    defaultValue: "",
    noUrl,
  });
  state.searchTerm = searchTerm || undefined;

  for (const filter of filters) {
    const component =
      {
        select: SelectFilter,
        multi_select: MultiSelectFilter,
        multi_dropdown: MultiDropdownFilter,
        date_range: DateRangeFilter,
        field_filters: FieldFiltersFilter,
        property_filters: PropertyFiltersFilter,
        folder: FolderFilter,
        user: UserFilter,
        boolean: BooleanFilter,
        company: CompanyFilter,
      }[filter.type] || MissingFilter;

    const {
      defaultValue = null,
      serialize = (v) => v,
      deserialize = (v) => v,
    } = { ...filter, ...component };

    const [value, valueSet] = useRouteState(decamelize(filter.name), {
      noUrl,
      defaultValue,
      serialize,
      deserialize,
    });

    Object.assign(filter, {
      component,
      value,
      valueSet,
      enabled:
        filter.alwaysEnabled || serialize(value) !== serialize(defaultValue),
    });
    state[filter.name] = filter.enabled ? value : undefined;
  }

  return [
    state,
    <FilterBar
      {...{
        searchTerm,
        searchTermSet,
        filters,
        enableSearchTerm,
        searchTermAutofocus,
      }}
      {...FilterBarProps}
    />,
  ];
}

function MissingFilter({ type }) {
  return <>Missing filter: ${type}</>;
}
