import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import { IS_TEST } from "helpers/config";

export default React.memo(MessagesProvider);

function MessagesProvider({ children }) {
  const [messageItems, messageItemsSet] = useState([]);
  useEffect(() => {
    const handler = (event) => {
      const { messageItem } = event.detail;
      messageItemsSet((m) => [...m, messageItem]);
    };

    window.addEventListener("appmessage", handler);

    return () => {
      window.removeEventListener("appmessage", handler);
    };
  }, []);

  return (
    <>
      {children}
      <div style={{ visibility: "visible" }}>
        {messageItems.map((messageItem) => (
          <MessageSnackbar key={messageItem.id} {...messageItem} />
        ))}
      </div>
    </>
  );
}

const MessageSnackbar = React.memo(function MessageSnackbar({
  message,
  type = "success",
  actions,
  autoHideDuration = IS_TEST ? null : 6000,
}) {
  const [open, openSet] = useState(true);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={(event, reason) => {
        if (reason === "clickaway") return;
        openSet(false);
      }}
    >
      <Alert
        severity={type}
        action={
          <>
            {actions}
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => openSet(false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            ,
          </>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
});
