import React, { useId, useState, useContext, useEffect } from "react";
import { noop, remove } from "lodash-es";

import { formContext } from "contexts";

export default function Field({
  name,
  defaultValue = "",
  onChange = noop,
  validate = noop,
  required = false,
  componentProps,
  component: Component,
}) {
  // validation
  const validateValue = (value) => {
    if (required) {
      if (
        // void value
        [null, undefined].includes(value) ||
        // empty string
        (value.constructor === String && !value.trim().length)
      )
        throw new Error("必须填写该项");
      if (value.constructor === Array && !value.length)
        throw new Error("必须选择该项");
    }
    validate(value);
  };
  const id = `${useId()}-${name}`;

  const [valueJSON, setValueJSON] = useState(() =>
    JSON.stringify(defaultValue),
  );

  const handleChange = (value) => {
    setValueJSON(JSON.stringify(value));
    onChange(value);
  };
  const value = JSON.parse(valueJSON);

  // communication with form
  const { fields } = useContext(formContext);

  useEffect(() => {
    fields.push({ id, name, value, validateValue });
    return () => {
      remove(
        fields,
        fields.find((f) => f.id === id),
      );
    };
  }, [id, name, valueJSON]);

  const field = fields.find((f) => f.id === id);
  const error = field?.error;

  return (
    <Component
      name={name}
      value={value}
      onChange={handleChange}
      error={error}
      {...componentProps}
    />
  );
}
