import ExtendedError from "./ExtendedError";

export default async function checkFetchResponse(response) {
  if (!response.ok) {
    const { status, statusText } = response;
    if (
      status === 400 &&
      response.headers.get("content-type").includes("text/plain")
    )
      throw new ExtendedError(await response.text());
    throw new ExtendedError(`网络错误: ${status} ${statusText}`, {
      status,
      statusText,
    });
  }
}
