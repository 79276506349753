import React from "react";
import { gql } from "@apollo/client";

import {
  Table,
  ActivityIcon,
  DateTimeCell,
  UsersCell,
  ItemPathCell,
} from "controls";

export const ActivitiesTableFragment = gql`
  fragment ActivitiesTableFragment on Activity {
    id
    category {
      id
      title
    }
    url
    content
    createdAt
    createdBy {
      id
    }
    item {
      id
    }
  }
`;

export default function ActivitiesTable({
  rows,
  rowsCount,
  fetchMore,
  ...others
}) {
  return (
    <Table
      {...others}
      fetchMore={fetchMore}
      columns={[
        {
          label: "时间",
          cellComponent: DateTimeCell,
        },
        {
          label: "成员",
          cellComponent: UsersCell,
        },
        {
          label: "类别",
        },
        {
          label: "位置",
          cellComponent: ItemPathCell,
        },
        {
          label: "内容",
        },
      ].filter((c) => c)}
      rowsCount={rowsCount}
      rows={rows?.map((activity) => ({
        id: activity.id,
        title: activity.content,
        href: activity.url,
        icon: <ActivityIcon />,
        values: [
          activity.createdAt,
          [activity.createdBy?.id].filter((i) => i),
          activity.category?.title || `未知动态(${activity.categoryName})`,
          activity.item?.id,
          activity.content,
        ],
      }))}
    />
  );
}
