import gql from "graphql-tag";

import useData from "./useData";
import useFilterBar from "./useFilterBar";

export default function useActivitiesFilterBar({
  hideUserFilter,
  hideItemFilter,
} = {}) {
  const data = useData(gql`
    query useActivitiesFilterBar {
      activityCategories {
        id
        name
        title
      }
    }
  `);

  return useFilterBar(
    { enableSearchTerm: true },
    [
      {
        type: "multi_dropdown",
        label: "类别",
        options:
          data?.activityCategories.map((activityCategory) => ({
            label: activityCategory.title,
            value: activityCategory.name,
          })) || [],
        name: "categoryNames",
      },
      {
        type: "date_range",
        label: "日期",
        name: "createdAtRange",
      },
      !hideUserFilter && {
        type: "user",
        label: "成员",
        name: "userId",
      },
      !hideItemFilter && {
        type: "folder",
        label: "位置",
        name: "itemId",
      },
    ].filter((f) => f),
  );
}
