import React, { useContext } from "react";
import { Paper } from "@mui/material";

import { documentContentContext } from "contexts";

import { pageContext } from "./helpers";
import Line from "./Line";

const BLEEDING = 5;

export default React.memo(Sheet);

function Sheet({ children, ...others }) {
  const { printing, documentContentMode } = useContext(documentContentContext);
  const { page } = useContext(pageContext);
  const { width, height, paddingHeight, paddingWidth } = page;

  return (
    <Paper
      square
      data-sheet
      elevation={printing ? 0 : undefined}
      {...others}
      style={{
        width: `${width}mm`,
        height: `${height}mm`,
        overflow: "hidden",
        position: "relative",
        ...(!printing && {
          margin: 10,
        }),
        ...(printing && {
          width: `${width - 10}mm`,
          height: `${height - 10}mm`,
          overflow: "visible",
          pageBreakAfter: "always",
          pageBreakInside: "avoid",
        }),
        ...others.style,
      }}
    >
      {documentContentMode === "edit" &&
        [
          [false, paddingHeight, paddingWidth - BLEEDING],
          [false, paddingHeight, width - paddingWidth],
          [false, height - paddingHeight, paddingWidth - BLEEDING],
          [false, height - paddingHeight, width - paddingWidth],
          [true, paddingHeight - BLEEDING, paddingWidth],
          [true, paddingHeight - BLEEDING, width - paddingWidth],
          [true, height - paddingHeight, paddingWidth],
          [true, height - paddingHeight, width - paddingWidth],
        ].map(([vertical, top, left], lineIndex) => (
          <Line
            key={lineIndex}
            pos={[top, left]}
            distance={BLEEDING}
            vertical={vertical}
            strokeColor="#ccc"
          />
        ))}
      {children}
    </Paper>
  );
}
