import React from "react";
import { gql } from "@apollo/client";
import { filesize } from "filesize";

import useRoute from "hooks/useRoute";
import { teamRoute } from "helpers/routes";
import DateTime from "controls/DateTime";
import ObjectCard from "controls/ObjectCard";

import ItemPath, { ItemPathFragment } from "./ItemPath";
import UsersLabel, { UsersLabelFragment } from "./UsersLabel";
import FolderObjectIcon from "./FolderObjectIcon";

export const FolderCardFragment = gql`
  fragment FolderCardFragment on Folder {
    id
    name
    size
    createdAt
    revisionedByUsers {
      ...UsersLabelFragment
    }
    ...ItemPathFragment
  }
  ${UsersLabelFragment}
  ${ItemPathFragment}
`;

export default function FolderCard({ folder }) {
  const { teamId } = useRoute(teamRoute);

  return (
    <>
      <ObjectCard
        icon={<FolderObjectIcon folderId={folder.id} />}
        title={folder.name}
        subheader={<ItemPath item={folder} />}
        href={`/${teamId}/${folder.id}`}
        fields={[
          {
            label: "创建时间",
            content: <DateTime value={folder.createdAt} />,
          },
          {
            label: "修订成员",
            content: <UsersLabel users={folder.revisionedByUsers} />,
          },
          {
            label: "尺寸",
            content: filesize(folder.size),
          },
        ]}
      />
    </>
  );
}
