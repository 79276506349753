import { createContext } from "react";

export const documentActionsContext = createContext({
  documentActionsSet: () => {},
});
export const authContext = createContext();
export const documentContentContext = createContext({
  documentContentMode: "view",
  printing: false,
});
export const formContext = createContext();
export const initContext = createContext();
export const viewportContext = createContext({
  documentActionsSet: () => {},
});
export const desktopStateContext = createContext();
