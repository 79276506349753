import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { FolderText } from "mdi-material-ui";

import { teamRoute } from "helpers/routes";
import { FolderIcon } from "controls/icons";
import ProjectFolderIcon from "icons/ProjectFolderIcon";
import useData from "hooks/useData";
import useRoute from "hooks/useRoute";

export default React.memo(FolderObjectIcon);

FolderObjectIcon.propTypes = {
  folderId: PropTypes.string.isRequired,
};

function FolderObjectIcon({ folderId, ...others }) {
  const { teamId } = useRoute(teamRoute);
  const dataTeamFolders = useData(
    gql`
      query FolderObjectIcon_teamFolders($teamId: ID!) {
        team(id: $teamId) {
          id
          templateItems {
            id
          }
        }
      }
    `,
    { teamId },
    { fetchPolicy: "cache-first" },
  );

  const data = useData(
    gql`
      query FolderObjectIcon($folderId: ID!) {
        folder(id: $folderId) {
          id
          project {
            id
          }
        }
      }
    `,
    { folderId },
    { fetchPolicy: "cache-first" },
  );

  if (dataTeamFolders?.team.templateItems.find((i) => i.id === folderId))
    return <FolderText {...others} />;

  if (data?.folder.project) return <ProjectFolderIcon {...others} />;

  return <FolderIcon {...others} />;
}
