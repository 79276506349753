import React from "react";

import TextInput from "inputs/TextInput";

import Field from "./Field";

const noop = () => {};

export default function FieldsContainer({
  fields,
  readOnly = false,
  ...others
}) {
  return (
    <div
      {...others}
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        overflow: "hidden",
        paddingTop: 5,
        ...others.style,
      }}
    >
      {fields
        .filter((f) => f)
        .map((field) => {
          const {
            required = false,
            onChange,
            name,
            label,
            inputComponent: InputComponent = TextInput,
            value = InputComponent.defaultValue || "",
            options = {},
            helperText,
            validate,
            readOnly: fieldReadOnly,
            key,
          } = field;
          return (
            <div
              style={{
                margin: 5,
              }}
              key={name || label}
            >
              {readOnly || fieldReadOnly ? (
                <InputComponent
                  key={key}
                  label={label}
                  value={value}
                  onChange={noop}
                  readOnly
                  helperText={helperText}
                  required={required}
                  {...options}
                />
              ) : (
                <Field
                  key={key}
                  required={required}
                  validate={validate}
                  name={name}
                  defaultValue={value}
                  onChange={onChange}
                  component={InputComponent}
                  componentProps={{
                    label: label,
                    helperText: helperText,
                    required: required,
                    name: name,
                    ...options,
                  }}
                />
              )}
            </div>
          );
        })}
    </div>
  );
}
