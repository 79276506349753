import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";

export default React.memo(TextArea);

TextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  multiline: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center", "right"]),
  verticalAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
};

const noop = () => {};

const FONT_SIZE_EM_MIN = 0.2;
const FONT_SIZE_DELTA = 0.85;

const SCALE_MIN = 0.01;
const SCALE_DELTA = 0.85;

function TextArea({
  value = "",
  onChange = noop,
  readOnly = false,
  multiline = false,
  ...others
}) {
  const transformValue = (value) =>
    multiline ? value : value.replace(/\n/g, "");

  const [inputValue, inputValueSet] = useState(transformValue(value));
  const [focused, focusedSet] = useState(false);
  const [inputValueDebounced, inputValueFn] = useDebounce(inputValue, 500);
  const textAreaRef = useRef();
  const [fontSizeEm, fontSizeEmSet] = useState(1);
  const [scale, scaleSet] = useState(1);

  useEffect(() => {
    if (inputValueDebounced !== value) onChange(inputValueDebounced);
  }, [inputValueDebounced]);

  // when not focused, updating value from source
  useEffect(() => {
    if (!focused) {
      inputValueSet(transformValue(value));
    }
  }, [value, focused]);

  useEffect(() => {
    if (
      multiline &&
      fontSizeEm > FONT_SIZE_EM_MIN &&
      textAreaRef.current.scrollHeight > textAreaRef.current.offsetHeight
    ) {
      fontSizeEmSet(fontSizeEm * FONT_SIZE_DELTA);
    }
    if (
      !multiline &&
      scale > SCALE_MIN &&
      textAreaRef.current.scrollHeight > textAreaRef.current.offsetHeight
    ) {
      scaleSet(scale * SCALE_DELTA);
    }
  }, [value, fontSizeEm, scale]);

  return (
    <div
      {...others}
      style={{
        position: "relative",
        ...others.style,
      }}
    >
      <textarea
        ref={textAreaRef}
        wrap="hard"
        value={inputValue}
        onChange={(event) =>
          inputValueSet(transformValue(event.currentTarget.value))
        }
        onFocus={(...args) => {
          focusedSet(true);
          others.onFocus?.call(this, ...args);
        }}
        onBlur={(...args) => {
          focusedSet(false);
          inputValueFn.flush();
          others.onBlur?.call(this, ...args);
        }}
        readOnly={readOnly}
        style={{
          color: "inherit",
          resize: "none",
          outline: "none",
          backgroundColor: "transparent",
          fontFamily: "inherit",
          fontSize: `${fontSizeEm}em`,
          border: "none",
          // if it's shrinking, use tight text line-height
          lineHeight: fontSizeEm < 1 ? "1" : "inherit",
          overflow: "hidden",
          textAlign: "inherit",
          fontVariantNumeric: "lining-nums",
          padding: 0,
          width: `${100 / scale}%`,
          transform: `scaleX(${scale})`,
          transformOrigin: "left",
          height: "100%",
          minHeight: "1.99em",
        }}
      />
    </div>
  );
}
