import { useEffect } from "react";

import useDOMEvent from "hooks/useDOMEvent";
import useHistory from "hooks/useHistory";

export default function RouteEventListener() {
  const history = useHistory();

  useDOMEvent(
    "message",
    (event) => {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch {
        return;
      }
      const { historyReplace } = data;
      if (historyReplace) history.replace(historyReplace);
    },
    [],
    () => window,
  );

  useEffect(() => {
    const eventHandler = (event) => {
      const a = event.target.closest("a[href]");
      if (!a) return;
      const href = a.getAttribute("href");
      const target = a.getAttribute("target");

      if (target && target !== "_self") return;
      if (href === "#") return event.preventDefault();
      if (!href.startsWith("/")) return;
      if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey)
        return;
      event.preventDefault();
      history.push(href);
    };
    document.addEventListener("click", eventHandler);
    return () => {
      document.removeEventListener("click", eventHandler);
    };
  }, []);

  return null;
}
