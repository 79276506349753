import React, { useState } from "react";
import { uniq, compact } from "lodash-es";
import PropTypes from "prop-types";
import {
  TextField,
  InputAdornment,
  Card,
  CardHeader,
  CardActions,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  IconButton,
} from "@mui/material";
import { CloseCircle, Delete, Plus, DotsHorizontal } from "mdi-material-ui";

import getDialogProps from "helpers/getDialogProps";
import useFormDialog from "hooks/useFormDialog";

import SortInput from "./SortInput";

MultiInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Error),
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  renderValueAvatar: PropTypes.any,
  renderValueLabel: PropTypes.any,
  renderItemDialogContent: PropTypes.any,
  renderSelectDialogContent: PropTypes.any,
  allowSort: PropTypes.bool,
};

export default function MultiInput({
  renderItemAvatar: ItemAvatar = () => null,
  renderItemLabel: ItemLabel = ({ item }) => <>{item}</>,
  label,
  value,
  onChange,
  error,
  readOnly,
  helperText,
  allowSort = false,
  renderItemDialogContent: ItemDialogContent,
  renderSelectDialogContent: SelectDialogContent,
  DialogProps,
  required,
  ...options
}) {
  const formDialog = useFormDialog();
  const [itemDialogState, itemDialogStateSet] = useState({
    open: false,
    item: null,
  });
  const [selectDialogState, selectDialogStateSet] = useState({
    open: false,
    items: value,
  });

  return (
    <>
      <TextField
        size="small"
        fullWidth
        label={label}
        InputLabelProps={{
          shrink: !!value.length,
        }}
        error={!!error}
        helperText={(error && error.message) || helperText}
        required={required}
        InputProps={{
          inputComponent: () => (
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                alignItems: "center",
                minHeight: "40px",
                flex: "1 1 auto",
                position: "relative",
              }}
            >
              {!value.length && !readOnly && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (ItemDialogContent)
                      itemDialogStateSet({
                        open: true,
                        item: null,
                      });
                    if (SelectDialogContent)
                      selectDialogStateSet({
                        open: true,
                        items: [...value],
                      });
                  }}
                />
              )}

              {!value.length && readOnly && <>(无)</>}
              {value.map((item, itemIndex) => (
                <Chip
                  size="small"
                  key={itemIndex}
                  avatar={<ItemAvatar {...options} item={item} />}
                  style={{ margin: 3 }}
                  label={<ItemLabel {...options} item={item} />}
                  {...(!readOnly && {
                    onDelete: () => onChange(value.filter((i) => i !== item)),
                  })}
                />
              ))}
            </div>
          ),
          style: {
            paddingLeft: 10,
            paddingRight: 5,
          },
          endAdornment: (
            <InputAdornment position="end">
              {!readOnly && !!value.length && (
                <IconButton
                  size="small"
                  title={`清除${label}`}
                  onClick={() => onChange([])}
                >
                  <CloseCircle />
                </IconButton>
              )}
              {!readOnly && ItemDialogContent && (
                <IconButton
                  size="small"
                  title={`添加${label}`}
                  onClick={() =>
                    itemDialogStateSet({
                      open: true,
                      item: null,
                    })
                  }
                >
                  <Plus />
                </IconButton>
              )}
              {!readOnly && SelectDialogContent && (
                <IconButton
                  size="small"
                  title={`选择${label}`}
                  onClick={() =>
                    selectDialogStateSet({
                      open: true,
                      items: [...value],
                    })
                  }
                >
                  <DotsHorizontal />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {ItemDialogContent && (
        <Dialog
          fullWidth
          maxWidth="xs"
          scroll="paper"
          open={itemDialogState.open}
          onClose={() =>
            itemDialogStateSet({ ...itemDialogState, open: false })
          }
          {...DialogProps}
          {...getDialogProps()}
        >
          <DialogTitle>其他{label}</DialogTitle>
          <DialogContent
            style={{
              height: "80vh",
              display: "flex",
              flexFlow: "column nowrap",
            }}
          >
            {itemDialogState.open && (
              <ItemDialogContent
                {...options}
                item={itemDialogState.item}
                itemSet={(...newItems) =>
                  itemDialogStateSet({
                    ...itemDialogState,
                    item: newItems[0],
                    items: newItems,
                  })
                }
                itemSubmit={(...newItems) => {
                  itemDialogStateSet({
                    ...itemDialogState,
                    item: newItems[0],
                    items: newItems,
                    open: false,
                  });
                  onChange(uniq(compact([...value, ...newItems])));
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                itemDialogStateSet({ ...itemDialogState, open: false })
              }
            >
              取消
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                itemDialogStateSet((itemDialogState) => ({
                  ...itemDialogState,
                  open: false,
                }));
                if (itemDialogState.item)
                  onChange(uniq([...value, ...itemDialogState.items]));
              }}
            >
              确定
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {SelectDialogContent && (
        <Dialog
          fullWidth
          maxWidth="xl"
          scroll="paper"
          open={selectDialogState.open}
          onClose={() =>
            selectDialogStateSet({ ...selectDialogState, open: false })
          }
          {...DialogProps}
          {...getDialogProps()}
        >
          <DialogTitle>选择{label}</DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexFlow: "row wrap",
              flex: "1 1 auto",
              alignItems: "flex-start",
            }}
          >
            <Card style={{ flex: "1 1 200px", margin: 5 }}>
              <CardHeader subheader={`已选择${label}`} />
              <List>
                {!selectDialogState.items.length && (
                  <ListSubheader>尚未选择{label}</ListSubheader>
                )}
                {selectDialogState.items.map((item, itemIndex) => (
                  <ListItem dense key={itemIndex}>
                    <ListItemAvatar>
                      <ItemAvatar {...options} item={item} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<ItemLabel {...options} item={item} />}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() =>
                          selectDialogStateSet((selectDialogState) => ({
                            ...selectDialogState,
                            items: selectDialogState.items.filter(
                              (i) => item !== i,
                            ),
                          }))
                        }
                        size="large"
                      >
                        <Delete />
                        <span
                          style={{
                            position: "absolute",
                            display: "inline-block",
                            visibility: "hidden",
                          }}
                        >
                          取消选择
                          <ItemLabel {...options} item={item} />
                        </span>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <CardActions>
                {allowSort && (
                  <Button
                    onClick={() =>
                      formDialog({
                        title: "调整顺序",
                        fields: [
                          {
                            label: "顺序",
                            name: "items",
                            value: selectDialogState.items,
                            inputComponent: SortInput,
                            options: {
                              items: selectDialogState.items.map((item) => ({
                                value: item,
                                label: <ItemLabel {...options} item={item} />,
                              })),
                            },
                          },
                        ],
                        onSubmit: ({ items }) =>
                          selectDialogStateSet((selectDialogState) => ({
                            ...selectDialogState,
                            items,
                          })),
                      })
                    }
                  >
                    调整顺序
                  </Button>
                )}
                <Button
                  onClick={() =>
                    selectDialogStateSet((selectDialogState) => ({
                      ...selectDialogState,
                      items: [],
                    }))
                  }
                >
                  清空
                </Button>
              </CardActions>
            </Card>
            <div
              style={{
                flex: "1 1 200px",
                margin: 5,
              }}
            >
              {selectDialogState.open && (
                <SelectDialogContent
                  {...options}
                  items={selectDialogState.items}
                  itemsSet={(newItems, cb) =>
                    selectDialogStateSet(
                      {
                        ...selectDialogState,
                        items: newItems,
                      },
                      cb,
                    )
                  }
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                selectDialogStateSet({ ...selectDialogState, open: false })
              }
            >
              取消
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                selectDialogStateSet(
                  {
                    ...selectDialogState,
                    open: false,
                  },
                  onChange(uniq(selectDialogState.items)),
                )
              }
            >
              确定
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
