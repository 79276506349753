import { useEffect } from "react";

import { FolderIcon } from "controls/icons";
import SingleInput from "./SingleInput";

export default function FileUploadInput({ ...others }) {
  return (
    <SingleInput
      {...others}
      renderItemLabel={ItemLabel}
      renderItemAvatar={ItemAvatar}
      renderItemDialogContent={ItemDialogContent}
    />
  );
}

function ItemLabel({ item }) {
  return <>{item}</>;
}

function ItemAvatar() {
  return <FolderIcon />;
}

function ItemDialogContent({ itemSubmit }) {
  useEffect(() => {
    Promise.resolve()
      .then(async () => {
        const localPath = await window.fmsDesktop.command("selectFolderDialog");
        itemSubmit(localPath);
      })
      .catch((error) => {
        itemSubmit(null);
        throw error;
      });
  }, []);

  return null;
}
