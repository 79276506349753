import QueryString from "query-string";

export default function filterBlobUrl(blobUrl) {
  if (!blobUrl) return blobUrl;
  const {
    query: { e },
  } = QueryString.parseUrl(blobUrl);
  if (e && Number(e) * 1000 < Date.now()) return null;
  return blobUrl;
}
