import { gql } from "@apollo/client";

import { AppTabs } from "controls";
import { useRoute, useData } from "hooks";
import { folderRoute } from "helpers";
import ActivitiesTab from "views/items/ActivitiesTab";
import DocumentsTab from "views/projects/DocumentsTab";
import ProjectCompaniesTab from "views/projects/ProjectCompaniesTab";
import InfoTab from "views/projects/InfoTab";
import ProjectDashboard from "views/projects/ProjectDashboard";
import MessagesTab from "views/projects/MessagesTab";

import PermissionsTab from "./PermissionsTab";
import ContentTab from "./ContentTab";

export default function FolderView() {
  const { folderId } = useRoute(folderRoute);

  const data = useData(
    gql`
      query FolderView($folderId: ID!) {
        folder(id: $folderId) {
          id
          project {
            id
          }
          ancestorFolderType
        }
      }
    `,
    { folderId },
  );

  const projectId =
    data?.folder.ancestorFolderType === "root"
      ? data?.folder.project?.id
      : null;

  return (
    <>
      <AppTabs
        loading={!data}
        tabs={[
          projectId && {
            name: "dashboard",
            title: "首页",
            content: <ProjectDashboard projectId={projectId} />,
          },
          projectId && {
            name: "info",
            title: "属性",
            content: <InfoTab projectId={projectId} />,
          },
          projectId && {
            name: "documents",
            title: "表单",
            content: <DocumentsTab projectId={projectId} />,
          },
          projectId && {
            name: "project_companies",
            title: "相关单位",
            content: <ProjectCompaniesTab projectId={projectId} />,
          },
          projectId && {
            name: "messages",
            title: "讨论",
            content: <MessagesTab projectId={projectId} />,
          },
          {
            title: "目录内容",
            name: "content",
            content: <ContentTab />,
          },
          {
            title: "权限",
            name: "permissions",
            content: <PermissionsTab />,
          },
          {
            title: "动态",
            name: "activities",
            content: <ActivitiesTab />,
          },
        ].filter((t) => t)}
      />
    </>
  );
}
