import { gql } from "@apollo/client";

import { useData, useRoute } from "hooks";
import { companyRoute } from "helpers";
import {
  CardsList,
  ProjectIcon,
  CompanyIcon,
  UserIcon,
  ActivityIcon,
  ObjectCard,
} from "controls";
import {
  ActivityCard,
  ActivityCardFragment,
  ItemPath,
  ItemPathFragment,
  UserAvatar,
  UserAvatarFragment,
} from "blocks";
import Dashboard from "dashboard/Dashboard";
import Widget from "dashboard/Widget";
import DASHBOARD_COLORS from "dashboard/DASHBOARD_COLORS";

export default function CompanyDashboard() {
  const { teamId, companyId } = useRoute(companyRoute);
  const data = useData(
    gql`
      query CompanyDashboard($companyId: ID!) {
        company(id: $companyId) {
          id
          name
          properties {
            id
            label
            values
          }
          projectCompanies {
            id
            role
            project {
              id
              name
              item {
                id
                ...ItemPathFragment
              }
            }
          }
          companyRoles {
            id
            title
            user {
              id
              name
              email
              mobile
              ...UserAvatarFragment
            }
          }
        }
      }
      ${ItemPathFragment}
      ${UserAvatarFragment}
    `,
    { companyId },
  );

  const [activitiesData, activitiesDataMeta] = useData(
    gql`
      query CompanyDashboard($companyId: ID!, $offset: Int) {
        company(id: $companyId) {
          id
          activities(offset: $offset, limit: 20) {
            id
            createdAt
            ...ActivityCardFragment
          }
          activitiesCount
        }
      }
      ${ActivityCardFragment}
    `,
    { companyId },
    { returnMeta: true },
  );

  return (
    <Dashboard>
      <Widget
        color={DASHBOARD_COLORS[0]}
        title={data?.company.name}
        icon={<CompanyIcon />}
      >
        <ObjectCard
          elevation={0}
          fields={[
            ...(data?.company.properties || []).map((property) => ({
              label: property.label,
              content: property.values.join(","),
            })),
          ]}
        />
      </Widget>

      <Widget color={DASHBOARD_COLORS[2]} title="成员" icon={<UserIcon />}>
        <CardsList
          style={{ margin: 5 }}
          cardWidth={200}
          cards={data?.company.companyRoles.map((companyRole) => (
            <ObjectCard
              key={companyRole.id}
              avatar={<UserAvatar user={companyRole.user} />}
              title={companyRole.user.name}
              subheader={companyRole.title}
              href={`/${teamId}/${companyRole.user.id}`}
              fields={[
                {
                  label: "电子邮箱",
                  content: companyRole.user.email,
                },
                {
                  label: "手机号码",
                  content: companyRole.user.mobile,
                },
              ]}
            />
          ))}
        />
      </Widget>

      <Widget color={DASHBOARD_COLORS[3]} title="项目" icon={<ProjectIcon />}>
        <CardsList
          style={{ margin: 5 }}
          cardWidth={250}
          cards={data?.company.projectCompanies.map((projectCompany) => (
            <ObjectCard
              key={projectCompany.id}
              icon={<ProjectIcon />}
              title={projectCompany.project.name}
              subheader={projectCompany.role}
              href={`/${teamId}/${projectCompany.project.item.id}`}
              fields={[
                {
                  label: "位置",
                  content: <ItemPath item={projectCompany.project.item} />,
                },
              ]}
            />
          ))}
        />
      </Widget>

      <Widget
        color={DASHBOARD_COLORS[5]}
        icon={<ActivityIcon />}
        title="动态"
        style={{ width: "100%" }}
      >
        <CardsList
          style={{ margin: 5 }}
          rows={activitiesData?.company.activities}
          rowsCount={activitiesData?.company.activitiesCount}
          fetchMore={activitiesDataMeta.fetchMore}
          cardComponent={<ActivityCard />}
          cardPropName="activity"
          getRowDate={(activity) => activity.createdAt}
        />
      </Widget>
    </Dashboard>
  );
}
