import React from "react";
import { DeskLamp } from "mdi-material-ui";

import { useRoute } from "hooks";
import { teamRoute } from "helpers";
import { AppView, AppTabs } from "controls";

import ActivitiesTab from "./ActivitiesTab";

export default function MyView() {
  const { teamId } = useRoute(teamRoute);

  return (
    <AppView
      title="工作台"
      crumbs={[
        {
          href: `/${teamId}/my`,
          label: "工作台",
          icon: <DeskLamp />,
        },
      ]}
    >
      <AppTabs
        tabs={[
          {
            name: "activities",
            title: "我的操作",
            content: <ActivitiesTab />,
          },
        ].filter((t) => t)}
      />
    </AppView>
  );
}
