import React, { useState } from "react";
import { gql } from "@apollo/client";

import { FormDialog } from "controls";
import { showMessage, teamRoute } from "helpers";
import { useAction, useData, useRoute, useHistory } from "hooks";
import { FolderInput, ItemInput } from "inputs";

export default function CreateDocumentDialog({
  folderId,
  templateDocumentId: templateDocumentIdDefault = null,
  ...others
}) {
  const { teamId } = useRoute(teamRoute);
  const history = useHistory();
  const [templateDocumentId, templateDocumentIdSet] = useState(
    templateDocumentIdDefault,
  );

  const data = useData(
    gql`
      query CreateDocumentDialog($teamId: ID!) {
        team(id: $teamId) {
          docTemplatesFolder {
            id
          }
        }
      }
    `,
    { teamId },
  );
  const createDocument = useAction(gql`
    mutation CreateDocumentDialog($input: CreateDocumentInput!) {
      createDocument(input: $input) {
        document {
          id
          name
        }
        team {
          id
        }
        folder {
          id
          items {
            id
          }
          documents {
            id
          }
        }
      }
    }
  `);

  const dataTemplateDocument = useData(
    gql`
      query CreateDocumentDialog_templateDoument($templateDocumentId: ID!) {
        document(id: $templateDocumentId) {
          id
          basename
        }
      }
    `,
    { templateDocumentId },
    { skip: !templateDocumentId },
  );

  return (
    <FormDialog
      {...others}
      disabled={!data}
      title="新建表单"
      onSubmit={async (formData) => {
        const result = (
          await createDocument({
            input: {
              ...formData,
            },
          })
        ).createDocument;
        showMessage({
          message: "成功创建表单",
        });
        history.push(`/${result.team.id}/${result.document.id}`);
      }}
      fields={[
        {
          name: "folderId",
          value: folderId,
          required: true,
          label: "目录",
          inputComponent: FolderInput,
          helperText: "请确认创建文档的目录位置",
        },
        {
          name: "templateDocumentId",
          label: "表单模版",
          inputComponent: ItemInput,
          value: templateDocumentIdDefault,
          required: true,
          onChange: templateDocumentIdSet,
          options: {
            itemTypes: ["Document"],
            topFolderId: data?.team.docTemplatesFolder.id,
          },
        },
        dataTemplateDocument && {
          name: "name",
          value: dataTemplateDocument?.document.basename,
          required: true,
          label: "新表单名称",
        },
      ].filter((f) => f)}
    />
  );
}
