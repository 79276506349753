import React from "react";
import { gql } from "@apollo/client";

import { useAction, useData } from "hooks";
import { ActionFormDialog } from "controls";
import { ItemInput } from "inputs";

export default function EditShareLinkDialog({ shareLinkId, ...others }) {
  const data = useData(
    gql`
      query EditShareLinkDialog($shareLinkId: ID!) {
        shareLink(id: $shareLinkId) {
          id
          expiresAt
          name
          item {
            id
          }
        }
      }
    `,
    { shareLinkId },
  );

  const shareLinkUpdate = useAction(gql`
    mutation EditShareLinkDialog($input: ShareLinkUpdateInput!) {
      shareLinkUpdate(input: $input) {
        shareLink {
          id
          name
          expiresAt
          item {
            id
          }
        }
      }
    }
  `);

  return (
    <ActionFormDialog
      {...others}
      title="修改公开浏览链接"
      disabled={!data}
      fields={[
        {
          name: "itemId",
          value: data?.shareLink.item.id,
          label: "位置",
          required: true,
          inputComponent: ItemInput,
        },
        {
          name: "expiresAt",
          value: data?.shareLink.expiresAt || "",
          label: "有效期至",
          options: { type: "date", InputLabelProps: { shrink: true } },
        },
        {
          name: "name",
          value: data?.shareLink.name,
          label: "备注",
          helperText: "仅为管理员辨别该链接",
        },
      ]}
      onSubmit={async (formData) => {
        await shareLinkUpdate({
          input: {
            shareLinkId,
            ...formData,
          },
        });
      }}
    />
  );
}
