import { useContext } from "react";

import newId from "../helpers/newId";
import { formDialogsContext } from "../providers/FormDialogsProvider";

export default function useFormDialog() {
  const { formDialogsSet } = useContext(formDialogsContext);
  const formDialog = (options) => {
    const id = newId();
    formDialogsSet((formDialogs) => [
      {
        ...options,
        onClose: () => {
          formDialogsSet((formDialogs) =>
            formDialogs.filter((f) => f.id !== id),
          );
        },
        id,
      },
      ...formDialogs,
    ]);
  };

  return formDialog;
}
