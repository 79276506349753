import React from "react";
import { times } from "lodash-es";
import { gql } from "@apollo/client";
import { DialogContent, Dialog, DialogActions, Button } from "@mui/material";
import { AccountAlert, Update } from "mdi-material-ui";

import { CardLoader } from "loaders";
import { getDialogProps } from "helpers";
import { useData } from "hooks";
import { ObjectCard, DateTime } from "controls";

export default function ViewAuditRequestDialog({ auditRequestId, onClose }) {
  const data = useData(
    gql`
      query ViewAuditRequestDialog($auditRequestId: ID!) {
        auditRequest(id: $auditRequestId) {
          id
          login
          createdAt
          ipAddressMasked
          ipAddressLocation
          userAgentDescription
          auditVersions {
            id
            itemId
            itemType
            event
            event
            objectChanges
          }
        }
      }
    `,
    { auditRequestId },
  );
  return (
    <>
      <Dialog
        open
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        {...getDialogProps()}
      >
        <DialogContent>
          <div style={{ display: "flex", flexFlow: "column nowrap" }}>
            <ObjectCard
              icon={<AccountAlert />}
              title="请求信息"
              fields={[
                {
                  label: "时间",
                  content: <DateTime value={data?.auditRequest.createdAt} />,
                },
                {
                  label: "操作人",
                  content: data?.auditRequest.login,
                },
                {
                  label: "IP地址",
                  content: (
                    <>
                      {data?.auditRequest.ipAddressMasked || "---"}(
                      {data?.auditRequest.ipAddressLocation || "---"})
                    </>
                  ),
                },
                {
                  label: "客户端",
                  content: <>{data?.auditRequest.userAgentDescription}</>,
                },
              ]}
            />
            {!data && times(3).map((i) => <CardLoader content key={i} />)}
            {data?.auditRequest.auditVersions.map((auditVersion) => (
              <ObjectCard
                icon={<Update />}
                key={auditVersion.id}
                title={`更改数据(${auditVersion.event})`}
                subheader={
                  <>
                    {auditVersion.itemType}#{auditVersion.itemId}
                  </>
                }
              >
                {Object.entries(auditVersion.objectChanges || {}).map(
                  ([attribute, [prev, next]]) => (
                    <div
                      key={attribute}
                      style={{ display: "flex", flexFlow: "row wrap" }}
                    >
                      <div style={{ width: 150, flex: "0 0 auto" }}>
                        {attribute}:
                      </div>
                      <div style={{ width: 300, flex: "1 0 0" }}>
                        {JSON.stringify(prev)}
                      </div>
                      <div style={{ flex: "0 0 auto", margin: "0 20px" }}>
                        {" -> "}
                      </div>
                      <div style={{ width: 300, flex: "1 0 0" }}>
                        {JSON.stringify(next)}
                      </div>
                    </div>
                  ),
                )}
              </ObjectCard>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>确定</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
