import React from "react";
import { gql } from "graphql-tag";

import { useActionFragment, useData, useHistory, useRoute } from "hooks";
import { FolderInput, ItemInput } from "inputs";
import { FormDialog } from "controls";
import { showMessage, teamRoute } from "helpers";

export default function AddFileDialog({ folderId, onClose }) {
  const history = useHistory();
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query AddFileDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          fileTemplatesFolder {
            id
          }
        }
      }
    `,
    { teamId },
  );

  const addFile = useActionFragment(
    "addFile",
    "folder { id items { id } files { id } } file { id }",
  );

  return (
    <FormDialog
      disabled={!data}
      title="上传文档"
      onClose={onClose}
      fields={[
        {
          label: "位置",
          name: "folderId",
          value: folderId,
          inputComponent: FolderInput,
          required: true,
        },
        {
          label: "名称",
          name: "name",
          value: "新文档",
          required: true,
          options: {
            autoFocus: true,
          },
        },
        {
          name: "templateFileId",
          label: "模版",
          inputComponent: ItemInput,
          required: true,
          options: {
            itemTypes: ["File"],
            topFolderId: data?.team.fileTemplatesFolder.id,
          },
        },
      ]}
      onSubmit={async (formData) => {
        const result = (
          await addFile({
            input: {
              ...formData,
            },
          })
        ).addFile;
        showMessage({ message: "成功上传文档" });
        history.push(`/${teamId}/${result.file.id}`);
      }}
    />
  );
}
