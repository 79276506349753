import React, { useEffect, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router";

import usePortal from "./usePortal";

export default function useDialogOpener(DialogComponent, others) {
  const [state, stateSet] = useState(null);
  const { mount, unmount } = usePortal();
  const location = useLocation();

  useLayoutEffect(() => {
    if (state) stateSet(null);
  }, [location.pathname]);

  useEffect(() => {
    if (state) {
      mount(
        <DialogComponent
          {...others}
          {...state.args}
          onClose={() => stateSet(null)}
        />,
      );
    } else {
      unmount();
    }
  }, [!state]);

  return (args) => stateSet({ args });
}
