import { gql } from "@apollo/client";
import { CompanyRegistrationIcon, Message, TabContent, Table } from "controls";
import { teamRoute } from "helpers";
import {
  useActionFragment,
  useConfirmDialog,
  useData,
  useFilterBar,
  useRoute,
} from "hooks";

export default function RegistrationsTab() {
  const [{ searchTerm, processed }, filterBar] = useFilterBar(
    {
      enableSearchTerm: true,
    },
    [
      {
        type: "boolean",
        name: "processed",
        label: "显示已处理",
        defaultValue: false,
        variant: "switch",
        alwaysEnabled: true,
      },
    ],
  );
  const { teamId } = useRoute(teamRoute);
  const confirmDialog = useConfirmDialog();

  const [data, dataMeta] = useData(
    gql`
      query RegistrationsView(
        $teamId: ID!
        $offset: Int
        $processed: Boolean!
        $searchTerm: String
      ) {
        team(id: $teamId) {
          id
          canConfigure
          companyRegisterEnabled
          companyRegisterUrl
          companyRegistrationsCount(
            searchTerm: $searchTerm
            processed: $processed
          )
          companyRegistrations(
            offset: $offset
            limit: 20
            searchTerm: $searchTerm
            processed: $processed
          ) {
            id
            name
            category
            createdAt
          }
        }
      }
    `,
    { teamId, processed, searchTerm },
    { returnMeta: true },
  );
  const configureTeam = useActionFragment(
    "configureTeam",
    "team { id companyRegisterEnabled companyRegisterUrl }",
  );

  return (
    <TabContent>
      {filterBar}
      {processed && (
        <Message>
          这些是已处理的单位注册，您依然可以查看并导入这些项目。
        </Message>
      )}
      {!processed && (
        <>
          {data?.team.companyRegisterEnabled === false && (
            <Message
              type="warning"
              actions={[
                {
                  title: "开启单位注册",
                  disabled: !data?.team.canConfigure,
                  onClick: async () => {
                    await confirmDialog();
                    await configureTeam({
                      input: {
                        teamId,
                        companyRegisterEnabled: true,
                      },
                    });
                  },
                },
              ]}
            >
              未开启单位注册表单
            </Message>
          )}
          {data?.team.companyRegisterEnabled === true && (
            <Message
              actions={[
                {
                  title: "关闭单位注册",
                  disabled: !data?.team.canConfigure,
                  onClick: async () => {
                    await confirmDialog();
                    await configureTeam({
                      input: {
                        teamId,
                        companyRegisterEnabled: false,
                      },
                    });
                  },
                },
              ]}
            >
              外部单位可以通过以下链接注册：
              <br />
              <a
                href={data?.team.companyRegisterUrl}
                target="_blank"
                rel="noreferrer"
              >
                {data?.team.companyRegisterUrl}
              </a>
            </Message>
          )}
        </>
      )}
      <Table
        dataMeta={dataMeta}
        columns={[
          {
            label: "单位名称",
          },
          {
            label: "单位类别",
          },
        ]}
        rowsCount={data?.team?.companyRegistrationsCount}
        rows={data?.team?.companyRegistrations.map((companyRegistration) => ({
          id: companyRegistration.id,
          icon: <CompanyRegistrationIcon />,
          title: companyRegistration.name,
          href: `/${teamId}/${companyRegistration.id}`,
          values: [companyRegistration.name, companyRegistration.category],
        }))}
      />
    </TabContent>
  );
}
