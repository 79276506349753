import compare from "./compare";

function sortBy(arr, iteratee = (a) => a) {
  if (!arr) return arr;
  const arrCopy = [...arr];

  arrCopy.sort((el1, el2) => compare(iteratee(el1), iteratee(el2)));

  return arrCopy;
}

export default sortBy;
