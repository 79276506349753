import { useContext } from "react";
import { Paper } from "@mui/material";
import { Plus } from "mdi-material-ui";

import { useRoute, useOpenChat, useFormDialog } from "hooks";
import { chatRoute } from "helpers";
import { UsersInput, ProjectInput } from "inputs";
import { ButtonMenu, UserIcon, ProjectIcon } from "controls";
import { viewportContext } from "contexts";

import ChatsList from "./ChatsList";
import Chat from "./Chat";

export default function ChatsView() {
  const openChat = useOpenChat();
  const { chatId } = useRoute(chatRoute, { optional: true });
  const formDialog = useFormDialog();
  const { denseView } = useContext(viewportContext);

  return (
    <div
      style={{
        flex: "1 0 auto",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "stretch",
      }}
    >
      <Paper
        variant="outlined"
        square
        style={{
          width: denseView ? 74 : 250,
          borderStyle: "none solid none none",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "stretch",
        }}
      >
        <div
          style={{
            padding: 5,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          {!denseView && <div style={{ margin: 5 }}>所有讨论</div>}
          <div style={{ flex: "1 0 auto" }} />
          <ButtonMenu
            title="创建讨论"
            actions={[
              {
                icon: <UserIcon />,
                title: "成员",
                description: <>发送消息给成员</>,
                onClick: () =>
                  formDialog({
                    title: "创建成员讨论",
                    fields: [
                      {
                        name: "userIds",
                        label: "成员",
                        value: [],
                        required: true,
                        inputComponent: UsersInput,
                      },
                    ],
                    onSubmit: async ({ userIds }) => {
                      openChat({ userIds });
                    },
                  }),
              },
              {
                icon: <ProjectIcon />,
                title: "项目",
                description: <>和指定项目相关的讨论</>,
                onClick: () =>
                  formDialog({
                    title: "创建项目讨论",
                    fields: [
                      {
                        name: "projectId",
                        label: "项目",
                        required: true,
                        inputComponent: ProjectInput,
                      },
                    ],
                    onSubmit: async ({ projectId }) => {
                      openChat({ projectId });
                    },
                  }),
              },
            ]}
          >
            <Plus />
          </ButtonMenu>
        </div>
        <ChatsList
          style={{
            flex: "1 0 auto",
            overflowY: "auto",
            height: 0,
          }}
        />
      </Paper>
      {!!chatId && <Chat key={chatId} style={{ flex: "1 1 auto" }} />}
    </div>
  );
}
