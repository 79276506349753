import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import {
  FilePdfBox,
  FileCad,
  FileWord,
  FileExcel,
  Archive,
  FilePowerpoint,
  FileImage,
} from "mdi-material-ui";

import { FileIcon } from "controls/icons";
import useData from "hooks/useData";
import IconLoader from "loaders/IconLoader";

FileObjectIcon.propTypes = {
  fileId: PropTypes.string.isRequired,
};

export default React.memo(FileObjectIcon);

function FileObjectIcon({ fileId, ...others }) {
  const data = useData(
    gql`
      query FileObjectIcon($fileId: ID!) {
        file(id: $fileId) {
          id
          fileType
        }
      }
    `,
    { fileId },
    { fetchPolicy: "cache-first" },
  );

  if (!data) return <IconLoader {...others} />;

  return (
    <>
      {{
        pdf: <FilePdfBox {...others} />,
        excel: <FileExcel {...others} />,
        powerpoint: <FilePowerpoint {...others} />,
        word: <FileWord {...others} />,
        wps: <FileWord {...others} />,
        dwg: <FileCad {...others} />,
        image: <FileImage {...others} />,
        archive: <Archive {...others} />,
      }[data.file.fileType] || <FileIcon {...others} />}
    </>
  );
}
