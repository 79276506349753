import React from "react";
import { gql } from "@apollo/client";
import { Delete, Magnify, Plus } from "mdi-material-ui";

import {
  useRoute,
  useData,
  useAction,
  useConfirmDialog,
  useFilterBar,
  useFormDialog,
  useHistory,
} from "hooks";
import { showMessage, teamRoute, apolloClient } from "helpers";
import {
  TabContent,
  Table,
  DateTimeCell,
  PicklistIcon,
  Message,
} from "controls";

export default function PicklistsTab() {
  const history = useHistory();
  const formDialog = useFormDialog();
  const { teamId } = useRoute(teamRoute);
  const confirmDialog = useConfirmDialog();
  const [{ searchTerm }, filterBar] = useFilterBar({ enableSearchTerm: true });

  const [data, { fetchMore }] = useData(
    gql`
      query PicklistsTab($teamId: ID!, $searchTerm: String, $offset: Int) {
        team(id: $teamId) {
          id
          picklists(searchTerm: $searchTerm, limit: 20, offset: $offset) {
            id
            name
            updatedAt
            options(limit: 3)
            optionsCount
            canRemove
          }
          picklistsCount(searchTerm: $searchTerm)
          canAddPicklist
        }
      }
    `,
    { searchTerm, teamId },
    { returnMeta: true },
  );

  const removePicklist = useAction(gql`
    mutation PicklistsTab($input: RemovePicklistInput!) {
      removePicklist(input: $input) {
        team {
          id
        }
      }
    }
  `);

  const addPicklist = useAction(gql`
    mutation PicklistsTab($input: AddPicklistInput!) {
      addPicklist(input: $input) {
        team {
          id
          picklists {
            id
          }
        }
        picklist {
          id
          name
        }
      }
    }
  `);

  return (
    <TabContent
      actions={[
        {
          icon: <Plus />,
          title: "创建数据列表",
          disabled: !data?.team.canAddPicklist,
          onClick: () =>
            formDialog({
              title: "创建数据列表",
              fields: [
                {
                  label: "数据列表名称",
                  name: "name",
                  required: true,
                },
              ],
              onSubmit: async (formData) => {
                const result = await addPicklist({
                  input: {
                    teamId,
                    ...formData,
                  },
                });
                showMessage({ message: "成功创建数据列表" });
                history.push(`/${teamId}/${result.addPicklist.picklist.id}`);
              },
            }),
        },
      ]}
    >
      <Message>数据列表可以提供在表单的数据单元格里填写的选项。</Message>
      {filterBar}
      <Table
        fetchMore={fetchMore}
        columns={[
          {
            label: "名称",
            sortable: true,
          },
          {
            label: "选项",
            width: 400,
          },
          {
            label: "修改时间",
            sortable: true,
            cellComponent: DateTimeCell,
          },
        ]}
        rowsCount={data?.team.picklistsCount}
        rows={data?.team.picklists.map((picklist) => ({
          id: picklist.id,
          icon: <PicklistIcon />,
          title: picklist.name,
          href: `/${teamId}/${picklist.id}`,
          values: [
            picklist.name,
            picklist.optionsCount === picklist.options.length
              ? picklist.options.join(", ")
              : `${picklist.options.join(", ")}... (共${
                  picklist.optionsCount
                }项)`,
            picklist.updatedAt,
          ],
          actions: [
            {
              icon: <Magnify />,
              label: "查看",
              href: `/${teamId}/${picklist.id}`,
            },
            {
              icon: <Delete />,
              label: "删除该数据列表",
              disabled: !picklist.canRemove,
              onClick: async () => {
                await confirmDialog();
                await removePicklist({ input: { picklistId: picklist.id } });
                await apolloClient.resetStore();
                showMessage({ message: "成功删除数据列表" });
              },
            },
          ],
        }))}
      />
    </TabContent>
  );
}
