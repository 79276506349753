export default async function screenshotBlob() {
  const canvas = document.createElement("canvas");
  const stream = await navigator.mediaDevices.getDisplayMedia();
  const track = stream.getVideoTracks()[0];
  const capture = new ImageCapture(track);
  const bitmap = await capture.grabFrame();
  track.stop();

  canvas.width = bitmap.width;
  canvas.height = bitmap.height;
  canvas.getContext("2d").drawImage(bitmap, 0, 0);

  const blob = await new Promise((resolve) => canvas.toBlob(resolve));

  return blob;
}
