import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import { showMessage, UserError, apolloClient, teamRoute } from "helpers";
import { FormDialog } from "controls";
import { FolderInput } from "inputs";
import { useData, useAction, useRoute, useHistory } from "hooks";

EnableFolderProjectDialog.propTypes = {
  folderId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function EnableFolderProjectDialog({ folderId, onClose }) {
  const history = useHistory();
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query EnableFolderProjectDialog($folderId: ID!, $teamId: ID!) {
        folder(id: $folderId) {
          id
          name
        }
        team(id: $teamId) {
          id
          projectTemplatesFolder {
            id
          }
        }
      }
    `,
    { folderId, teamId },
  );
  const addProject = useAction(gql`
    mutation EnableFolderProjectDialog($input: AddProjectInput!) {
      addProject(input: $input) {
        folder {
          id
          items {
            id
          }
          subFolders {
            id
          }
        }
        projectFolder {
          id
        }
      }
    }
  `);

  if (!data) return null;

  return (
    <>
      <FormDialog
        title="添加项目"
        onClose={onClose}
        onSubmit={async ({ templateProjectFolderId, ...formData }) => {
          // get projectId by folderId
          let result = await apolloClient.query({
            query: gql`
              query EnableFolderProjectDialog($folderId: ID!) {
                folder(id: $folderId) {
                  id
                  name
                  project {
                    id
                  }
                }
              }
            `,
            variables: {
              folderId: templateProjectFolderId,
            },
          });
          if (!result.data.folder.project)
            throw new UserError(
              `选择的目录“${result.data.folder.name}”不是一个项目模版`,
            );
          result = await addProject({
            input: {
              templateProjectFolderId,
              ...formData,
            },
          });
          showMessage({ message: `成功创建项目“${formData.name}”` });
          history.push(`/${teamId}/${result.addProject.projectFolder.id}`);
        }}
        fields={[
          {
            name: "folderId",
            value: folderId,
            required: true,
            label: "目录",
            inputComponent: FolderInput,
            helperText: "请确认创建项目的目录位置",
          },
          {
            name: "name",
            required: true,
            label: "项目名称",
            helperText: "该名称也会作为项目存放目录的名称",
          },
          {
            label: "项目模版",
            name: "templateProjectFolderId",
            required: true,
            inputComponent: FolderInput,
            options: {
              topFolderId: data?.team.projectTemplatesFolder.id,
            },
          },
        ]}
      />
    </>
  );
}
