import { gql } from "@apollo/client";
import {
  AvatarGroup,
  Badge,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";

import { useRoute, useChannel, useData } from "hooks";
import { teamRoute, chatRoute } from "helpers";
import { UserAvatar, UserAvatarFragment } from "blocks";
import { DateTime, ProjectIcon, FetchMoreButton } from "controls";

export default function ChatsList({ ...others }) {
  const { teamId } = useRoute(teamRoute);
  const { chatId } = useRoute(chatRoute, { optional: true });
  const [data, dataMeta] = useData(
    gql`
      query ChatsList($teamId: ID!) {
        team(id: $teamId) {
          id
          chatsCount(hasMessages: true)
          chats(hasMessages: true) {
            id
            messagesCountUnread: messagesCount(unread: true)
            name
            headMessage {
              id
              content
              createdAt
              user {
                id
                name
              }
            }
            project {
              id
            }
            users {
              id
              ...UserAvatarFragment
            }
          }
        }
      }
      ${UserAvatarFragment}
    `,
    { teamId },
    { returnMeta: true },
  );

  useChannel("ChatChannel", { teamId }, () => dataMeta.refetch());

  return (
    <List component="div" dense disablePadding {...others}>
      {data?.team.chats.map((chat) => (
        <ListItem
          key={chat.id}
          button
          component="a"
          href={`/${teamId}/${chat.id}`}
          selected={chatId === chat.id}
        >
          <ListItemAvatar>
            <Badge
              badgeContent={chat.messagesCountUnread}
              variant={chat.project ? "dot" : undefined}
              color="secondary"
            >
              <Avatar
                style={{
                  ...(chat.users.length > 1 && {
                    overflow: "visible",
                    backgroundColor: "transparent",
                  }),
                }}
              >
                {!!chat.users.length && (
                  <div
                    style={{
                      transform: `scale(${1 / Math.min(2, chat.users.length)})`,
                    }}
                  >
                    <AvatarGroup max={2}>
                      {chat.users.map((user) => (
                        <UserAvatar key={user.id} user={user} />
                      ))}
                    </AvatarGroup>
                  </div>
                )}
                {chat.project && <ProjectIcon fontSize="inherit" />}
              </Avatar>
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={<>{chat.name}</>}
            primaryTypographyProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "bold",
              },
            }}
            secondary={
              <>
                {chat.headMessage && (
                  <>
                    <span style={{ fontWeight: "bold" }}>
                      {chat.headMessage.user?.name}
                    </span>
                    (
                    <DateTime
                      value={chat.headMessage.createdAt}
                      style={{ opacity: 0.5 }}
                    />
                    ):&nbsp;
                    {chat.headMessage.content}
                  </>
                )}
              </>
            }
            secondaryTypographyProps={{
              component: "div",
              style: { maxHeight: "3em", overflow: "hidden" },
            }}
          />
        </ListItem>
      ))}
      <FetchMoreButton
        fetchMore={dataMeta.fetchMore}
        rows={data?.team.chats}
        rowsCount={data?.team.chatsCount}
      />
    </List>
  );
}
