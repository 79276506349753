import React, { useContext } from "react";
import { gql } from "@apollo/client";
import { AppBar, IconButton, Toolbar, Badge } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import SelectTeamDialog from "dialogs/SelectTeamDialog";
import { rightDrawerContext } from "providers/RightDrawerProvider";
import { viewportContext } from "contexts";
import { teamRoute } from "helpers";
import { useRoute, useDialogOpener, useData } from "hooks";
import { TextLoader } from "loaders";
import logoImage from "images/logo_button.png";

import UserMenu from "./UserMenu";

export const NAVBAR_HEIGHT = 48;

export default function Navbar() {
  const openSelectTeamDialog = useDialogOpener(SelectTeamDialog);
  const { teamId } = useRoute(teamRoute);
  const { rightDrawerOpen, rightDrawerTabs } = useContext(rightDrawerContext);
  const { drawerOpenSet, denseView } = useContext(viewportContext);
  const data = useData(
    gql`
      query Navbar($teamId: ID!) {
        team(id: $teamId) {
          id
          name
        }
      }
    `,
    { teamId },
  );

  return (
    <AppBar
      color="default"
      position="sticky"
      style={{
        paddingTop: `env(safe-area-inset-top)`,
      }}
      elevation={1}
    >
      <Toolbar
        style={{
          height: NAVBAR_HEIGHT,
          minHeight: NAVBAR_HEIGHT,
        }}
      >
        {denseView && (
          <IconButton
            edge="start"
            size={denseView ? "small" : undefined}
            color="inherit"
            onClick={() => drawerOpenSet((drawerOpen) => !drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
        )}
        <a
          style={{
            textDecoration: "none",
            color: "inherit",
            flex: "0 1 auto",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "inline-block",
          }}
          href="#"
          title="切换团队"
          onClick={() => openSelectTeamDialog()}
        >
          {!denseView && (
            <img
              alt="优建云"
              style={{
                width: 28,
                height: 28,
                verticalAlign: "middle",
                marginRight: 10,
              }}
              src={logoImage}
            />
          )}
          {data?.team.name || <TextLoader />}
        </a>
        <div style={{ flexGrow: 1 }}></div>
        {rightDrawerTabs.map((tab) => (
          <IconButton
            key={tab.name}
            color="inherit"
            size={denseView ? "small" : undefined}
            onClick={() => rightDrawerOpen(tab.name)}
            title={tab.title}
          >
            <Badge color="secondary" badgeContent={tab.badgeContent}>
              {tab.icon}
            </Badge>
          </IconButton>
        ))}
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}
