export const HEITI_FONT_FAMILY = "Source Han Sans CN";
export const SONGTI_FONT_FAMILY = "Source Han Serif CN";

export const UI_FONT_FAMILIES = [
  // https://github.com/zenozeng/fonts.css/blob/main/dist/fonts.css
  "-apple-system",
  "Noto Sans",
  "Helvetica Neue",
  "Helvetica",
  "Nimbus Sans L",
  "Arial",
  "Liberation Sans",
  "PingFang SC",
  "Hiragino Sans GB",
  "Noto Sans CJK SC",
  "Source Han Sans SC",
  "Source Han Sans CN",
  "Microsoft YaHei",
  "Wenquanyi Micro Hei",
  "WenQuanYi Zen Hei",
  "ST Heiti",
  "SimHei",
  "WenQuanYi Zen Hei Sharp",
  "sans-serif",

  // Not sure why are they still needed for Emojis
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];
