import { useRef, useLayoutEffect, useState } from "react";

import { filterBlobUrl } from "helpers";
import { Message } from "controls";
import { useProgressBar } from "hooks";

export default function VideoPlayer({ previewable }) {
  const ref = useRef();
  const blobUrlFiltered = filterBlobUrl(previewable.blobUrl);
  const thumbnailUrlFiltered = filterBlobUrl(previewable.thumbnailUrl);
  const previewUrlFiltered = filterBlobUrl(previewable.previewUrl);
  const [errored, erroredSet] = useState(false);

  const playerDeps = [previewUrlFiltered, blobUrlFiltered];
  useLayoutEffect(() => {
    erroredSet(false);
  }, [...playerDeps]);

  useProgressBar(!previewUrlFiltered && !blobUrlFiltered);

  if (errored)
    return (
      <Message
        message={
          previewable.status === "pending" ? (
            <>
              正在转码该视频文件，请稍等几分钟后再刷新查看；您也可以直接下载该视频文件进行播放。
            </>
          ) : (
            <>无法播放该视频文件，请现在该视频文件进行播放。</>
          )
        }
        actions={[
          {
            title: "下载文件",
            href: filterBlobUrl(previewable.blobDownloadUrl),
          },
        ]}
      />
    );

  return (
    <video
      ref={ref}
      key={playerDeps.join("-")}
      onError={() => erroredSet(true)}
      controls
      style={{ flex: "1 1 auto", height: 0 }}
      poster={thumbnailUrlFiltered}
      autoPlay
    >
      {previewUrlFiltered && (
        <source src={previewUrlFiltered} type="video/mp4" />
      )}
      {blobUrlFiltered && <source src={blobUrlFiltered} />}
    </video>
  );
}
