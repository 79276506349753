import React from "react";
import gql from "graphql-tag";
import { Play, Pencil, Delete, MicrosoftExcel } from "mdi-material-ui";

import {
  AppView,
  Message,
  DateTime,
  DataQueryIcon,
  TabContent,
} from "controls";
import { dataQueryRoute, downloadFromAPI, showMessage } from "helpers";
import {
  useRoute,
  useData,
  useConfirmDialog,
  useAction,
  useDialogOpener,
  useRecordContentChannel,
  useHistory,
} from "hooks";
import { FavoritableIconButton, FavoritableIconButtonFragment } from "blocks";
import { TableLoader } from "loaders";
import { UpdateDataQueryDialog } from "dialogs/DataQueryDialogs";

import DataTable, { DataTableFragment } from "./DataTable";

export default React.memo(DataQueryView);

function DataQueryView() {
  const history = useHistory();
  const confirmDialog = useConfirmDialog();
  const openUpdateQueryDialog = useDialogOpener(UpdateDataQueryDialog);
  const { dataQueryId, teamId } = useRoute(dataQueryRoute);

  const data = useData(
    gql`
      query DataQueryView($dataQueryId: ID!) {
        dataQuery(id: $dataQueryId) {
          id
          status
          name
          queriedAt
          projectsCount
          projectsNoDataCount
          orphanDocumentsCount
          canExecute
          canUpdate
          canRemove
          ...DataTableFragment
          ...FavoritableIconButtonFragment
        }
      }
      ${DataTableFragment}
      ${FavoritableIconButtonFragment}
    `,
    { dataQueryId },
  );

  useRecordContentChannel("DataQuery", dataQueryId);

  const executeDataQuery = useAction(gql`
    mutation DataQueryView($input: ExecuteDataQueryInput!) {
      executeDataQuery(input: $input) {
        dataQuery {
          id
          status
        }
      }
    }
  `);

  const removeDataQuery = useAction(gql`
    mutation DataQueryView($input: RemoveDataQueryInput!) {
      removeDataQuery(input: $input) {
        team {
          id
          dataQueries {
            id
          }
        }
      }
    }
  `);

  return (
    <AppView
      title="统计"
      crumbs={[
        {
          icon: <DataQueryIcon />,
          label: "统计",
          href: `/${teamId}/data_queries`,
        },
        {
          icon: <DataQueryIcon />,
          label: data?.dataQuery.name,
          href: `/${teamId}/${dataQueryId}`,
        },
      ]}
      tools={
        <>
          <FavoritableIconButton favoritable={data?.dataQuery} />
        </>
      }
    >
      <TabContent
        actions={[
          {
            icon: <Play />,
            title: "运行该查询",
            disabled: !data?.dataQuery.canExecute,
            onClick: async () => {
              await confirmDialog();
              await executeDataQuery({ input: { dataQueryId } });
              showMessage({ message: "已开始运行查询" });
            },
          },
          {
            icon: <Pencil />,
            title: "修改该查询",
            disabled: !data?.dataQuery.canUpdate,
            onClick: () => openUpdateQueryDialog({ dataQueryId }),
          },
          {
            icon: <Delete />,
            title: "删除该查询",
            disabled: !data?.dataQuery.canRemove,
            onClick: async () => {
              await confirmDialog();
              await removeDataQuery({ input: { dataQueryId } });
              showMessage({ message: "成功删除查询" });
              history.push(`/${teamId}/data_queries`);
            },
          },
          {
            title: "导出到Excel",
            icon: <MicrosoftExcel />,
            disabled: data?.dataQuery.status !== "queried",
            onClick: () =>
              downloadFromAPI(`export_data_query_xlsx`, {
                data_query_id: dataQueryId,
              }),
          },
        ]}
      >
        {!data && <TableLoader />}
        {data?.dataQuery.status === "querying" && (
          <Message message="查询正在运行，请稍候。" />
        )}
        {data?.dataQuery.status === "initial" && (
          <Message message="查询还未运行，请运行该查询后再查看。" />
        )}
        {data?.dataQuery.status === "errored" && (
          <Message
            type="error"
            message="查询运行过程中发生错误，请尝试重新运行该查询。"
          />
        )}
        {data?.dataQuery.status === "queried" && (
          <>
            <Message
              message={
                <>
                  包含{data?.dataQuery.projectsCount}个项目和
                  {data?.dataQuery.orphanDocumentsCount}个无项目表单的数据{" "}
                  {data?.dataQuery.projectsNoDataCount
                    ? `,  其中${data?.dataQuery.projectsNoDataCount}个项目无内容`
                    : " "}
                  <br />
                  注意：此处显示的数据为最近一次运行的结果(于
                  <DateTime value={data?.dataQuery.queriedAt} />
                  运行的结果)，该查询会于每天凌晨会自动运行
                  ，如需查询最新数据，请点击“运行该查询”。
                </>
              }
            />
            <DataTable dataQuery={data?.dataQuery} />
          </>
        )}
      </TabContent>
    </AppView>
  );
}
