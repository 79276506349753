import React from "react";
import { defer } from "lodash-es";
import { gql } from "@apollo/client";

import { teamRoute, showMessage } from "helpers";
import { FormDialog, Message } from "controls";
import { useRoute, useData, useAction, useHistory } from "hooks";

export default function LeaveTeamDialog({ ...others }) {
  const history = useHistory();
  const { teamId } = useRoute(teamRoute);
  const data = useData(
    gql`
      query LeaveTeamDialog($teamId: ID!) {
        team(id: $teamId) {
          id
          name
        }
      }
    `,
    { teamId },
  );
  const leaveTeam = useAction(
    gql`
      mutation LeaveTeamDialog($input: LeaveTeamInput!) {
        leaveTeam(input: $input) {
          clientMutationId
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: gql`
            query {
              teams {
                id
              }
            }
          `,
        },
      ],
    },
  );
  return (
    <FormDialog
      title={`确认离开团队 ${data.team.name}`}
      {...others}
      onSubmit={async () => {
        await leaveTeam({
          input: {
            teamId: data.team.id,
          },
        });
        showMessage({
          message: `成功离开团队 ${data.team.name}`,
        });
        defer(() => history.push("/"));
      }}
    >
      <Message
        message={`该操作可以离开该团队，离开该团队后您将无法再看到团队（${data.team.name}）里的任何信息，如需重新加入团队需要团队管理员的邀请，请谨慎操作。`}
      />
    </FormDialog>
  );
}
