import React from "react";
import { Cog } from "mdi-material-ui";

import { AppView } from "controls";
import { teamRoute } from "helpers";
import { useRoute } from "hooks";

import CreateTeam from "./CreateTeam";
import CloneItemToTeam from "./CloneItemToTeam";
import PersonifyUser from "./PersonifyUser";

export default function SuperadminView() {
  const { teamId } = useRoute(teamRoute);

  return (
    <AppView
      title="超级管理员工具"
      crumbs={[
        {
          icon: <Cog />,
          href: `/${teamId}/superadmin`,
          label: "超级管理员工具",
        },
      ]}
    >
      <CreateTeam />
      <CloneItemToTeam />
      <PersonifyUser />
    </AppView>
  );
}
